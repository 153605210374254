import styles from './modalWrapper.module.scss';

function modalWrapper(props) {
  return (
    <>
      <div className={styles.Backdrop}></div>
      <div className={styles.ModalWrapper}>
        {props.children}
      </div>
    </>
  )
}

export default modalWrapper;