import { useState, useEffect } from "react";
import styles from "./advancedStatistics.module.scss";
// dummy data
import {
  keyStatistics,
  riskReturnHitRatio,
  // bestWorstTrades,
  probabilityGainAfterGain,
  probabilityLossAfterLoss
} from "devData/chartsData/index";
import { bestWorstTrades } from "devData/cryptoChartsData";

// components
import KeyStatistics from "components/advancedStatic/keyStatistics/keyStatistics";
import RiskReturnHitRatio from "components/advancedStatic/riskReturnHitRatio/riskReturnHitRatio.chart";
import BestWorstTrades from "components/advancedStatic/crypto/bestWorstTrades.chart";
import ProbabilityBarChart from "components/advancedStatic/probabilityBarCharts/probabilityBar.chart";

function AdvancedStatistics() {
  const [chartsData, setChartsData] = useState(null);

  useEffect(() => {
    setChartsData({
      keyStatistics,
      riskReturnHitRatio,
      bestWorstTrades,
      probabilityGainAfterGain,
      probabilityLossAfterLoss    
    });
  }, []);

  if (!chartsData) {
    return <div className={styles.Subpage}>Loading Data</div>;
  }

  return (
    <div className={styles.Subpage}>
      <KeyStatistics data={chartsData["keyStatistics"]} />
      <div className={styles.ChartsContainer}>
        <RiskReturnHitRatio
          key="Risk return ratio & hit ratio"
          chartTitle="Risk return ratio & hit ratio"
          chartData={chartsData["riskReturnHitRatio"]}
        />
        <BestWorstTrades 
          key="Best/worst trades"
          chartTitle="Best/worst trades"
          chartData={chartsData["bestWorstTrades"]}          
        />
        <ProbabilityBarChart 
          key="Probability gain after gain"
          chartTitle="Probability gain after gain"
          barValueKey="percentageGain"
          chartData={chartsData["probabilityGainAfterGain"]}                    
        />
        <ProbabilityBarChart 
          key="Probability loss after loss"
          chartTitle="Probability loss after loss"
          barValueKey="percentageLoss"
          chartData={chartsData["probabilityLossAfterLoss"]}                    
        />
      </div>
    </div>
  );
}

export default AdvancedStatistics;
