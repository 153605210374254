const basicProbabilityBarOptions = (barValueKey, chartData) => {
  let annotationColor = barValueKey === "percentageGain" ? "#00FF00" : "#FF0000";
  let annotationLabelColor = barValueKey === "percentageGain" ? "black" : "white";
  return {
    animation: {
      duration: 0,
    },
    interaction: {
      mode: "x",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      autocolors: false,
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: chartData.benchmark,
            yMax: chartData.benchmark,
            borderColor: annotationColor,
            borderWidth: 1,
            borderDash: [1,2],
          },
          label1: {
            drawTime: "afterDraw",
            type: "label",
            xValue: 5,
            yValue: chartData.benchmark,
            color: annotationLabelColor,
            backgroundColor: annotationColor,
            content: ["Avg"],
            font: {
              size: 10,
            },
            height: 0.5,
            padding: {
              left: 3,
              right: 2,
              top: -1,
              bottom: -1,
            },
            borderRadius: 3,
          }
        }
      }
    },
    layout: {
      padding: {
        bottom: 10,
        left: 30,
        top: 60,
        right: 20
      },
    },
    borderRadius: 5,
    maxBarThickness: 15,
    borderWidth: 1,
    scales: {
      y: {
        grace: '5%',
        grid: {
          color: (context) => {
            return '#4D4D4D'
          },
          borderDash: (a) => {
            return [1, 2];
          },
          drawBorder: false,
          tickLength: -20,
          tickBorderDash: (a) => {
            return [1, 2];
          }
        },
        ticks: {
          color: "#999999",
          font: {
            size: 10,
            family: 'Noto Sans',
            weight: 700
          },
          labelOffset: -7,
          padding: 0,
          mirror: true,
          maxTicksLimit: 5,
          callback: function (value, index, ticks) {
            if (index === ticks.length-1) {
              return ["%", value, ""]
            } else {
              return value
            }
          }
        },
      },
      x: {
        grid: {
          offset: false,
          color: '#4D4D4D',
          borderDash: [1, 2],
          borderColor: '#4D4D4D',
          borderWidth: 1,
          tickColor: "#4D4D4D",
          tickLength: 4,
        },
        ticks: {
          padding: 0,
          color: "#999999",
          font: {
            size: 10
          },
        },
      }
    },
  }
};

export default basicProbabilityBarOptions;