import { openModal } from 'redux/slices/modalSlice';
import { useDispatch } from 'react-redux';
import * as modalTypes from 'constants/modalTypes';
import styles from './addTradingAccountReminder.module.scss';
import htFonts from 'shared/htFont.module.scss';

export default function AddTradingAccountReminder() {
  const dispatch = useDispatch();
  const openAddTradingAccountModal = () => {
    dispatch(openModal({
      displayedModal: modalTypes.ADD_TRADING_ACCOUNT_MODAL
    }))
  }

  return (
    <div className={styles.Page}>
      <div>You do not have any trading account linked yet.</div>
      <div>Please add a trading account to start trade analyzing.</div>
      <div 
        className={styles.AddTradingAccountButton}
        onClick={() => openAddTradingAccountModal()}>
        <span className={`${htFonts.htWallet} ${styles.AddTradingAccountIcon}`}></span>
        Add trading account
      </div>
    </div>
  )
}