import PinInput from "react-pin-input";
import { sendEmailVerificationRequest, sendEmailVerificationCode } from 'utils/requests';
import { useState, useEffect } from 'react';
import styles from './emailVerification.module.scss';
import Spinner from "UI/spinner";
import { setAuthenticationState, setUser, setCurrentTradingAccount } from "redux/slices/authenticationSlice";
import { authenticationStates } from 'utils/constant';
import { useDispatch } from "react-redux";
import { closeModal } from 'redux/slices/modalSlice';
import { sendLoginCredential } from 'utils/requests';
import { useNavigate } from 'react-router-dom';

export default function EmailVerification({
  emailVerificationStep,
  setEmailVerificationStep,
  setEmailVerificationCode,
  emailVerificationCode,
  loginCredential,
}) {
  const { email } = loginCredential;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let content;
  const [emailVerificationCredential, setEmailVerificationCredential] = useState(null);
  const [emailVerificationError, setEmailVerificationError] = useState(false);
  const [countDownValue, setCountDownValue] = useState(60);

  useEffect(() => {
    if (emailVerificationStep === 3) {
      (async function() {
        const resp = await sendLoginCredential(loginCredential);
        if (resp.message === 'CORRECT_CREDENTIAL') {
          dispatch(setAuthenticationState({
            authenticationState: authenticationStates.AUTHENTICATED
          }));
          dispatch(setUser({
            user: resp.data
          }))
          if (resp.data.trading_accounts) {
            dispatch(setCurrentTradingAccount({
              currentTradingAccount: resp.data.trading_accounts[0]
            }));
          }
        }
        dispatch(closeModal({
          displayedModal: null,
        }));
        return navigate('/dashboard/analysis');
      })();
    }
  }, [emailVerificationStep, loginCredential, dispatch, navigate]);

  useEffect(() => {
    let interval;
    if (countDownValue > 0 && emailVerificationStep === 2) {
      interval = setInterval(() => {
        setCountDownValue(countDownValue - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [countDownValue, emailVerificationStep]);

  const requestEmailVerification = async (email) => {
    setCountDownValue(60);
    const result = await sendEmailVerificationRequest(email);
    if (result.message === 'success') {
      setEmailVerificationStep(2);
      setEmailVerificationCredential(result.data);
    }
  }

  const validateEmailVerificationCode = async (email, emailVerificationCredential) => {
    const result = await sendEmailVerificationCode(emailVerificationCode, emailVerificationCredential);
    if (result.message === 'success') {
      setEmailVerificationStep(3);
    } else {
      setEmailVerificationError(true);
    }
  }

  if (emailVerificationStep === 1) {
    content = (
      <div>
        <div style={{ color: "#4D4D4D", fontWeight: 'bold' }}>Please verify your email</div>
        <div 
          onClick={() => requestEmailVerification(email)}
          className={styles.SendButton}>
          Send
        </div>
      </div>
    )
  }

  if (emailVerificationStep === 2) {
    const inputStyle = emailVerificationError?
      {
        borderColor: "red",
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        marginRight: "10px",
        fontSize: "20px",
        fontWeight: "700",
        color: 'red'
      } :
      {
        borderColor: "#FFFFFF",
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        marginRight: "10px",
        fontSize: "20px",
        fontWeight: "700",
      };
    content = (
      <div>
        <div className={styles.SubTitle}>Please enter verification code.</div>
        <PinInput
          length={4}
          initialValue=""
          onChange={(value, index) => {}}
          type="numeric"
          inputMode="number"
          style={{
            width: "80%",
            alignContent: "center",
            margin: 'auto'
          }}
          inputStyle={inputStyle}
          onComplete={(value, index) => {
            setEmailVerificationCode(value);
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/} />
        {
          emailVerificationError?
          <div 
            style={{
              color: 'red'
            }}>
            Wrong email verification code.
          </div> : null
        }
        {
          countDownValue > 0?
          <div style={{ color: '#999999', fontWeight: 'bold', marginTop: '20px', textAlign: 'right', width: '90%'} }>
            Send again in {countDownValue}s
          </div> :
          <div 
            className={styles.SendAgain}
            onClick={() => requestEmailVerification(email, emailVerificationCredential)}>
            <u>Resend</u>
          </div>

        }
        <div 
          className={styles.ConfirmButton}
          onClick={() => validateEmailVerificationCode(email, emailVerificationCredential)}>
          Confirm
        </div>
      </div>
    )
  }

  if (emailVerificationStep === 3) {
    content = (
      <div>
        <div>Successfully verified email.</div>
        <div style={{ marginBottom: 20}}>Redirecting to Dashboard</div>
        <Spinner />
      </div>
    )
  }

  return (
    <div className={styles.Container}>
      { content }
    </div>
  )
}