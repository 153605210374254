const responsiveTradeMedicChartKeys = {
  //General page
  PROFIT_BY_DAYTIME_PER_TRADE: "profit_by_daytime_per_trade",
  PROFIT_BY_WEEKDAY_PER_TRADE: "profit_by_weekday_per_trade",
  //Behavior page
  TRADES_PER_DAY_PER_TRADE: "trades_per_day_per_trade",
  BREAK_SINCE_LAST_TRADE_PER_TRADE: "break_since_last_trade_per_trade",
  BREAK_AFTER_LOSS_TRADE_PER_TRADE: "break_after_loss_trade_per_trade",
  OPEN_DIFFERENT_SYMBOLS_PER_TRADE: "open_different_symbols_per_trade",
  PROFIT_AFTER_DAILY_PROFIT_LOSS: "profit_after_daily_profit_loss",
  //Timing page
  PROFIT_IF_TRADE_OPEN_EARLIER_LATER: "profit_if_trade_open_earlier_later",
  PROFIT_IF_TRADE_CLOSE_EARLIER_LATER: "profit_if_trade_close_earlier_later",
  PROFIT_IF_TP_CLOSER_FURTHER_AWAY: "profit_if_tp_closer_further_away",
  PROFIT_IF_SL_CLOSER_FURTHER_AWAY: "profit_if_sl_closer_further_away",
  PROFIT_IF_MANUAL_STOP_BEFORE_TP: "profit_if_manual_stop_before_tp",
  //Risk Management page
  RISK_AFTER_LOSING_TRADES_PER_TRADE: "risk_after_losing_trades_per_trade",
  PROFIT_IF_ADDING_TO_NEGATIVE_POSITIONS: "profit_if_adding_to_negative_positions",
  PROFIT_SECURE_PAYOFF_FROM_REVERTING_TRADES: "profit_secure_payoff_from_reverting_trades",
  RISK_PER_TRADE_LAST_TRADES: "risk_per_trade_last_trades",
  MAX_RISK_ALL_OPEN_TRADES_LAST_TRADES: "max_risk_all_open_trades_last_trades",
  PERCENTAGE_OF_POSITION_WITH_SL: "percentage_of_position_with_sl",
  //Strategy page
  PROFIT_HIGH_VS_LOW_VOLATILITY: "profit_high_vs_low_volatility",
  PROFIT_WITH_AGAINST_ST_MT_TREND: "profit_with_against_st_mt_trend",
  AVERAGE_PAYOFF_WIN_VS_LOSS_TRADE: "average_payoff_win_vs_loss_trade",
  PROFIT_FOR_TRADE_DURING_OVERBOUGHT_OVERSOLD: "profit_for_trade_during_overbought_oversold",
  //Cost page
  PROFIT_OF_CONFLICTING_SYMBOLS: "profit_of_conflicting_symbols",
}

export default responsiveTradeMedicChartKeys;