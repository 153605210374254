import { chartKeys } from 'utils/constant';

const profit_for_trade_during_overbought_oversold = {
  chartTitle:
    "Profit with(+) & against(-) short-term(ST) & medium-term(MT) trend",
  chartKey: chartKeys.PROFIT_FOR_TRADE_DURING_OVERBOUGHT_OVERSOLD,
  hoverTextArray: {
    usd: [
      {
        templateString: "",
        valueKey: "usd",
        unit: "USD/trade",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "usdBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        },
        unit: ""
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "percentageBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
  },
  haspercentageBenchmark: true,
  interval: [
    'against',
    'outside of',
    'along',
  ],
}

export default profit_for_trade_during_overbought_oversold;