const balance = {
  chartTitle: "Balance (USD)",
  999: [
    {
      time: new Date("2022-04"),
      equity: 9455,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2022-05"),
      equity: 8666.45,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2022-06"),
      equity: 8179.86,
      depositWithdrawal: -1000,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2022-07"),
      equity: 10068.63,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2022-08"),
      equity: 9715.35,
      depositWithdrawal: -1000,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2022-09"),
      equity: 11498.23,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2022-10"),
      equity: 11289.27,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2022-11"),
      equity: 14217.75,
      depositWithdrawal: 2000,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2022-12"),
      equity: 12843.67,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2023-01"),
      equity: 12850.29,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2023-02"),
      equity: 13421.37,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2023-03"),
      equity: 13408.14,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
    {
      time: new Date("2023-04"),
      equity: 14980.50,
      depositWithdrawal: 0,
      duration: "1Y 6M",
      equityChange: 43.77,
    },
  ]
};


export default balance;