import Spinner from "UI/spinner";
import React, { Children, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getChartsData, getCurrentTradingAccount, getLastChartUpdatedAt, getIsDataFetched } from "redux/selectors";
import styles from './index.module.scss';
import { createDataObjectForChart } from "utils/helpers/chart";
import chartKeys from 'utils/constant/chartKeys';
import { useLocation } from 'react-router-dom';
import { chartKeysByPage } from "utils/constant";
import { createIsChartsDataAvailable } from "utils/helpers/chart";

function SubPageWrapper({ children }) {
  const location = useLocation();
  const subPathArray = location.pathname.split('/');
  const currentPath = subPathArray[subPathArray.length - 1];
  const chartsKeyArray = Object.values(chartKeys);
  const chartKeysOnCurrentPath = chartKeysByPage[currentPath];
  const chartsData = useSelector(getChartsData(chartKeysOnCurrentPath));

  const isDataFetched = useSelector(getIsDataFetched);

  const chartsAvailable = createIsChartsDataAvailable(chartsData, chartKeysOnCurrentPath);
  let chartDataObjects = {};
  chartKeysOnCurrentPath.forEach(chartKey => {
    chartDataObjects[chartKey] = createDataObjectForChart(chartKey, chartsData[chartKey]);
  });

  if (!isDataFetched) {
    return (
      <div className={styles.Subpage}>
        <div style={{position: "absolute", top: '50%', left: '50%', transform: "translate(-50%, -50%)"}}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (!chartsAvailable && isDataFetched) {
    return (
      <div style={{ margin: "40px auto", fontWeight: 'bold', color: "#4D4D4D", gridColumn: '1/3'}}>
        We're having issue fetching charts' data. <a href='https://discord.gg/xv5zdDae'>Please contact our support</a>.
      </div>
    ); 
  }

  const renderChildren = () => {
    return Children.map(children, (child) => {
      return React.cloneElement(child, {
        chartKeysOfThePage: chartsKeyArray,
        chartDataObjects
      });
    })
  };
  
  return (
    <>
      { renderChildren() }
    </>
  );
}

export default SubPageWrapper;