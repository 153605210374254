import { chartKeys } from "utils/constant";

const trades_per_day_per_trade = {
  chartTitle: "Trades per day (per trade)",
  chartKey: chartKeys.TRADES_PER_DAY_PER_TRADE,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'usdBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        },
        unit: " "
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ]
  },
  interval: [
    '1-3',
    '4-6',
    '7-10',
    '11-15',
    '16-25',
    '25+'
  ],
}

export default trades_per_day_per_trade;