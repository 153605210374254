import { Routes, Route, Navigate } from "react-router-dom";
import AnalysisIndex from "./analysis/index.page";
import AccountOverviewIndex from "./overview/index.page";
import Alert from "./alert.page";
import TradeMedic from "./tradeMedic.page";
import { useSelector } from "react-redux";
import { getIsDataFetched, getUser } from "redux/selectors";
import AddTradingAccountReminder from "./addTradingAccountReminder.page";
import DashboardFooter from "components/footer/dashboardFooter";
import { getCurrentTradingAccount } from "redux/selectors/index";
//css
import styles from "./dashboard.module.scss";
// crypto
import CryptoAnalysisIndex from "./cryptoAnalysis/index.page";
import CryptoOverviewIndex from "./cryptoOverview/index.page";
import { useEffect } from "react";
import { fetchAllChartsData } from "utils/requests";
import chartKeys from 'utils/constant/chartKeys';
import { useDispatch } from "react-redux";
import { setChartsData, setIsDataFetched } from "redux/slices/chartSlices";

function Dashboard(props) {
  const user = useSelector(getUser);
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const dispatch = useDispatch();
  const isDataFetched = useSelector(getIsDataFetched);

  useEffect(() => {
    // if (isChartsDataFetched === false) {
    if (currentTradingAccount) {
      const chartsKeyArray = Object.values(chartKeys);
      (async function () {
        const result = await fetchAllChartsData(currentTradingAccount.trading_account_id);
        if (result.data) {
          if (!isDataFetched) {
            dispatch(
              setIsDataFetched({
                isDataFetched: true
              })
            );
          }
          dispatch(
            setChartsData({
              chartsData: result.data,
            })
          );
        } else {
          // setChartsDataError(true);
          if (!isDataFetched) {
            dispatch(
              setIsDataFetched({
                isDataFetched: true
              })
            );
          }
          console.error('fetching data failed');
        }
      })();
    }
    // }
  }, [dispatch, currentTradingAccount]);

  const content = user.trading_accounts.length ? (
    <Routes>
      <Route path="/trade-medic/*" element={<TradeMedic />} />
      <Route path="/alert/*" element={<Alert />} />
      <Route
        path="/analysis/*"
        element={
          currentTradingAccount.account_type === "crypto" ? (
            <CryptoAnalysisIndex />
          ) : (
            <AnalysisIndex currentTradingAccount={currentTradingAccount}/>
          )
        }
      />
      <Route
        path="/overview/*"
        element={
          currentTradingAccount.account_type === "crypto" ? (
            <CryptoOverviewIndex />
          ) : (
            <AccountOverviewIndex />
          )
        }
      />
      <Route
        path="/*"
        element={<Navigate to="/dashboard/analysis/general" replace />}
      />
    </Routes>
  ) : (
    // <ProtectedRoute>
    //   { props.children }
    // </ProtectedRoute>
    <AddTradingAccountReminder />
  );

  return (
    <>
      <div className={styles.Dashboard}>
        {/* <Routes>
        <Route path="/analysis/*" element={<AnalysisIndex />} />
        <Route path="/overview/*" element={<AccountOverviewIndex />} />
      </Routes> */}
        {content}
      </div>
      <DashboardFooter />
    </>
  );
}

export default Dashboard;
