let profit = [];
let profitValue = 11;

for (let i = 0; i<365; i++) {
  let date = new Date();
  date.setDate(date.getDate() + i);
  const topping = Math.random().toFixed(2) > 0.5;

  if (topping) {
    profitValue += 0.36;
  } else {
    profitValue += 0;
  }

  profit[i] = {
    date: date,
    trades: profitValue,
    lots: profitValue,
  }
};


let loss = [];
let lossValue = 60;
for (let i = 0; i<365; i++) {
  let date = new Date();
  date.setDate(date.getDate() + i);
  const topping = Math.random().toFixed(2) > 0.5;

  if (topping) {
    lossValue += 0.17;
  } else {
    lossValue += 0;
  }

  loss[i] = {
    date: date,
    trades: lossValue,
    lots: lossValue
  }
};

let insiginificant = [];
for (let i = 0; i<365; i++) {
  let date = new Date();
  date.setDate(date.getDate() + i);

  insiginificant[i] = {
    date: date,
    trades: 100,
    lots: 100,
  }
};

const tradesPattern = {
  chartTitle: 'Trades made in profit/loss making patterns',
  999: {
    profit: profit,
    loss: loss,
    insiginificant: insiginificant
  },
  lastestData: {
    999: {
      profit: {
        patterns: 69,
        pips: 4395,
        profit: 24859.42,
        breakSinceLastTrade: 3431,
        riskPerTrade: 2745,
        profitBySymbol: 1700,
        positionWithSL: 1029,
        profitIfTradeOpen: 343
      },
      loss: {
        patterns: 53,
        pips: -3249,
        profit: -19485.43,
        profitByDaytime: 3131,
        riskPerTrade: 2945,
        profitBySymbol: 1900,
        positionWithSL: 1029,
        profitIfTradeOpen: 143
      },
      insignificant: {
        patterns: 23,
        // pips: 3431,
        // usd: 2877000
      }
    }
  },

  // hoverTextArray: {
  //   'Trades': [
  //     {
  //       templateString: '',
  //       valueKey: 'pips',
  //       unit: 'pips',
  //       textColor: function(hoveredData) {
  //         return hoveredData.pips >= 0? "#56A07E" : "#C15357"
  //       }
  //     },
  //     {
  //       templateString: '',
  //       valueKey: 'profit',
  //       textColor: function(hoveredData) {
  //         return hoveredData.profit >= 0? "#56A07E" : "#C15357"
  //       },
  //       unit: ' USD'
  //     },
  //     {
  //       templateString: 'Trades: ',
  //       valueKey: 'trades',
  //     },
  //     {
  //       templateString: 'B: ',
  //       valueKey: 'pipsBenchmark',
  //       createLabelColor: function() {
  //         return '#FFB700';
  //       },
  //       textColor: function() {
  //         return '#FFB700';
  //       }
  //     }
  //   ],
  //   'Lots': [
  //     {
  //       templateString: '',
  //       valueKey: 'percentage',
  //       unit: '%',
  //       textColor: function(hoveredData) {
  //         return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
  //       }
  //     },
  //     {
  //       templateString: '',
  //       valueKey: 'profit',
  //       textColor: function(hoveredData) {
  //         return hoveredData.profit >= 0? "#56A07E" : "#C15357"
  //       },
  //       unit: ' USD'
  //     },
  //     {
  //       templateString: 'Trades: ',
  //       valueKey: 'trades',
  //     },
  //     {
  //       templateString: 'B: ',
  //       valueKey: 'percentageBenchmark',
  //       createLabelColor: function() {
  //         return '#FFB700';
  //       },
  //       textColor: function() {
  //         return '#FFB700';
  //       }
  //     }
  //   ]
  // },
}

export default tradesPattern;