import { useDispatch} from 'react-redux';
import { useState } from 'react';
import styles from './index.module.scss';
import { useEffect } from "react";
import { validateResetPasswordToken, sendResetPasswordEmail, sendNewPassword } from "utils/requests";
import Spinner from 'UI/spinner';
import * as modalTypes from 'constants/modalTypes';
import InputWithDynamicLabel from "components/inputWithDynamicLabel";
//asset
import unCheckIcon from "assets/unCheckedIcon.svg";
import checkedIcon from "assets/checkedIcon.svg";
import { updateModal } from "redux/slices/modalSlice"
import { useLocation, useNavigate } from 'react-router-dom';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

function ResetPasswordModal({
  modalData
}) {
  const [userEmail, setUserEmail] = useState("");
  const [tokenStatus, setTokenStatus] = useState("PENDING");
  const [emailInput, setEmailInput] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [newPasswordAllowed, setNewPasswordAllowed] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
    pwValidators: {
      eightCharacters: false,
      upperCase: false,
      lowerCase: false,
      number: false,
      allowedSymbols: false,
    }
  });
  const [passwordResetSuccessfully, setPasswordResetSuccessfully] = useState(false);
  const dispatch = useDispatch();
  const debounceNewPasswordInput = useDebounce(formData.password);
  const location = useLocation();
  const query = location.search;
  const queryStrings = query.replace("?", "").split("&");
  const token = queryStrings[0].replace("t=", "");
  const navigate = useNavigate();

  useEffect(() => {
    (async function() {
      if (token) {
        const resp = await validateResetPasswordToken(token);
        if (resp.data) {
          setUserEmail(resp.data.email);
          setTokenStatus("VALID_TOKEN");
        }
        if (resp.message === "INVALID_TOKEN") {
          setTokenStatus("INVALID_TOKEN");
        }
      }
    })();
  }, []);

  const checkValidEmail = (debouncedEmailInput) => {
    const validEmailRegExp = RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    return validEmailRegExp.test(debouncedEmailInput);
  };

  useEffect(() => {
    if (debounceNewPasswordInput) {
      const passwordValidators = validatePassword(debounceNewPasswordInput);
      setFormData({
        ...formData,
        pwValidators: passwordValidators
      });
    }
  }, [debounceNewPasswordInput]);

  useEffect(() => {
    let isNewPasswordValid = true;
    for (let key in formData.pwValidators) {
      if (!formData.pwValidators[key]) {
        isNewPasswordValid = false;
      }
    }

    if (formData.passwordConfirmation === formData.password && isNewPasswordValid) {
      setNewPasswordAllowed(true);
    }
  }, [formData])

  const submitEmailForResetPasswordLink = async (email) => {
    const isEmailValid = checkValidEmail(emailInput);
    if (!isEmailValid) {
      return setEmailValidationError(true);
    }

    setResetEmailSent("PENDING");
    const result = await sendResetPasswordEmail(email);
    setResetEmailSent("SENT");
  };

  const goToResendEmail = () => {
    dispatch(updateModal({
      displayedModal: modalTypes.RESET_PASSWORD_MODAL,
      modalData: {
        type: "ASK_FOR_EMAIL"
      }
    }));
  };

  const newPasswordOnSubmit = async (token, newPassword) => {
    const result = await sendNewPassword(token, newPassword);
    if (result.message === 'SUCCESS') {
      setPasswordResetSuccessfully(true);
    }
    if (result.message === 'INVALID_TOKEN') {
      setPasswordResetSuccessfully(false);
      setTokenStatus('INVALID_TOKEN');
    }
  }

  const goSignIn = () => {
    navigate('/login')
  }

  const validatePassword = (password) => {
    let validators = {
      eightCharacters: false,
      upperCase: false,
      lowerCase: false,
      number: false,
      allowedSymbols: false,
    };

    const allowedSymbolsRegExp = RegExp("[@#$%]");
    validators.allowedSymbols = allowedSymbolsRegExp.test(password);
    const atLeastOneDigitRegExp = RegExp("[0-9]");
    validators.number = atLeastOneDigitRegExp.test(password);
    const lowerCaseRegExp = RegExp("[a-z]");
    validators.lowerCase = lowerCaseRegExp.test(password);
    const upperCaseRegExp = RegExp("[A-Z]");
    validators.upperCase = upperCaseRegExp.test(password);

    if (password.length >= 8) {
      validators.eightCharacters = true;
    } else {
      validators.eightCharacters = false;
    }

    return validators;
  };

  let content;
  if (token) {
    if (tokenStatus === "PENDING") {
      content = (
        <div className={styles.SpinnerContainer}>
            <Spinner />
        </div>
      )
    }
    if (tokenStatus === "INVALID_TOKEN") {
      content = (
        <div style={{ margin: '40px auto', color: '#4D4D4D', width: '80%'}}>
          <div>Invalid Reset Password link</div>
          <div
            className={styles.ResendEmailButton} 
            onClick={() => goToResendEmail()}>Resend email</div>
        </div>
      )
    }
    if (tokenStatus === "VALID_TOKEN") {
      content = (
        <div style={{ width: "80%", margin: "40px auto"}}>
          <div>
            {userEmail}
          </div>
          <div>
            <div className={styles.validationSection}>
              <div className={styles.checkStatusTitle}>
                Setup password, it MUST be:
              </div>
              <div className={styles.checkStatus}>
                {formData.pwValidators.eightCharacters ? (
                  <img src={checkedIcon} alt="icon" />
                ) : (
                  <img src={unCheckIcon} alt="icon" />
                )}
                <div> at least 8 characters</div>
              </div>
              <div className={styles.checkStatus}>
                {formData.pwValidators.upperCase ? (
                  <img src={checkedIcon} alt="icon" />
                ) : (
                  <img src={unCheckIcon} alt="icon" />
                )}
                <div> uppercase</div>
              </div>
              <div className={styles.checkStatus}>
                {formData.pwValidators.lowerCase ? (
                  <img src={checkedIcon} alt="icon" />
                ) : (
                  <img src={unCheckIcon} alt="icon" />
                )}
                <div> lowercase</div>
              </div>
              <div className={styles.checkStatus}>
                {formData.pwValidators.number ? (
                  <img src={checkedIcon} alt="icon" />
                ) : (
                  <img src={unCheckIcon} alt="icon" />
                )}
                <div> number</div>
              </div>
              <div className={styles.checkStatus} id={styles.checkStatusFive}>
                {formData.pwValidators.allowedSymbols ? (
                  <img src={checkedIcon} alt="icon" />
                ) : (
                  <img src={unCheckIcon} alt="icon" />
                )}
                <div> any following symbols: @ # $ %</div>
              </div>
            </div>
            <div>
              <InputWithDynamicLabel 
                type="PASSWORD"
                placeholder="New Password"
                inputTitle="New Password"
                value={formData.password}
                onChangeHandler={(value) => setFormData({...formData, password: value })} />
            </div>
            <div>
              <InputWithDynamicLabel 
                type="PASSWORD"
                placeholder="Re-enter New Password"
                inputTitle="Re-enter"
                value={formData.passwordConfirmation}
                onChangeHandler={(value) => setFormData({...formData, passwordConfirmation: value })} />
            </div>
            <div>
              {
                newPasswordAllowed?
                  <div 
                    onClick={() => newPasswordOnSubmit(token, formData.password)}
                    className={`${styles.ResetPasswordButton} ${styles.active}`}>
                    Reset password
                  </div> 
                : <div className={styles.ResetPasswordButton}>Reset password</div>
              }
            </div>
          </div>
        </div>
      )
    }
    if (passwordResetSuccessfully) {
      content = (
        <div className={styles.ResetSuccessSection}>
          <div>Password reset successfully</div>
          <div className={styles.CheckMarkContainer}>
            <img src={checkedIcon} />
          </div>
          <div 
            onClick={() => goSignIn()}
            className={styles.SignInButton}>
            Sign In
          </div>
        </div>
      )
    }
  }
  if (!token) {
    if (!resetEmailSent) {
      content = (
        <div className={styles.ForgetPasswordSection}>
          <div><strong>Forget Password</strong></div>
          <div>Enter your registered email to receive reset password link.</div>
          <div className={styles.InputWrapper}>
            {
              emailInput?
                <div className={styles.InputLabel}>Email</div> : null
            }
            <input 
              placeholder="Email"
              type="email"
              className={emailInput? styles.ActualInput : styles.InputTemplate }
              value={emailInput} 
              onChange={(e) => {
                setEmailValidationError(false);
                setEmailInput(e.target.value);
              }} />
          </div>
          {
            emailValidationError?
            <div style={{ color: "red", marginTop: '6px'}}>Please enter valid email.</div> : null
          }
          {
            !emailValidationError && emailInput?
            <div 
              className={`${styles.SubmitButton} ${styles.active}`}
              onClick={() => submitEmailForResetPasswordLink(emailInput)}>
              Submit
            </div> :
            <div 
              className={styles.SubmitButton}
              onClick={() => submitEmailForResetPasswordLink(emailInput)}>
              Submit
            </div>
          }
          <div className={styles.GoToSignIn}>
            <u onClick={() => navigate('/login')}>Sign In</u>
          </div>
        </div>
      );
    } 
    if (resetEmailSent === "PENDING") {
      content = (
        <div className={styles.SpinnerContainer}>
          <Spinner />
        </div>
      );
    }
    if (resetEmailSent === "SENT") {
      content = (
        <div style={{ color: "#4D4D4D", width: "80%", margin: "40px auto"}}>
          <div>The email is sent.</div>
          <div>Please check your email account.</div>
        </div>
      );
    }
  }

  return (
    <div>
      { content }
    </div>
  )
}

export default ResetPasswordModal;