import { useState } from "react";
import SidebarHoverMenu from "UI/sidebarHoverMenu";

import { sidebarItemsList } from "./sidebarItemsList";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import styles from "./sidebar.module.scss";
import htFonts from "shared/htFont.module.scss";

function Sidebar(props) {
  const [hoverMenu, setHoverMenu] = useState(false);
  const location = useLocation();
  const secondLayer = location.pathname.split("/")[2];

  return (
    <div className={styles.Sidebar}>
      <ul className={styles.SidebarItemContainer}>
        <li
          className={
            secondLayer === "overview"
              ? `${styles.SidebarItem} ${styles.Selected}`
              : `${styles.SidebarItem}`
          }
          onMouseOver={() => {
            setHoverMenu("overview");
          }}
          onMouseLeave={() => {
            setHoverMenu(false);
          }}
        >
          <span className={`${htFonts.htDashboard} ${styles.Icon}`}></span>
          {hoverMenu === "overview" ? (
            <SidebarHoverMenu
              menuTitle={sidebarItemsList[hoverMenu].title}
              items={sidebarItemsList[hoverMenu].itemsList}
            />
          ) : null}
        </li>
        <li
          className={
            secondLayer === "analysis"
              ? `${styles.SidebarItem} ${styles.Selected}`
              : `${styles.SidebarItem}`
          }
          onMouseOver={() => {
            setHoverMenu("analysis");
          }}
          onMouseLeave={() => {
            setHoverMenu(false);
          }}
        >
          <span className={`${htFonts.htBarChart} ${styles.Icon}`}></span>
          {hoverMenu === "analysis" ? (
            <SidebarHoverMenu
              menuTitle={sidebarItemsList[hoverMenu].title}
              items={sidebarItemsList[hoverMenu].itemsList}
            />
          ) : null}
        </li>
        <li
          className={
            secondLayer === "trade-medic"
              ? `${styles.SidebarItem} ${styles.Selected}`
              : `${styles.SidebarItem}`
          }
        >
          <Link to="/dashboard/trade-medic" id={styles.alertIcon}>
            <span className={`${htFonts.htMedbox} ${styles.Icon}`} id={secondLayer === "trade-medic"
              ? `${styles.Selected}`
              : null}></span>
          </Link>
        </li>
        <li
          className={
            secondLayer === "alert"
              ? `${styles.SidebarItem} ${styles.Selected}`
              : `${styles.SidebarItem}`
          }
        >
          <Link to="/dashboard/alert" id={styles.alertIcon}>
            <span className={`${htFonts.htBell} ${styles.Icon}`} id={secondLayer === "alert"
              ? `${styles.Selected}`
              : null}></span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
