import { chartKeys } from 'utils/constant';

const profit_by_daytime_per_trade = {
  chartTitle: "Profit by daytime (per trade)",
  chartKey: chartKeys.PROFIT_BY_DAYTIME_PER_TRADE,
  hoverTextArray: {
    'pips': [
      {
        templateString: '',
        valueKey: 'pips',
        unit: 'pips',
        followBarColor: true
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'pipsBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ]
  },
  createHoverTextArray: (currency) => {
    return {
      'pips': [
        {
          templateString: '',
          valueKey: 'pips',
          unit: 'pips',
          followBarColor: true
        },
        {
          templateString: '',
          valueKey: 'profit',
          followBarColor: true,
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'pipsBenchmark',
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        }
      ],
      'percentage': [
        {
          templateString: '',
          valueKey: 'percentage',
          unit: '%',
          followBarColor: true
        },
        {
          templateString: '',
          valueKey: 'profit',
          followBarColor: true,
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'percentageBenchmark',
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        }
      ]
    }
  },
  interval: [
    'MN-0400',
    '0400-0800',
    '0800-NN',
    'NN-1600',
    '1600-2000',
    '2000-MN'
  ],
}

export default profit_by_daytime_per_trade;