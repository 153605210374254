import createBarColorComparison from "components/charts/helpers/createBarColorComparison";
import createHoverBarColorComparison from "components/charts/helpers/createHoverBarColorComparison";
import createBarBorderColorComparison from "components/charts/helpers/createBarBorderColorComparison";
import { chartKeys } from 'utils/constant';

const profit_high_vs_low_volatility = {
  chartTitle: "Profit high vs. low volatility",
  chartKey: chartKeys.PROFIT_HIGH_VS_LOW_VOLATILITY,
  createBarColorComparison: createBarColorComparison,
  createHoverBarColorComparison: createHoverBarColorComparison,
  createBarBorderColorComparison: createBarBorderColorComparison,
  hoverTextArray: {
    pips: [
      {
        templateString: '',
        valueKey: 'pips',
        unit: 'pips',
        // textColor: function(hoveredData) {
        //   return hoveredData.pips >= 0? "#56A07E" : "red"
        // }
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        unit: 'USD',
        followBarColor: true,
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ],
    percentage: [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        // textColor: function(hoveredData) {
        //   return hoveredData.percentage >= 0? "#56A07E" : "red"
        // }
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        unit: 'USD',
        followBarColor: true,
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ]
  },
  createHoverTextArray: (currency) => {
    return {
      pips: [
        {
          templateString: '',
          valueKey: 'pips',
          unit: 'pips',
          // textColor: function(hoveredData) {
          //   return hoveredData.pips >= 0? "#56A07E" : "red"
          // }
          followBarColor: true,
        },
        {
          templateString: '',
          valueKey: 'profit',
          unit: currency,
          followBarColor: true,
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: "B: ",
          valueKey: "pipsBenchmark",
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        }
      ],
      percentage: [
        {
          templateString: '',
          valueKey: 'percentage',
          unit: '%',
          // textColor: function(hoveredData) {
          //   return hoveredData.percentage >= 0? "#56A07E" : "red"
          // }
          followBarColor: true,
        },
        {
          templateString: '',
          valueKey: 'profit',
          unit: currency,
          followBarColor: true,
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: "B: ",
          valueKey: "percentageBenchmark",
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        }
      ]
    }
  },
  interval: [
    'High',
    'Low',
  ],
}

export default profit_high_vs_low_volatility;