const chartKeyWithNameMap = {
  profit_by_daytime_per_trade: "Profit By Day Time (per trade)",
  long_and_short_per_trade: "Long & Short (per trade)",
  profit_by_symbol_per_trade: "Profit By Symbol (per trade)",
  profit_by_trade_length_per_trade: "Profit By Trade Length (per trade)",
  profit_by_weekday_per_trade: "Profit By Weekday (per trade)",
  trades_per_day_per_trade: "Trades Per Day (per trade)",
  break_since_last_trade_per_trade: "Break Since Last Trade (per trade)",
  break_after_loss_trade_per_trade: "Break After Loss Trade (per trade)",
  open_different_symbols_per_trade: "Open Different Symbols (per trade)",
  open_position_in_profit_loss: "Open Position In Profit/Loss",
  profit_after_daily_profit_loss: "Profit After Daily Profit/Loss",
  top_5_patterns_behavior: "Top 5 profit and loss patterns Behavior",
  profit_if_trade_open_earlier_later: "Profit If Trade Open Earlier/Later",
  profit_if_trade_close_earlier_later: "Profit If Trade Close Earlier/Later",
  profit_if_tp_closer_further_away: "Profit If TP Closer/Further Away",
  profit_if_sl_closer_further_away: "Profit If SL Closer/Further Away",
  profit_if_manual_stop_before_tp: "Profit if Manual Stop Before TP",
  percent_of_trades_stopped_early_in_profit: "Percent Of Trades Stopped Early In Profit",
  top_5_patterns_timing: "Top 5 profit and loss patterns Timing",
  risk_after_losing_trades_per_trade: "Risk After Losing Trade(s) (per trade)",
  risk_after_losing_trades_open_trade: "Risk After Losing Trade(s) (open trade)",
  profit_if_adding_to_negative_positions: "Profit If Adding To Negative Position(s)",
  profit_secure_payoff_from_reverting_trades: "Profit Secure, Payoff From Reverting Trades",
  risk_per_trade_last_trades: "Risk Per Trade (last trades)",
  max_risk_all_open_trades_last_trades: "Max Risk All Open Trades (last trades)",
  percentage_of_position_with_sl: "% Of Position With SL",
  top_5_patterns_risk: "Top 5 profit and loss patterns Risk",
  profit_high_vs_low_volatility: "Profit High vs. Low Volatility",
  profit_with_against_st_mt_trend: "Profit With(+) & Against(-) Short-Term(ST) & Medium-Term(MT) trend",
  profit_when_sl_adjusted_vs_profit_when_not: "Profit When SL Adjusted vs. Profit When Not",
  profit_if_publishing_trades: "Profit If Publishing Trades",
  profit_after_withdrawal_in_pips: "Profit After Withdrawal (in pips)",
  average_payoff_win_vs_loss_trade: "Average Payoff Win vs. Loss Trade",
  profit_for_trade_during_overbought_oversold: "Profit For Trade During Overbought/Oversold",
  top_5_patterns_strategic: "Top 5 profit and loss patterns Strategic",
  spread_and_commission: "Spread & Commission",
  profit_of_conflicting_symbols: "Profit Of Conflicting Symbols",
  general_info: "General info",
  trades_in_patterns_timeview: "Trades made in profit/loss making patterns (timeview)",
  trades_in_patterns_count: "Trades made in profit/loss making patterns (count)",
  performance_over_time: "Performance over time",
  drawdown_balance: "Drawdown (balance)",
  balance_currency: "Balance (currency)",
  performance_pips: "Performance (pips)",
  symbols: "Symbols",
  performance_months: "Performance by month",
  average_pips_per_trade: "Average pips per trade",
  average_trade_length: "Average trade length (mins)",
  average_trades_per_day: "Average trades per day",
  key_statistics: "Key statistics",
  return_risk_ratio_hit_ratio: "Return Risk Ratio & Hit Ratio",
  best_worst_trade: "Best/ worst trades",
  probability_gain_after_gain: "Probability gain after gain",
  probability_loss_after_loss: "Probability loss after loss",
};

export default chartKeyWithNameMap;