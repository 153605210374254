import styles from "./dashboardFooter.module.scss";
import htFonts from "shared/htFont.module.scss";

function DashboardFooter() {
  return (
    <div className={styles.background}>
      <div className={styles.footerContainer}>
        <div className={styles.footerTop}>
          <div className={styles.footerCopyright}>
            <span className={`${htFonts.htLogo} ${styles.logo}`}></span>
            <strong style={{ marginLeft: "5px", marginRight: "10px" }}>
              hoc-trade
            </strong>
            © 2023 hoc-trade limited, all rights reserved
          </div>
          <div className={styles.footerLinks}>
            <a href="https://www.hoc-trade.com/terms-and-conditions" target="_blank" rel="noreferrer">
              <div className={styles.linkBlock}>Terms & conditions</div>
            </a>
            <a href="https://www.hoc-trade.com/privacy" target="_blank" rel="noreferrer">
              <div className={styles.linkBlock} id={styles.linkBlockTwo}>
                Privacy policy
              </div>
            </a>
            <a href="https://www.hoc-trade.com/about" target="_blank" rel="noreferrer">
              <div className={styles.linkBlock} id={styles.linkBlockThree}>
                About us
              </div>
            </a>
            <a href="https://discord.com/invite/hcvytJnb5U" target="_blank" rel="noreferrer nooopener">
              <div className={styles.linkBlock} id={styles.linkBlockFour}>
                Contact us
              </div>
            </a>
          </div>
        </div>
        <div className={styles.footerSocial}>
            <a href="https://www.facebook.com/hoctradeanalytics" target="_blank" rel="noreferrer nooopener">
              <div
                className={`${htFonts.htFbIcon} ${styles.socialIcon}`}
              ></div>
            </a>
            <a href="https://twitter.com/hoctrade" target="_blank" rel="noreferrer nooopener">
              <div
                className={`${htFonts.htTwitterIcon} ${styles.socialIcon}`}
              ></div>
            </a>
            <a href="https://www.linkedin.com/company/hoc-trade/" target="_blank" rel="noreferrer nooopener">
              <div
                className={`${htFonts.htLinkedInIcon} ${styles.socialIcon}`}
              ></div>
            </a>
            <a href="https://www.youtube.com/channel/UCbdMrO1l_fK06fThtLVtyJg" target="_blank" rel="noreferrer nooopener">
              <div
                className={`${htFonts.htYtIcon} ${styles.socialIcon}`}
              ></div>
            </a>
            <a href="https://www.instagram.com/hoctradeanalytics/" target="_blank" rel="noreferrer nooopener">
              <div
                className={`${htFonts.htIgIcon} ${styles.socialIcon}`}
              ></div>
            </a>
            <a href="https://discord.com/invite/hcvytJnb5U" target="_blank" rel="noreferrer nooopener">
              <div
                className={`${htFonts.htDiscord} ${styles.socialIcon}`}
              ></div>
            </a>
          </div>
        <div className={styles.disclaimer}>
          HIGH RISK WARNING: Foreign exchange and other CFD trading carries a
          high level of risk and may not be suitable for everyone. The usage of
          leverage creates additional risk and loss exposure. Your investment
          objectives, experience level and risk tolerance have to be taken into
          consideration before you decide to trade foreign exchange or other CFD
          products. You could lose some or all of your entire investment. Do not
          invest money that you cannot afford to lose and always use an
          appropriate risk management. It’s important to educate yourself on the
          risks associated with foreign exchange trading, and seek professional
          advice from an independent financial or tax advisor in case of any
          questions. Any data and information is provided ‘as is’ solely for
          informational purposes, and is not intended for trading purposes or
          advice. Past performance, which are presented on this website or you
          find somewhere else, is not indicative of future results.
        </div>
      </div>
    </div>
  );
}

export default DashboardFooter;