import chartDataObjectMap from "utils/constant/chartDataObjectMap";
import processWithBenchmarks from './processWithBenchmarks';

export default function createDataObjectForChart(chartKey, chartData) {
  if (typeof chartData === 'string' && (chartData.includes('codeX:1001') || chartData.includes('codeX:2001'))) {
    return {
      ...chartDataObjectMap[chartKey],
      noData: true
    }
  }

  let chartDataObject = {
    ...chartDataObjectMap[chartKey],
    ...chartData
  };

  return chartDataObject;
}