import styles from "./mobileTopFivePatternsBars.module.scss";
import MobileTopFivePatternsBar from "./mobileTopFivePatternsBar";

function MobileTopFivePatternsBars({data}) {
  if (!data) return;
  return (
    <div className={styles.progressBarsWrapper}>
      {
        data.map((item) => {
          return <MobileTopFivePatternsBar key={item.text} data={item} />
        })
      }
    </div>
  )
}

export default MobileTopFivePatternsBars;