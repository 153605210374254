import YouTube from 'react-youtube';
import legendMap from 'devData/legendMap';

import styles from './commissionComparisonInfoModal.module.scss';
import htFonts from 'shared/htFont.module.scss';

import { useSelector, useDispatch } from 'react-redux';
import { closeModal, updateModal } from 'redux/slices/modalSlice';
import { getAllChartsInfoModalContent, getDisplayedModalDataKey, getSelectedBroker, getSortedBrokersData } from 'redux/selectors/index';
import * as modalTypes from 'constants/modalTypes';

function CommissionComparisonChartInfoModal() {
  const allChartsInfoModalContent = useSelector(getAllChartsInfoModalContent);
  const displayedModalDataKey = useSelector(getDisplayedModalDataKey);
  const modalContent = allChartsInfoModalContent[displayedModalDataKey];
  const selectedBroker = useSelector(getSelectedBroker);
  const sortedBrokersData = useSelector(getSortedBrokersData);
  const dispatch = useDispatch();

  const close = () => {
    return dispatch(closeModal({
      displayedModal: null,
      displayedModalDataKey: null,
      selectedBroker: [],
    }));
  };

  const goBack = () => {
    const copiedSelectedBroker = [...selectedBroker];
    return dispatch(updateModal({
      displayedModal: modalTypes.COMMISSION_COMPARISON_MODAL,
      displayedModalDataKey: null,
      selectedBroker: copiedSelectedBroker,
      sortedBrokersData: sortedBrokersData
    }));

  }

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  return (
    <div className={styles.chartInfoModal}>
      <div className={styles.ChartInfoModalTopBtn}>
        <div className={styles.BackToLastPage}>
          <div 
              className={`${htFonts.htBackArrow} ${styles.CancelIcon}`}
              onClick={() => goBack()}></div>
          Back to comparison
        </div>
        <div className={styles.CrossIconButton}>
          <div 
            className={`${htFonts.htCross} ${styles.CancelIcon}`}
            onClick={() => close()}></div>
        </div>
      </div>
      <div className={styles.ChartInfoModalHeader}>
        <div className={styles.IconButton}>
          <div className={`${htFonts.htStar} ${styles.Icon} ${styles.D9D0BF}`}></div>
        </div>
        <div className={styles.IconButton}>
          <div className={`${htFonts.htBell} ${styles.Icon} ${styles.D9D0BF}`}></div>
        </div>
        <div className={styles.IconButton}>
          <div className={`${htFonts.htGlobe} ${styles.Icon} ${styles.C1272D}`}></div>
        </div>
      </div>
      <div className={styles.ChartInfoModalBody}>
        <div>
          <div className={styles.SectionTitle}>Chart legend</div>
          <div className={styles.LegendsContainer}>
            {modalContent.legends.map(legend => {
              return (
                <div className={styles.Legend}>
                    <img 
                    className={styles.LegendImage} 
                    src={legendMap[legend].imgSrc} 
                    alt={legendMap[legend].label} />
                  <div>{legendMap[legend].label}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <div className={styles.SectionTitle}>{modalContent.chartTitle}</div>
          <div>{modalContent.chartDescription}</div>
        </div>
        {
          modalContent.youtubeVideoId? 
          <div>
            <div className={styles.SectionTitle}>Watch tutorial</div>
            <div className={styles.PlayerWrapper}>
              <YouTube videoId={modalContent.youtubeVideoId} opts={opts} className={styles.YoutubePlayer}/>
            </div>
          </div> : null
        }
        <div>
          <div className={styles.VideoButton}>
            <div className={`${htFonts.htVideo} ${styles.VideoIcon}`}></div>
            <a href="https://www.youtube.com/channel/UCbdMrO1l_fK06fThtLVtyJg" className={styles.VideoButtonText} target="_blank" rel="noreferrer nooopener">See all videos</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommissionComparisonChartInfoModal;