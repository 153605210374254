import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styles from "./symbols.module.scss";

import ChartHeader from "../../../charts/chartHeader";
import createHoverTextPlugin from "./hoverText.plugin";

ChartJS.register(ArcElement, Tooltip, Legend);

function Symbols(props) {
  const { chartData } = props;
  const hoverTextPlugin = createHoverTextPlugin(chartData[999]);
  const colors = [
    "#C15357",
    "#FFB700",
    "#56A07E",
    "#06AFC9",
    "#CC5EC7",
    "#F44336",
    "#FFEBEE",
    "#FFCDD2",
    "#EF9A9A",
    "#E57373",
    "#EF5350",
    "#F44336",
    "#E53935",
    "#D32F2F",
    "#C62828",
    "#B71C1C",
    "#FF8A80",
    "#FF5252",
    "#FF1744",
    "#D50000",
    "#E91E63",
    "#FCE4EC",
    "#F8BBD0",
    "#F48FB1",
    "#F06292",
    "#EC407A",
    "#E91E63",
    "#D81B60",
    "#C2185B",
    "#AD1457",
    "#880E4F",
    "#FF80AB",
    "#FF4081",
    "#F50057",
    "#C51162",
    "#9C27B0",
    "#F3E5F5",
    "#E1BEE7",
    "#CE93D8",
    "#BA68C8",
    "#AB47BC",
    "#9C27B0",
    "#8E24AA",
    "#7B1FA2",
    "#6A1B9A",
    "#4A148C",
    "#EA80FC",
    "#E040FB",
    "#D500F9",
  ];

  const intervals = chartData[999].map((data) => {
    return data.symbol;
  });

  const data = {
    labels: intervals,
    datasets: [
      {
        data: chartData[999].map((data) => {
          return data.percentage;
        }),
        backgroundColor: colors,
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader
        chartTitle={chartData.chartTitle}
        chartKey="symbols"
      />
      <div className={styles.Chart}>
        <Doughnut
          data={data}
          options={{
            cutout: 50,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: true,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                top: 40
              }
            }
          }}
          plugins={[
            hoverTextPlugin
          ]}
        />
      </div>
      <div className={styles.SymbolsContainer}>
        {intervals.map((interval, index) => {
          return (
            <div className={styles.SymbolContainer} key={index}>
              <div className={styles.SymbolColor} style={{backgroundColor: colors[index]}}></div>
              <div className={styles.SymbolLabel}>{interval}</div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default Symbols;
