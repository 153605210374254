const drawAreaWithoutIntersection = (
  ctx,
  currentPoint,
  nextPoint,
  highestPointPosY,
  color,
  chartArea
) => {
  // Draw area
  const opacity = 0.5;
  const gradient = ctx.createLinearGradient(
    0,
    highestPointPosY,
    0,
    chartArea.height
  );
  gradient.addColorStop(0, `rgba(${color}, ${opacity})`);
  gradient.addColorStop(1, `rgba(${color}, 0)`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw line
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawFirstAreaWithIntersection = (
  ctx,
  currentPoint,
  percentageAvgPosY,
  crossPointPosX,
  chartArea,
  highestPointPosY,
  color
) => {
  // Draw area
  const opacity = 0.5;
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(crossPointPosX, percentageAvgPosY);
  ctx.lineTo(crossPointPosX, chartArea.bottom);
  ctx.lineTo(currentPoint.x, chartArea.bottom);
  ctx.lineTo(currentPoint.x, currentPoint.y);

  const gradient = ctx.createLinearGradient(
    0,
    highestPointPosY,
    0,
    chartArea.height
  );
  gradient.addColorStop(0, `rgba(${color}, ${opacity})`);
  gradient.addColorStop(1, `rgba(${color}, 0)`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw Line
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(crossPointPosX, percentageAvgPosY);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawSecondAreaWithIntersection = (
  ctx,
  percentageAvgPosY,
  crossPointPosX,
  nextPoint,
  chartArea,
  highestPointPosY,
  color
) => {
  // Draw area
  const opacity = 0.5;
  ctx.beginPath();
  ctx.moveTo(crossPointPosX, percentageAvgPosY);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.lineTo(nextPoint.x, chartArea.bottom);
  ctx.lineTo(crossPointPosX, chartArea.bottom);
  ctx.lineTo(crossPointPosX, percentageAvgPosY);

  const gradient = ctx.createLinearGradient(
    0,
    highestPointPosY,
    0,
    chartArea.height
  );
  gradient.addColorStop(0, `rgba(${color}, ${opacity})`);
  gradient.addColorStop(1, `rgba(${color}, 0)`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw Line
  ctx.beginPath();
  ctx.moveTo(crossPointPosX, percentageAvgPosY);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const createPosWithSLAreaPlugin = (chartData) => {
  const posWithSLAreaPlugin = {
    id: "pos_with_SL_area_plugin",
    afterDraw: (chart) => {
      const chartArea = chart.chartArea;
      const pointsElement = chart._metasets[0].data;
      const percentageAvg = chartData.percentageAvg;
      const yScale = chart.scales.y;
      const percentageAvgPosY = yScale.getPixelForValue(percentageAvg);

      const pointsPos = pointsElement.map((point) => {
        return {
          x: point.x,
          y: yScale.getPixelForValue(point.$context.parsed.y),
        };
      });
      const highestPointPosY = Math.min(...pointsPos.map((obj) => obj.y));

      for (let i = 0; i < pointsPos.length; i++) {
        if (i === pointsPos.length - 1) break;
        const currentPoint = pointsPos[i];
        const nextPoint = pointsPos[i + 1];
        const currentPointPosX = currentPoint.x;
        const currentPointPosY = currentPoint.y;
        const nextPointPosX = nextPoint.x;
        const nextPointPosY = nextPoint.y;
        const sideX = nextPointPosX - currentPointPosX;
        const sideY = nextPointPosY - currentPointPosY;
        const percentageAvgPosYToNextPointY = nextPointPosY - percentageAvgPosY;
        const distanceToNextPointXFromCrossPoint =
          (percentageAvgPosYToNextPointY / sideY) * sideX;
        const crossPointPosX =
          nextPointPosX - distanceToNextPointXFromCrossPoint;
        const previousPoint = pointsPos[i - 1];

        // has two area color
        // top-left to bottom-right
        if (
          currentPointPosY < percentageAvgPosY &&
          nextPointPosY > percentageAvgPosY
        ) {
          const ctx = chart.ctx;
          drawFirstAreaWithIntersection(
            ctx,
            currentPoint,
            percentageAvgPosY,
            crossPointPosX,
            chartArea,
            highestPointPosY,
            "86, 160, 126"
          );
          drawSecondAreaWithIntersection(
            ctx,
            percentageAvgPosY,
            crossPointPosX,
            nextPoint,
            chartArea,
            highestPointPosY,
            "193, 83, 87"
          );

          // bottom-left to top-right
        } else if (
          currentPointPosY > percentageAvgPosY &&
          nextPointPosY < percentageAvgPosY
        ) {
          const ctx = chart.ctx;
          drawFirstAreaWithIntersection(
            ctx,
            currentPoint,
            percentageAvgPosY,
            crossPointPosX,
            chartArea,
            highestPointPosY,
            "193, 83, 87"
          );
          drawSecondAreaWithIntersection(
            ctx,
            percentageAvgPosY,
            crossPointPosX,
            nextPoint,
            chartArea,
            highestPointPosY,
            "86, 160, 126"
          );
          // Has one area color
        } else {
          const ctx = chart.ctx;
          ctx.beginPath();
          ctx.moveTo(currentPointPosX, currentPointPosY);
          ctx.lineTo(nextPointPosX, nextPointPosY);
          ctx.lineTo(nextPointPosX, chartArea.bottom);
          ctx.lineTo(currentPointPosX, chartArea.bottom);
          ctx.lineTo(currentPointPosX, currentPointPosY);

          if (
            currentPointPosY >= percentageAvgPosY &&
            nextPointPosY >= percentageAvgPosY
          ) {
            drawAreaWithoutIntersection(
              ctx,
              currentPoint,
              nextPoint,
              highestPointPosY,
              "193, 83, 87",
              chartArea
            );
          } else if (
            currentPointPosY === percentageAvgPosY &&
            nextPointPosY > percentageAvgPosY
          ) {
            drawAreaWithoutIntersection(
              ctx,
              currentPoint,
              nextPoint,
              highestPointPosY,
              "193, 83, 87",
              chartArea
            );
          } else if (
            currentPointPosY > percentageAvgPosY &&
            nextPointPosY === percentageAvgPosY
          ) {
            drawAreaWithoutIntersection(
              ctx,
              currentPoint,
              nextPoint,
              highestPointPosY,
              "193, 83, 87",
              chartArea
            );
          } else if (
            currentPointPosY < percentageAvgPosY &&
            nextPointPosY < percentageAvgPosY
          ) {
            drawAreaWithoutIntersection(
              ctx,
              currentPoint,
              nextPoint,
              highestPointPosY,
              "86, 160, 126",
              chartArea
            );
          } else if (
            currentPointPosY < percentageAvgPosY &&
            nextPointPosY === percentageAvgPosY
          ) {
            drawAreaWithoutIntersection(
              ctx,
              currentPoint,
              nextPoint,
              highestPointPosY,
              "86, 160, 126",
              chartArea
            );
          } else if (
            currentPointPosY === percentageAvgPosY &&
            nextPointPosY < percentageAvgPosY
          ) {
            drawAreaWithoutIntersection(
              ctx,
              currentPoint,
              nextPoint,
              highestPointPosY,
              "86, 160, 126",
              chartArea
            );
          } else if (
            currentPointPosY === percentageAvgPosY &&
            nextPointPosY === percentageAvgPosY
          ) {
            if (previousPoint !== undefined) {
              if (previousPoint.y > percentageAvgPosY) {
                drawAreaWithoutIntersection(
                  ctx,
                  currentPoint,
                  nextPoint,
                  highestPointPosY,
                  "193, 83, 87",
                  chartArea
                );
              } else {
                drawAreaWithoutIntersection(
                  ctx,
                  currentPoint,
                  nextPoint,
                  highestPointPosY,
                  "86, 160, 126",
                  chartArea
                );
              }
            } else {
              drawAreaWithoutIntersection(
                ctx,
                currentPoint,
                nextPoint,
                highestPointPosY,
                "86, 160, 126",
                chartArea
              );
            }
          }
        }
      }

      // draw dots
      pointsElement.forEach((pointElement) => {
        const ctx = chart.ctx;
        ctx.beginPath();
        ctx.fillStyle = pointElement.options.backgroundColor;
        ctx.strokeStyle = pointElement.options.backgroundColor;
        ctx.arc(pointElement.x, pointElement.y, 2, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
      });
    },
  };

  return posWithSLAreaPlugin;
};

export default createPosWithSLAreaPlugin;
