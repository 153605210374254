import styles from "./subPageLayout.module.scss";
import { useState, useEffect, useMemo } from "react";
// components
import TopFivePatterns from "components/topFivePatterns";
import BarChart from "components/charts/crypto/bar.chart";
import BarChartWithEmptyTicks from "components/charts/crypto/barWithEmptyTicks.chart";
// utils
import { fetchCryptoChartsData } from "utils/requests";
import { getChartsData, getCurrentTradingAccount } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { chartKeys } from "utils/constant";
import { setChartsData } from "redux/slices/chartSlices";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import { createDataObjectForCryptoChart } from "utils/helpers/chart";

const ChartMode = {
  USD: "usd",
  PERCENTAGE: "percentage",
};

function Strategy() {
  const [chartMode, setChartMode] = useState(ChartMode.USD);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartsDataError, setChartsDataError] = useState(false);
  const dispatch = useDispatch();
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const chartKeysThisPage = useMemo(
    () => [
      chartKeys.PROFIT_HIGH_VS_LOW_VOLATILITY,
      chartKeys.PROFIT_WITH_AGAINST_ST_MT_TREND,
      chartKeys.PROFIT_WHEN_SL_ADJUSTED_VS_PROFIT_WHEN_NOT,
      chartKeys.PROFIT_IF_PUBLISHING_TRADES,
      chartKeys.PROFIT_AFTER_WITHDRAWAL_IN_PIPS,
      chartKeys.AVERAGE_PAYOFF_WIN_VS_LOSS_TRADE,
      chartKeys.PROFIT_FOR_TRADE_DURING_OVERBOUGHT_OVERSOLD,
    ],
    []
  );

  const chartsData = useSelector(getChartsData(chartKeysThisPage));

  useEffect(() => {
    (async function () {
      const result = await fetchCryptoChartsData(currentTradingAccount.trading_account_id, chartKeysThisPage);
      if (result.data) {
        dispatch(
          setChartsData({
            chartsData: result.data,
          })
        );
        setCurrentTimeFrame(1);
      } else {
        setChartsDataError(true);
      }
    })();
  }, [dispatch, chartKeysThisPage, currentTradingAccount]);

  if (!createIsChartsDataAvailable(chartsData, chartKeysThisPage) && !chartsDataError) {
    return <div className={styles.Subpage}>Loading Data</div>;
  }

  if (chartsDataError) {
    return (
      <div style={{ margin: "40px auto", fontWeight: 'bold', color: "#4D4D4D" }}>
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  let chartDataObjects = {};
  chartKeysThisPage.forEach(chartKey => {
    chartDataObjects[chartKey] = createDataObjectForCryptoChart(chartKey, chartsData[chartKey]);
  });

  return (
    <div className={styles.Subpage}>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns />
      </div>
      <BarChartWithEmptyTicks 
        key="Profit high vs. low volatility"
        chartTitle="Profit high vs. low volatility"
        chartData={chartDataObjects["profit_high_vs_low_volatility"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_high_vs_low_volatility'].hoverTextArray} />
      <BarChart 
        key="Profit with(+) & against(-) short-term(ST) & medium-term(MT) trend"
        chartTitle="Profit with(+) & against(-) short-term(ST) & medium-term(MT) trend"
        chartData={chartDataObjects["profit_with_against_st_mt_trend"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_with_against_st_mt_trend'].hoverTextArray} />
      <BarChartWithEmptyTicks 
        key="Profit when SL adjusted vs. profit when not"
        chartTitle="Profit when SL adjusted vs. profit when not"
        chartData={chartDataObjects["profit_when_sl_adjusted_vs_profit_when_not"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_when_sl_adjusted_vs_profit_when_not'].hoverTextArray} />
      <BarChartWithEmptyTicks 
        key="Profit if publishing trades"
        chartTitle="Profit if publishing trades"
        chartData={chartDataObjects["profit_if_publishing_trades"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_publishing_trades'].hoverTextArray} />
      <BarChartWithEmptyTicks 
        key="Profit after withdrawl"
        chartTitle="Profit after withdrawl"
        chartData={chartDataObjects["profit_after_withdrawal_in_pips"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_after_withdrawal_in_pips'].hoverTextArray} />
      <BarChartWithEmptyTicks 
        key="Average payoff win vs. Loss trade"
        chartTitle="Average payoff win vs. Loss trade"
        chartData={chartDataObjects["average_payoff_win_vs_loss_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['average_payoff_win_vs_loss_trade'].hoverTextArray} />
      <BarChartWithEmptyTicks 
        key="Profit for trade during overbought/oversold"
        chartTitle="Profit for trade during overbought/oversold"
        chartData={chartDataObjects["profit_for_trade_during_overbought_oversold"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_for_trade_during_overbought_oversold'].hoverTextArray} />
    </div>
  );
}

export default Strategy;
