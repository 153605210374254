const riskReturnHitRatio = {
  chartTitle: "Return risk ratio & hit ratio",
  riskReturnRatioBenchmark: 1.37,
  hitRatioBenchmark: 51,
  999: {
    riskReturnRatio: [
      {
        time: new Date("2022-04"),
        num: 1.3,
      },
      {
        time: new Date("2022-05"),
        num: 1.1,
      },
      {
        time: new Date("2022-06"),
        num: 1.2,
      },
      {
        time: new Date("2022-07"),
        num: 1.4,
      },
      {
        time: new Date("2022-08"),
        num: 1.7,
      },
      {
        time: new Date("2022-09"),
        num: 1.9,
      },
      {
        time: new Date("2022-10"),
        num: 1.5,
      },
      {
        time: new Date("2022-11"),
        num: 1.5,
      },
      {
        time: new Date("2022-12"),
        num: 1.4,
      },
      {
        time: new Date("2023-01"),
        num: 0.9,
      },
      {
        time: new Date("2023-02"),
        num: 1.3,
      },
      {
        time: new Date("2023-03"),
        num: 1.2,
      },
      {
        time: new Date("2023-04"),
        num: 1.4,
      }
    ],
    hitRatio: [
      {
        time: new Date("2022-04"),
        num: 56,
      },
      {
        time: new Date("2022-05"),
        num: 40,
      },
      {
        time: new Date("2022-06"),
        num: 48,
      },
      {
        time: new Date("2022-07"),
        num: 58,
      },
      {
        time: new Date("2022-08"),
        num: 52,
      },
      {
        time: new Date("2022-09"),
        num: 42,
      },
      {
        time: new Date("2022-10"),
        num: 38,
      },
      {
        time: new Date("2022-11"),
        num: 55,
      },
      {
        time: new Date("2022-12"),
        num: 53,
      },
      {
        time: new Date("2023-01"),
        num: 67,
      },
      {
        time: new Date("2023-02"),
        num: 56,
      },
      {
        time: new Date("2023-03"),
        num: 45,
      },
      {
        time: new Date("2023-04"),
        num: 50,
      }
    ]
  }

};


export default riskReturnHitRatio;