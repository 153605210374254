import styles from "./alertModal.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useState, useEffect } from "react";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import alertsAreaMap from "devData/alertsAreaMap";
//utils
import { fetchChartsData, setAlertToRead } from "utils/requests";
import { createDataObjectForChart } from "utils/helpers/chart";
import { getChartsData, getCurrentTradingAccount } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { setChartsData } from "redux/slices/chartSlices";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import createCorrespondChartForAlertModal from "utils/constant/createCorrespondChartForAlertModal";
import parse from "html-react-parser";
import { setAlertsData, setReadToTrueForSingleAlert } from "redux/slices/alertSlice";
import chartKeys from 'utils/constant/chartKeys';
import Spinner from "UI/spinner";
import {   getSelectedAlertData
} from "redux/selectors/index";

const ChartMode = {
  PIPS: "pips",
  PERCENTAGE: "percentage",
  NUMBER: "number",
};

function AlertModal(props) {
  const { close, displayedModalDataKey, modalContent, 
    // selectedAlertData 
  } =
    props;
  const dispatch = useDispatch();
  const [currentTimeFrame, setCurrentTimeFrame] = useState(1);
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const [chartsDataError, setChartsDataError] = useState(false);
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const chartsData = useSelector(getChartsData([displayedModalDataKey]));
  const [isAlertRead, setIsAlertRead] = useState(false);
  const selectedAlertData = useSelector(getSelectedAlertData);

  useEffect(() => {
    if (!selectedAlertData.read) {
      (async function() {
        const resultFromAlertToRead = await setAlertToRead(selectedAlertData.alertId);
        if (resultFromAlertToRead.statusCode === 200) {
          setIsAlertRead(true);
          // dispatch(setAlertsData({ alertsData: resultFromAlertToRead.data }));
          dispatch(setReadToTrueForSingleAlert({ alertId: resultFromAlertToRead.data.alertId }))
        };
      })()
    } else {
      setIsAlertRead(true);
    }
  // }, [chartsData, chartsKeyArray, currentTradingAccount, dispatch, displayedModalDataKey, selectedAlertData]);
  }, []);

  if (
    !createIsChartsDataAvailable(chartsData, [displayedModalDataKey]) &&
    !chartsDataError
  ) {
    return <div className={styles.alertModal}>
      <div style={{minHeight: "500px", position: "relative"}}>
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      </div>
    </div>;
  }

  if (chartsDataError) {
    return (
      <div
        style={{ margin: "40px auto", fontWeight: "bold", color: "#4D4D4D" }}
      >
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  let chartDataObject = createDataObjectForChart(
    displayedModalDataKey,
    chartsData[displayedModalDataKey]
  );

  const chartProps = {
    key: chartDataObject.chartKey,
    chartTitle: chartDataObject.chartTitle,
    chartData: chartDataObject,
    hoverTextArray: chartDataObject.hoverTextArray,
    currentTimeFrame: currentTimeFrame,
    setCurrentTimeFrame: setCurrentTimeFrame,
    chartMode: chartMode,
    setChartMode: setChartMode,
    dualMode: true,
    hasTimeFrame: true,
    currentTradingAccount: currentTradingAccount
  };

  let chart = createCorrespondChartForAlertModal(
    displayedModalDataKey,
    chartProps
  );

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  let areaIcons = {
    behavioral: htFonts.htCoffee,
    timing: htFonts.htTiming,
    risk: htFonts.htAlert,
    strategy: htFonts.htChessCastle,
    cost: htFonts.htCoins,
  };

  let page;
  switch (alertsAreaMap[displayedModalDataKey]) {
    case "behavior":
      page = "behavioral";
      break;
    case "timing":
      page = "timing";
      break;
    case "risk":
      page = "risk-management";
      break;
    case "strategy":
      page = "strategy";
      break;
    case "cost":
      page = "costs";
      break;
    default:
  }

  let text;
  if (selectedAlertData.reason === "New loss making pattern") {
    text = `We identified a <strong>new loss-making pattern</strong> in your trading for the analysis "${selectedAlertData.area}"!`;
  } else if (selectedAlertData.reason === "Action in loss-making pattern") {
    text = `Attention: Your recent trade matches one of your <strong>existing loss-making patterns</strong> for the analysis "${selectedAlertData.area}"!`;
  } else if (selectedAlertData.reason === "Risky behavior") {
    text = `Caution: Your recent action indicates an usual trade risk-profile based on the analysis "${selectedAlertData.area}"!`;
  } else {
    text = "";
  }

  return (
    <>
      {isAlertRead ? (
        <div className={styles.alertModal}>
          <div className={styles.CrossIconButton}>
            <div
              className={`${htFonts.htCross} ${styles.CancelIcon}`}
              onClick={() => close()}
            ></div>
          </div>
          <div style={{ width: '90%'}}>{parse(selectedAlertData.msg)}</div>
          {selectedAlertData.reason !== "Risky behavior" ? (
            <div>
              <strong>Category: </strong>
              {selectedAlertData.category}
            </div>
          ) : null}
          <div className={styles.content}>
            <div className={styles.contentChart}>
              { chart }
              <div className={styles.linkBtn}>
                <Link
                  to={`/dashboard/analysis/${page}`}
                  id={styles.link}
                  onClick={() => close()}
                >
                  <div id={styles.linkContent}>
                    <span
                      className={`${
                        areaIcons[alertsAreaMap[displayedModalDataKey]]
                      }`}
                      style={{
                        marginRight: "3px",
                        fontSize: "18px",
                        marginTop: "2px",
                      }}
                    ></span>
                    Go to analysis/{page}
                  </div>
                </Link>
              </div>
            </div>
            <div className={styles.contentVideo}>
              {modalContent.youtubeVideoId ? (
                <div>
                  <div className={styles.PlayerWrapper}>
                    <YouTube
                      videoId={modalContent.youtubeVideoId}
                      opts={opts}
                      className={styles.YoutubePlayer}
                    />
                  </div>
                </div>
              ) : null}
              {modalContent.youtubeVideoId ? (
                <div className={styles.VideoButton}>
                  <div
                    className={`${htFonts.htVideo} ${styles.VideoIcon}`}
                  ></div>
                  <a
                    className={styles.VideoButtonText}
                    href="https://www.youtube.com/@hoc-trade/videos"
                    target="_blank"
                    rel="noreferrer"
                  >
                    See all videos
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AlertModal;
