import styles from "./basicStatistics.module.scss";
// components
import Balance from 'components/overview/basicStatic/balance/balance.chart';
import PerformancePips from 'components/overview/basicStatic/performancePips/performancePips';
import Symbols from 'components/overview/basicStatic/symbols/symbols';
import BarChart from 'components/overview/basicStatic/barChart/barChart';
import ChartNoDataWrapper from 'components/charts/chartNoDataWrapper';

function BasicStatistics({ chartDataObjects }) {
  return (
    <>
      <ChartNoDataWrapper 
        chartData={chartDataObjects['balance_currency']}>
        <Balance 
          key="Balance (USD)"
          chartTitle="Balance (USD)"
          chartData={chartDataObjects["balance_currency"]}
        />
      </ChartNoDataWrapper>
      <ChartNoDataWrapper 
        chartData={chartDataObjects['performance_pips']}>
        <PerformancePips 
          key="Performance (pips)"
          chartTitle="Performance (pips)"
        />
      </ChartNoDataWrapper>
      <ChartNoDataWrapper 
        chartData={chartDataObjects['symbols']}>
        <Symbols 
          key="Symbols"
          chartTitle="Symbols"
        />
      </ChartNoDataWrapper>
      <ChartNoDataWrapper 
        chartData={chartDataObjects['performance_months']}>
        <BarChart 
          key="Performance by month"
          chartTitle="Performance by month"
          barValueKey="percentage" />
      </ChartNoDataWrapper>
      <ChartNoDataWrapper 
        chartData={chartDataObjects['average_pips_per_trade']}>
        <BarChart 
          key="Average pips per trade"
          chartTitle="Average pips per trade"
          barValueKey="pips" />
      </ChartNoDataWrapper>
      <ChartNoDataWrapper 
        chartData={chartDataObjects['average_trade_length']}>
      <BarChart 
        key="Average trade length (mins)"
        chartTitle="Average trade length (mins)"
        barValueKey="mins" />
      </ChartNoDataWrapper>
      <ChartNoDataWrapper 
        chartData={chartDataObjects['average_trades_per_day']}>
        <BarChart 
          key="Average trades per day"
          chartTitle="Average trades per day"
          barValueKey="trades" />
      </ChartNoDataWrapper>
    </>
  )
}

export default BasicStatistics;