const generalInfo = {
  999: {
    profitPercentage: 54.14,
    profitPips: 1232,
    profitUSD: 733.00,
    lastThirtyDaysPercentage: 9.33,
    drawdown: 38.28,
    balance: 15050.39,
    trades: 1501,
    pipsPerTrade: 1.49,
    since: "Jan-22-2023"
  }
}

export default generalInfo;