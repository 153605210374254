import { chartKeys } from 'utils/constant';

const profit_if_manual_stop_before_tp = {
  chartTitle: "Profit if manual stop before TP",
  chartKey: chartKeys.PROFIT_IF_MANUAL_STOP_BEFORE_TP,
  hoverTextArray: {
    pips: [
      {
        templateString: '',
        valueKey: 'pips',
        unit: 'pips',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.pips >= anotherBar[0].pips? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.pips >= anotherBar[0].pips? "#56A07E" : "#C15357"
        },
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.percentage >= anotherBar[0].percentage? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.percentage >= anotherBar[0].percentage? "#56A07E" : "#C15357"
        },
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ]
  },
  createHoverTextArray: (currency) => {
    return {
      pips: [
        {
          templateString: '',
          valueKey: 'pips',
          unit: 'pips',
          textColor: function(hoveredData, data) {
            const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
            return hoveredData.pips >= anotherBar[0].pips? "#56A07E" : "#C15357"
          }
        },
        {
          templateString: '',
          valueKey: 'profit',
          textColor: function(hoveredData, data) {
            const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
            return hoveredData.pips >= anotherBar[0].pips? "#56A07E" : "#C15357"
          },
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'pipsBenchmark',
          createLabelColor: function(hoveredData) {
            return "#FFB700";
          },
          textColor: function(hoveredData) {
            return '#FFB700';
          }
        }
      ],
      'percentage': [
        {
          templateString: '',
          valueKey: 'percentage',
          unit: '%',
          textColor: function(hoveredData, data) {
            const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
            return hoveredData.percentage >= anotherBar[0].percentage? "#56A07E" : "#C15357"
          }
        },
        {
          templateString: '',
          valueKey: 'profit',
          textColor: function(hoveredData, data) {
            const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
            return hoveredData.percentage >= anotherBar[0].percentage? "#56A07E" : "#C15357"
          },
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'percentageBenchmark',
          createLabelColor: function(hoveredData) {
            return "#FFB700";
          },
          textColor: function(hoveredData) {
            return '#FFB700';
          }
        }
      ]
    }
  },
  interval: function(chartData) {
    return chartData.interval;
  },
  createBarColor: function(chartData) {
    const firstIntervalData = chartData['999']['data'][chartData.interval[0]].pips;
    const secondIntervalData = chartData['999']['data'][chartData.interval[1]].pips;
    if (firstIntervalData > secondIntervalData) {
      return ['#C15357', '#56A07E', ]
    } else {
      return ['#56A07E', '#C15357', ]
    }
  },
  createHoverBarColor: function (chartData) {
    const firstIntervalData = chartData['999']['data'][chartData.interval[0]].pips;
    const secondIntervalData = chartData['999']['data'][chartData.interval[1]].pips;
    if (firstIntervalData > secondIntervalData) {
      return ["#BE1E2D", "#009245"]
    } else {
      return ["#009245", "#BE1E2D"]
    }
  }
}

export default profit_if_manual_stop_before_tp;