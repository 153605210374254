import styles from "../../overview/accountOverview/alerts/alertCard.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modalSlice';
//constants
import * as modalTypes from 'constants/modalTypes';

function SandboxOverviewPageAlertCard({ cardData, index }) {
  const dispatch = useDispatch();
  let reasonIcons = {
    "New loss making pattern": htFonts.htNegChart,
    "Risky behavior": htFonts.htSpeedometer,
    "Action in loss-making pattern": htFonts.htAlertChart,
  };

  let backgroundColor;
  if (index === 1) {
    backgroundColor = "#026C7C"
  } else {
    backgroundColor = "#047F92"
  }

  const alertOnClickHandler = () => {
    return dispatch(openModal({
      displayedModal: modalTypes.CHART_INFO_MODAL,
      displayedModalDataKey: cardData.videoKey
    }));  }

  return (
    <div className={!cardData.read ? `${styles.CardContainer} ${styles.newAlert}` : styles.CardContainer} style={{backgroundColor: backgroundColor}} id={styles[`card${index}`]}>
      <div className={styles.CardHeader}>
        <div className={styles.IconContainer}>
          <span className={`${reasonIcons[cardData.reason]}`}></span>
        </div>
        <div className={styles.Titles}>
          <strong>{cardData.reason}</strong>
          <div style={{color: "#cccccc", fontSize: "11px"}}>{cardData.time} Order ID:{cardData.orderID}</div>
        </div>
      </div>
      <div className={styles.CardBody}>
        <img src={require(`../../../assets/analysisChartScreenshots/${cardData.chartKey}.png`)} style={{borderRadius: "5px"}} alt=" "></img>
        <div className={styles.BodyContent}>
          <strong>{cardData.area}</strong>
          <div>{cardData.msg}</div>
          <div className={styles.VideoBtn} onClick={() => alertOnClickHandler()}>
            <span className={htFonts.htVideo}></span>
            Watch video
          </div>
        </div>
      </div>
    </div>
  )
}

export default SandboxOverviewPageAlertCard;