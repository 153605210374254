import { chartKeys } from 'utils/constant';

const profit_if_trade_open_earlier_later = {
  chartTitle: "Profit if trade open earlier/later",
  chartKey: chartKeys.PROFIT_IF_TRADE_OPEN_EARLIER_LATER,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        textColor: function(hoveredData) {
          return hoveredData.usd >= 0? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        unit: 'USD',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        },
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'usdBenchmark',
        createLabelColor: function(hoveredData) {
          return "#FFB700";
        },
        textColor: function(hoveredData) {
          return '#FFB700';
        },
        unit: ""
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        },
        unit: 'USD',
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function(hoveredData) {
          return "#FFB700";
        },
        textColor: function(hoveredData) {
          return '#FFB700';
        }
      }
    ]
  },
  interval: [
    '-10m',
    '-5m',
    '-3m',
    '-1m',
    '0m',
    '1m',
    '3m',
    '5m',
    '10m'
  ],
}

export default profit_if_trade_open_earlier_later;