const xAxisTopPlugin = {
  id: "custom_x_axis_top_plugin",
  afterDraw: (chart) => {
    const { ctx, scales } = chart;
    const labelItems = scales.x._labelItems;
    ctx.strokeStyle = "#4D4D4D";
    ctx.strokeWidth = '1px';
    ctx.beginPath();
    //draw vertical ticks
    labelItems.forEach((item, index) => {
      ctx.moveTo(
        labelItems[index].translation[0] + (index % 2? 0.5 : 0),
        labelItems[index].translation[1] + labelItems[0].font.lineHeight - 30
      );
      ctx.lineTo(
        labelItems[index].translation[0] + (index % 2? 0.5 : 0),
        labelItems[index].translation[1] + labelItems[0].font.lineHeight -25
      )
    });
    //draw extra horizontal
    ctx.moveTo(scales.x.left - 20, labelItems[0].translation[1] + labelItems[0].font.lineHeight - 30);
    ctx.lineTo(
      scales.x.left + scales.x.maxWidth,
      labelItems[0].translation[1] + labelItems[0].font.lineHeight - 30
    )
    ctx.stroke();
    //draw extra length in x axis
    ctx.beginPath();
    const { left, top } = chart.scales.x;
    ctx.moveTo(left, top + 16.5);
    ctx.lineTo(left - 20, top + 16.5);
    ctx.stroke();
  }
}

export default xAxisTopPlugin;