import { useState, useEffect } from "react";
import styles from "../../dashboard/alert.module.scss";
import htFonts from "shared/htFont.module.scss";

import data from "devData/alerts";
import PaginationButtons from "components/tradeHistory/paginationButtons";
import useTable from "utils/useTable";
import FilterButtons from "components/alertPage/filterButtons";
import alertsAreaMap from "devData/alertsAreaMap";
import SandboxTableRow from "components/alertPage/sandboxTableRow";

function SandboxAlert() {
  const rowsPerPage = 10;
  let numberOfUnreadAlert = 0;
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const { slice, range } = useTable(filteredData, page, rowsPerPage);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);

  useEffect(() => {
    function getFilteredData() {
      let tempData = [];
      // only has reason filter
      if (selectedReasons.length !== 0 && selectedAreas.length === 0) {
        data.forEach((obj) => {
          if (selectedReasons.includes(obj.reason)) {
            tempData.push(obj);
          }
        });
        setFilteredData(tempData);
      }
      // only has area filter
      if (selectedAreas.length !== 0 && selectedReasons.length === 0) {
        data.forEach((obj) => {
          if (selectedAreas.includes(alertsAreaMap[obj.chartKey])) {
            tempData.push(obj);
          }
        });
        setFilteredData(tempData);
      }
      // has both reason and area fitler
      if (selectedAreas.length !== 0 && selectedReasons.length !== 0) {
        data.forEach((obj) => {
          if (
            selectedAreas.includes(alertsAreaMap[obj.chartKey]) &&
            selectedReasons.includes(obj.reason)
          ) {
            tempData.push(obj);
          }
        });
        setFilteredData(tempData);
      }
      // no any filter
      if (selectedAreas.length === 0 && selectedReasons.length === 0) {
        setFilteredData(data);
      }
    }
    getFilteredData();
  }, [selectedAreas, selectedReasons]);

  return (
    <div className={styles.alertContainer}>
      <div className={styles.alertPageHeader}>
        <FilterButtons
          selectedReasons={selectedReasons}
          setSelectedReasons={setSelectedReasons}
          selectedAreas={selectedAreas}
          setSelectedAreas={setSelectedAreas}
        />
        <div className={styles.headerRight}>
          <strong>{numberOfUnreadAlert} new alerts</strong> / Total:{" "}
          {filteredData.length}
        </div>
      </div>
      <table className={styles.alertTable}>
        <thead>
          <tr className={styles.titleRow}>
            <th>Time</th>
            <th>Area</th>
            <th>Category</th>
            <th>Reason</th>
            <th>Order</th>
            <th>
              <a
                id={styles.videosBtn}
                href="https://www.youtube.com/@hoc-trade/videos"
                target="_blank"
                rel="noreferrer"
              >
                <span className={htFonts.htVideo}></span>
                All videos
              </a>
            </th>
          </tr>
        </thead>
        <tbody className={styles.rowsContainer}>
          {slice.map((data, index) => {
            return <SandboxTableRow data={data} index={index} key={index} />;
          })}
        </tbody>
      </table>
      <div className={styles.bottomContainer}>
        <PaginationButtons
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
      </div>
    </div>
  );
}

export default SandboxAlert;
