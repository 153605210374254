import styles from "./responsiveTradeMedicInfoModal.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import blueTriangleBullet from "../../assets/triangle-bullet.png";
// utils
import { getResponsiveTradeMedicChartsData } from "redux/selectors";
import { createDataObjectForChart } from "utils/helpers/chart";
import createCorrespondChartForAlertModal from "utils/constant/createCorrespondChartForAlertModal";
import responsiveTradeMedicInfoContent from "devData/responsiveTradeMedicInfoContent";

const ChartMode = {
  PIPS: "pips",
  PERCENTAGE: "percentage",
  NUMBER: "number",
};

function ResponsiveTradeMedicInfoModal({ close, selectedTradeMedicData }) {
  const currentTradingAccount = useSelector(
    getResponsiveTradeMedicChartsData("currentTradingAccount")
  );
  const chartsData = useSelector(
    getResponsiveTradeMedicChartsData([selectedTradeMedicData.chart_key])
  );
  const [isRiskIssue, setIsRiskIssue] = useState(false);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isDownSwipe = -distance > minSwipeDistance;
    if (isDownSwipe) {
      close();
    }
  };

  useEffect(() => {
    if (
      selectedTradeMedicData.chart_key ===
        "risk_after_losing_trades_per_trade" ||
      selectedTradeMedicData.chart_key ===
        "max_risk_all_open_trades_last_trades" ||
      selectedTradeMedicData.chart_key === "percentage_of_position_with_sl" ||
      selectedTradeMedicData.chart_key === "risk_per_trade_last_trades" ||
      selectedTradeMedicData.chart_key === "average_payoff_win_vs_loss_trade"
    ) {
      setIsRiskIssue(true);
    }
  }, [selectedTradeMedicData, setIsRiskIssue]);

  if (selectedTradeMedicData.issue_name === "Trade Medic") {
    return (
      <div className={styles.TradeMedicGeneralInfo}>
        <div className={styles.ModalHeader}>
          <div className={styles.IssueName}>Trade Medic</div>
          <div className={styles.CrossIconButton}>
            <div
              className={`${htFonts.htCross} ${styles.CancelIcon}`}
              onClick={() => close()}
            ></div>
          </div>
        </div>
        <div className={styles.ModalContent}>
          Your trading issues reference to your trading behavior since account
          inception. For custom analyses on shorter timeframes, please refer to
          the according output charts. The issues described are not MECE
          (mutually exclusive & collectively exhaustive). For detailed
          explanation on the single issues or risk areas, please refer to the
          dedicated information provided for each item.
        </div>
      </div>
    );
  }

  if (
    selectedTradeMedicData.issue_name === "The 8 point issue area assessment"
  ) {
    return (
      <div className={styles.TradeMedicGeneralInfo}>
        <div className={styles.ModalHeader}>
          <div className={styles.IssueName}>
            The 8 point issue area assessment
          </div>
          <div className={styles.CrossIconButton}>
            <div
              className={`${htFonts.htCross} ${styles.CancelIcon}`}
              onClick={() => close()}
            ></div>
          </div>
        </div>
        <div className={styles.ModalContent}>
          <p>
            The 8 point issue area assessment provides a high level overview
            which areas of your trading show the most severe (negative) impact
            on your trading performance.
          </p>{" "}
          <p>
            The output of this analysis is applicable to guide you to the areas
            of your trading which require a strong focus from you, however not
            to work single issues. The impact of risk issues has not been
            quantified, but is derived based on the number of risk issues
            detected in your trading.
          </p>{" "}
          All details can be accessed through the single issue analytics in your
          Trade Medic report. Note that the performance effect of trading issues
          is not mutually exclusive & collectively exhaustive (MECE).
        </div>
      </div>
    );
  }

  let infoDetailBoxFive;
  if (
    selectedTradeMedicData.medic_icon === 0 ||
    selectedTradeMedicData.performance_effect === 0
  ) {
    infoDetailBoxFive = (
      <div className={styles.InfoDetailBoxFiveGreen}>
        <div className={styles.MessageContainer}>
          <span className={htFonts.htCheck}></span>
          <div className={styles.MessageText}>
            {selectedTradeMedicData.text}
          </div>
        </div>
      </div>
    );
  } else {
    infoDetailBoxFive = (
      <div className={styles.InfoDetailBoxFiveRed}>
        <div className={styles.MessageContainer}>
          <span className={htFonts.htAlert}></span>
          <div className={styles.MessageText}>
            {selectedTradeMedicData.text}
          </div>
        </div>
      </div>
    );
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const selectedTradeMedicDataFurtherDetail =
    responsiveTradeMedicInfoContent[selectedTradeMedicData.interval]
      .furtherDetail;

  let chartDataObject = createDataObjectForChart(
    selectedTradeMedicData.chart_key,
    chartsData[selectedTradeMedicData.chart_key]
  );

  const chartProps = {
    key: chartDataObject.chart_key,
    chartTitle: chartDataObject.chartTitle,
    chartData: chartDataObject,
    hoverTextArray: chartDataObject.hoverTextArray,
    currentTimeFrame: currentTimeFrame,
    setCurrentTimeFrame: setCurrentTimeFrame,
    chartMode: chartMode,
    setChartMode: setChartMode,
    dualMode: true,
    hasTimeFrame: true,
    currentTradingAccount: currentTradingAccount,
  };

  let chart = createCorrespondChartForAlertModal(
    selectedTradeMedicData.chart_key,
    chartProps
  );

  return (
    <div className={styles.TradeMedicInfoModal}>
      <div className={styles.ModalHeader}>
        <div className={styles.CrossIconButton}>
          <div
            className={`${htFonts.htCross} ${styles.CancelIcon}`}
            onClick={() => close()}
          ></div>
          <div></div>
        </div>
        <div
          className={styles.MobileCloseLine}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        ></div>
      </div>
      <div className={styles.ModalBody}>
        <div className={styles.ModalContent}>
          <div className={styles.InfoDetailContainer}>
            <div className={styles.InfoDetailBoxOne}>
              <h3>Issue</h3>
              <h2>{selectedTradeMedicData.issue_name}</h2>
            </div>
            <div className={styles.InfoDetailBoxTwo}>
              <h3>Area</h3>
              <div className={styles.TextContainer}>
                <span className={htFonts.htErrorTrade}></span>
                <h2>{selectedTradeMedicData.category}</h2>
              </div>
            </div>
            {isRiskIssue ? null : (
              <div className={styles.InfoDetailBoxThree}>
                <h3>Performance effect</h3>
                <div className={styles.PerformanceText}>
                  {numberWithCommas(selectedTradeMedicData.performance_effect)}{" "}
                  {currentTradingAccount.currency}
                </div>
              </div>
            )}
            {isRiskIssue ? null : (
              <div className={styles.InfoDetailBoxFour}>
                <h3>Severity</h3>
                <div className={styles.SeverityContainer}>
                  <div className={styles.SeverityBar}>
                    {
                      selectedTradeMedicData.severity < 10 ? <div
                      className={styles.ServityBarDot}
                      style={{
                        right: `calc(100% - ${selectedTradeMedicData.severity}% - 20px)`,
                      }}
                    ></div> : <div
                    className={styles.ServityBarDot}
                    style={{
                      right: `calc(100% - ${selectedTradeMedicData.severity}%)`,
                    }}
                  ></div>
                    }
                  </div>
                  <div className={styles.SeverityText}>
                    {selectedTradeMedicData.severity}%
                  </div>
                </div>
              </div>
            )}
            {infoDetailBoxFive}
          </div>
          <div className={styles.ExplanationBox}>
            <h2>Explanation</h2>
            <div>
              {parse(
                responsiveTradeMedicInfoContent[selectedTradeMedicData.interval]
                  .explanation.firstSection
              )}
            </div>
          </div>
          <h2 style={{ textAlign: "left" }}>
            Your trading activity: Output chart
          </h2>
          <div className={styles.ReportChartContainer}>
            <div className={styles.ContentLeft}>
              <div className={styles.Chart}>{chart}</div>
            </div>
            <div className={styles.ContentRight}>
              <div>
                {parse(
                  responsiveTradeMedicInfoContent[
                    selectedTradeMedicData.interval
                  ].explanation.secondSection
                )}
              </div>
              <div className={styles.FurtherDetailBox}>
                <div className={styles.Subtitle}>Further information:</div>
                {selectedTradeMedicDataFurtherDetail ? (
                  <u>
                    <div className={styles.FurtherInformation}>
                      {selectedTradeMedicDataFurtherDetail.map(
                        (detail, index) => {
                          return (
                            <a
                              href={detail.link}
                              target="_blank"
                              rel="noreferrer"
                              key={`further detail ${index}`}
                            >
                              <img
                                src={blueTriangleBullet}
                                style={{ marginRight: "10px" }}
                                alt="triangle bullet"
                              ></img>
                              {detail.content}
                            </a>
                          );
                        }
                      )}
                    </div>
                  </u>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveTradeMedicInfoModal;
