const drawVerticalLine = (ctx, x, y2, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x, 10);
  ctx.lineTo(x, y2);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawCircleOnBar = (ctx, x, y, strokeStyle, fillStyle) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle;
  ctx.fillStyle = fillStyle;
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
};

const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1, y);
  ctx.lineTo(x2, y);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

function generateHoverTextString(textObject, hoveredDataPoint) {
  let textString;
  let decimalNumber = decimalNumberMap[textObject.valueKey];
  if (hoveredDataPoint.raw[textObject.valueKey] === null || hoveredDataPoint.raw[textObject.valueKey] === undefined) return "";
  textString = `${hoveredDataPoint.raw[textObject.valueKey]?.toFixed(decimalNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  if (textObject.unit) {
    textString += textObject.unit
  }
  return textString;
}

const measureTextWidth = (ctx, textArray, hoveredDataPoint) => {
  let textStringWidth = 0;
  textArray.forEach((object, index) => {
    let a = object.templateString;
    const textString = generateHoverTextString(object, hoveredDataPoint);

    textStringWidth += ctx.measureText(`${a}:${textString}`).width;
    if (index !== textArray.length -1) {
      textStringWidth += 15;
    }
  });
  return textStringWidth;
};

const decimalNumberMap = {
  percentage: 2,
  pips: 2,
  profit: 2,
  pipsBenchmark: 1,
  percentageBenchmark: 2,
}

/**
 *
 * @param {Text[]} textArray
 * @param {object} textArray.element
 * @property { string } textArray.element.templateString
 * @property { string } textArray.element.valueKey
 * @property { string } textArray.element.unit?
 * @property { string } textArray.element.textColor?
 *
 */
const drawOnHoverText = (textArray, ctx, hoveredDataPoint, chartData) => {
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "white";
  const textStringWidth = measureTextWidth(ctx, textArray, hoveredDataPoint);
  const chartArea = hoveredDataPoint.chart.chartArea;
  const centerX = (chartArea.right + chartArea.left) / 2;

  let currentX = centerX - textStringWidth / 2;
  for (let i = 0; i < textArray.length; i++) {
    const textObject = textArray[i];
    const textString = generateHoverTextString(textObject, hoveredDataPoint);
    if (!textString) break;
    ctx.save();
    if (textObject['createLabelColor']) {
      ctx.fillStyle = textObject.createLabelColor(hoveredDataPoint.raw);
    }
    ctx.fillText(textObject.templateString, currentX, 15);
    currentX += ctx.measureText(textObject.templateString).width;
    ctx.restore();

    ctx.save();
    if (textObject["textColor"]) {
      ctx.fillStyle = textObject.textColor(hoveredDataPoint.raw, chartData);
    } 
    if (textObject['followBarColor']) {
      ctx.fillStyle = hoveredDataPoint.dataset.borderColor[hoveredDataPoint.dataIndex];
      if (parseInt(textString) < 0) {
        ctx.fillStyle = "#C15357";
      }
    }
    ctx.font = "700 12px Noto Sans";

    ctx.fillText(textString, currentX, 15);
    currentX += ctx.measureText(textString).width;
    ctx.restore();
    if (i !== textArray.length - 1) {
      currentX += 15;
    }
  }
};

const createOnHoverPlugin = (hoverTextArray) => {
  const onHoverPlugin = {
    id: "on_bar_hover_plugin",
    beforeDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      const chartData = chart.data.datasets[0].data;
      // tooltip.opacity = 0;
      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const barElement = tooltip.dataPoints[0].element;
        const chartArea = chart.chartArea;

        drawVerticalLine(ctx, barElement.x, chartArea.bottom, "#06AFC9");
        drawCircleOnBar(ctx, barElement.x, barElement.y, "#06AFC9", "white");
        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          20,
          "#00333B"
        );

        drawHorizontalLines(
          ctx,
          chartArea.left - 20,
          chartArea.left + chartArea.width,
          0.5,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left - 20,
          chartArea.left + chartArea.width,
          20.5,
          "#06AFC9"
        );

        drawOnHoverText(hoverTextArray, ctx, tooltip.dataPoints[0], chartData);
      }
    },
  };

  return onHoverPlugin;
};

export default createOnHoverPlugin;
