import { io } from 'socket.io-client';
import { setChartsData, setLastChartUpdateAt } from 'redux/slices/chartSlices';
import { setTradeMedicData } from 'redux/slices/tradeMedicSlice';
import { getAllAlertsData } from 'redux/selectors';
import chartKeys from './constant/chartKeys';
import { SOCKET_IO_URL } from './constant/environment';
import { addAlertData } from 'redux/slices/alertSlice';

let connectedSocket;

export default async function createSocketConnection(
  userId, 
  tradingAccountId, 
  dispatch
) {
  const socket = io(SOCKET_IO_URL, {
    auth: {
      userId,
      tradingAccountId
    }
  });

  socket.on('init', ({ ping }) => {
    
  });

  socket.on('chartData', ({ chartsData }) => {
    console.log('received chart data', chartsData);
    Object.values(chartKeys).forEach((chartKey) => {
      if (chartsData && !chartsData[chartKey]) {
        chartsData[chartKey] = 'user_no_data';
      }
    });

    dispatch(setChartsData({
      chartsData: chartsData
    }));
    dispatch(setLastChartUpdateAt({
      lastChartUpdatedAt: Date.now()
    }));
  });

  socket.on('tradeMedicData', ({ tradeMedicData }) => {
    console.log('tradeMedicData socket', tradeMedicData);
    dispatch(setTradeMedicData({
      tradeMedicData: tradeMedicData
    }));
  });

  socket.on('alertData', ({ newAlertData }) => {
    dispatch(addAlertData({
      newAlertData: newAlertData
    }));
  });

  socket.on('tradeHistory', ({ tradeHistory }) => {
    // dispatch()
    console.log('tradeHistory', tradeHistory);
  });

  socket.on('socket-check', (message) => {
    console.log('message', message);
  });

  socket.on('socket-check-taui', ({ message }) => {
    console.log('message', message);
    console.log('socketId', socket.id);
  });

  socket.on('connect', () => {
    // pining the server through websocket to keep the socketId alive
    const socketRefreshInterval = setInterval(() => {
      socket.emit('socket-refresh', { socketId: socket.id, tradeAccountUniqueId: socket.auth.tradingAccountId, userId: socket.auth.userId });
    }, 1000 * 60 * 5);

    socket.on('disconnect', async () => {
      clearInterval(socketRefreshInterval);
    });
  });

  connectedSocket = socket;
  return socket;
}

export {
  connectedSocket,
  createSocketConnection
}
