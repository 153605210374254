import createBarColorComparison from "components/charts/helpers/createBarColorComparison";
import createHoverBarColorComparison from "components/charts/helpers/createHoverBarColorComparison";
import createBarBorderColorComparison from "components/charts/helpers/createBarBorderColorComparison";
import { chartKeys } from 'utils/constant';

const profit_of_conflicting_symbols = {
  chartTitle: "Profit of conflicting symbols",
  chartKey: chartKeys.PROFIT_OF_CONFLICTING_SYMBOLS,
  createBarColorComparison: createBarColorComparison,
  createHoverBarColorComparison: createHoverBarColorComparison,
  createBarBorderColorComparison: createBarBorderColorComparison,
  hoverTextArray: {
    pips: [
      {
        templateString: "",
        valueKey: "pips",
        unit: "pips",
        textColor: function (hoveredData) {
          return hoveredData.pips >= 0 ? "#56A07E" : "red";
        },
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        textColor: function (hoveredData) {
          return hoveredData.profit >= 0 ? "#56A07E" : "red";
        },
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B:",
        valueKey: "pipsBenchmark",
        unit: "",
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        textColor: function (hoveredData) {
          return hoveredData.percentage >= 0 ? "#56A07E" : "red";
        },
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        textColor: function (hoveredData) {
          return hoveredData.profit >= 0 ? "#56A07E" : "red";
        },
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B:",
        valueKey: "percentageBenchmark",
        unit: "",
      },
    ],
  },
  createHoverTextArray: (currency) => {
    return {
      pips: [
        {
          templateString: "",
          valueKey: "pips",
          unit: "pips",
          followBarColor: true,
        },
        {
          templateString: "",
          valueKey: "profit",
          unit: currency,
          followBarColor: true,
        },
        {
          templateString: "Trades: ",
          valueKey: "trades",
        },
        {
          templateString: "B:",
          valueKey: "pipsBenchmark",
          unit: "",
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        },
      ],
      percentage: [
        {
          templateString: "",
          valueKey: "percentage",
          unit: "%",
          followBarColor: true,
        },
        {
          templateString: "",
          valueKey: "profit",
          unit: currency,
          followBarColor: true,
        },
        {
          templateString: "Trades: ",
          valueKey: "trades",
        },
        {
          templateString: "B:",
          valueKey: "percentageBenchmark",
          unit: "",
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        },
      ],
    }
  },
  haspercentageBenchmark: true,
  interval: ["No conflict", "Conflict"],
}

export default profit_of_conflicting_symbols;