import styles from "./keyStatistics.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useDispatch } from 'react-redux';
import { openModal } from "redux/slices/modalSlice";
import * as modalTypes from 'constants/modalTypes';

function KeyStatistics(props) {
  const { data } = props;
  const dispatch = useDispatch();
  const chartData = data[999];
  const infoIconOnClickHandler = (chartKey) => {
    return dispatch(openModal({
      displayedModal: modalTypes.CHART_INFO_MODAL,
      displayedModalDataKey: chartKey
    }));
  }

  return (
    <div className={styles.KeyStatisticsContainer}>
      <div className={styles.Header}>
        <div>Key statistics</div>
      </div>
      <div className={styles.Content}>
        <div className={styles.Column} id={styles.ColumnOne}>
          <div className={styles.Subtitle}>
            <div>Sharpe ratio</div>
            <div
              className={`${htFonts.htInfo} ${styles.InfoIcon}`}
              onClick={() => infoIconOnClickHandler("sharp_ratio")}
            ></div>
          </div>
          <div className={styles.Value}>{chartData.sharpeRatio.toFixed(2)}</div>
        </div>
        <div className={styles.Column} id={styles.ColumnTwo}>
          <div className={styles.Subtitle}>
            <div>Z-value</div>
            <div
              className={`${htFonts.htInfo} ${styles.InfoIcon}`}
              onClick={() => infoIconOnClickHandler("zValue")}
            ></div>
          </div>
          <div className={styles.Value}>{chartData.zValue.toFixed(2)}</div>
        </div>
        <div className={styles.Column} id={styles.ColumnThree}>
          <div className={styles.Subtitle}>
            <div>AHPR</div>
            <div
              className={`${htFonts.htInfo} ${styles.InfoIcon}`}
              onClick={() => infoIconOnClickHandler("ahpr")}
            ></div>
          </div>
          <div className={styles.Value} style={{color: "#009245"}}>{chartData.ahpr.toFixed(2)}</div>
        </div>
        <div className={styles.Column}>
          <div className={styles.Subtitle}>
            <div>GHPR</div>
            <div
              className={`${htFonts.htInfo} ${styles.InfoIcon}`}
              onClick={() => infoIconOnClickHandler("ghpr")}
            ></div>
          </div>
          <div className={styles.Value} style={{color: "#C1272D"}}>{chartData.ghpr.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
}

export default KeyStatistics;
