function createBarBorderColorComparison(chartData, currentTimeFrame, chartMode) {
  const intervals = chartData.interval;

  let highValue;

  intervals.forEach(interval => {
    const barData = chartData[currentTimeFrame].data[interval];
    if (!highValue || barData[chartMode] > highValue) {
      highValue = barData[chartMode];
    }
  });

  const barColors = intervals.map(interval => {
    const barData = chartData[currentTimeFrame].data[interval];
    const tradeCount = barData.trades;

    if (tradeCount < 5 ) {
      return "#CCCCCC";
    } else {
      if (barData[chartMode] === highValue) {
        //high value color
        return '#56A07E';
      } else {
        // low value color
        return '#C15357';
      }
    }
  });

  return barColors;
}

export default createBarBorderColorComparison;