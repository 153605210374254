import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, openModal, updateModal } from 'redux/slices/modalSlice';
import styles from './loginModal.module.scss';
import htFonts from "shared/htFont.module.scss";
import Spinner from '../../UI/spinner';
import { sendLoginCredential } from '../../utils/requests';
import { useNavigate } from 'react-router-dom';
import { setAuthenticationState, setCurrentTradingAccount, setUser } from 'redux/slices/authenticationSlice';
import { authenticationStates } from 'utils/constant';
import * as modalTypes from '../../constants/modalTypes';
import AuthModalUI from '../../UI/authModalUI';
import EmailVerification from './loginModalContent/emailVerification';

export default function LoginModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [loginCredential, setLoginCredential] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    credential: ""
  });
  const [emailVerificationStep, setEmailVerificationStep] = useState(0);
  const [emailVerificationCode, setEmailVerificationCode] = useState("");

  const close = () => {
    return dispatch(closeModal(null));
  };

  const openForgetPasswordModal = () => {
    return dispatch(updateModal({
      displayedModal: modalTypes.RESET_PASSWORD_MODAL,
      modalData: {
        type: "ASK_FOR_EMAIL"
      }
    }))
  }

  const openRegisterModal = () => {
    return dispatch(openModal({
      displayedModal: modalTypes.SIGN_UP_MODAL
    }))
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setIsPending(true);
    setErrorMessage("");
    if (!validateEmail(loginCredential.email)) {
      setIsPending(false);
      return setErrorMessage({
        email: "Invalid email format",
        credential: ""
      });
    }
    const result = await sendLoginCredential(loginCredential);

    if (result.message === "INCORRECT_CREDENTIAL") {
      setIsPending(false);
      return setErrorMessage({
        email: "",
        credential: "Incorrect sign in credential. Please re-enter."
      })
    }
    if (result.message === "CORRECT_CREDENTIAL") {
      dispatch(setAuthenticationState({
        authenticationState: authenticationStates.AUTHENTICATED
      }));
      dispatch(setUser({
        user: result.data
      }))
      if (result.data.trading_accounts) {
        dispatch(setCurrentTradingAccount({
          currentTradingAccount: result.data.trading_accounts[0]
        }))
      }
      close();
      return navigate('/dashboard/overview/account-overview');
    }
    if (result.message === "EMAIL_NOT_VERIFIED") {
      setEmailVerificationStep(1);
    }
  }
  const validateEmail = (email) => {
    const validEmailRegExp = RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    return validEmailRegExp.test(email);
  }

  const onChangeHandler = (field, newValue) => {
    const copiedLoginCredential = {...loginCredential};
    copiedLoginCredential[field] = newValue;
    setLoginCredential(copiedLoginCredential);
  }

  const loginButton = loginCredential.email && loginCredential.password?
    <div className={styles.LoginButton} onClick={(e) => submitForm(e)}>
      SIGN IN <span className={`${htFonts.htLoginLogout} ${styles.LoginIcon}`}></span>
    </div> :
    <div className={`${styles.LoginButton} ${styles.DisabledButton}`}>
      SIGN IN <span className={`${htFonts.htLoginLogout} ${styles.LoginIcon}`}></span>
    </div>;

  let pageContent;
  pageContent = isPending?
    <div className={styles.SpinnerContainer}>
      <div className={styles.SignInIndicator}>
        Signing in...
      </div>
      <Spinner />
    </div> : 
    <form className={styles.LoginForm}>
      <div className={styles.InputWrapper}>
        {
          loginCredential.email?
            <div className={styles.InputLabel}>Email</div> : null
        }
        <input 
          placeholder="Email"
          type="text"
          className={loginCredential.email? styles.ActualInput : styles.InputTemplate }
          value={loginCredential.email} 
          onChange={(e) => onChangeHandler("email", e.target.value)} />
      </div>
      <div className={styles.InputWrapper}>
        {
          loginCredential.password?
            <div className={styles.InputLabel}>Password</div> : null
        }
        <input 
          placeholder="Password"
          type="password"
          className={loginCredential.password? styles.ActualInput : styles.InputTemplate }
          value={loginCredential.password} 
          onChange={(e) => onChangeHandler("password", e.target.value)} />
      </div>
      <div className={styles.Block}>
        {
          errorMessage.credential?
            errorMessage.credential : 
            errorMessage.email?
              errorMessage.email : null
        }
      </div>
      <div>
        <div className={styles.ForgetPasswordText} onClick={() => navigate('/reset-password')}>
          <u>Forgot password?</u>
        </div>
      </div>
      {
        loginButton
      }
      <div className={styles.NoteToRegister}>
        New to hoc-trade? 
        <span>
          <a className={styles.RegisterUnderline} href="/register">
            Register
          </a>
        </span>
      </div>
    </form>

  if (emailVerificationStep !== 0) {
    pageContent = <EmailVerification 
      emailVerificationStep={emailVerificationStep}
      setEmailVerificationCode={setEmailVerificationCode}
      emailVerificationCode={emailVerificationCode}
      setEmailVerificationStep={setEmailVerificationStep}
      email={loginCredential.email} 
      loginCredential={loginCredential} />
  }
  
  return (
    <div>
      { pageContent }
    </div>
  );
}