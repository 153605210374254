import styles from './index.module.scss';

export default function progressDots({
  phases,
  currentPhase
}) {
  let phaseSections = phases.map((phase) => {
    if (phase <= currentPhase) {
      return (
        <div
          key={phase}
          id={`styles${phase}`}
          style={{
            backgroundColor: "#047F92",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
          }}
        ></div>
      );
    } else {
      return (
        <div
          key={phase}
          id={`styles${phase}`}
          style={{
            backgroundColor: "#999999",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
          }}
        ></div>
      );
    }
  });

  return (
    <div className={styles.phaseSection}>
      { phaseSections }
    </div>
  )
}