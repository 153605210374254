import benchmark from 'assets/legend-benchmark.svg';
import averageGreen from 'assets/legend-avg-green.svg';
import averageRed from 'assets/legend-avg-red.svg';
import averageYellow from 'assets/legend-avg-yellow.svg';
import benchmarkGreen from 'assets/legend-benchmark-green.svg';
import benchmarkRed from 'assets/legend-benchmark-red.svg';
import blueLine from 'assets/legend-line-blue.svg';
import greenLine from 'assets/legend-line-green.svg';
import redLine from 'assets/legend-line-red.svg';
import lossReverse from 'assets/legend-loss-reverse.svg';
import loss from 'assets/legend-loss.svg';
import neutral from 'assets/legend-neutral.svg';
import limitedSignificance from 'assets/legend-not-so-significant.svg';
import profit from 'assets/legend-profit.svg';
import insignificant from 'assets/legend-insignificant.svg';
import comparisonYellow from 'assets/legend-comparison-yellow.svg';
// import neutral from 'assets/legend-neutral.svg';
import RRR from 'assets/legend-RRR.svg';
import benchmarkHR from 'assets/legend-benchmark-HR.svg';
import benchmarkRRR from 'assets/legend-benchmark-RRR.svg';

const legendMap = {
  benchmark: {
    label: 'Benchmark',
    imgSrc: benchmark
  },
  profit: {
    label: 'Profit',
    imgSrc: profit
  },
  loss: {
    label: 'Loss',
    imgSrc: loss
  },
  lowerRisk: {
    label: 'Lower Risk',
    imgSrc: profit
  },
  higherRisk: {
    label: 'Higher Risk',
    imgSrc: loss
  },
  higherProfit: {
    label: 'Higher profit',
    imgSrc: profit
  },
  lowerProfit: {
    label: 'Lower profit',
    imgSrc: loss
  },
  limitedSignificance: {
    label: 'Limited Significance',
    imgSrc: limitedSignificance,
  },
  insignificant: {
    label: 'Insignificant',
    imgSrc: insignificant,
  },
  hitRatio: {
    label: 'Hit Ratio',
    imgSrc: neutral,
  },
  averageHitRateGreen: {
    label: 'Average hit rate >= 0.5',
    imgSrc: benchmarkGreen,
  },
  averageHitRateRed: {
    label: 'Average hit rate < 0.5',
    imgSrc: benchmarkRed,
  },
  neutralData: {
    label: 'Neutral data',
    imgSrc: neutral,
  },
  risk: {
    label: 'Risk',
    imgSrc: lossReverse,
  },
  balance: {
    label: 'Balance',
    imgSrc: blueLine,
  },
  deposit: {
    label: 'Deposit',
    imgSrc: profit,
  },
  withDrawal: {
    label: 'Withdrawal',
    imgSrc: loss,
  },
  profitLine: {
    label: 'Profit line',
    imgSrc: greenLine,
  },
  lossLine: {
    label: 'Loss line',
    imgSrc: redLine,
  },
  averageYellowLine: {
    label: 'Average line',
    imgSrc: averageYellow,
  },
  averageGreenLine: {
    label: 'Average line',
    imgSrc: averageGreen,
  },
  averageRedLine: {
    label: 'Average line',
    imgSrc: averageRed,
  },
  currentCommission: {
    label: 'Current commission',
    imgSrc: lossReverse,
  },
  lowerCommission: {
    label: 'Lower commission',
    imgSrc: comparisonYellow
  },
  lowestCommission: {
    label: 'Lowest commission',
    imgSrc: profit
  },
  probabilityAverageHitRateGreen: {
    label: 'Average hit rate',
    imgSrc: averageGreen,
  },
  probabilityAverageHitRateRed: {
    label: 'Average loss rate',
    imgSrc: averageRed,
  },
  benchmarkRRR: {
    label: 'Your average Return-Risk-Ratio',
    imgSrc: benchmarkRRR
  },
  benchmarkHR: {
    label: 'Your average Hit rate',
    imgSrc: benchmarkHR
  },
  returnRiskRatio: {
    label: 'Return-Risk ratio',
    imgSrc: RRR
  },
}

export default legendMap;