const performancePips = {
  chartTitle: 'Performance (pips)',
  999: [
    {
      time: new Date("2022-04"),
      percentage: -60,
      performancePercentage: -80,
      performancePips: 1361.9,
      equityTotal: 223560.55,
      equity: 9455.0,
      duration: "1Y 6M",
      trades: 57
    },
    {
      time: new Date("2022-05"),
      percentage: -40,
      performancePercentage: -40,
      performancePips: 1255.2,
      equityTotal: 223560.55,
      equity: 8666.5,
      duration: "1Y 6M",
      trades: 92
    },
    {
      time: new Date("2022-06"),
      percentage: 99.02,
      performancePercentage: 80,
      performancePips: 1285.9,
      equityTotal: 223560.55,
      equity: 8179.9,
      duration: "1Y 6M",
      trades: 81
    },
    {
      time: new Date("2022-07"),
      percentage: 98.68,
      performancePercentage: 80,
      performancePips: 1434.4,
      equityTotal: 223560.55,
      equity: 10068.6,
      duration: "1Y 6M",
      trades: 52
    },
    {
      time: new Date("2022-08"),
      percentage: 94.91,
      performancePercentage: 80,
      performancePips: 1511.4,
      equityTotal: 223560.55,
      equity: 9715.4,
      duration: "1Y 6M",
      trades: 29
    },
    {
      time: new Date("2022-09"),
      percentage: 94.87,
      performancePercentage: 80,
      performancePips: 1598.9,
      equityTotal: 223560.55,
      equity: 11498.2,
      duration: "1Y 6M",
      trades: 59
    },
    {
      time: new Date("2022-10"),
      percentage: 94.87,
      performancePercentage: 80,
      performancePips: 1581.2,
      equityTotal: 223560.55,
      equity: 11289.3,
      duration: "1Y 6M",
      trades: 71
    },
    {
      time: new Date("2022-11"),
      percentage: 94.84,
      performancePercentage: 80,
      performancePips: 1711.5,
      equityTotal: 223560.55,
      equity: 14217.8,
      duration: "1Y 6M",
      trades: 50
    },
    {
      time: new Date("2022-12"),
      percentage: -60,
      performancePercentage: 80,
      performancePips: 1802.8,
      equityTotal: 223560.55,
      equity: 12843.7,
      duration: "1Y 6M",
      trades: 54
    },
    {
      time: new Date("2023-01"),
      percentage: -60,
      performancePercentage: 80,
      performancePips: 1911.1,
      equityTotal: 223560.55,
      equity: 12850.3,
      duration: "1Y 6M",
      trades: 55
    },
    {
      time: new Date("2023-02"),
      percentage: -94.84,
      performancePercentage: 80,
      performancePips: 2044.8,
      equityTotal: 223560.55,
      equity: 13421.4,
      duration: "1Y 6M",
      trades: 70
    },
    {
      time: new Date("2023-03"),
      percentage: -94.84,
      performancePercentage: 80,
      performancePips: 2031.1,
      equityTotal: 223560.55,
      equity: 13408.1,
      duration: "1Y 6M",
      trades: 98
    },
    {
      time: new Date("2023-04"),
      percentage: -94.84,
      performancePercentage: 80,
      performancePips: 2236.5,
      equityTotal: 14980.5,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 140
    }
  ],
  hoverTextArray: {
    'textArray': [
      {
        title: 'Return: ',
        valueKey: 'performancePips',
        unit: 'pips',
        createTextColor: function(hoveredPointRawData, chartData) {
          return hoveredPointRawData.y > 0? '#C15357': '#56A07E';
        },
        createValueText: function(value) {
          if (value) {
            return value.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      },
      {
        title: 'Trades: ',
        valueKey: 'trades',
        unit: '',
      },
      {
        title: 'Equity: ',
        valueKey: 'equity',
        unit: 'USD',
      }
    ]
  }
};


export default performancePips;