import styles from "./speedometer.module.scss";
import speedometerImag from "assets/speedometer-dial-2.png";
import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Speedometer({ data }) {
  const { width } = useWindowDimensions();
  const colors = [
    "#39B54A",
    "#8DC63F",
    "#D7DF23",
    "#F9ED32",
    "#FBB040",
    "#F7941D",
    "#F15A29",
    "#E84630",
    "#BE1E2D",
  ];

  const pointerCircleStyling =
    width >= 540
      ? {
          width: "12px",
          height: "12px",
          backgroundColor: colors[data.severityScore - 1],
          borderRadius: "50%",
          border: "2px solid white",
          position: "absolute",
          left: "calc(50% - 8px)",
          bottom: "calc(50% - 8px)",
        }
      : {
          width: "8px",
          height: "8px",
          backgroundColor: colors[data.severityScore - 1],
          borderRadius: "50%",
          border: "2px solid white",
          position: "absolute",
          left: "calc(50% - 6px)",
          top: "34px",
        };

  return (
    <div className={styles.SpeedometerWrapper}>
      <img
        src={speedometerImag}
        alt="speedometer"
        className={styles.SpeedometerImage}
      />
      <div
        className={styles.PointerArrow}
        id={styles["animation" + data.severityScore.toString()]}
      ></div>
      <div style={pointerCircleStyling}></div>
      <div className={styles.AssessmentName}>{data.assessmentName}</div>
    </div>
  );
}

export default Speedometer;
