import { chartKeys } from 'utils/constant';

const profit_by_daytime_per_trade = {
  chartTitle: "Profit By Day Time",
  chartKey: chartKeys.PROFIT_BY_DAYTIME_PER_TRADE,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ]
  },
  interval: [
    'MN-0400',
    '0400-0800',
    '0800-NN',
    'NN-1600',
    '1600-2000',
    '2000-MN'
  ],
}

export default profit_by_daytime_per_trade;