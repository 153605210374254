import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { checkSandboxLoginStatus } from "utils/requests";
import SandboxDashboard from "./dashboard/dashboard.page";
import SandboxLogin from "./sandboxLogin";
import SandboxProtectedRoute from "./sandboxProtectedRoute";
import SandboxGeneralLayout from "UI/layout/sandboxGeneralLayout";
import Spinner from "UI/spinner";

function useSandboxAuth(setSandboxAuth) {
  const sandboxToken = sessionStorage.getItem("sandboxToken");
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (sandboxToken) {
        const result = await checkSandboxLoginStatus({ token: sandboxToken });
        if (result.message === "INVALID_TOKEN") {
          setSandboxAuth("UNAUTHENTICATED");
          return navigate("/sandbox/login");
        }
        if (result.message === "AUTHENTICATED") {
          setSandboxAuth("AUTHENTICATED");
          return <Navigate to="/sandbox/dashboard/*" />;
        }
      } else {
        setSandboxAuth("UNAUTHENTICATED");
        return navigate("/sandbox/login");
      }
    })();
  }, [sandboxToken, setSandboxAuth, navigate]);
}

function Sandbox() {
  const [sandboxAuth, setSandboxAuth] = useState("PENDING");
  const [errorMessage, setErrorMessage] = useState(null);
  useSandboxAuth(setSandboxAuth);

  return sandboxAuth === "PENDING" ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spinner />
    </div>
  ) : (
    <Routes>
      <Route
        path="/login"
        element={
          <SandboxLogin
            setSandboxAuth={setSandboxAuth}
            sandboxAuth={sandboxAuth}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        }
      />
      <Route
        path="/dashboard/*"
        element={
          <SandboxProtectedRoute sandboxAuth={sandboxAuth}>
            <SandboxGeneralLayout>
              <SandboxDashboard />
            </SandboxGeneralLayout>
          </SandboxProtectedRoute>
        }
      />
      <Route
        path="/"
        element={<Navigate to="/sandbox/login" />}
      />
    </Routes>
  );
}

export default Sandbox;
