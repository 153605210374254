import { createSlice } from '@reduxjs/toolkit';

const initialAlertState = [];

export const alertSlice = createSlice({
  name: 'alert',
  initialState: initialAlertState,
  reducers: {
    setAlertsData: (state, action) => {
      return [
        // ...state,
        ...action.payload.alertsData
      ]
    },
    addAlertData: (state, action) => {
      const currentAlertData = [...state];
      currentAlertData.push(action.payload.newAlertData);
      return currentAlertData;
    },
    setReadToTrueForSingleAlert: (state, action) => {
      const clonedArray = state.map((alert) => ({
        ...alert
      }));
      const { alertId } = action.payload;
      const targetIndex = clonedArray.findIndex((alert) => alert.alertId === alertId);
      clonedArray[targetIndex].read = true;
      return clonedArray;
    },
    deleteAlertByAlertId: (state, action) => {
      const { alertId } = action.payload;
      const clonedArray = state.map((alert) => ({
        ...alert
      }));
      const targetIndex = clonedArray.findIndex((alert) => alert.alertId === alertId);
      clonedArray.splice(targetIndex, 1);
      return clonedArray;
    },
    toggleAlertReadStatus: (state, action) => {
      const { alertId, readStatus } = action.payload;
      const clonedArray = state.map((alert) => ({
        ...alert
      }));
      const targetIndex = clonedArray.findIndex((alert) => alert.alertId === alertId);
      clonedArray[targetIndex].read = readStatus? readStatus : !clonedArray[targetIndex].read;
      return clonedArray;
    }
  },
});

export const { 
  setAlertsData,
  addAlertData,
  setReadToTrueForSingleAlert,
  deleteAlertByAlertId,
  toggleAlertReadStatus,
} = alertSlice.actions;

export const alertReducer = alertSlice.reducer;