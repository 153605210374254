//chart component
import BarChart from "components/charts/bar.chart";
import ProfitIfManualBeforeTPChart from "components/charts/profitIfManualBeforeTP.chart";
import PercentOfTradeStoppedEarlyProfit from "components/charts/percentOfTradeStoppedEarlyInProfit.chart";
import BarWithPercentageSymbolYAxis from "components/charts/barWithPercentageSymbolYAxis.chart";
import PositionWithSL from "components/charts/positionWithSL.chart";
import BarChartWithEmptyTicks from "components/charts/barWithEmptyTicks.chart";
import SpreadAndCommission from "components/charts/spreadAndCommission.chart";

const chartComponentMap = {
  // Behavior
  trades_per_day_per_trade: (props) => <BarChart {...props} />,
  break_since_last_trade_per_trade: (props) => <BarChart {...props} />,
  break_after_loss_trade_per_trade: (props) => <BarChart {...props} />,
  open_different_symbols_per_trade: (props) => <BarChart {...props} />,
  open_position_in_profit_loss: (props) => <BarChart {...props} />,
  profit_after_daily_profit_loss: (props) => <BarChart {...props} />,
  // timing
  profit_if_trade_open_earlier_later: (props) => <BarChart {...props} />,
  profit_if_trade_close_earlier_later: (props) => <BarChart {...props} />,
  profit_if_tp_closer_further_away: (props) => <BarChart {...props} />,
  profit_if_sl_closer_further_away: (props) => <BarChart {...props} />,
  profit_if_manual_stop_before_tp: (props) => <ProfitIfManualBeforeTPChart {...props} />,
  percent_of_trades_stopped_early_in_profit: (props) => <PercentOfTradeStoppedEarlyProfit {...props} />,
  // risk management
  risk_after_losing_trades_per_trade: (props) => <BarWithPercentageSymbolYAxis {...props} />,
  risk_after_losing_trades_open_trade: (props) => <BarWithPercentageSymbolYAxis {...props} />,
  profit_if_adding_to_negative_positions: (props) => <BarChart {...props} />,
  profit_secure_payoff_from_reverting_trades: (props) => <BarChart {...props} />,
  risk_per_trade_last_trades: (props) => <BarWithPercentageSymbolYAxis {...props} />,
  max_risk_all_open_trades_last_trades: (props) => <BarWithPercentageSymbolYAxis {...props} />,
  percentage_of_position_with_sl: (props) => <PositionWithSL {...props} />,
  // strategy
  profit_high_vs_low_volatility: (props) => <BarChartWithEmptyTicks {...props} />,
  profit_with_against_st_mt_trend: (props) => <BarChart {...props} />,
  profit_when_sl_adjusted_vs_profit_when_not: (props) => <BarChartWithEmptyTicks {...props} />,
  profit_if_publishing_trades: (props) => <BarChartWithEmptyTicks {...props} />,
  profit_after_withdrawal_in_pips: (props) => <BarChartWithEmptyTicks {...props} />,
  average_payoff_win_vs_loss_trade: (props) => <BarChartWithEmptyTicks {...props} />,
  profit_for_trade_during_overbought_oversold: (props) => <BarChartWithEmptyTicks {...props} />,
  // cost
  spread_and_commission: (props) => <SpreadAndCommission {...props} />,
  profit_of_conflicting_symbols: (props) => <BarChartWithEmptyTicks {...props} />,

  //general
  profit_by_daytime_per_trade: (props) => <BarChart {...props} />,
  long_and_short_per_trade: (props) => <BarChart {...props} />,
  profit_by_symbol_per_trade: (props) => <BarChart {...props} />,
  profit_by_trade_length_per_trade: (props) => <BarChart {...props} />,
  profit_by_weekday_per_trade: (props) => <BarChart {...props} />,
}

const createCorrespondChartForAlertModal = (chartKey, chartProps) => {
  let chartCompomentProps = chartProps;
  // risk management page
  if (chartKey === "risk_after_losing_trades_per_trade" || chartKey === "risk_after_losing_trades_open_trade") {
    chartCompomentProps.dualMode = false;
    chartCompomentProps.chartMode = "percentage";
  } else if (chartKey === "profit_if_adding_to_negative_positions" || chartKey === "profit_secure_payoff_from_reverting_trades") {
    chartCompomentProps.hasTimeFrame = true;
    chartCompomentProps.dualMode = true;
  } else if (chartKey === "risk_per_trade_last_trades" || chartKey === "max_risk_all_open_trades_last_trades" || chartKey === "percentage_of_position_with_sl") {
    chartCompomentProps.defaultUnit = "percentage";
    chartCompomentProps.currentTimeFrame = 999;
    chartCompomentProps.hasTimeFrame = false;
    chartCompomentProps.dualMode = false;
    chartCompomentProps.chartMode = "percentage";
  };
  // timing page
  if (chartKey === "profit_if_manual_stop_before_tp") {
    chartCompomentProps.hasTimeFrame = false;
  } else if (chartKey === "percent_of_trades_stopped_early_in_profit") {
    chartCompomentProps.defaultUnit = "percentage";
    chartCompomentProps.currentTimeFrame = 999;
    chartCompomentProps.hasTimeFrame = false;
    chartCompomentProps.dualMode = false;
  }
  // strategy page
  if (chartKey === "profit_after_withdrawal_in_pips") {
    chartCompomentProps.defaultUnit = "pips";
    chartCompomentProps.dualMode = false;
    chartCompomentProps.chartMode = "pips";
  }
  // cost page
  if (chartKey === "spread_and_commission") {
    chartCompomentProps.currentTimeFrame = 999;
    chartCompomentProps.defaultUnit = "number";
    chartCompomentProps.chartMode = "number";
    chartCompomentProps.dualMode = false;
    chartCompomentProps.hasTimeFrame = false;
  }

  const createFunction = chartComponentMap[chartKey];
  const chart = createFunction(chartCompomentProps);
  return chart;
}

export default createCorrespondChartForAlertModal;