import { Bar } from "react-chartjs-2";
import ChartHeader from "../../charts/chartHeader";
import styles from "./probabilityBar.module.scss";
import createProbabilityBarOptions from "./probabilityBar.options";

//plugins
import xAxisPlugin from "./xAxis.plugin";
import createBackgroundPlugin from "components/charts/plugins/createBackground.plugin";
import createHoverTextPlugin from "./hoverText.plugin";

function BarChart({ chartData, barValueKey }) {
  let { chartTitle } = chartData;
  let allTimeData = chartData[999];
  let barColor = barValueKey === "percentageGain" ? "#56A07E" : "#C15357";
  const options = createProbabilityBarOptions(barValueKey, chartData);
  const backgroundPlugin = createBackgroundPlugin(true);
  const hoverTextPlugin = createHoverTextPlugin(chartData, barValueKey);

  let allIntervals = allTimeData.map((data) => {
    let time = typeof data.time === 'string'? new Date(data.time) : data.time;
    let timeOffsetInMillSecs = (time.getTimezoneOffset())*60*1000;
    let timestamp = (time.getTime());
    let clientTime = new Date(timestamp+timeOffsetInMillSecs);
    
    return clientTime;
  })

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval.getMonth() === 0) {
      return interval = interval.toLocaleString("default", { year: "numeric" })
    }
    
    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return interval = interval.toLocaleString("default", { month: "short" });
      } else {
        showInterval = true;
        return "";
      }
    }
    return interval;
  });

  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: allTimeData.map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data[barValueKey]
          }
        }),
        yAxisId: "y",
        backgroundColor: barColor,
      },
    ]

  }

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader
        chartTitle={chartTitle}
        chartKey={chartData.chartKey}
      />
      <div className={styles.Chart}>
        <Bar 
          key={Math.random()}
          data={data}
          options={options}
          plugins={[
            backgroundPlugin,
            xAxisPlugin,
            hoverTextPlugin
          ]}
        />
      </div>
    </div>
  )
}

export default BarChart;