import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartHeader from "./chartHeader";

//styling
import styles from "./barChart.module.scss";
// import htFonts from "shared/htFont.module.scss";
//constant
import { ChartMode } from "constants/index";
//plugins
import createBackgroundPlugin from './plugins/createBackground.plugin';
import createOnHoverPlugin from "./plugins/hover.plugin";
import xAxisButtonPlugin from "./plugins/xAxisTicks.plugin";
import yZeroAxisPlugin from "./plugins/yZeroAxis.plugin";
// import createBarChartOptions from './barChart.options';
//helpers
import createBarChartOptions from './helpers/createBarChartOptions';
import createGeneralBarBorderColor from './helpers/createGeneralBarBorderColor';
import createGeneralBarColor from './helpers/createGeneralBarColor';
import createGeneralHoverBorderColor from './helpers/createGeneralHoverBorderColor';
import createGeneralHoverBarColor from './helpers/createGeneralHoverBarColor';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

/**
 * 
 * @param {object} props 
 * @property {boolean} dualMode default: false, if true will display pips button and % button for switching 
 * @property {boolean} hasTimeFrame
 *
 */

function BarChart(props) {
  const {
    dualMode, 
    chartTitle, 
    chartData, 
    hasTimeFrame, 
    // hoverTextArray, 
    chartMode, 
    setChartMode, 
    currentTimeFrame, 
    setCurrentTimeFrame,
    currentTradingAccount
  } = props;

  let hoverTextArray = props.chartData.createHoverTextArray? props.chartData.createHoverTextArray(currentTradingAccount.currency) : props.hoverTextArray

  if (chartData.noData) {
    return (
      <div className={styles.Chart}>
        <ChartHeader 
          chartTitle={chartTitle} 
          chartKey={chartData.chartKey} />
        <div className={styles.ChartButtonsContainer}>
          {
            dualMode? 
            <div className={styles.ModeButtonsContainer}>
              <div 
                className={
                  chartMode === ChartMode.PIPS?
                  `${styles.DualModeButton} ${styles.Selected}` :
                  styles.DualModeButton
                }
                id={styles.Pips}
                onClick={() => setChartMode(ChartMode.PIPS)}>Pips</div>
              <div 
                className={
                  chartMode === ChartMode.PERCENTAGE?
                  `${styles.DualModeButton} ${styles.Selected}` :
                  styles.DualModeButton
                }
                id={styles.Percentage}
                onClick={() => setChartMode(ChartMode.PERCENTAGE)}>%</div>
            </div> : null
          }
          {
            hasTimeFrame?
            <div className={styles.TimeButtonsContainer}>
              <div 
                className={
                  currentTimeFrame === 1?
                  `${styles.TimeButton} ${styles.Selected}` : `${styles.TimeButton}`
                }
                onClick={() => setCurrentTimeFrame(1)}>1m</div>
              <div 
                className={
                  currentTimeFrame === 3?
                  `${styles.TimeButton} ${styles.Selected}` : `${styles.TimeButton}`
                }
                onClick={() => setCurrentTimeFrame(3)}>3m</div>
              <div 
                className={
                  currentTimeFrame === 12?
                  `${styles.TimeButton} ${styles.Selected}` : `${styles.TimeButton}`
                }
                onClick={() => setCurrentTimeFrame(12)}>1y</div>
              <div 
                className={
                  currentTimeFrame === 999?
                  `${styles.TimeButton} ${styles.Selected}` : `${styles.TimeButton}`
                }
                onClick={() => setCurrentTimeFrame(999)}>all</div>
            </div> : null
          } 
        </div>
        <div className={styles.ChartContainer} style={{position: 'relative'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: 20,}}>
            Not sufficient data available yet
          </div>
        </div>
      </div>
    )
  }

  const backgroundPlugin = createBackgroundPlugin(true);
  const barColor = 
    chartData.createBarColor? 
    chartData.createBarColor(chartData, currentTimeFrame) : null;
  const hoverBarColor = 
    chartData.createHoverBarColor? 
    chartData.createHoverBarColor(chartData, currentTimeFrame) : null;
  const generalBarBorderColor = createGeneralBarBorderColor(chartData, currentTimeFrame, hasTimeFrame, chartMode);
  const generalBarColor = createGeneralBarColor(chartData, currentTimeFrame, hasTimeFrame, chartMode);
  const generalHoverBarColor = createGeneralHoverBarColor(chartData, currentTimeFrame, hasTimeFrame, chartMode);
  const generalHoverBorderColor = createGeneralHoverBorderColor(chartData, currentTimeFrame, hasTimeFrame, chartMode);

  const hoverTextPlugin = createOnHoverPlugin(hoverTextArray[chartMode]);
  const barChartOptions = createBarChartOptions(chartData, currentTimeFrame, chartMode);

  let interval = chartData.interval?
    chartData.interval : 
    chartData.createIntervals?
    chartData.createIntervals(chartData, currentTimeFrame) : Object.keys(chartData[currentTimeFrame].data);
  const data = {
    labels: interval,
    datasets: [
      {
        data: interval.map((interval , index)=> {
          if (hasTimeFrame && currentTimeFrame) {
            return {
              x: interval,
              ...chartData[currentTimeFrame].data[interval],
            }
          } else {
            return {
              x: interval,
              ...chartData[999].data[interval],
            }
          }
        }),
        borderWidth: 1,
        borderColor: barColor ? barColor : generalBarBorderColor,
        backgroundColor: barColor ? barColor : generalBarColor,
        maxBarThickness: 40,
        barPercentage: 0.5,
        borderRadius: 5,
        minBarLength: 1,
        hoverBackgroundColor: hoverBarColor ? hoverBarColor : generalHoverBarColor,
        hoverBorderColor: hoverBarColor ? hoverBarColor : generalHoverBorderColor,
        parsing: {
          yAxisKey: chartMode
        }
      },
    ],
  }
  return (
    <div className={styles.Chart}>
      <ChartHeader 
        chartTitle={chartTitle} 
        chartKey={chartData.chartKey} />
      <div className={styles.ChartButtonsContainer}>
        {
          dualMode? 
          <div className={styles.ModeButtonsContainer}>
            <div 
              className={
                chartMode === ChartMode.PIPS?
                `${styles.DualModeButton} ${styles.Selected}` :
                styles.DualModeButton
              }
              id={styles.Pips}
              onClick={() => setChartMode(ChartMode.PIPS)}>Pips</div>
            <div 
              className={
                chartMode === ChartMode.PERCENTAGE?
                `${styles.DualModeButton} ${styles.Selected}` :
                styles.DualModeButton
              }
              id={styles.Percentage}
              onClick={() => setChartMode(ChartMode.PERCENTAGE)}>%</div>
          </div> : null
        }
        {
          hasTimeFrame?
          <div className={styles.TimeButtonsContainer}>
            <div 
              className={
                currentTimeFrame === 1?
                `${styles.TimeButton} ${styles.Selected}` : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(1)}>1m</div>
            <div 
              className={
                currentTimeFrame === 3?
                `${styles.TimeButton} ${styles.Selected}` : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(3)}>3m</div>
            <div 
              className={
                currentTimeFrame === 12?
                `${styles.TimeButton} ${styles.Selected}` : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(12)}>1y</div>
            <div 
              className={
                currentTimeFrame === 999?
                `${styles.TimeButton} ${styles.Selected}` : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(999)}>all</div>
          </div> : null
        } 
      </div>
      <div className={styles.ChartContainer}>
        <Bar 
          key={Math.random()}
          options={
            barChartOptions
          }
          data={data}
          plugins={[
            backgroundPlugin,
            xAxisButtonPlugin,
            yZeroAxisPlugin,
            hoverTextPlugin,
          ]} /> 
      </div>
    </div>
  )
}


export default BarChart;