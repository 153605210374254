import { chartKeys } from 'utils/constant';

const profit_if_sl_closer_further_away = {
  chartTitle: "Profit if SL closer/further away",
  chartKey: chartKeys.PROFIT_IF_SL_CLOSER_FURTHER_AWAY,
  hoverTextArray: {
    'pips': [
      {
        templateString: '',
        valueKey: 'pips',
        unit: 'pips',
        textColor: function(hoveredData) {
          return hoveredData.pips >= 0? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        },
        unit: 'USD',
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'pipsBenchmark',
        createLabelColor: function(hoveredData) {
          return "#FFB700";
        },
        textColor: function(hoveredData) {
          return '#FFB700';
        }
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        },
        unit: 'USD',
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function(hoveredData) {
          return "#FFB700";
        },
        textColor: function(hoveredData) {
          return '#FFB700';
        }
      }
    ]
  },
  createHoverTextArray: (currency) => {
    return {
      'pips': [
        {
          templateString: '',
          valueKey: 'pips',
          unit: 'pips',
          textColor: function(hoveredData) {
            return hoveredData.pips >= 0? "#56A07E" : "#C15357"
          }
        },
        {
          templateString: '',
          valueKey: 'profit',
          textColor: function(hoveredData) {
            return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
          },
          unit: currency,
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'pipsBenchmark',
          createLabelColor: function(hoveredData) {
            return "#FFB700";
          },
          textColor: function(hoveredData) {
            return '#FFB700';
          }
        }
      ],
      'percentage': [
        {
          templateString: '',
          valueKey: 'percentage',
          unit: '%',
          textColor: function(hoveredData) {
            return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
          }
        },
        {
          templateString: '',
          valueKey: 'profit',
          textColor: function(hoveredData) {
            return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
          },
          unit: currency,
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'percentageBenchmark',
          createLabelColor: function(hoveredData) {
            return "#FFB700";
          },
          textColor: function(hoveredData) {
            return '#FFB700';
          }
        }
      ]
    }
  },
  interval: [
    '-10pip',
    '-5pip',
    '-3pip',
    '-1pip',
    'as-is',
    '1pip',
    '3pip',
    '5pip',
    '10pip'
  ],
}

export default profit_if_sl_closer_further_away;