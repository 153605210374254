import { createSlice } from '@reduxjs/toolkit';
import * as modalTypes from 'constants/modalTypes';
import chartsInfoModalContent from 'devData/chartsInfoModalContent';

const modalContentEssentialKeys = {
  [modalTypes.CHART_INFO_MODAL]: ['displayedModal', 'displayedModalDataKey'],
  [modalTypes.COMMISSION_COMPARISON_MODAL]: ['displayedModal', 'selectedBroker', 'sortedBrokersData'],
}

function checkEssentialKeys(keys, payload) {
  if (keys) {
    keys.forEach(key => {
      if (!payload[key]) {
        console.error(`Missing modal data key: ${key} to open modal: ${payload.displayedModal}`)
      }
    });
  }
}

const initModalState = {
  displayedModal: null,
  displayedModalDataKey: null,
  allChartsInfoModalContent: chartsInfoModalContent,
  commissionComparisonData: null,
  selectedBroker: [],
  sortedBrokersData: [],
  // currentBroker: 'current-fx'
  modalData: null,
  selectedAlertData: null,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState: initModalState,
  reducers: {
    openModal: (state, action) => {
      const essentialKeys = modalContentEssentialKeys[action.payload.displayedModal];
      checkEssentialKeys(essentialKeys, action.payload);
      return {
        ...state,
        ...action.payload
      }
    },
    closeModal: (state, action) => {
      state.displayedModal = null;
      state.selectedBroker = [];
      state.sortedBrokersData = [];
      state.modalData = null;
    },
    updateModal: (state, action) => {
      return {
        ...initModalState,
        ...action.payload
      }
    },
    updateModalData: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    getAllChartsInfoModalContent: (state, action) => {
      state.allChartsInfoModalContent = action.payload;
    },
    getCommissionComparisonData: (state, action) => {
      state.commissionComparisonData = action.payload;
    },
  },
});

export const { 
  openModal, 
  closeModal, 
  updateModal,
  getAllChartsInfoModalContent,
  getCommissionComparisonData,
  updateModalData,
} = modalSlice.actions;

export const modalReducer = modalSlice.reducer;