import chartKeys from './chartKeys';
const chartKeysByPage = {
  general: [
    chartKeys.PROFIT_BY_DAYTIME_PER_TRADE,
    chartKeys.LONG_AND_SHORT_PER_TRADE,
    chartKeys.PROFIT_BY_SYMBOL_PER_TRADE,
    chartKeys.PROFIT_BY_TRADE_LENGTH_PER_TRADE,
    chartKeys.PROFIT_BY_WEEKDAY_PER_TRADE,
    chartKeys.TOP_FIVE_PATTERNS_GENERAL,
  ],
  behavioral: [
    chartKeys.TRADES_PER_DAY_PER_TRADE,
    chartKeys.BREAK_SINCE_LAST_TRADE_PER_TRADE,
    chartKeys.BREAK_AFTER_LOSS_TRADE_PER_TRADE,
    chartKeys.OPEN_DIFFERENT_SYMBOLS_PER_TRADE,
    chartKeys.OPEN_POSITION_IN_PROFIT_LOSS,
    chartKeys.PROFIT_AFTER_DAILY_PROFIT_LOSS,
    chartKeys.TOP_FIVE_PATTERNS_BEHAVIOR,
  ],
  strategy: [
    chartKeys.PROFIT_HIGH_VS_LOW_VOLATILITY,
    chartKeys.PROFIT_WITH_AGAINST_ST_MT_TREND,
    chartKeys.PROFIT_WHEN_SL_ADJUSTED_VS_PROFIT_WHEN_NOT,
    chartKeys.PROFIT_IF_PUBLISHING_TRADES,
    chartKeys.PROFIT_AFTER_WITHDRAWAL_IN_PIPS,
    chartKeys.AVERAGE_PAYOFF_WIN_VS_LOSS_TRADE,
    chartKeys.PROFIT_FOR_TRADE_DURING_OVERBOUGHT_OVERSOLD,
    chartKeys.TOP_FIVE_PATTERNS_STRATEGIC,
  ],
  'risk-management': [
    chartKeys.RISK_AFTER_LOSING_TRADES_PER_TRADE,
    chartKeys.RISK_AFTER_LOSING_TRADES_OPEN_TRADE,
    chartKeys.PROFIT_IF_ADDING_TO_NEGATIVE_POSITIONS,
    chartKeys.PROFIT_SECURE_PAYOFF_FROM_REVERTING_TRADES,
    chartKeys.RISK_PER_TRADE_LAST_TRADES,
    chartKeys.MAX_RISK_ALL_OPEN_TRADES_LAST_TRADES,
    chartKeys.PERCENTAGE_OF_POSITION_WITH_SL
  ],
  timing: [
    chartKeys.PROFIT_IF_TRADE_OPEN_EARLIER_LATER,
    chartKeys.PROFIT_IF_TRADE_CLOSE_EARLIER_LATER,
    chartKeys.PROFIT_IF_TP_CLOSER_FURTHER_AWAY,
    chartKeys.PROFIT_IF_SL_CLOSER_FURTHER_AWAY,
    chartKeys.PROFIT_IF_MANUAL_STOP_BEFORE_TP,
    chartKeys.PERCENT_OF_TRADES_STOPPED_EARLY_IN_PROFIT,
    chartKeys.TOP_FIVE_PATTERNS_TIMING
  ],
  costs: [
    chartKeys.SPREAD_AND_COMMISSION,
    chartKeys.PROFIT_OF_CONFLICTING_SYMBOLS,
  ],
  'account-overview': [
    chartKeys.GENERAL_INFO,
    chartKeys.PERFORMANCE_OVER_TIME,
    chartKeys.DRAWDOWN_BALANCE,
    chartKeys.TRADES_IN_PATTERNS_COUNT,
    chartKeys.TRADES_IN_PATTERNS_TIMEVIEW,
    chartKeys.TOP_FIVE_PATTERNS_OVERALL,
  ],
  'basic-stats': [
    chartKeys.BALANCE_CURRENCY,
    chartKeys.PERFORMANCE_PIPS,
    chartKeys.PERFORMANCE_BY_MONTHS,
    chartKeys.AVERAGE_PIPS_PER_TRADE,
    chartKeys.AVERAGE_TRADES_PER_DAY,
    chartKeys.AVERAGE_TRADE_LENGTH,
    chartKeys.SYMBOLS
  ],
  'advanced-stats': [
    chartKeys.KEY_STATISTICS,
    chartKeys.BEST_WORST_TRADE,
    chartKeys.PROBABILITY_GAIN_AFTER_GAIN,
    chartKeys.PROBABILITY_LOSS_AFTER_LOSS,
    chartKeys.RETURN_RISK_RATIO_HIT_RATIO,
  ]
}

export default chartKeysByPage;