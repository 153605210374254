import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TradeMedicContentContainer from "./tradeMedicContentContainer";
import Spinner from "UI/spinner";
import { validateTradeMedicResponsivePageToken } from "utils/requests";

function TradeMedicResponsive() {
  const [isPending, setIsPending] = useState(true);
  const [tradeMedicData, setTradeMedicData] = useState("");
  const location = useLocation();
  const search = location.search;
  const query = new URLSearchParams(search);
  const token = query.get("t");

  useEffect(() => {
    (async function() {
      const result = await validateTradeMedicResponsivePageToken(token);
      if (result.message === "authorized") {
        setTradeMedicData(result.data);
        setIsPending(false);
      } else {
        setIsPending(false);
      }
    })();
  }, [token]);

  if (isPending) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (tradeMedicData) {
    return <TradeMedicContentContainer tradeMedicData={tradeMedicData} />;
  }

  return (
    <div
      style={{
        margin: "0 auto",
        fontWeight: "bold",
        color: "#4D4D4D",
        paddingTop: "40px",
      }}
    >
      We're having issue fetching charts' data. Please contact our support.
    </div>
  );
}

export default TradeMedicResponsive;
