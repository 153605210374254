import createBarColorComparison from "components/charts/helpers/createBarColorComparison";
import createHoverBarColorComparison from "components/charts/helpers/createHoverBarColorComparison";
import createBarBorderColorComparison from "components/charts/helpers/createBarBorderColorComparison";
import { chartKeys } from 'utils/constant';

const profit_if_publishing_trades = {
  chartTitle: "Profit if publishing trades",
  chartKey: chartKeys.PROFIT_IF_PUBLISHING_TRADES,
  createBarColorComparison: createBarColorComparison,
  createHoverBarColorComparison: createHoverBarColorComparison,
  createBarBorderColorComparison: createBarBorderColorComparison,
  hoverTextArray: {
    usd: [
      {
        templateString: "",
        valueKey: "usd",
        unit: "USD/trade",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "usdBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        },
        unit: ""
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "percentageBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
  },
  haspercentageBenchmark: true,
  interval: ["before", "after"],
}

export default profit_if_publishing_trades;