import { useEffect, useState } from "react";
import styles from "./accountSelection.module.scss";
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentTradingAccount, getUser } from "redux/selectors";
import { sendLogoutRequest } from 'utils/requests';
import { setUser, setAuthenticationState, setCurrentTradingAccount, setLogOut } from 'redux/slices/authenticationSlice'
import { authenticationStates } from 'utils/constant';
import { resetChartsData } from 'redux/slices/chartSlices';

function AccountSelection(props) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  //night mode should be in react-redux
  const [isNightMode, setIsNightMode] = useState(false);
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const user = useSelector(getUser);

  useEffect(() => {
    function closeModal(e) {
      if (e.keyCode === 27) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', closeModal)
    }
    return () => document.removeEventListener('keydown', closeModal);
  }, [isOpen])

  const logout = async () => {
    const result = await sendLogoutRequest();

    if (result.message === "ACCOUNT_LOGGED_OUT") {
      dispatch(resetChartsData());
      dispatch(setLogOut());
    }
  }

  return (
    <>
    <div className={styles.AccountSelection}>
      <div className={styles.AvatarContainer}>
        <img 
          className={styles.Avatar}
          src={'https://hoctrade.s3.us-west-1.amazonaws.com/hoctrade_logo_for_profile.png'} 
          alt="Avatar"/>
      </div>
      <div className={styles.Dropdown}>
        <div className={styles.WalletIconContainer}>
          <div className={styles.htWallet} id={styles.Wallet}></div>
          <div className={styles.VerifiedIconContainer}>
            <span className={styles.VerifiedIcon}></span>
          </div>
        </div>
        <span className={styles.CurrentAccount}>
          {/* Winnie-fx-demo */}
          {currentTradingAccount? currentTradingAccount.portfolio_name : '-'}
        </span>
          {
            <div className={isOpen? `${styles.AccountList} ${styles.isOpen}` : styles.AccountList}>
              <hr className={styles.SeparateLine}/>
              <div className={styles.SwitchAccount}>Switch trade Account</div>
              {
                user.trading_accounts.map(account => 
                  <div
                    key={account.account_id}
                    className={
                      account.trading_account_id === currentTradingAccount.trading_account_id? 
                      styles.SelectedAccountOption: styles.AccountOption}>
                    <div className={styles.htWallet}>
                    {
                      account.verified? 
                      <div className={styles.VerifiedIconContainer}>
                        <span className={styles.VerifiedIcon}></span>
                      </div> : null
                    }
                    </div>
                    { account.portfolio_name }
                  </div>
                )
              }
              <hr className={styles.SeparateLine}/>
              <div className={styles.DropdownOption}>
                <div className={styles.htWalletSetting}></div>
                Trade account setting
              </div>
              <div className={styles.DropdownOption}>
                <div className={styles.htWalletAdd}></div>
                Add trade account</div>
              <hr className={styles.SeparateLine}/>
              <div 
                className={styles.DropdownOption}
                onClick={() => setIsNightMode(!isNightMode)}>
                <div className={styles.htMoon}></div>
                Dark mode
                <div
                  className={styles.switch}>
                  <input 
                    type="checkbox" 
                    onChange={() => console.log('toggle is night mode')}
                    checked={isNightMode} />
                  <span 
                    className={`${styles.slider} ${styles.round}`}>
                  </span>
                </div>
              </div>
              <div className={styles.DropdownOption}>
                <div className={styles.htSupport}></div>
                <a href="https://discord.com/invite/hcvytJnb5U" target="blank" rel="noreferrer nooopener">
                  Help & support
                </a>
              </div>
              <div className={styles.Logout} onClick={() => logout()}>
                <div className={styles.htLogout}></div>
                Logout</div>
            </div>
          }
      </div>
      <div 
        className={isOpen? styles.CloseButton: styles.OpenButton }
        onClick={() => setIsOpen(!isOpen)}>
        {
          isOpen? 
            <span className={styles.TriangleFlip}></span> : 
            <span className={styles.Triangle}></span>
        }
      </div>
    </div>
    {
      isOpen?
        <div style={{ position: 'fixed', top: 0, left: 0, height: '100%', width: '100%' }}
          onClick={() => setIsOpen(false)}></div>
         : null
    }
    </>
  )
}

export default AccountSelection;