import styles from "./generalLayout.module.scss";
import SandboxNavbar from "components/navbar/sandboxNavbar";
import SandboxSidebar from "components/sidebar/sandboxSidebar";
import SandboxMobileSidebar from "components/sidebar/sandboxMobileSidebar";
import Breadcrumb from "components/breadcrumb/breadcrumb";
import { useState } from "react";

function SandboxGeneralLayout(props) {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  return (
    <div className={styles.GeneralLayout}>
      <SandboxNavbar />
      <SandboxSidebar />
      <div className={styles.Page}>
        <Breadcrumb
          isMobileSidebarOpen={isMobileSidebarOpen}
          setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        />
        <div className={styles.Content}>{props.children}</div>
      </div>
      {isMobileSidebarOpen ? (
        <SandboxMobileSidebar
          isMobileSidebarOpen={isMobileSidebarOpen}
          setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        />
      ) : null}
    </div>
  )
}

export default SandboxGeneralLayout;