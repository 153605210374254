import { chartKeys } from 'utils/constant';

const profit_secure_payoff_from_reverting_trades = {
  chartTitle: "Profit secure, payoff from reverting trades",
  chartKey: chartKeys.PROFIT_SECURE_PAYOFF_FROM_REVERTING_TRADES,
  hoverTextArray: {
    pips: [
      {
        templateString: "",
        valueKey: "pips",
        unit: "pips",
        // textColor: function (hoveredData) {
        //   return hoveredData.pips >= 0 ? "#56A07E" : "red";
        // },
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        // textColor: function(hoveredData) {
        //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
        // },
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        // textColor: function (hoveredData) {
        //   return hoveredData.percentage >= 0 ? "#56A07E" : "red";
        // },
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        // textColor: function(hoveredData) {
        //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
        // },
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
    ],
  },
  interval: ["5-10", "11-15", "16-20", "21-30", "31-40", "40+"],
}

export default profit_secure_payoff_from_reverting_trades;