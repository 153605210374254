import createDiagonalPattern from "./createDiagonalPattern";

// create the bar color when hover the two-bar chart, the lower one is red and the higher one is green.
function createHoverBarColorComparison(
  chartData,
  currentTimeFrame,
  chartMode
) {
  const intervals = chartData.interval;

  let highValue;

  intervals.forEach(interval => {
    const barData = chartData[currentTimeFrame].data[interval];
    if (!highValue || barData[chartMode] > highValue) {
      highValue = barData[chartMode];
    }
  });

  const barColors = intervals.map(interval => {
    const barData = chartData[currentTimeFrame].data[interval];
    const tradeCount = barData.trades;

    if (tradeCount < 5 ) {
      return createDiagonalPattern("#999999");
    } else if (tradeCount > 20) {
      if (barData[chartMode] === highValue) {
        //high value color
        return '#009245';
      } else {
        // low value color
        return '#BE1E2D';
      }
    } else {
      if (barData[chartMode] === highValue) {
        //high value color
        return createDiagonalPattern('#009245')
      } else {
        // low value color
        return createDiagonalPattern('#BE1E2D')
      }
    }
  });

  return barColors;
}

export default createHoverBarColorComparison;
