import { useState } from "react";
import BarChart from "components/charts/bar.chart";
import TopFivePatterns from "components/topFivePatterns";
import styles from "../../../dashboard/analysis/general.module.scss";
// utils
import { chartKeys } from "utils/constant";
import { createDataObjectForChart } from "utils/helpers/chart";
import { ChartMode } from "utils/constant/index";
import { getSandboxChartsData } from "redux/selectors";
import { useSelector } from "react-redux";

function SandboxGeneral({ chartKeysOfThePage, currentTradingAccount }) {
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const chartsData = useSelector(getSandboxChartsData(chartKeysOfThePage));
  let chartDataObjects = {};
  chartKeysOfThePage.forEach((chartKey) => {
    chartDataObjects[chartKey] = createDataObjectForChart(
      chartKey,
      chartsData[chartKey]
    );
  });

  return (
    <>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns
          chartData={chartDataObjects[chartKeys.TOP_FIVE_PATTERNS_GENERAL]}
        />
      </div>
      <BarChart
        key="Profit by daytime (per trade)"
        chartTitle="Profit by daytime (per trade)"
        chartData={chartDataObjects[chartKeys.PROFIT_BY_DAYTIME_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.PROFIT_BY_DAYTIME_PER_TRADE].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Long & short (per trade)"
        chartTitle="Long & Short (per trade)"
        chartData={chartDataObjects[chartKeys.LONG_AND_SHORT_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.LONG_AND_SHORT_PER_TRADE].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Profit By Symbol (Per trade)"
        chartTitle="Profit By Symbol (Per trade)"
        chartData={chartDataObjects[chartKeys.PROFIT_BY_SYMBOL_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.PROFIT_BY_SYMBOL_PER_TRADE].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Profit By Trade Length (Per trade)"
        chartTitle="Profit By Trade Length (Per trade)"
        chartData={chartDataObjects[chartKeys.PROFIT_BY_TRADE_LENGTH_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.PROFIT_BY_TRADE_LENGTH_PER_TRADE]
            .hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Profit by weekday (per trade)"
        chartTitle="Profit by weekday (per trade)"
        chartData={chartDataObjects[chartKeys.PROFIT_BY_WEEKDAY_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.PROFIT_BY_WEEKDAY_PER_TRADE].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
    </>
  );
}

export default SandboxGeneral;
