const drawVerticalLine = (ctx, x, y2, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x, 45);
  ctx.lineTo(x, y2);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawCircleOnBar = (ctx, x, y, strokeStyle, fillStyle) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle;
  ctx.fillStyle = fillStyle;
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
};

const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1, y+25);
  ctx.lineTo(x2, y+25);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawOnHoverText = (ctx, hoveredDataPoint, chartData) => {
  // Measure text width
  let timeObject = hoveredDataPoint.raw.x;
  let timeOffsetInMillSecs = (timeObject.getTimezoneOffset()) * 60 * 1000;
  let timestamp = (timeObject.getTime());
  let clientTime = new Date(timestamp + timeOffsetInMillSecs);
  let time = clientTime.toLocaleString("default", { year: "numeric", month: "short" });

  let hoveTextArray = chartData.hoverTextArray.textArray;
  let textString = '';

  textString = hoveTextArray.map((textObject) => {
    let title = textObject.title;
    let valueText = hoveredDataPoint.raw[`${textObject.valueKey}`];
    if (textObject.createValueText) {
      valueText = textObject.createValueText(hoveredDataPoint.raw[`${textObject.valueKey}`]);
    }
    return `${title}${valueText}`;
  }).join(' ');
  const textStringWidth = ctx.measureText(textString).width + 20;
  // Get start point
  const chartArea = hoveredDataPoint.chart.chartArea;
  const centerX = (chartArea.right + chartArea.left) / 2 - 20;
  let currentX = centerX - textStringWidth / 2;

  hoveTextArray.forEach((textObject) => {
    ctx.save();
    ctx.font = '12px sans-serif';
    ctx.fillStyle = "#FFFFFF";
    const title = textObject.title;
    const value = textObject.createValueText? 
      textObject.createValueText(hoveredDataPoint.raw[`${textObject.valueKey}`]) :
      hoveredDataPoint.raw[`${textObject.valueKey}`];
    const unit = textObject.unit;
    if (title) {
      ctx.fillText(title, currentX, 40);
      currentX += ctx.measureText(title).width;
    }
    if (textObject.createTextColor) {
      ctx.fillStyle = textObject.createTextColor(hoveredDataPoint.raw, chartData);
    }
    ctx.font = '700 12px sans-serif';
    ctx.fillText(value, currentX, 40);
    currentX += ctx.measureText(value).width;
    if (unit) {
      ctx.fillText(unit, currentX, 40);
      currentX += ctx.measureText(unit).width;
    }
    currentX += 10;
    ctx.restore();
  });

  //draw time string, such as "Apr 2022"
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "#06AFC9";
  const timeTextStringWidth = ctx.measureText(time).width;
  const timeTextStartPointX = hoveredDataPoint.element.x - (timeTextStringWidth / 2);
  const timeTextEndPointX = hoveredDataPoint.element.x + timeTextStringWidth / 2;
  let drawingStartPointX = 0;

  if (timeTextStartPointX <= 10) {
    drawingStartPointX = 10;
  } else if (timeTextEndPointX > chartArea.right - 10) {
    drawingStartPointX = chartArea.right - timeTextStringWidth;
  } else {
    drawingStartPointX = timeTextStartPointX;
  }
  ctx.fillText(time, drawingStartPointX, 18)
  ctx.restore();
};

const createPerformanceHoverTextPlugin = (chartData) => {
  const hoverPlugin = {
    id: "performance_hover_text_plugin",
    beforeDraw: (chart) => {
      // disable original tooltips
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      // const chartData = chart.data.datasets[0].data;
      tooltip.opacity = 0;

      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const pointElement = tooltip.dataPoints[0].element;
        const chartArea = chart.chartArea;

        drawVerticalLine(ctx, pointElement.x, chartArea.bottom, "#06AFC9");
        drawCircleOnBar(ctx, pointElement.x, pointElement.y, "#06AFC9", "white");
        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          0,
          "#00333B"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          0.5,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          20.5,
          "#06AFC9"
        );

        drawOnHoverText(ctx, tooltip.dataPoints[0], chartData);
      }
    }

  }

  return hoverPlugin
}

export default createPerformanceHoverTextPlugin;