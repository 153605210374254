const createBarColor = (barValueKey, chartData) => {
  let barColors;
  if (barValueKey === "percentage" || barValueKey === "pips" || barValueKey === "usd") {
    barColors = chartData.map((data) => {
      if (data[barValueKey] >= 0 ) {
        return "#56A07E";
      } else {
        return "#C15357";
      }
    })
  } else {
    barColors = "#047F92";
  }
  return barColors;
}

export default createBarColor;