let API_BASE_URL;
let SOCKET_IO_URL;
let CAPTCHA_KEY;

let buildEnvironment = process.env.REACT_APP_BUILD_ENVIRONMENT;
let nodeEnv = process.env.NODE_ENV;

if (nodeEnv === 'development') {
  API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  SOCKET_IO_URL = process.env.REACT_APP_SOCKET_IO_URL;
  CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY
}
// we put staging under "NODE_ENV = production" because we are running "npm run build" to build for staging
if (nodeEnv === 'production') {
  API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  SOCKET_IO_URL = process.env.REACT_APP_SOCKET_IO_URL;
  CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  if (buildEnvironment === 'staging') {
    API_BASE_URL = process.env.REACT_APP_STAGING_API_BASE_URL;
    SOCKET_IO_URL = process.env.REACT_APP_STAGING_SOCKET_IO_URL;
    CAPTCHA_KEY = process.env.REACT_APP_STAGING_CAPTCHA_SITE_KEY
  }
}

export {
  API_BASE_URL,
  SOCKET_IO_URL,
  CAPTCHA_KEY
}