const basicStaticBarOptions = (barValueKey, chartData) => {
  const options =  {
    animation: {
      duration: 0,
    },
    interaction: {
      mode: "x",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      autocolors: false,
    },
    layout: {
      padding: {
        bottom: 0,
        left: 30,
        top: 60,
        right: 20
      },
    },
    borderRadius: 5,
    maxBarThickness: 15,
    borderWidth: 1,
    scales: {
      y: {
        grace: '5%',
        grid: {
          color: (context) => {
            return '#4D4D4D'
          },
          borderDash: (a) => {
            return [1, 2];
          },
          drawBorder: false,
          tickLength: -20,
          tickBorderDash: (a) => {
            return [1, 2];
          }
        },
        ticks: {
          color: "#999999",
          font: {
            size: 10,
            family: 'Noto Sans',
            weight: 700
          },
          labelOffset: -7,
          padding: 0,
          mirror: true,
          maxTicksLimit: 5,
        },
      },
      x: {
        grid: {
          offset: false,
          color: '#4D4D4D',
          borderDash: [1, 2],
          borderColor: '#4D4D4D',
          borderWidth: 1,
          tickColor: "#4D4D4D",
          tickLength: 4,
        },
        ticks: {
          padding: 0,
          color: "#999999",
          font: {
            size: 10
          },
        },
      }
    },
  }

  // create customized y ticks with percentage symbol
  if (barValueKey === "percentage") {
    let percentageScalesYTicks = {
      color: "#999999",
      font: {
        size: 10,
        family: 'Noto Sans',
        weight: 700
      },
      labelOffset: -7,
      padding: 0,
      mirror: true,
      maxTicksLimit: 6,
      callback: function (value, index, ticks) {
        if (index === ticks.length-1) {
          return ["%", value, ""]
        } else {
          return value
        }
      }
    }
    options.scales.y.ticks = percentageScalesYTicks;
  }

  // create benchmark annotations
  const annotations = {};
  if (barValueKey !== "percentage") {
    // for (let i = 0; i < chartData.length; i++) {
    //   annotations[`line-${i}`] = {
    //     type: "line",
    //     // yMin: chartData[i].benchmark,
    //     // yMax: chartData[i].benchmark,
    //     // xMin: (ctx) => {
    //     //   const chart = ctx.chart;
    //     //     const barElements = chart._metasets[0].data;
    //     //     const barElement = barElements[i];
    //     //     if (barElements.length > 1) {
    //     //       const gridSpace = barElements[1].x - barElements[0].x;
    //     //       if (barElement.x && barElement.width) {
    //     //         const perc = barElements[i].width / gridSpace;
    //     //         return i - perc / 2;
    //     //       }
    //     //     }

    //     // },
    //     // xMax: (ctx) => {
    //     //   const chart = ctx.chart;
    //     //     const barElements = chart._metasets[0].data;
    //     //     const barElement = barElements[i];
    //     //     if (barElements.length > 1) {
    //     //       const gridSpace = barElements[1].x - barElements[0].x;
    //     //       if (barElement.x && barElement.width) {
    //     //         const perc = barElements[i].width / gridSpace;
    //     //         return i + perc / 2;
    //     //       }
    //     //     }
    //     // },
    //     borderColor: "#FFB700",
    //     borderWidth: 1
    //   };
    //   annotations[`point-${i}`] = {
    //     type: "point",
    //     xValue: i,
    //     yValue: chartData[i].benchmark,
    //     backgroundColor: "#FFCD00",
    //     borderColor: "#FFB700",
    //     radius: 2
    //   };
  
    // }
  
    options.plugins.annotation = {
      annotations
    }
  }
  return options;
};

export default basicStaticBarOptions;