import styles from './loginAndRegister.module.scss';
import LoginModal from 'components/modals/loginModal';
import SignUpModal from 'components/modals/signUpModal';
import ResetPasswordModal from 'components/modals/resetPasswordModal';
import { useLocation, Navigate } from 'react-router-dom';
import { getUser, getAuthenticationState} from "redux/selectors";
import { useSelector } from 'react-redux';
import { authenticationStates } from 'utils/constant';
import Spinner from 'UI/spinner';

function LoginAndRegister() {
  const path = useLocation().pathname;
  const [
    user,
    authenticationState
  ] = [useSelector(getUser), useSelector(getAuthenticationState)];

  let pageContent;

  if (path === '/login' && !user && authenticationState !== authenticationStates.AUTHENTICATED) {
    pageContent = <LoginModal />
  } else if (path === '/register' && !user && authenticationState !== authenticationStates.AUTHENTICATED) {
    pageContent= <SignUpModal />
  } else if (path === '/reset-password') {
    pageContent = <ResetPasswordModal />;
  } else if (user && authenticationState === authenticationStates.AUTHENTICATED) {
    return <Navigate to="/dashboard/overview/account-overview" />;
  } else {
    pageContent = <Spinner />;
  }

  return (
    <div className={styles.Page}>
      <div className={styles.DecorationImage}>
      </div>
      <div className={styles.RightColumn}>
        <div className={styles.FormContainer}>
          <h1>hoc-trade</h1>
          { pageContent }
        </div>  
      </div>
    </div>
  )
}

export default LoginAndRegister;