import styles from "../signUpModal.module.scss";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modalSlice';

//constants
import * as modalTypes from 'constants/modalTypes';

// assets
import unCheckIcon from "assets/unCheckedIcon.svg";
import checkedIcon from "assets/checkedIcon.svg";
import createAccountArrow from "assets/createAccountArrow.svg";


function SignUpForm(props) {
  const setSignUpPhase = props.setSignUpPhase;
  const setSignUpData = props.setSignUpData;
  const errorMessage = props.errorMessage;
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
    pwValidators: {
      eightCharacters: false,
      upperCase: false,
      lowerCase: false,
      number: false,
      allowedSymbols: false,
    },
    usernameValidator: false,
    emailValidator: false,
    confirmedPassword: false,
  });
  const [agreement, setAgreement] = useState(false);

  // debounce
  const [usernameInput, setUsernameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [passwordTwoInput, setPasswordTwoInput] = useState("");
  const debouncedUsernameInput = useDebounce(usernameInput, 1000);
  const debouncedEmailInput = useDebounce(emailInput, 1000);
  const debouncedPasswordTwoInput = useDebounce(passwordTwoInput, 500);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 1000);
      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  useEffect(() => {
      if (debouncedUsernameInput) {
        checkUsernameLength(debouncedUsernameInput);
      }
      if (debouncedEmailInput) {
        checkValidEmail(debouncedEmailInput);
      }
      if (debouncedPasswordTwoInput) {
        confirmPassword(debouncedPasswordTwoInput);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      debouncedUsernameInput, 
      debouncedEmailInput, 
      debouncedPasswordTwoInput,
      formData.password
    ]
  );

  // validations
  const validatePassword = (password) => {
    let validators = {
      eightCharacters: false,
      upperCase: false,
      lowerCase: false,
      number: false,
      allowedSymbols: false,
    };

    const allowedSymbolsRegExp = RegExp("[@#$%]");
    validators.allowedSymbols = allowedSymbolsRegExp.test(password);
    const atLeastOneDigitRegExp = RegExp("[0-9]");
    validators.number = atLeastOneDigitRegExp.test(password);
    const lowerCaseRegExp = RegExp("[a-z]");
    validators.lowerCase = lowerCaseRegExp.test(password);
    const upperCaseRegExp = RegExp("[A-Z]");
    validators.upperCase = upperCaseRegExp.test(password);

    if (password.length >= 8) {
      validators.eightCharacters = true;
    } else {
      validators.eightCharacters = false;
    }

    return validators;
  };

  const checkUsernameLength = (debouncedUsernameInput) => {
    let lengthCount = debouncedUsernameInput.length;
    if (lengthCount <= 25) {
      setFormData({
        ...formData,
        username: debouncedUsernameInput,
        usernameValidator: true,
      });
    } else {
      setFormData({
        ...formData,
        usernameValidator: false,
      });
    }
  };

  const checkValidEmail = (debouncedEmailInput) => {
    const validEmailRegExp = RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    if (validEmailRegExp.test(debouncedEmailInput)) {
      setFormData({
        ...formData,
        email: debouncedEmailInput,
        emailValidator: true,
      });
    } else {
      setFormData({
        ...formData,
        emailValidator: false,
      });
    }
  };

  const confirmPassword = (debouncedPasswordTwoInput) => {
    if (debouncedPasswordTwoInput === formData.password) {
      setFormData({
        ...formData,
        password2: debouncedPasswordTwoInput,
        confirmedPassword: true,
      });
    } else {
      setFormData({
        ...formData,
        password2: "",
        confirmedPassword: false,
      });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    switch (name) {
      case "password":
        const result = validatePassword(value);
        setFormData({
          ...formData,
          password: value,
          pwValidators: result,
        });
        break;
      default:
    }
  };

  const openLoginModal = () => {
    return dispatch(openModal({
      displayedModal: modalTypes.LOGIN_MODAL,
    }));
  }

  let signUpBtn = false;
  if (
    formData.pwValidators.eightCharacters &&
    formData.pwValidators.upperCase &&
    formData.pwValidators.lowerCase &&
    formData.pwValidators.allowedSymbols &&
    formData.pwValidators.number &&
    formData.usernameValidator &&
    formData.emailValidator &&
    formData.confirmedPassword
  ) {
    signUpBtn = true;
  } else {
    signUpBtn = false;
  }

  // Submit form
  const register = (e) => {
    e.preventDefault();
    setSignUpData(formData);
    setSignUpPhase(2);
  };

  return (
    <form className={styles.Signupform}>
      <div className={styles.usernameInputSection}>
        <input
          className={styles.usernameInput}
          type="text"
          placeholder="Username"
          name="username"
          value={usernameInput}
          maxLength="25"
          onChange={(e) => setUsernameInput(e.target.value)}
          required
        />
        {debouncedUsernameInput.length !== 0 ? (
          <div className={styles.checkUsernameLength}>
            {debouncedUsernameInput.length}/25
            {formData.usernameValidator ? (
              <img src={checkedIcon} alt="icon" />
            ) : (
              <img src={unCheckIcon} alt="icon" />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <input
        className={styles.formInput}
        type="email"
        placeholder="Email"
        name="email"
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
        required
      />
      {(errorMessage && emailInput === "") ? (
        <div style={{ color: "red", width: "70%", fontSize: "12px" }}>
          {errorMessage}
        </div>
      ) : (
        <></>
      )}
      {debouncedEmailInput && !formData.emailValidator ? (
        <div style={{ color: "red", width: "70%", fontSize: "12px" }}>
          Invalid email address
        </div>
      ) : (
        <></>
      )}
      <div className={styles.validationSection}>
        <div className={styles.checkStatusTitle}>
          Setup password, it MUST be:
        </div>
        <div className={styles.checkStatus}>
          {formData.pwValidators.eightCharacters ? (
            <img src={checkedIcon} alt="icon" />
          ) : (
            <img src={unCheckIcon} alt="icon" />
          )}
          <div> at least 8 characters</div>
        </div>
        <div className={styles.checkStatus}>
          {formData.pwValidators.upperCase ? (
            <img src={checkedIcon} alt="icon" />
          ) : (
            <img src={unCheckIcon} alt="icon" />
          )}
          <div> uppercase</div>
        </div>
        <div className={styles.checkStatus}>
          {formData.pwValidators.lowerCase ? (
            <img src={checkedIcon} alt="icon" />
          ) : (
            <img src={unCheckIcon} alt="icon" />
          )}
          <div> lowercase</div>
        </div>
        <div className={styles.checkStatus}>
          {formData.pwValidators.number ? (
            <img src={checkedIcon} alt="icon" />
          ) : (
            <img src={unCheckIcon} alt="icon" />
          )}
          <div> number</div>
        </div>
        <div className={styles.checkStatus} id={styles.checkStatusFive}>
          {formData.pwValidators.allowedSymbols ? (
            <img src={checkedIcon} alt="icon" />
          ) : (
            <img src={unCheckIcon} alt="icon" />
          )}
          <div> any following symbols: @ # $ %</div>
        </div>
      </div>
      <input
        className={styles.formInput}
        type="password"
        placeholder="Password"
        name="password"
        value={formData.password}
        onChange={(e) => handleChange(e)}
        required
      />
      <input
        className={styles.formInput}
        type="password"
        placeholder="Re-enter password"
        name="password2"
        value={passwordTwoInput}
        onChange={(e) => setPasswordTwoInput(e.target.value)}
        required
      />
      {debouncedPasswordTwoInput &&
      !formData.confirmedPassword &&
      formData.password ? (
        <div style={{ color: "red", width: "70%", fontSize: "12px" }}>
          Passwords don't match
        </div>
      ) : (
        <></>
      )}
      <div className={styles.AgreementSection}>
        <label>
          <input
            type="checkbox"
            name="agreement"
            onChange={(e) => setAgreement(e.target.checked)}
          />
          I have read and agree to the <a href="/*">terms & conditions</a> and{" "}
          <a href="/*">privacy & cookie policies</a>.
          <br />
          By signing up you will receive news and notifications from hoc-trade,
          which you may unsubscribe any time.
        </label>
      </div>
      <div className={styles.ButtonContainer}>
        <button
          className={styles.signUpBtn}
          id={signUpBtn && agreement ? styles.enabledBtn : styles.disabledBtn}
          type="submit"
          onClick={(e) => register(e)}
          disabled={signUpBtn && agreement ? false : true}
        >
          Create account
          <img src={createAccountArrow} alt="arrow icon" />
        </button>
      </div>
      <div className={styles.DirectToSignIn}>
        Already registered?{" "}
        <a href="/login" className={styles.SignInButton}>
          <strong>SIGN IN</strong>
        </a>
      </div>
    </form>
  );
}

export default SignUpForm;
