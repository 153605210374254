import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import styles from "./performance.module.scss";
import options from "./performance.options";
import ChartHeader from "../../../charts/chartHeader";
import { Link } from "react-router-dom";
import htFonts from "shared/htFont.module.scss";

// plugins
import createPerformancePipsPlugin from "./performance.plugin";
import createBackgroundPlugin from "components/charts/plugins/createBackground.plugin";
import xAxisPlugin from "../../accountOverview/xAxis.plugin";
import createPerformancePipsHoverTextPlugin from "./performanceHoverText.plugin";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  // annotationPlugin
);

const PerformancePips = (props) => {
  const { chartData } = props;
  // const hoverTextArray = chartData.hoverTextArray;
  const performancePipsPlugin = createPerformancePipsPlugin();
  const backgroundPlugin = createBackgroundPlugin(true);
  const hoverTextPlugin = createPerformancePipsHoverTextPlugin(chartData);
  let scoreboardData = chartData[999][chartData[999].length-1];

  let allIntervals = chartData[999].map((data) => {
    let time = data.time;
    let timeOffsetInMillSecs = time.getTimezoneOffset() * 60 * 1000;
    let timestamp = time.getTime();
    let clientTime = new Date(timestamp + timeOffsetInMillSecs);

    return clientTime;
  });

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval.getMonth() === 0) {
      return (interval = interval.toLocaleString("default", {
        year: "numeric",
      }));
    }

    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return (interval = interval.toLocaleString("default", {
          month: "short",
        }));
      } else {
        showInterval = true;
        return "";
      }
    }
    return interval;
  });


  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: chartData[999].map((data) => {
          return {
            x: data.time,
            y: data.performanceUsd
          }
        }),
        yAxisId: "y",
      },
      // feed data to right y axis
      {
        data: chartData[999].map((data) => {
          return {
            x: data.time,
            y: data.performanceUsd
          }
        }),
        yAxisID: "y1",
      }
    ]
  }

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader 
        chartTitle={chartData.chartTitle}
        chartKey="performance_pips"
      />
      <div className={styles.Chart}>
        <Line
          key={Math.random()}
          data={data}
          options={options}
          plugins={[
            performancePipsPlugin,
            backgroundPlugin,
            xAxisPlugin,
            hoverTextPlugin
          ]}
        />
      </div>
      <div className={styles.Scoreboard}>
        <div className={styles.ScoreboardLeft}>
          <div className={styles.columnTwo}>
            <div style={{color: "#06AFC9"}}>Equity</div>
            <div>{scoreboardData.equityTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD</div>
            <div className={styles.Label}>{scoreboardData.equity.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD</div>
          </div>
        </div>
        <div className={styles.ScoreboardRight}>
          <div style={{color: "#06AFC9"}}>Age</div>
          <div>{scoreboardData.duration}</div>
          <Link to="/dashboard/overview/account-overview" style={{ textDecoration: 'none' }}>
            <div className={styles.ScoreboardBtn}>
              <span className={htFonts.htMedal}></span>
              Scoreboard
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PerformancePips;