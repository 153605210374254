import styles from "./mobileSidebar.module.scss";
import htFonts from "shared/htFont.module.scss";
import { sidebarItemsList } from "./sidebarItemsList";
import { useLocation, Link } from "react-router-dom";

function MobileSidebar({ isMobileSidebarOpen, setIsMobileSidebarOpen }) {
  const location = useLocation();
  const locationArray = location.pathname.split("/");

  return (
    <div className={styles.MobileSidebar}>
      <div className={styles.Backdrop} onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}></div>
      <div className={styles.SidebarContent}>
        <div className={styles.SidebarHeader}>
          <div
            className={styles.CloseContainer}
            onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
          >
            <span className={styles.Close}>+</span>
          </div>
          <div
            className={
              locationArray[1] === "dashboard"
                ? `${styles.HeaderTitle} ${styles.Selected}`
                : `${styles.HeaderTitle}`
            }
          >
            Dashboard
          </div>
        </div>
        <div className={styles.SidebarBody}>
          <div
            className={
              locationArray[2] === "overview"
                ? `${styles.ItemTitle} ${styles.Selected}`
                : `${styles.ItemTitle}`
            }
          >
            <span className={`${htFonts.htDashboard} ${styles.Icon}`}></span>

            {sidebarItemsList.overview.title}
          </div>
          <hr className={styles.SeperatedLine}></hr>
          <div className={styles.ItemsList}>
            {sidebarItemsList.overview.itemsList.map((item) => {
              return (
                <Link to={item.path} key={item.path} onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}>
                  <div
                    className={
                      location.pathname === item.path
                        ? `${styles.ItemContainer} ${styles.Selected}`
                        : `${styles.ItemContainer}`
                    }
                    key={item.path}
                  >
                    <span
                      className={`${htFonts[item.iconClassName]} ${
                        styles.ItemIcon
                      }`}
                    ></span>
                    {item.label}
                  </div>
                </Link>
              );
            })}
          </div>
          <hr className={styles.SeperatedLine}></hr>
          <div
            className={
              locationArray[2] === "analysis"
                ? `${styles.ItemTitle} ${styles.Selected}`
                : `${styles.ItemTitle}`
            }
          >
            <span className={`${htFonts.htBarChart} ${styles.Icon}`}></span>

            {sidebarItemsList.analysis.title}
          </div>
          <hr className={styles.SeperatedLine}></hr>
          <div className={styles.ItemsList}>
            {sidebarItemsList.analysis.itemsList.map((item) => {
              return (
                <Link to={item.path} key={item.path} onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}>
                  <div
                    className={
                      location.pathname === item.path
                        ? `${styles.ItemContainer} ${styles.Selected}`
                        : `${styles.ItemContainer}`
                    }
                    key={item.path}
                  >
                    <span
                      className={`${htFonts[item.iconClassName]} ${
                        styles.ItemIcon
                      }`}
                    ></span>
                    {item.label}
                  </div>
                </Link>
              );
            })}
          </div>
          <hr className={styles.SeperatedLine}></hr>
          <Link to="/dashboard/trade-medic" style={{textDecoration: "none"}} onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}>
          <div
            className={
              locationArray[2] === "trade-medic"
                ? `${styles.ItemTitle} ${styles.Selected}`
                : `${styles.ItemTitle}`
            }
          >
            <span className={`${htFonts.htMedbox} ${styles.Icon}`}></span>

            Trade Medic
          </div>
          </Link>
          <hr className={styles.SeperatedLine}></hr>
          <Link to="/dashboard/alert" style={{textDecoration: "none"}} onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}>
          <div
            className={
              locationArray[2] === "alert"
                ? `${styles.ItemTitle} ${styles.Selected}`
                : `${styles.ItemTitle}`
            }
          >
            <span className={`${htFonts.htBell} ${styles.Icon}`}></span>

            Alert
          </div>
          </Link>
          <hr className={styles.SeperatedLine}></hr>
        </div>
      </div>
    </div>
  );
}

export default MobileSidebar;
