import { useSelector } from "react-redux";
import { getUser, getAuthenticationState } from "redux/selectors";
import { authenticationStates } from "utils/constant";
import { Navigate } from "react-router-dom";

function Landing() {
  const user = useSelector(getUser);
  const authenticationState = useSelector(getAuthenticationState);

  if (user && authenticationState === authenticationStates.AUTHENTICATED) {
    return <Navigate to="/dashboard" />;
  }

  return <Navigate to='/login' />;
}

export default Landing;