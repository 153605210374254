import { useState } from "react";
import BarChart from "components/charts/bar.chart";
import ProfitIfManualBeforeTPChart from 'components/charts/profitIfManualBeforeTP.chart';
import PercentOfTradeStoppedEarlyProfit from 'components/charts/percentOfTradeStoppedEarlyInProfit.chart';
import TopFivePatterns from "components/topFivePatterns";
import styles from "../../../dashboard/analysis/general.module.scss";
// utils
import { createDataObjectForChart } from "utils/helpers/chart";
import { ChartMode } from "utils/constant/index";
import { getSandboxChartsData } from "redux/selectors";
import { useSelector } from "react-redux";

function SandboxTiming({ chartKeysOfThePage, currentTradingAccount }) {
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const chartsData = useSelector(getSandboxChartsData(chartKeysOfThePage));
  let chartDataObjects = {};
  chartKeysOfThePage.forEach((chartKey) => {
    chartDataObjects[chartKey] = createDataObjectForChart(
      chartKey,
      chartsData[chartKey]
    );
  });

  return (
    <>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns chartData={chartDataObjects["top_5_patterns_timing"]}/>
      </div> 
      <BarChart 
        key="Profit if trade open earlier/later"
        chartTitle="Profit if trade open earlier/later"
        chartData={chartDataObjects["profit_if_trade_open_earlier_later"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_trade_open_earlier_later'].hoverTextArray}
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart 
        key="Profit if trade close earlier/later"
        chartTitle="Profit if trade close earlier/later"
        chartData={chartDataObjects["profit_if_trade_close_earlier_later"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_trade_close_earlier_later'].hoverTextArray} 
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart 
        key="Profit if TP close/further away"
        chartTitle="Profit if TP closer/further away"
        chartData={chartDataObjects["profit_if_tp_closer_further_away"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_tp_closer_further_away'].hoverTextArray} currentTradingAccount={currentTradingAccount}
      />
      <BarChart 
        key="Profit if SL closer/further away"
        chartTitle="Profit if SL closer/further away"
        chartData={chartDataObjects["profit_if_sl_closer_further_away"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true} 
        hoverTextArray={chartDataObjects['profit_if_sl_closer_further_away'].hoverTextArray} currentTradingAccount={currentTradingAccount}
      />
      <ProfitIfManualBeforeTPChart 
        key="Profit if manual stop before TP"
        chartTitle="Profit if manual stop before TP"
        chartData={chartDataObjects["profit_if_manual_stop_before_tp"]}
        hasTimeFrame={false}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_manual_stop_before_tp'].hoverTextArray} 
        currentTradingAccount={currentTradingAccount}
      />
      <PercentOfTradeStoppedEarlyProfit 
        key="Percent of trades stopped early in profit"
        chartTitle="Percent of trades stopped early in profit"
        chartData={chartDataObjects["percent_of_trades_stopped_early_in_profit"]}
        hasTimeFrame={false}
        currentTimeFrame={'999'}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={'percentage'}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects['percent_of_trades_stopped_early_in_profit'].hoverTextArray} />
    </>
  )
}

export default SandboxTiming;