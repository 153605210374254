import chartKeys from './chartKeys';
import chartKeysByPage from './chartKeysByPage';
import alertTypeReasonTextMap from './alertTypeReasonTextMap';
import responsiveTradeMedicChartKeys from './responsiveTradeMedicChartKeys';

const authenticationStates = {
  AUTHENTICATED: "AUTHENTICATED",
  AUTHENTICATION_PENDING: "AUTHENTICATION_PENDING",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED"
}

const ChartMode = {
  PIPS: "pips",
  PERCENTAGE: "percentage",
  NUMBER: "number",
};

export {
  authenticationStates,
  chartKeys,
  ChartMode,
  chartKeysByPage,
  alertTypeReasonTextMap,
  responsiveTradeMedicChartKeys,
}