function createPercentageSymbolBarChartOptions() {
  const baseBarChartOptions = {
    interaction: {
      mode: 'x',
      intersect: false
    },
    responsive: true,
    maintainAspectRatio: false,
    axes: {
      backgroundColor: '#4D4D4D',
    },
    scales: {
      y: {
        grace: '5%',
        grid: {
          color: (context) => {
            return '#4D4D4D'
          },
          borderDash: (a) => {
            return [1, 2];
          },
          // borderColor: '#4D4D4D'
          drawBorder: false,
          tickLength: -20,
          tickBorderDash: (a) => {
            return [1, 2];
          }
        },
        ticks: {
          color: "#999999",
          font: {
            size: 10,
            family: 'Noto Sans',
            weight: 700
          },
          labelOffset: -7,
          padding: 0,
          mirror: true,
          maxTicksLimit: 5,
          callback: function(value, index, ticks) {
            let lastLabel = ticks[ticks.length - 1]
            if (value === lastLabel.value) {
              return ["%", lastLabel.value, ""]
            } else {
              return value
            }
          }
        },
      },
      x: {
        grid: {
          offset: false,
          color: '#4D4D4D',
          borderDash: [1, 2],
          borderColor: '#4D4D4D',
          borderWidth: 1,
          tickColor: "#4D4D4D",
          tickLength: 4,
        },
        ticks: {
          padding: 0,
          color: "#999999",
          font: {
            size: 10
          }
        }
      }
    },
    layout: {
      padding: {
        bottom: 10,
        left: 30,
        right: 10,
        top: 50
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    },
    devicePixelRatio: 2,
  };
  return baseBarChartOptions;
}



export default createPercentageSymbolBarChartOptions;