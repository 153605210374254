let profit = [];
let profitValue = 11;

for (let i = 0; i<365; i++) {
  let date = new Date();
  date.setDate(date.getDate() + i);
  const topping = Math.random().toFixed(2) > 0.5;

  if (topping) {
    profitValue += 0.36;
  } else {
    profitValue += 0;
  }

  profit[i] = {
    date: date,
    trades: profitValue,
    lots: profitValue,
  }
};


let loss = [];
let lossValue = 60;
for (let i = 0; i<365; i++) {
  let date = new Date();
  date.setDate(date.getDate() + i);
  const topping = Math.random().toFixed(2) > 0.5;

  if (topping) {
    lossValue += 0.17;
  } else {
    lossValue += 0;
  }

  loss[i] = {
    date: date,
    trades: lossValue,
    lots: lossValue
  }
};

let insiginificant = [];
for (let i = 0; i<365; i++) {
  let date = new Date();
  date.setDate(date.getDate() + i);

  insiginificant[i] = {
    date: date,
    trades: 100,
    lots: 100,
  }
};

const tradesPattern = {
  chartTitle: 'Trades made in profit/loss making patterns',
  999: {
    profit: profit,
    loss: loss,
    insiginificant: insiginificant
  },
  lastestData: {
    profit: {
      patterns: 84,
      // pips: 3431,
      // usd: 2877000,
      usd: 3431,
      profitByDaytime: 3431,
      riskPerTrade: 2745,
      profitBySymbol: 1700,
      positionWithSL: 1029,
      profitIfTradeOpen: 343
    },
    loss: {
      patterns: 42,
      // pips: -2466,
      // usd: 1911000,
      usd: -2466,
      profitByDaytime: 3131,
      riskPerTrade: 2945,
      profitBySymbol: 1900,
      positionWithSL: 1029,
      profitIfTradeOpen: 143
    },
    insiginificant: {
      patterns: 39,
      // pips: 3431,
      // usd: 2877000
    }
  },
}

export default tradesPattern;