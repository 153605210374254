import styles from "./progressBars.module.scss";
import ProgressBar from "./progressBar";

const backgroundColorMap = {
  'profit': '#1E4B42',
  'loss': '#443035',
}

const hoverColorMap = {
  'profit': '#009245',
  'loss': '#C1272D',
}

const fontColorMap = {
  'profit': '#56A07E',
  'loss': '#C15357',
}

const ProgressBars = ({ data, type }) => {
  if (data[12] === 'no_user_data' || data.noData) {
    return;
  }

  const chartData = data[12].data.filter((pattern) => pattern.type === type).sort((a, b) => b.value - a.value);

  let processedBarData = chartData.map((barData) => ({
    ...barData,
    backgroundColor: backgroundColorMap[barData.type],
    hoverColor: hoverColorMap[barData.type],
    fontColor: fontColorMap[barData.type],
  }));

  return (
    <div className={styles.progressBarWrapper}>
      {
        processedBarData.map((data, index) => {
          return <ProgressBar key={`${data.key}-${index}`} data={data}/>
        })
      }
    </div>
  );
};

export default ProgressBars;
