const generalInfo = {
  profitPercentage: 0.54,
  // profitPips: 1232,
  profitUSD: 733.00,
  lastThirtyDaysPercentage: 1,
  drawdownPercentage: -5.00,
  balance: 223560.55,
  trades: 430,
  // pipsPerTrade: 2.9,
  since: "Mar-03-2021"
}

export default generalInfo;