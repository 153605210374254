import { chartKeys } from 'utils/constant';

const open_different_symbols_per_trade = {
  chartTitle: 'Open different symbols per trade',
  chartKey: chartKeys.OPEN_DIFFERENT_SYMBOLS_PER_TRADE,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'usdBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        },
        unit: ""
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ]
  },
  interval: [
    '1',
    '2',
    '3',
    '4-5',
    '5-10',
    '10+'
  ],
}

export default open_different_symbols_per_trade