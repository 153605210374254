import { Routes, Route, Navigate } from "react-router-dom";

import General from "./general.page";
import RiskManagement from "./riskManagement.page";
import Behavioral from "./behavioral.page";
import Timing from "./timing.page";
import Strategy from "./strategy.page";
import Costs from './costs.page';
import SubPageWrapper from "./SubPageWrapper";
import { chartKeysByPage } from "utils/constant";
import styles from './SubPageWrapper/index.module.scss';

function AnalysisIndex({ currentTradingAccount }) {
  const pathAndComponentPairs = [
    { path: '/general', Component: General },
    { path: '/risk-management', Component: RiskManagement },
    { path: '/behavioral', Component: Behavioral },
    { path: '/timing', Component: Timing },
    { path: '/strategy', Component: Strategy },
    { path: '/costs', Component: Costs }
  ];

  let routes = pathAndComponentPairs.map(({path, Component}) => {
    return (
      <Route 
        path={path} 
        element={
          <SubPageWrapper>
            <Component currentTradingAccount={currentTradingAccount} />
          </SubPageWrapper>
        } />
    );
  })

  return (
    <div className={styles.Subpage}>
      <Routes>
        { routes }
        <Route path="/*" element={<Navigate to="/general" replace />}/>
      </Routes>
    </div>
  );
}

export default AnalysisIndex;
