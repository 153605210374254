const drawVerticalLine = (ctx, x, y2, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x, 30);
  ctx.lineTo(x, y2);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawCircleOnBar = (ctx, x, y, strokeStyle, fillStyle) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle;
  ctx.fillStyle = fillStyle;
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
};

const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1, y+10);
  ctx.lineTo(x2, y+10);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawOnHoverText = (ctx, hoveredDataPoint, chartData) => {
  // Measure text width
  let index = hoveredDataPoint.dataIndex;
  let textStringWidth = 0;
  let timeObject = chartData[index].time;
  // let timeObject = hoveredDataPoint.raw.x;
  if (typeof timeObject === 'string') {
    timeObject = new Date(timeObject);
  }
  let timeOffsetInMillSecs = (timeObject.getTimezoneOffset())*60*1000;
  let timestamp = (timeObject.getTime());
  let clientTime = new Date(timestamp+timeOffsetInMillSecs);
  let time = clientTime.toLocaleString("default", { year: "numeric", month: "short" });
  let equity = chartData[index].equity.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let depositWithdrawal = chartData[index].depositWithdrawal;
  let textString = `Time: ${time} Value: ${equity} Transfer: ${depositWithdrawal}`;
  textStringWidth += ctx.measureText(textString).width;
  // let percentageString = hoveredDataPoint.raw.y+"%";
  // let textString = `Time: ${time} Return: ${percentageString}`;
  // textStringWidth += ctx.measureText(textString).width;

  // // Get start point
  const chartArea = hoveredDataPoint.chart.chartArea;
  const centerX = (chartArea.right + chartArea.left) / 2 -20;
  let currentX = centerX - textStringWidth / 2;

  ctx.save();
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "#FFFFFF";
  ctx.fillText("Time: ", currentX, 25);
  currentX += ctx.measureText("Time: ").width;
  ctx.font = "700 12px sans-serif";
  ctx.fillText(time, currentX, 25);
  currentX += (ctx.measureText(time).width + 20);
  ctx.font = "12px sans-serif";
  ctx.fillText("Value: ", currentX, 25);
  currentX += ctx.measureText("Value: ").width;
  // ctx.fillStyle = equity >=0 ? "#56A07E" : "#C15357";
  ctx.font = "700 12px sans-serif";
  ctx.fillText(equity, currentX, 25);
  currentX += ctx.measureText(equity).width+20;
  ctx.font = "12px sans-serif";
  ctx.fillText("Transfer: ", currentX, 25);
  currentX += ctx.measureText("Transfer: ").width;
  ctx.font = "700 12px sans-serif";
  ctx.fillStyle = depositWithdrawal >=0 ? "#56A07E" : "#C15357";
  ctx.fillText(depositWithdrawal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","), currentX, 25);
  ctx.restore();
};

const createOnHoverTextPlugin = (chartData) => {
  const onHoverTextPlugin = {
    beforeDraw: (chart) => {
      // disable original tooltips
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      // const chartData = chart.data.datasets[0].data;
      tooltip.opacity = 0;

      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const pointElement = tooltip.dataPoints[0].element;
        const chartArea = chart.chartArea;

        drawVerticalLine(ctx, pointElement.x, chartArea.bottom, "#06AFC9");
        drawCircleOnBar(ctx, pointElement.x, pointElement.y, "#06AFC9", "white");
        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          20,
          "#00333B"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          0.5,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          20.5,
          "#06AFC9"
        );

        drawOnHoverText(ctx, tooltip.dataPoints[0], chartData[999]);
      }
    }
  }

  return onHoverTextPlugin;
}

export default createOnHoverTextPlugin;