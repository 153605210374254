const xAxis = {
  id: "custom_x_axis",
  afterDraw: (chart) => {
    const { ctx, scales } = chart;
    const labelItems = scales.x._labelItems;
    ctx.strokeStyle = "#4D4D4D";
    ctx.strokeWidth = '1px';
    ctx.beginPath();
    //draw vertical ticks
    labelItems.forEach((item, index) => {
      ctx.moveTo(
        labelItems[index].translation[0] + (index % 2? 0.5 : 0),
        labelItems[index].translation[1] + labelItems[0].font.lineHeight + 4
      );
      ctx.lineTo(
        labelItems[index].translation[0] + (index % 2? 0.5 : 0),
        labelItems[index].translation[1] + labelItems[0].font.lineHeight
      )
    });
    //draw extra horizontal
    ctx.moveTo(scales.x.left - 5, labelItems[0].translation[1] + labelItems[0].font.lineHeight + 4.5);
    ctx.lineTo(
      scales.x.left + scales.x.maxWidth,
      labelItems[0].translation[1] + labelItems[0].font.lineHeight + 4.5
    )
    ctx.stroke();
    //draw extra length in x axis
    ctx.beginPath();
    const { left, top } = chart.scales.x;
    ctx.moveTo(left, top);
    ctx.lineTo(left - 5, top);
    ctx.stroke();
  }
}

export default xAxis;