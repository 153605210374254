const addTradingAccountInfoContent = {
  your_trading_software: {
    title: "Your trading software",
    content: `<p>Select the trading software you are using for your trading account.</p>

    <p>Note: Additional trading software may be enabled in the future</p>`,
  },
  your_broker: {
    title: "Your broker",
    content: `<p>Please select the broker you use for trading</p>`,
  },
  your_server: {
    title: "Your server",
    content: `<p>Please select the server from your broker which you use for trading. Note: select the same server you use when connecting in the Metatrader account. If your server is not listed, please reach out to us here <a href="https://dr-fee.com/contact">https://dr-fee.com/contact</a></p>`,
  },
  login_id: {
    title: "Login-ID",
    content: `<p>Enter the Login-ID from your trading software (for example MetaTrader 4). You can find the Login-ID in your MetaTrader (if logged-in) or in the email from your broker</p>`,
  },
  investor_password: {
    title: "Investor Password",
    content: `<p>Enter your Investor-password to your trading account.<br />
    Note: We cannot perform any action on your trading account with the investor password; it provides us a read-only access to your trading account so we can retrieve the historic and ongoing trades in order to perform the analyses.</p>
    <p>Not sure how to find your investor password? Check our YouTube video HERE (Link:&nbsp;<a href="https://www.youtube.com/watch?v=qpLP1De09qc" rel="noopener noreferrer" target="_blank">https://www.youtube.com/watch?v=qpLP1De09qc</a>)</p>
    
    <p>Important: Do NOT enter your normal (master) password from your trading account!</p>`,
  },
  portfolio_name: {
    title: "Portfolio name",
    content: `<p>Choose a unique name for your portfolio.</p>

    <p>Note: This name may be publicly visible in case you opt to publish your account</p>`,
  },
  trading_experience: {
    title: "Level of trading experience",
    content: `<p>Select how much trading experience you have.</p>

    <p>Analyses may be tailored to your trading experience.</p>
    
    <p>Note: This information cannot be changed anymore at a later stage.</p>`,
  },
  trading_type: {
    title: "Trading type",
    content: `<p>Select whether you trade manually or automated (Expert Advisor (EA)/ automated trading system).</p>

    <p>Analyses may be tailored to your trading type.</p>
    
    <p>Note: This information cannot be changed anymore at a later stage.</p>`,
  },
  api_key: {
    title: "API key",
    content: `<p>Please insert the API key for your account. The API key can be found in your exchange account.</p>

    <p>A detailed explanation on how to find your API info can be found <a href="https://www.youtube.com/watch?v=XG4zEr-FmkI">here</a>.</p>
    
    <p>Please insert the API key for the corresponding API secret. Make sure you utilize a read-only API.</p>`
  },
  api_secret: {
    title: "API secret",
    content: `<p>Please insert the API secret for your account. The API secret can be found in your exchange account.</p>

    <p>A detailed explanation on how to find your API info can be found <a href="https://www.youtube.com/watch?v=XG4zEr-FmkI">here</a>.</p>
    
    <p>Please insert the API secret for the corresponding API key. Make sure you utilize a read-only API.</p>`
  }
};

export default addTradingAccountInfoContent;
