function createBaseLineChartOptions() {
  const baseLineChartOptions = {
    intersect: false,
    animation: {
      duration: 0
    },
    interaction: {
      mode: 'x',
      intersect: false
    },
    responsive: true,
    maintainAspectRatio: false,
    axes: {
      backgroundColor: '#4D4D4D',
    },
    scales: {
      y: {
        position: 'left',
        grid: {
          color: (context) => {
            return '#4D4D4D'
          },
          borderDash: (a) => {
            return [1, 2];
          },
          drawBorder: false,
          tickLength: 0,
          tickBorderDash: (a) => {
            return [1, 2];
          }
        },
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          color: "#999999",
          font: {
            size: 10,
            family: 'Arial',
            weight: 700
          },
          labelOffset: -7,
          padding: 0,
          mirror: true,
          stepSize: 25,
          callback: function(value, index, label) {
            switch (index) {
              case 0:
                return "0";
              case 1:
                return "";
              case 2:
                return "50";
              case 3:
                return "";
              case 4:
                return ["%", "100", ""];
              default:
                return "";
            }
          }
        }
      },
      y1: {
        type: 'linear',
        position: 'right',
        grid: {
          drawOnChartArea: false
        },
        beginAtZero: true,
        min: 0,
        max: 100,  
        ticks: {
          color: "#999999",
          font: {
            size: 10,
            family: 'Arial',
            weight: 700
          },
          labelOffset: -7,
          padding: 0,
          mirror: true,
          stepSize: 25,
          callback: function(value, index, ticks) {
            switch (index) {
              case 0:
                return "0";
              case 1:
                return "";
              case 2:
                return "50";
              case 3:
                return "";
              case 4:
                return ["%", "100", ""];
              default:
                return "";
            }
          }
        }
      },
      x: {
        grid: {
          offset: false,
          color: '#4D4D4D',
          borderDash: [1, 2],
          borderColor: '#4D4D4D',
          borderWidth: 1,
          tickColor: "#4D4D4D",
          tickLength: 4,
        },
        ticks: {
          padding: 0,
          color: "#999999",
          font: {
            size: 10
          }
        }
      }
    },
    layout: {
      padding: {
        bottom: 10,
        left: 10,
        right: 10,
        top: 50
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    },
    devicePixelRatio: 2,
    categoryPercentage: 0.2
  };
  return baseLineChartOptions;
}



export default createBaseLineChartOptions;
