import ReCAPTCHA from "react-google-recaptcha";
import styles from "../signUpModal.module.scss";
import { API_BASE_URL, CAPTCHA_KEY } from "utils/constant/environment";

function Captcha(props) {
  const setSignUpPhase = props.setSignUpPhase;
  const signUpData = props.signUpData;
  const setEmailVerificationCredential = props.setEmailVerificationCredential;
  const setErrorMessage = props.setErrorMessage;
  const setIsPending = props.setIsPending;

  let submittedForData = {
    email: signUpData.email,
    password: signUpData.password
  }
  const onChange = async () => {
    setIsPending(true);
    const resp = await fetch(`${API_BASE_URL}/auth/signup`, {
      method: "POST",
      body: JSON.stringify(submittedForData),
      headers: {
        "Content-Type": "application/json"
      }
    });

    const respJson = await resp.json();
    if (respJson.message === 'success') {
      setIsPending(false);
      setEmailVerificationCredential(respJson.data);
      setSignUpPhase(3);
    }
    if (respJson.statusCode === 400) {
      setIsPending(false);
      setErrorMessage(respJson.message);
      setSignUpPhase(1);
    }
  }

  return (
    <div className={styles.CaptchaSection}>
      <ReCAPTCHA
        className={styles.Captcha}
        sitekey={CAPTCHA_KEY}
        onChange={onChange}
        type="image"
      />
    </div>
  )
}

export default Captcha;