import { useState, useEffect } from 'react';
import styles from "./basicStatistics.module.scss";
// dummy data
import {
  balance,
  symbols,
  performanceByMonth,
  averageTradeLength,
  averageTradesPerDay
} from "devData/chartsData/index";
import { performance, averageProfitPerTrade } from "devData/cryptoChartsData/index";
// components
import Balance from 'components/overview/basicStatic/balance/balance.chart';
import Performance from 'components/overview/basicStatic/crypto/performance';
import Symbols from 'components/overview/basicStatic/symbols/symbols';
import BarChart from 'components/overview/basicStatic/barChart/barChart';

function BasicStatistics() {
  const [chartsData, setChartsData] = useState(null);

  useEffect(() => {
    setChartsData({
      balance,
      performance,
      symbols,
      performanceByMonth,
      averageProfitPerTrade,
      averageTradeLength,
      averageTradesPerDay    
    });
  }, []);

  if (!chartsData) {
    return (
      <div className={styles.Subpage}>
        Loading Data
      </div>
    )
  }

  return (
    <div className={styles.Subpage}>
      <Balance 
        key="Balance (USD)"
        chartTitle="Balance (USD)"
        chartData={chartsData["balance"]}
      />
      <Performance 
        key="Performance"
        chartTitle="Performance"
        chartData={chartsData["performance"]}
      />
      <Symbols 
        key="Symbols"
        chartTitle="Symbols"
        chartData={chartsData["symbols"]}
      />
      <BarChart 
        key="Performance by month"
        chartTitle="Performance by month"
        barValueKey="percentage"
        chartData={chartsData["performanceByMonth"]}        
      />
      <BarChart 
        key="Average profit per trade"
        chartTitle="Average profit per trade"
        barValueKey="usd"
        chartData={chartsData["averageProfitPerTrade"]}        
      />
      <BarChart 
        key="Average trade length (mins)"
        chartTitle="Average trade length (mins)"
        barValueKey="mins"
        chartData={chartsData["averageTradeLength"]}        
      />
      <BarChart 
        key="Average trades per day"
        chartTitle="Average trades per day"
        barValueKey="trades"
        chartData={chartsData["averageTradesPerDay"]}        
      />
    </div>
  )
}

export default BasicStatistics;