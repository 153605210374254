import { useState } from "react";
import BarChartWithEmptyTicks from "components/charts/barWithEmptyTicks.chart";
import SpreadAndCommission from "components/charts/spreadAndCommission.chart";
import CostComparison from "components/costComparison/costComparison";
import RoboForex from "components/advertisements/roboForex";
//styling
import styles from "./costs.module.scss";
// utils
import { createDataObjectForChart } from "utils/helpers/chart";
import { getChartsData } from "redux/selectors";
import { useSelector } from "react-redux";
import { ChartMode } from 'utils/constant/index';

function Costs({ chartKeysOfThePage, currentTradingAccount }) {
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(1);
  const chartsData = useSelector(getChartsData(chartKeysOfThePage));

  let chartDataObjects = {};
  chartKeysOfThePage.forEach((chartKey) => {
    chartDataObjects[chartKey] = createDataObjectForChart(
      chartKey,
      chartsData[chartKey]
    );
  });

  return (
    <>
      <SpreadAndCommission
        key="Spread & commission"
        chartTitle="Spread & commission"
        chartData={chartDataObjects["spread_and_commission"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.NUMBER}
        chartMode={ChartMode.NUMBER}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={
          chartDataObjects["spread_and_commission"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChartWithEmptyTicks
        key="Profit of conflicting symbols"
        chartTitle="Profit of conflicting symbols"
        chartData={chartDataObjects["profit_of_conflicting_symbols"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["profit_of_conflicting_symbols"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <div className={styles.CostSection}>
        <CostComparison />
        <RoboForex />
      </div>
    </>
  );
}

export default Costs;
