import styles from "./inputInfo.module.scss";
import prevImg from "../../../assets/previousPage.png";
import addTradingAccountInfoContent from "devData/addTradingAccountInfoContent";
import parse from "html-react-parser";

function InputInfo({ infoContentKey, setInfoContentKey }) {
  let selectedInfoContent = addTradingAccountInfoContent[infoContentKey];

  return (
    <div className={styles.inputInfoContainer}>
      <div className={styles.infoModalHeader}>
        <div onClick={() => setInfoContentKey("")} className={styles.backBtn}>
          <img src={prevImg} alt="previous" />
          Back
        </div>
      </div>
      <div className={styles.infoModalBody}>
        <div className={styles.title}>{selectedInfoContent.title}</div>
        <div className={styles.content}>
          {parse(selectedInfoContent.content)}
        </div>
      </div>
    </div>
  );
}

export default InputInfo;
