import styles from "./sandboxAlertModal.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useState } from "react";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import alertsAreaMap from "devData/alertsAreaMap";
import { getSandboxChartsData } from "redux/selectors";
import { useSelector } from "react-redux";
import chartsInfoModalContent from "devData/chartsInfoModalContent";
// utils
import createCorrespondChartForAlertModal from "utils/constant/createCorrespondChartForAlertModal";
import { createDataObjectForChart } from "utils/helpers/chart";

const ChartMode = {
  PIPS: "pips",
  PERCENTAGE: "percentage",
  NUMBER: "number",
};

function SandboxAlertModal({ data, setIsModalOpen }) {
  const [currentTimeFrame, setCurrentTimeFrame] = useState(1);
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const currentTradingAccount = {
    account_id: 1234,
    account_publie: true,
    broker: "hoctrade",
    currency: "usd",
    portfolio_name: "sandbox",
    server: "test",
    status: "demo",
    trading_account_id: "hoctrade@test",
    user_id: 1,
  };
  const correspondingChartData = useSelector(
    getSandboxChartsData([data.chartKey])
  );
  let chartDataObject = createDataObjectForChart(
    data.chartKey,
    correspondingChartData[data.chartKey]
  );
  const chartProps = {
    key: chartDataObject.chartKey,
    chartTitle: chartDataObject.chartTitle,
    chartData: chartDataObject,
    hoverTextArray: chartDataObject.hoverTextArray,
    currentTimeFrame: currentTimeFrame,
    setCurrentTimeFrame: setCurrentTimeFrame,
    chartMode: chartMode,
    setChartMode: setChartMode,
    dualMode: true,
    hasTimeFrame: true,
    currentTradingAccount: currentTradingAccount,
  };
  let chart = createCorrespondChartForAlertModal(data.chartKey, chartProps);

  let areaIcons = {
    behavioral: htFonts.htCoffee,
    timing: htFonts.htTiming,
    risk: htFonts.htAlert,
    strategy: htFonts.htChessCastle,
    cost: htFonts.htCoins,
  };

  let page;
  switch (alertsAreaMap[data.chartKey]) {
    case "behavior":
      page = "behavioral";
      break;
    case "timing":
      page = "timing";
      break;
    case "risk":
      page = "risk-management";
      break;
    case "strategy":
      page = "strategy";
      break;
    case "cost":
      page = "costs";
      break;
    case "general":
      page = "general";
      break;
    default:
  }

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  return (
    <>
      <div className={styles.BackDrop}></div>
      <div className={styles.ModalWrapper}>
        <div className={styles.alertModal}>
          <div className={styles.CrossIconButton}>
            <div
              className={`${htFonts.htCross} ${styles.CancelIcon}`}
              onClick={() => setIsModalOpen(false)}
            ></div>
          </div>
          <div style={{ fontSize: "16px" }}>{data.msg}</div>
          <div style={{ fontSize: "16px" }}>
            <strong>Category: </strong>
            {data.category}
          </div>
          <div className={styles.content}>
            <div className={styles.contentChart}>
              {chart}
              <div className={styles.linkBtn}>
                <Link
                  to={`/sandbox/dashboard/analysis/${page}`}
                  id={styles.link}
                  onClick={() => setIsModalOpen(false)}
                >
                  <div id={styles.linkContent}>
                    <span
                      className={`${areaIcons[alertsAreaMap[data.chartKey]]}`}
                      style={{
                        marginRight: "3px",
                        fontSize: "18px",
                        marginTop: "2px",
                      }}
                    ></span>
                    Go to analysis/{page}
                  </div>
                </Link>
              </div>
            </div>
            <div className={styles.contentVideo}>
              <div>
                <div className={styles.PlayerWrapper}>
                  <YouTube
                    videoId={
                      chartsInfoModalContent[data.chartKey].youtubeVideoId
                    }
                    opts={opts}
                    className={styles.YoutubePlayer}
                  />
                </div>
              </div>
              <div className={styles.VideoButton}>
                <div className={`${htFonts.htVideo} ${styles.VideoIcon}`}></div>
                <a
                  className={styles.VideoButtonText}
                  href="https://www.youtube.com/@hoc-trade/videos"
                  target="_blank"
                  rel="noreferrer"
                >
                  See all videos
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SandboxAlertModal;
