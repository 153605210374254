import React from "react";
import Select, { components } from "react-select";
import arrowImg from "../../../assets/selectArrow.svg";

const SelectComponent = ({ instanceId, formData, setFormData, options, formField }) => {
  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };

  const IndicatorsContainer = (props) => {
    return (
      <div 
        style={{ 
          background: "#E6E6E6", 
          height: "100%", 
          display: "flex", 
          paddingLeft: "10px", 
          paddingRight: "10px",
          borderBottomRightRadius: '8px',
          borderTopRightRadius: '8px',
        }}>
        <components.IndicatorsContainer {...props}>
          <img width="15px" height="15px" src={arrowImg} alt="Indicator"></img>
        </components.IndicatorsContainer>
      </div>
    );
  };  

  return (
    <Select
      instanceId={instanceId}
      components={{ Placeholder, IndicatorsContainer }}
      placeholder={"Please Select"}
      options={options}
      onChange={(value) =>
        setFormData({
          ...formData,
          [formField]: value
        })
      }
      value={formData[formField]}
      styles={{
        option: (provided, state) => ({
          // ...provided,
          cursor: "pointer",
          border: 'none',
          padding: 5,
          color: "#000000",
          "&:hover": {
            backgroundColor: "#ECECEC"
          }
        }),
        control: (base) => ({
          ...base,
          height: 28,
          borderRadius: 8
        }),
        input: (base) => ({
          ...base,
          color: "#000000",
          fontWeight: "400"
        }),
        singleValue: (base) => ({
          ...base,
          color: "#000000"
        }),
        valueContainer: (base) => ({
          ...base,
          fontWeight: "400",
        }),
        menu: (provided, state) => ({
          ...provided,
          marginTop: 0,
          color: "#000000",
          fontWeight: "400",
          padding: 5,
          // marginTop: -3,
          borderBottomRightRadius: 15,
          borderBottomLeftRadius: 15
        }),
        DropdownIndicator: (base) => ({
          ...base,
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: "1em",
          padding: 0,
          color: "#999999",
          fontWeight: 400,
        }),
      }}
    />
  );
};

export default SelectComponent;