const bestWorstTradesOptions = {
  animation: {
    duration: 0,
  },
  parsing: true,
  interaction: {
    mode: "x",
    intersect: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    autocolors: false,
  },
  layout: {
    padding: {
      bottom: 25,
      left: 25,
      top: 40,
      right: 10
    },
  },
  borderRadius: 3,
  maxBarThickness: 8,
  borderWidth: 0,
  scales: {
    y: {
      grid: {
        color: (context) => {
          return '#4D4D4D'
        },
        borderDash: (a) => {
          return [1, 2];
        },
        drawBorder: false,
        tickLength: -10,
        tickBorderDash: (a) => {
          return [1, 2];
        }
      },
      ticks: {
        color: "#999999",
        font: {
          size: 10,
          family: 'Noto Sans',
          weight: 700
        },
        labelOffset: -7,
        padding: 0,
        mirror: true,
        maxTicksLimit: 5,
        // callback: function (value, index, ticks) {
        //   if (index === ticks.length-1) {
        //     return ["Risk return ratio", value, ""];
        //   } else if (index === 1 || index === 3) {
        //     return ""
        //   } else {
        //     return value
        //   }
        // }
      },
    },
    x: {
      grid: {
        offset: false,
        color: '#4D4D4D',
        borderDash: [1, 2],
        borderColor: '#4D4D4D',
        borderWidth: 1,
        tickColor: "#4D4D4D",
        tickLength: 4,
      },
      ticks: {
        padding: 0,
        color: "#999999",
        font: {
          size: 10
        },
      },
    }
  },
}

export default bestWorstTradesOptions;