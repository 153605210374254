import { API_BASE_URL } from "utils/constant/environment";

const domainUrl = API_BASE_URL;

async function sendRequest(httpMethod, url, customHeaders, body, includeCredentials) {
  switch(httpMethod) {
    case "get": 
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...customHeaders
        },
        credentials: includeCredentials? "include": "same-origin"
      });
    case "post":
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...customHeaders
        },
        body: JSON.stringify(body),
        credentials: includeCredentials? "include" : "same-origin"
      });
    default:
      break;
  }
}

async function get(url, customHeaders, includeCredentials) {
  const resp = await sendRequest("get", url, customHeaders, null, includeCredentials);
  const respJson = await resp.json();
  return respJson;
}

async function post(url, customHeaders, body, includeCredentials) {
  const resp = await sendRequest("post", url, customHeaders, body, includeCredentials);
  const respJson = await resp.json();
  return respJson;
}

/**
 * 
 * @param {object} credentials 
 * @property {string} credentials.email
 * @property {string} credentials.password
 */
export async function sendLoginCredential(credentials) {
  const result = await post(`${domainUrl}/auth/login`, {}, credentials);
  return result;
}

export async function sendAuthRequest() {
  const result = await get(`${domainUrl}/auth`, {}, true);
  return result;
}

export async function sendTradingAccountCredential(credentials) {
  const result = await post(`${domainUrl}/trading-account`, {}, credentials, true);
  return result;
}

export async function sendEmailVerificationRequest(email) {
  const result = await post(`${domainUrl}/auth/email-verification-code`, {}, { email });
  return result;
}

export async function sendEmailVerificationCode(verificationCodeInput, emailVerificationCredential) {
  const result = await post(`${domainUrl}/auth/email-verification`,
    {}, 
    {
      verificationCodeInput: verificationCodeInput,
      ...emailVerificationCredential
    }
  );
  return result;
}

export async function sendLogoutRequest() {
  const result = await get(`${domainUrl}/auth/logout`, {}, true);
  return result;
}

export async function fetchChartsData(tradingAccountUniqueId, chartKeys) {
  const result = await post(`${domainUrl}/api/chart-data`, {}, { tradingAccountUniqueId, chartKeys }, true);
  return result;
}

export async function fetchAllChartsData(tradingAccountUniqueId) {
  const result = await get(`${domainUrl}/api/chart-data/charts/data/${tradingAccountUniqueId}`, {}, true);
  return result;
}

export async function sendNewEmailVerificationCode(email) {
  const result = await post(`${domainUrl}/auth/email-verification-code`, {}, { email });
  return result;
}

export async function validateResetPasswordToken(token) {
  const result = await post(`${domainUrl}/auth/reset-password-token-validation`, {}, { token });
  return result;
}

export async function sendNewPassword(token, newPassword) {
  const result = await post(`${domainUrl}/auth/reset-password`, {}, { token, newPassword });
  return result;
}

export async function sendResetPasswordEmail(email) {
  const result = await post(`${domainUrl}/auth/reset-password-email`, {}, { email });
  return result;
}

export async function getAlertData(tradingAccountUniqueId) {
  const result = await post(`${domainUrl}/api/alert`, {}, { tradingAccountUniqueId }, true);
  return result;
}

export async function deleteAlertData(alertId) {
  const result = await post(`${domainUrl}/api/alert/delete`, {}, { alertId }, true);
  return result;
}

export async function changeAlertReadStatus(alertId) {
  const result = await post(`${domainUrl}/api/alert/change-read-status`, {}, { alertId }, true);
  return result;
}

export async function sendCryptoTradingAccountCredential(credentials) {
  const result = await post(`${domainUrl}/trading-account/crypto`, {}, credentials, true);
  return result;
}

export async function fetchCryptoChartsData(tradingAccountUniqueId, chartKeys) {
  const result = await post(`${domainUrl}/api/chart-data/crypto`, {}, { tradingAccountUniqueId, chartKeys }, true);
  return result;
}

export async function insertNewAlert(tradingAccountUniqueId) {
  const result = await post(`${domainUrl}/api/alert/insert-new-alert`, {}, { tradingAccountUniqueId }, true);
  return result;
}

export async function setAlertToRead(alertId) {
  const result = await post(`${domainUrl}/api/alert/set-alert-to-read`, {}, { alertId }, true);
  return result;
}

export async function sendSandboxLoginCredential(sandboxCredential) {
  const result = await post(`${domainUrl}/sandbox/login`, {}, sandboxCredential);
  return result;
}

export async function checkSandboxLoginStatus(token) {
  const result = await post(`${domainUrl}/sandbox/validate-jwt`, {}, token);
  return result;
}

export async function fetchSandboxChartsData(chartKeys) {
  const result = await post(`${domainUrl}/sandbox/sandbox-charts-data`, {}, {chartKeys});
  return result;
}

export async function resendSandboxKey(email) {
  const result = await post(`${domainUrl}/sandbox/resend-sandbox-key`, {}, email);
  return result;
}

export async function getTradeMedicData(tradingAccountUniqueId) {
  const result = await post(`${domainUrl}/api/trade-medic/trade-medic-data`, {}, {tradingAccountUniqueId});
  return result;
}

export async function validateTradeMedicResponsivePageToken(token) {
  const result = await post(`${domainUrl}/api/trade-medic/trade-medic-responsive-auth`, {}, {token});
  return result;
}