import { createSlice } from '@reduxjs/toolkit';

const initialTradeMedicState = [];

export const sandboxTradeMedicSlice = createSlice({
  name: 'sandboxTradeMedicData',
  initialState: initialTradeMedicState,
  reducers: {
    setSandboxTradeMedicData: (state, action) => {
      return [
        // ...state,
        ...action.payload.sandboxTradeMedicData
      ]
    }
  },
});

export const { 
  setSandboxTradeMedicData
} = sandboxTradeMedicSlice.actions;

export const sandboxTradeMedicReducer = sandboxTradeMedicSlice.reducer;