const sandboxTradeHistory = [
  {
    order: 976555683,
    openDate: "Jul 31, 2023, 20:44:15",
    closeDate: "Jul 31, 2023, 21:41:20",
    tradeLength: "57m05s",
    symbol: "EURUSD",
    action: "Sell",
    lots: 1.05,
    priceOpen: 1.10011,
    priceClose: 1.09968,
    highPips: 4,
    lowPips: -1,
    executedPips: 4,
    commission: 0,
    swap: 0,
    profitUSD: 45.15,
    profitPips: 4.3
  },
  {
    order: 976549944,
    openDate: "Jul 31, 2023, 13:21:37",
    closeDate: "Jul 31, 2023, 18:41:49",
    tradeLength: "5h20m12s",
    symbol: "GBPUSD",
    action: "Sell",
    lots: 0.90,
    priceOpen: 1.28606,
    priceClose: 1.28355,
    highPips: 30,
    lowPips: -6,
    executedPips: 27,
    commission: 0,
    swap: 0,
    profitUSD: 225.9,
    profitPips: 25.1
  },
  {
    order: 976545114,
    openDate: "Jul 31, 2023, 12:05:01",
    closeDate: "Jul 31, 2023, 15:38:09",
    tradeLength: "3h33m08s",
    symbol: "EURUSD",
    action: "Sell",
    lots: 0.90,
    priceOpen: 1.10408,
    priceClose: 1.10078,
    highPips: 33,
    lowPips: -5,
    executedPips: 26,
    commission: 0,
    swap: 0,
    profitUSD: 297.0,
    profitPips: 33.0
  },
  {
    order: 976540284,
    openDate: "Jul 31, 2023, 11:55:13",
    closeDate: "Jul 31, 2023, 15:39:15",
    tradeLength: "3h44m02s",
    symbol: "EURUSD",
    action: "Sell",
    lots: 1.10,
    priceOpen: 1.10357,
    priceClose: 1.10079,
    highPips: 29,
    lowPips: -9,
    executedPips: 28,
    commission: 0,
    swap: 0,
    profitUSD: 250.20,
    profitPips: 27.8
  },
  {
    order: 976539050,
    openDate: "Jul 31, 2023, 08:42:55",
    closeDate: "Jul 31, 2023, 10:28:59",
    tradeLength: "1h46m04s",
    symbol: "BTCUSD",
    action: "Sell",
    lots: 0.5,
    priceOpen: 29346.22,
    priceClose: 29385.43,
    highPips: 1,
    lowPips: -39,
    executedPips: 0,
    commission: 0,
    swap: 0,
    profitUSD: -196.05,
    profitPips: -39.2
  },
  {
    order: 976535107,
    openDate: "Jul 28, 2023, 10:39:37",
    closeDate: "Jul 28, 2023, 12:44:51",
    tradeLength: "2h05m14s",
    symbol: "GBPUSD",
    action: "Buy",
    lots: 0.8,
    priceOpen: 1.28656,
    priceClose: 1.28657,
    highPips: 14,
    lowPips: -33,
    executedPips: 3,
    commission: 0,
    swap: 0,
    profitUSD: 0.8,
    profitPips: 0.1
  },
  {
    order: 976530175,
    openDate: "Jul 27, 2023, 17:12:11",
    closeDate: "Jul 27, 2023, 17:39:41",
    tradeLength: "27m30s",
    symbol: "SP500",
    action: "Buy",
    lots: 0.95,
    priceOpen: 4575.52,
    priceClose: 4558.62,
    highPips: 4,
    lowPips: -17,
    executedPips: -13,
    commission: 0,
    swap: 0,
    profitUSD: -160.55,
    profitPips: -16.9
  },
  {
    order: 976525245,
    openDate: "Jul 27, 2023, 14:14:05",
    closeDate: "Jul 27, 2023, 15:59:39",
    tradeLength: "1h45m34s",
    symbol: "EURUSD",
    action: "Buy",
    lots: 1.00,
    priceOpen: 1.09921,
    priceClose: 1.09982,
    highPips: 18,
    lowPips: -7,
    executedPips: -10,
    commission: 0,
    swap: 0,
    profitUSD: 61,
    profitPips: 6.1
  },
  {
    order: 976520322,
    openDate: "Jul 27, 2023, 10:28:36",
    closeDate: "Jul 27, 2023, 15:49:59",
    tradeLength: "5h21m23s",
    symbol: "GBPUSD",
    action: "Sell",
    lots: 0.70,
    priceOpen: 1.29206,
    priceClose: 1.28333,
    highPips: 87,
    lowPips: -2,
    executedPips: 70,
    commission: 0,
    swap: 0,
    profitUSD: 611.1,
    profitPips: 87.3
  },
  {
    order: 976517202,
    openDate: "Jul 27, 2023, 09:24:12",
    closeDate: "Jul 27, 2023, 10:28:15",
    tradeLength: "1h04m03s",
    symbol: "GBPJPY",
    action: "Buy",
    lots: 1.00,
    priceOpen: 181.682,
    priceClose: 181.469,
    highPips: 7,
    lowPips: -22,
    executedPips: -10,
    commission: 0,
    swap: 0,
    profitUSD: -151.9,
    profitPips: -21.7
  },
]

export default sandboxTradeHistory;