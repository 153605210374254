export const sandboxSidebarItemsList = {
  overview: {
    title: "Overview",
    itemsList: [
      {
        label: "Account Overview",
        path: "/sandbox/dashboard/overview/account-overview",
        iconClassName: "htWallet"
      },
      {
        label: "Basic Statistics",
        path: "/sandbox/dashboard/overview/basic-stats",
        iconClassName: "htPieChart"
      },
      {
        label: "Advanced Statistics",
        path: "/sandbox/dashboard/overview/advanced-stats",
        iconClassName: "htClusterChart"
      },
      {
        label: "Trade history",
        path: "/sandbox/dashboard/overview/trade-history",
        iconClassName: "htClipboard"
      }
    ]
  },
  analysis: {
    title: "Analysis",
    itemsList: [
      // {
      //   label: "Customized",
      //   path: "/sandbox/dashboard/analysis/customized",
      //   iconClassName: "htStar"
      // },
      {
        label: "General",
        path: "/sandbox/dashboard/analysis/general",
        iconClassName: "htBarChart"
      },
      {
        label: "Behavioral",
        path: "/sandbox/dashboard/analysis/behavioral",
        iconClassName: "htCoffee"
      },
      {
        label: "Timing",
        path: "/sandbox/dashboard/analysis/timing",
        iconClassName: "htTiming"
      },
      {
        label: "Risk Management",
        path: "/sandbox/dashboard/analysis/risk-management",
        iconClassName: "htAlert"
      },
      {
        label: "Strategy",
        path: "/sandbox/dashboard/analysis/strategy",
        iconClassName: "htChessCastle"
      },
      {
        label: "Costs",
        path: "/sandbox/dashboard/analysis/costs",
        iconClassName: "htCoins"
      }
    ]
  },
  alert: {
    title: "Alert"
  },
  tradeMedic: {
    title: "Trade Medic"
  }
}