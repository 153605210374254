import styles from "./tableRow.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
import alertsAreaMap from "devData/alertsAreaMap";
import chartKeyWithNameMap from "utils/constant/chartKeyNameMap";
//constants
import * as modalTypes from "constants/modalTypes";
//utils
import { deleteAlertData, changeAlertReadStatus } from "utils/requests";
import { setAlertsData, deleteAlertByAlertId, toggleAlertReadStatus } from "redux/slices/alertSlice";
import generateTimeFormatForAlertFromUTC from "utils/helpers/generateTimeFormatForAlertFromUTC";

export default function TableRow({ data, index, setIsAlertDataChanged }) {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const alertOnClickHandler = (e, chartKey, selectedAlertData) => {
    if (e.target.cellIndex <= 4) {
      return dispatch(
        openModal({
          displayedModal: modalTypes.ALERT_MODAL,
          displayedModalDataKey: chartKey,
          selectedAlertData: selectedAlertData,
        })
      );
    }
  };

  const mouseLeaveHandler = () => {
    setIsHover(false);
    setIsDropdownOpen(false);
  };

  const onClickReadUnread = async (alertId) => {
    const result = await changeAlertReadStatus(alertId);
    if (result.data) {
      dispatch(
        toggleAlertReadStatus({ alertId: result.data.alertId, readStatus: result.data.read })
      );
    } else {
      console.log("fetching alert read error")
    }
  }

  const onClickDeleteHandler = async (alertId) => {
    const result = await deleteAlertData(alertId);
    if (result.data) {
      const { alertId } = result.data;
      dispatch(deleteAlertByAlertId({ alertId: alertId }));
    } else {
      console.log("fetching alert delete error")
    }
  };

  let areaIcons = {
    general: htFonts.htBarChart,
    behavior: htFonts.htCoffee,
    timing: htFonts.htTiming,
    risk: htFonts.htAlert,
    strategy: htFonts.htChessCastle,
    cost: htFonts.htCoins,
  };

  let reasonIcons = {
    "New loss making pattern": htFonts.htNegChart,
    "Risky behavior": htFonts.htSpeedometer,
    "Action in loss-making pattern": htFonts.htAlertChart,
  };

  const reasonText = {
    0: "New loss-making pattern",
    1: "Action in loss-making pattern",
    2: "Risk Behavior"
  }

  return (
    <tr
      className={styles.orderRow}
      key={index}
      style={data.read ? null : { fontWeight: "bold" }}
      onClick={(e) => alertOnClickHandler(e, data.chartKey, data)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => mouseLeaveHandler()}
    >
      <td>{generateTimeFormatForAlertFromUTC(data.time)}</td>
      <td className={styles.itemWithIcon}>
        <span
          className={`${areaIcons[alertsAreaMap[data.chartKey]]}`}
          style={{ marginRight: "3px", fontSize: "18px", fontWeight: "normal" }}
        ></span>
        {chartKeyWithNameMap[data.chartKey]}
      </td>
      <td>{data.category}</td>
      <td className={styles.itemWithIcon}>
        <span
          className={`${reasonIcons[data.reason]}`}
          style={{ marginRight: "3px", fontSize: "18px" }}
        ></span>
        {reasonText[data.alertType]}
      </td>
      <td>{data.orderID}</td>
      <td className={styles.actionBtnContainer}>
        {isHover ? (
          <div
            className={styles.actionBtn}
          >
            <div 
              className={styles.CircleDotsContainer}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <div className={styles.CircleDot}></div>
              <div className={styles.CircleDot}></div>
              <div className={styles.CircleDot}></div>
            </div>
          </div>
        ) : null}
        {isDropdownOpen ? (
          <div className={styles.dropdownMenu} ref={wrapperRef}>
            <div
              className={styles.iconContainer}
              onClick={() => onClickDeleteHandler(data.alertId)}
            >
              <span
                className={htFonts.htTrash}
                style={{ fontWeight: "normal" }}
              ></span>
              Delete
            </div>
            <div className={styles.iconContainer} onClick={() => onClickReadUnread(data.alertId)}>
              {data.read ? (
                <>
                  <span
                    className={htFonts.htEnvelopeOpen}
                    style={{ fontWeight: "normal" }}
                  ></span>
                  Unread
                </>
              ) : (
                <>
                  <span
                    className={htFonts.htEnvelope}
                    style={{ fontWeight: "normal" }}
                  ></span>
                  Read
                </>
              )}
            </div>
          </div>
        ) : null}
      </td>
    </tr>
  );
}
