function generateTimeFormatForAlertFromUTC(UTCTime) {
  const date = new Date(UTCTime * 1000);

  // Get the year, month, day, hours, minutes, and seconds
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Create the formatted string
  const formattedDate = `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export default generateTimeFormatForAlertFromUTC;