import { chartKeys } from 'utils/constant';

const long_and_short_per_trade = {
  chartTitle: "Long & short (per trade)",
  chartKey: chartKeys.LONG_AND_SHORT_PER_TRADE,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: "USD"
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: "USD"
      },
      {
        templateString: 'Trades:  ',
        valueKey: 'trades',
      },
    ],
  },
  interval: [
    'long',
    'short',
  ],
}

export default long_and_short_per_trade;