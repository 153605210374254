import { chartKeys } from 'utils/constant';

const profit_with_against_st_mt_trend = {
  chartTitle:
    "Profit with(+) & against(-) short-term(ST) & medium-term(MT) trend",
  chartKey: chartKeys.PROFIT_WITH_AGAINST_ST_MT_TREND,
  hoverTextArray: {
    usd: [
      {
        templateString: "",
        valueKey: "usd",
        unit: "USD/trade",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "usdBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        },
        unit: ""
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "percentageBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
  },
  haspercentageBenchmark: true,
  interval: [
    'average',
    'ST+/MT+',
    'ST+/MT-',
    'ST-/MT+',
    'ST-/MT-'
  ],
}

export default profit_with_against_st_mt_trend;