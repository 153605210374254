import { useState } from "react";
import styles from "./tradeHistory.module.scss";
import data from "devData/tradeHistory";
import PaginationButtons from "components/tradeHistory/paginationButtons";
import ProgressBar from "components/tradeHistory/progressBar";
import SearchBar from "components/tradeHistory/searchBar";
import useTable from "utils/useTable";
import htFonts from "shared/htFont.module.scss";
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modalSlice';
import * as modalTypes from 'constants/modalTypes';

function TradeHistory() {
  const [currentTimeFrame, setCurrentTimeFrame] = useState(3);
  const [searchKeyword, setSearchKeyword] = useState("");
  let selectedPeriodData = getSelectedPeriodData(data);
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(selectedPeriodData, page, rowsPerPage);
  const dispatch = useDispatch();

  const infoIconOnClickHandler = (chartKey) => {
    return dispatch(openModal({
      displayedModal: modalTypes.CHART_INFO_MODAL,
      displayedModalDataKey: chartKey
    }));
  }

  function getSelectedPeriodData(data) {
    let lastOpenDate = data[0].openDate;
    let dataArray = [];
    data.forEach((item) => {
      let lastOpenDateObj = new Date(lastOpenDate);
      let itemOpenDateObj = new Date(item.openDate);
      if (currentTimeFrame === 3) {
        lastOpenDateObj.setMonth(lastOpenDateObj.getMonth()-3);
        if (lastOpenDateObj - itemOpenDateObj <= 0) {
          dataArray.push(item);
        }
      }
      if (currentTimeFrame === 6) {
        lastOpenDateObj.setMonth(lastOpenDateObj.getMonth()-6);
        if (lastOpenDateObj - itemOpenDateObj <= 0) {
          dataArray.push(item);
        }      }
      if (currentTimeFrame === 12) {
        lastOpenDateObj.setMonth(lastOpenDateObj.getMonth()-12);
        if (lastOpenDateObj - itemOpenDateObj <= 0) {
          dataArray.push(item);
        }      }
      if (currentTimeFrame === 999) {
        dataArray = data;
      }
    })
    return dataArray;
  }

  const rowColor = [
    "#9AB8BD", "#B3C4C8", "#9AB8BD", "#B3C4C8","#9AB8BD", "#B3C4C8","#9AB8BD", "#B3C4C8","#9AB8BD", "#B3C4C8"
  ];

  const swapEqualToZeroStyle = {
    color: "#C1272D",
    opacity: 0.5
  }

  return (
    <div className={styles.tradeHistoryContainer}>
      <div className={styles.timeBtnsContainer}>
        <div className={styles.timeButton} onClick={() => setCurrentTimeFrame(3)} style={{backgroundColor: currentTimeFrame === 3 ? "#047F92" : null}}>3m</div>
        <div className={styles.timeButton} onClick={() => setCurrentTimeFrame(6)} style={{backgroundColor: currentTimeFrame === 6 ? "#047F92" : null}}>6m</div>
        <div className={styles.timeButton} onClick={() => setCurrentTimeFrame(12)} style={{backgroundColor: currentTimeFrame === 12 ? "#047F92" : null}}>1y</div>
        <div className={styles.timeButton} onClick={() => setCurrentTimeFrame(999)} style={{backgroundColor: currentTimeFrame === 999 ? "#047F92" : null}}>all</div>
      </div>
      <div 
        className={`${htFonts.htInfo} ${styles.InfoIcon}`}
        id={styles.InfoIcon}
        onClick={() => infoIconOnClickHandler("trade_history_table")}>
      </div>
      <table className={styles.tradeHistoryTable}>
        <thead>
          <tr className={styles.titleRow}>
            <th>Order</th>
            <th>Open/close</th>
            <th>Length</th>
            <th>Symbol</th>
            <th>Lots</th>
            <th>Price open/close</th>
            <th>High/low (pips)</th>
            <th>Commission</th>
            <th>Swap</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
        {
          slice.map((data, index) => {
            return (
              <tr className={styles.orderRow} key={index} style={{backgroundColor: `${rowColor[index]}`}}>
                <td>{data.order}</td>
                <td>{data.openDate}<br/>{data.closeDate}</td>
                <td>{data.tradeLength}</td>
                <td>{data.symbol} <div className={styles.actionLabel}>{data.action.toUpperCase()}</div></td>
                <td>{data.lots}</td>
                <td>{data.priceOpen}<br />{data.priceClose}</td>
                <td><ProgressBar highPips={data.highPips} lowPips={data.lowPips} executedPips={data.executedPips}/></td>
                {/* <td>{data.highPips} {data.lowPips}</td> */}
                <td style={{color: "#C1272D"}}>{data.commission}USD</td>
                <td style={data.swap === 0 ? swapEqualToZeroStyle : {color: "#C1272D"}}>{data.swap}USD</td>
                <td style={data.profitUSD >= 0 ? {color: "#009245", fontWeight: "bold"} : {color: "#C1272D", fontWeight: "bold"}}>{data.profitUSD}USD<div style={data.profitPips >= 0 ? {color: "#009245", fontWeight: "bold"} : {color: "#C1272D", fontWeight: "bold"}}>{data.profitPips}Pips</div></td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
      <div className={styles.bottomContainer}>
        {/* <input className={styles.searchContainer} onClick={(e) => onClickSearch(e)}></input> */}
        <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} setPage={setPage} page={page} selectedPeriodData={selectedPeriodData}/>
        <PaginationButtons range={range} slice={slice} setPage={setPage} page={page} />
      </div>
    </div>
  );
}

export default TradeHistory;
