import styles from "./tableRow.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useState, useRef, useEffect } from "react";
import alertsAreaMap from "devData/alertsAreaMap";
import SandboxAlertModal from "components/modals/sandboxAlertModal";

export default function SandboxTableRow({ data, index }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const wrapperRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const alertOnClickHandler = (e) => {
    if (e.target.cellIndex <= 4) {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const mouseLeaveHandler = () => {
    setIsHover(false);
    setIsDropdownOpen(false);
  };

  let areaIcons = {
    general: htFonts.htBarChart,
    behavior: htFonts.htCoffee,
    timing: htFonts.htTiming,
    risk: htFonts.htAlert,
    strategy: htFonts.htChessCastle,
    cost: htFonts.htCoins,
  };

  let reasonIcons = {
    "New loss making pattern": htFonts.htNegChart,
    "Risky behavior": htFonts.htSpeedometer,
    "Action in loss-making pattern": htFonts.htAlertChart,
  };

  return (
    <>
      <tr
        className={styles.orderRow}
        key={index}
        style={data.read ? null : { fontWeight: "bold" }}
        onClick={(e) => alertOnClickHandler(e, data)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => mouseLeaveHandler()}
      >
        <td>{data.time}</td>
        <td className={styles.itemWithIcon}>
          <span
            className={`${areaIcons[alertsAreaMap[data.chartKey]]}`}
            style={{
              marginRight: "3px",
              fontSize: "18px",
              fontWeight: "normal",
            }}
          ></span>
          {data.area}
        </td>
        <td>{data.category}</td>
        <td className={styles.itemWithIcon}>
          <span
            className={`${reasonIcons[data.reason]}`}
            style={{ marginRight: "3px", fontSize: "18px" }}
          ></span>
          {data.reason}
        </td>
        <td>{data.orderID}</td>
        <td className={styles.actionBtnContainer}>
          {isHover ? (
            <div className={styles.actionBtn}>
              <div
                className={styles.CircleDotsContainer}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className={styles.CircleDot}></div>
                <div className={styles.CircleDot}></div>
                <div className={styles.CircleDot}></div>
              </div>
            </div>
          ) : null}
          {isDropdownOpen ? (
            <div className={styles.dropdownMenu} ref={wrapperRef}>
              <div
                className={styles.iconContainer}
                // onClick={() => onClickDeleteHandler(data.orderID)}
              >
                <span
                  className={htFonts.htTrash}
                  style={{ fontWeight: "normal" }}
                ></span>
                Delete
              </div>
              <div
                className={styles.iconContainer}
                // onClick={() => onClickReadUnread(data.orderID)}
              >
                {data.read ? (
                  <>
                    <span
                      className={htFonts.htEnvelopeOpen}
                      style={{ fontWeight: "normal" }}
                    ></span>
                    Unread
                  </>
                ) : (
                  <>
                    <span
                      className={htFonts.htEnvelope}
                      style={{ fontWeight: "normal" }}
                    ></span>
                    Read
                  </>
                )}
              </div>
            </div>
          ) : null}
        </td>
      </tr>
      {
        isModalOpen ? <SandboxAlertModal data={data} setIsModalOpen={setIsModalOpen}/> : null
      }
    </>
  );
}
