function createGeneralHoverBorderColor(
  chartData,
  currentTimeFrame,
  hasTimeFrame,
  chartMode
) {
  const intervals = chartData.interval?? Object.keys(chartData[currentTimeFrame].data);
  const generalHoverBorderColor = intervals.map((interval) => {
    if (hasTimeFrame && currentTimeFrame) {
      const tradeCount = chartData[currentTimeFrame].data[interval]?.trades;
      if (tradeCount < 5) {
        return "#999999";
      } else {
        return chartData[currentTimeFrame].data[interval]?.[chartMode] >= 0
          ? "#009245"
          : "#BE1E2D";
      }
    } else {
      return chartData[999].data[interval]?.[chartMode] >= 0
        ? "#56A07E"
        : "#C15357";
    }
  });
  return generalHoverBorderColor;
}

export default createGeneralHoverBorderColor;
