const createDiagonalPattern = (color) => {
  let patternCanvas = document.createElement('canvas');
  let ctx = patternCanvas.getContext('2d');
  patternCanvas.width = 5.5;
  patternCanvas.height = 5.5;
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.moveTo(4, 5);
  ctx.lineTo(5, 4);
  ctx.stroke();
  ctx.beginPath();
  ctx.moveTo(0, 4);
  ctx.lineTo(4, 0);
  ctx.stroke();
  return ctx.createPattern(patternCanvas, 'repeat');
}

export default createDiagonalPattern;