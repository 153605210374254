import { useSelector, useDispatch } from "react-redux";
import {
  getDisplayedModal,
  getAllChartsInfoModalContent,
  getDisplayedModalDataKey,
  getModalData,
  getSelectedTradeMedicData,
} from "redux/selectors/index";
import * as modalTypes from "constants/modalTypes";
import { closeModal } from "redux/slices/modalSlice";
//modals
import ChartInfoModal from "components/modals/chartInfoModal";
import CommissionComparisonChartInfoModal from "./modals/commissionComparisonInfoModal";
import CommissionComparisonModal from "./modals/commissionComparisonModal";
import SignUpModal from "./modals/signUpModal";
import LoginModal from "./modals/loginModal";
import AddTradingAccountModal from "./modals/addTradingAccountModal";
import ResetPasswordModal from "./modals/resetPasswordModal";
import AlertModal from "./modals/alertModal";
import TradeMedicInfoModal from "./modals/tradeMedicInfoModal";
import AlertInfoModal from "./modals/alertInfoModal";
import SandboxTradeMedicInfoModal from "./modals/sandboxTradeMedicInfoModal";
import SandboxCommissionComparisonModal from "./modals/sandboxCommissionComparisonModal";
import SandboxCommissionComparisonChartInfoModal from "./modals/sandboxCommissionComparisonInfoModal";
import ResponsiveTradeMedicInfoModal from "./modals/responsiveTradeMedicInfoModal";

import ModalWrapper from "UI/modalWrapper";
import { useEffect } from "react";

function ModalManager() {
  const displayedModal = useSelector(getDisplayedModal);
  const allChartsInfoModalContent = useSelector(getAllChartsInfoModalContent);
  const displayedModalDataKey = useSelector(getDisplayedModalDataKey);
  const modalData = useSelector(getModalData);
  const selectedTradeMedicData = useSelector(getSelectedTradeMedicData);
  const dispatch = useDispatch();

  const close = () => {
    return dispatch(
      closeModal({
        displayedModal: null,
      })
    );
  };

  function closeModalByEscape(e) {
    if (e.code === "Escape") {
      close();
      document.body.removeEventListener("keydown", closeModalByEscape);
    }
  }

  useEffect(() => {
    if (displayedModal) {
      document.body.style.overflowY = "hidden";
      document.addEventListener("keydown", closeModalByEscape);
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [displayedModal]);

  let modal;
  switch (displayedModal) {
    case modalTypes.CHART_INFO_MODAL:
      modal = (
        <ChartInfoModal
          close={close}
          modalContent={allChartsInfoModalContent[displayedModalDataKey]}
        />
      );
      break;
    case modalTypes.ALERT_MODAL:
      modal = (
        <AlertModal
          close={close}
          displayedModalDataKey={displayedModalDataKey}
          modalContent={allChartsInfoModalContent[displayedModalDataKey]}
          // selectedAlertData={selectedAlertData}
        />
      );
      break;
    case modalTypes.ALERT_INFO_MODAL:
      modal = <AlertInfoModal />;
      break;
    case modalTypes.COMMISSION_COMPARISON_MODAL:
      modal = <CommissionComparisonModal />;
      break;
    case modalTypes.COMMISSION_COMPARISON_INFO_MODAL:
      modal = <CommissionComparisonChartInfoModal />;
      break;
    case modalTypes.SIGN_UP_MODAL:
      modal = <SignUpModal />;
      break;
    case modalTypes.LOGIN_MODAL:
      modal = <LoginModal />;
      break;
    case modalTypes.ADD_TRADING_ACCOUNT_MODAL:
      modal = <AddTradingAccountModal />;
      break;
    case modalTypes.RESET_PASSWORD_MODAL:
      modal = <ResetPasswordModal modalData={modalData} />;
      break;
    case modalTypes.TRADE_MEDIC_INFO_MODAL:
      modal = (
        <TradeMedicInfoModal
          close={close}
          selectedTradeMedicData={selectedTradeMedicData}
        />
      );
      break;
    case modalTypes.SANDBOX_TRADE_MEDIC_INFO_MODAL:
      modal = (
        <SandboxTradeMedicInfoModal
          close={close}
          selectedTradeMedicData={selectedTradeMedicData}
        />
      );
      break;
    case modalTypes.SANDBOX_COMMISSION_COMPARISON_MODAL:
      modal = <SandboxCommissionComparisonModal />;
      break;
    case modalTypes.SANDBOX_COMMISSION_COMPARISON_INFO_MODAL:
      modal = <SandboxCommissionComparisonChartInfoModal />;
      break;
    case modalTypes.RESPONSIVE_TRADE_MEDIC_INFO_MODAL:
      modal = (
        <ResponsiveTradeMedicInfoModal
          close={close}
          selectedTradeMedicData={selectedTradeMedicData}
        />
      );
      break;
    default:
      return null;
  }

  return <ModalWrapper>{modal}</ModalWrapper>;
}

export default ModalManager;
