const symbols = {
  chartTitle: 'Symbols',
  999: [
    {
      symbol: "EUR/USD",
      percentage: 36,
      trades: 534
    },
    {
      symbol: "GBP/USD",
      percentage: 24,
      trades: 353
    },
    {
      symbol: "USDCHF",
      percentage: 12,
      trades: 177
    },
    {
      symbol: "USD/JPY",
      percentage: 13,
      trades: 192
    },
    {
      symbol: "CAD/CHF",
      percentage: 16,
      trades: 244
    },
  ]
}

export default symbols;