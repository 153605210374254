import { createSlice } from '@reduxjs/toolkit';
import { chartKeys } from 'utils/constant';

const chartKeyValues = Object.values(chartKeys);
const initialChartState = {
  lastChartUpdatedAt: Date.now(),
  isDataFetched: false,
};
chartKeyValues.forEach(chartKey => initialChartState[chartKey] = null);

export const chartSlice = createSlice({
  name: 'chart',
  initialState: initialChartState,
  reducers: {
    setChartsData: (state, action) => {
      return {
        ...state,
        ...action.payload.chartsData,
      }
    },
    resetChartsData: (state, action) => {
      const chartKeyValues = Object.values(chartKeys);
      const newState = {
        lastUpdatedAt: Date.now()
      };
      chartKeyValues.forEach(chartKey => newState[chartKey] = null);
      return newState;
    },
    setLastChartUpdateAt: (state, action) => {
      return {
        ...state,
        lastChartUpdatedAt: action.payload.lastChartUpdatedAt
      }
    },
    setIsDataFetched: (state, action) => {
      return {
        ...state,
        isDataFetched: action.payload.isDataFetched
      }
    }
  },
});

export const { 
  setChartsData,
  resetChartsData,
  setLastChartUpdateAt,
  setIsDataFetched
} = chartSlice.actions;

export const chartReducer = chartSlice.reducer;