import { useState } from "react";

function MobileTopFivePatternsBar({ data }) {
  const [isHover, setIsHover] = useState(false);
  let barColor = data.type === "profit" ? "#56A07E" : "#C15357";
  let hoveredBarBackgroundColor =
    data.type === "profit" ? "#009245" : "#93272D";

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const containerStyles = {
    height: 30,
    width: "100%",
    backgroundColor: "#B8C8CA",
    borderRadius: 3,
    marginBottom: 3,
    display: "flex",
    flexDirection: "column",
  };

  const fillerStyles = {
    width: `${data.value}%`,
    height: 10,
    backgroundColor: barColor,
    borderRadius: "inherit",
    textAlign: "left",
    transition: "all 0.3s ease",
  };

  const hoveredBarTextStyles = {
    fontSize: "12px",
    textAlign: "left",
    fontWeight: "bold",
    backgroundColor: hoveredBarBackgroundColor,
    color: "white",
    borderRadius: "0 0 5px 5px",
    zIndex: 100,
    paddingLeft: "3px"
  };

  const labelTextStyles = {
    fontSize: "12px",
    textAlign: "left",
    color: "#00333B"
  }

  return (
    <>
      <div>
        <div
          style={containerStyles}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div style={labelTextStyles}>{data.text}</div>
          {isHover ? (
            <div style={hoveredBarTextStyles}>
              {data.hoveredBarText[0]}
              <br />
              {data.hoveredBarText[1]}
            </div>
          ) : (
            <div style={fillerStyles}></div>
          )}
        </div>
      </div>
    </>
  );
}

export default MobileTopFivePatternsBar;
