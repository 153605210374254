import { chartKeys } from 'utils/constant';

const profit_if_manual_stop_before_tp = {
  chartTitle: "Profit if manual stop before TP",
  chartKey: chartKeys.PROFIT_IF_MANUAL_STOP_BEFORE_TP,
  hoverTextArray: {
    "usd": [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.usd >= anotherBar[0].usd? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.usd >= anotherBar[0].usd? "#56A07E" : "#C15357"
        },
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.percentage >= anotherBar[0].percentage? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.percentage >= anotherBar[0].percentage? "#56A07E" : "#C15357"
        },
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ]
  },
  interval: [
    'Manual Stop',
    'Original TP setting',
  ],
  createBarColor: function(chartData) {
    const manualStopData = chartData['999']['data']['Manual Stop'];
    const originalTPSettingData = chartData['999']['data']['Original TP setting']
    
    if (manualStopData > originalTPSettingData) {
      return ['#56A07E', '#C15357']
    } else {
      return ['#C15357', '#56A07E']
    }
  },
  createHoverBarColor: function (chartData) {
    const manualStopData = chartData['999']['data']['Manual Stop'];
    const originalTPSettingData = chartData['999']['data']['Original TP setting']
    
    if (manualStopData > originalTPSettingData) {
      return ["#009245", "#BE1E2D"]
    } else {
      return ["#BE1E2D", "#009245"]
    }
  }
}

export default profit_if_manual_stop_before_tp;