const alerts = [
  {
    chartKey: "profit_by_daytime_per_trade",
    reason: "Action in loss-making pattern",
    time: "2023.10.02 10:26:25",
    orderID: "976555683",
    area: "Profit by daytime (per trade)",
    msg: "You just opened a trade during a time of the day in which you on average show a loss in your trades.",
    videoKey: "profit_by_daytime_per_trade",
    category: "2000-mn",
    read: true,
  },
  {
    chartKey: "profit_by_symbol_per_trade",
    reason: "Action in loss-making pattern",
    time: "2023.07.31 13:21:37",
    orderID: "976549944",
    area: "Profit by symbol (per trade)",
    msg: "You just opened a GBPUSD trade. Trading this symbol on average led to a loss for you in the past",
    videoKey: "profit_by_symbol_per_trade",
    category: "GBPUSD",
    read: false,
  },
  {
    chartKey: "percentage_of_position_with_sl",
    reason: "Risky behavior",
    time: "2023.07.31 12:05:01",
    orderID: "976545114",
    area: "Percentage of position with SL",
    msg: "Missing Stop Loss: The trade you just opened does not have a Stop Loss. Please be mindful of high risk",
    videoKey: "percentage_of_position_with_sl",
    category: "n/a",
    read: false,
  },
  {
    chartKey: "risk_per_trade_last_trades",
    reason: "Risky behavior",
    time: "2023.07.31 08:42:55",
    orderID: "976539050",
    area: "Risk per trade (last trades)",
    msg: "The risk profile of your current trade is significantly higher compared to your previous average. Be cautious!",
    videoKey: "risk_per_trade_last_trades",
    category: "n/a",
    read: true,
  },
  {
    chartKey: "profit_by_symbol_per_trade",
    reason: "Action in loss-making pattern",
    time: "2023.07.31 08:42:55",
    orderID: "976539051",
    area: "Profit by symbol (per trade)",
    msg: "You just opened a BTCUSD trade. Trading this symbol on average led to a loss for you in the past",
    videoKey: "profit_by_symbol_per_trade",
    category: "BTCUSD",
    read: true,
  },
  {
    chartKey: "profit_if_manual_stop_before_tp",
    reason: "Action in loss-making pattern",
    time: "2023.07.28 10:39:37",
    orderID: "976535107",
    area: "Profit if manual stop before TP",
    msg: "You just manually closed your trade in profit. Kindly be reminded that on average you would have had a better performance not doing so",
    videoKey: "profit_if_manual_stop_before_tp",
    category: "Manual Stop",
    read: true,
  },
  {
    chartKey: "break_after_loss_trade_per_trade",
    reason: "Action in loss-making pattern",
    time: "2023.07.27 10:28:36",
    orderID: "976520322",
    area: "Break after loss trade (per trade)",
    msg: "You just opened a trade with a break of 0-5m after your recent loss trade. Be cautious of revenge trading",
    videoKey: "break_after_loss_trade_per_trade",
    category: "0-5m",
    read: true,
  },
  {
    chartKey: "open_position_in_profit_loss",
    reason: "Action in loss-making pattern",
    time: "2023.07.27 10:28:36",
    orderID: "976520322",
    area: "Open position in profit/loss",
    msg: "When you just opened the trade you already had positions with unrealized gains of <-4%. Be cautious of open positions influecing your behavior",
    videoKey: "open_position_in_profit_loss",
    category: "n/a",
    read: true,
  },
  {
    chartKey: "trades_per_day_per_trade",
    reason: "New loss making pattern",
    time: "2023.07.27 09:24:12",
    orderID: "976517202",
    area: "Trades per day (per trade)",
    msg: "We detected a new loss-making pattern for you in the analysis Trades per day",
    videoKey: "trades_per_day_per_trade",
    category: "11-15",
    read: true,
  },
  {
    chartKey: "profit_by_symbol_per_trade",
    reason: "New loss making pattern",
    time: "2023.07.27 09:24:12",
    orderID: "976517202",
    area: "Profit by symbol (per trade)",
    msg: "We detected a new loss-making pattern for you in the analysis Profit by Symbol",
    videoKey: "profit_by_symbol_per_trade",
    category: "GBPJPY",
    read: true,
  },
]

export default alerts;