import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styles from "./donutCharts.module.scss";
import htFonts from "shared/htFont.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

function Donuts(props) {
  let { chartData } = props;

  if (chartData.noData) {
    return (
      <div style={{
        backgroundColor: '#00272E',
        borderBottomLeftRadius: 20,
        color: 'white',
        height: 300,
        lineHeight: '300px',
        fontSize: '1.1rem'
      }}>
        Not Sufficient Data Available yet
      </div>
    );
  }
  chartData = chartData[999];
  const profitPatterns = chartData.profit.patterns;
  const lossPatterns = chartData.loss.patterns;
  const insignificantPatterns = chartData.insignificant.patterns;

  const profitData = {
    labels: ["profit", "loss", "insiginificant"],
    datasets: [
      {
        data: [profitPatterns, lossPatterns, insignificantPatterns],
        backgroundColor: ["#56A07E", "#443035", "#264349"],
        borderWidth: 0,
      },
    ],
  };

  const lossData = {
    labels: ["profit", "loss", "insiginificant"],
    datasets: [
      {
        data: [profitPatterns, lossPatterns, insignificantPatterns],
        backgroundColor: ["#1E4B42", "#C15357", "#264349"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.ProfitDonut}>
        <div className={styles.donutChartWrapper}>
          <Doughnut
            width={90}
            height={90}
            data={profitData}
            options={{
              cutout: 35,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              responsive: false,
              maintainAspectRatio: false,
            }}
          />
          <span className={`${htFonts.htUpChart} ${styles.htUpChart}`}/>
        </div>
        <div className={styles.PatternsInfo}>
          <div className={styles.PatternValue}>{profitPatterns}</div>
          <div>
            Profit making patterns
            <br />
            <strong>
              {chartData.profit.pips?.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}Pips / {chartData.profit.profit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}USD
            </strong>
          </div>
        </div>
      </div>
      <div className={styles.LossDonut}>
        <div className={styles.donutChartWrapper}>
          <Doughnut
            width={90}
            height={90}
            data={lossData}
            options={{
              cutout: 35,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              responsive: false,
              maintainAspectRatio: false,
            }}
          />
          <span className={`${htFonts.htDownChart} ${styles.htUpChart}`}/>
        </div>
        <div className={styles.PatternsInfo}>
          <div className={styles.PatternValue}>{lossPatterns}</div>
          <div className={styles.PatternDetails}>
            Loss making patterns
            <br />
            <strong>
              {chartData.loss.pips?.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}Pips / {chartData.loss.profit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}USD
            </strong>
          </div>
        </div>
      </div>
      <div className={styles.Insiginificants}>
        <div className={styles.InsiginificantsValue}>
          {insignificantPatterns}
        </div>
        Insignificant
      </div>
    </div>
  );
}

export default Donuts;
