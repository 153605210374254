import styles from "../../overview/accountOverview/tradesPattern/progressBars.module.scss";
import ProgressBar from "./progressBar";

const ProgressBars = (props) => {
  const { fontColor, backgroundColor, hoverColor, type } = props;

  let barData;
  if (type === 'profit') {
    barData = [
      {
        key: "Break since last trade",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 100,
        fontColor: fontColor,
        interval: '30+',
        type: 'profit',
        pips: 4.9
      },
      {
        key: "Profit by Trade Length",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 92,
        fontColor: fontColor,
        interval: '1d+',
        type: 'profit',
        pips: 4.5
      },
      {
        key: "Profit by Trade Length",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 88,
        fontColor: fontColor,
        interval: '1-3h',
        type: 'profit',
        pips: 4.3
      },
      {
        key: "Trades per Day",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 84,
        fontColor: fontColor,
        interval: '1-3',
        type: 'profit',
        pips: 4.1
      },
      {
        key: "Profit by weekday",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 84,
        fontColor: fontColor,
        interval: 'Fri',
        type: 'profit',
        pips: 4.1
      },
    ];
  } else {
    barData = [
      {
        key: "Break after loss trade",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 100,
        fontColor: fontColor,
        interval: '0-5m',
        type: 'loss',
        pips: -7.1
      },
      {
        key: "Break after loss trade",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 61,
        fontColor: fontColor,
        interval: '5-10m',
        type: 'loss',
        pips: -4.3
      },
      {
        key: "Profit after daily profit/ loss",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 58,
        fontColor: fontColor,
        interval: '<-5%',
        type: 'loss',
        pips: -4.1
      },
      {
        key: "Break since last trade",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 45,
        fontColor: fontColor,
        interval: 'ongoing',
        type: 'loss',
        pips: -3.2
      },
      {
        key: "Open positions in profit/ loss",
        backgroundColor: backgroundColor,
        hoverColor: hoverColor,
        percentage: 45,
        fontColor: fontColor,
        interval: '<-4%',
        type: 'loss',
        pips: -3.2
      },
    ];
  }

  return (
    <div className={styles.progressBarWrapper}>
      {
        barData.map((data, index) => {
          return <ProgressBar key={`${data.key}-${index}`} data={data}/>
        })
      }
    </div>
  );
};

export default ProgressBars;