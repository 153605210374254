import SelectComponent from "../SelectComponent";
import sharedStyles from "../shared.module.scss";
import styles from "./cryptoCredentialSection.module.scss";
import htFonts from "shared/htFont.module.scss";

export default function cryptoCredentialSection({
  cryptoTradingAccountInfo,
  setCryptoTradingAccountInfo,
  setPhase,
  submitCredentials,
  setInfoContentKey,
}) {
  const tradingExperienceOptions = [
    {
      label: "< 6 months",
      value: "< 6 moths",
    },
    {
      label: "6 - 12 months",
      value: "6 - 12 months",
    },
    {
      label: "12 - 36 months",
      value: "12 - 36 months",
    },
    {
      label: "> 36 months",
      value: "> 36 months",
    },
  ];

  const tradingTypeOptions = [
    {
      label: "Manual",
      value: "Manual",
    },
    {
      label: "Trading robot (Expert Advisor)",
      value: "Trading robot (Expert Advisor)",
    },
  ];

  const validateCredentialSection = () => {
    let passed = true;
    const fields = [
      "portfolioName",
      "tradingExperience",
      "tradingType",
    ];
    fields.forEach((field) => {
      if (!cryptoTradingAccountInfo[field]) {
        passed = false;
      }
    });
    return passed;
  };

  const sectionInfoValidated = validateCredentialSection();

  const onInputChange = (field, newValue) => {
    const copiedTradingAccountInformation = { ...cryptoTradingAccountInfo };
    copiedTradingAccountInformation[field] = newValue;
    setCryptoTradingAccountInfo(copiedTradingAccountInformation);
  };

  const onCheckboxChange = (field) => {
    const copiedTradingAccountInformation = { ...cryptoTradingAccountInfo };
    copiedTradingAccountInformation[field] =
      !copiedTradingAccountInformation[field];
      setCryptoTradingAccountInfo(copiedTradingAccountInformation);
  };

  return (
    <div>
      <form className={styles.Form}>
        <div className={sharedStyles.InfoRow}>
          <label className={sharedStyles.InfoLabel}>
            Portfolio name
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("portfolio_name")}
            ></span>
          </label>
          <input
            className={`${sharedStyles.InfoInput} ${styles.Input}`}
            value={cryptoTradingAccountInfo["portfolioName"]}
            onChange={(e) => onInputChange("portfolioName", e.target.value)}
          />
        </div>
        <div className={`${sharedStyles.InfoRow} ${styles.CheckboxRow}`}>
          <input
            type="checkbox"
            checked={cryptoTradingAccountInfo["accountPublic"]}
            onChange={(e) => onCheckboxChange("accountPublic")}
          />
          <div>
            <div className={styles.CheckboxLabel}>
              Set this account as public
            </div>
            <div className={styles.CheckboxDescription}>
              For a public account, other people will be able to see your
              trading performance.
            </div>
          </div>
        </div>
        <div className={sharedStyles.InfoRow}>
          <label className={sharedStyles.InfoLabel}>
            Level of trading experience
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("trading_experience")}
            ></span>
          </label>
          <SelectComponent
            setFormData={setCryptoTradingAccountInfo}
            formData={cryptoTradingAccountInfo}
            instanceId="trading-experience-select"
            formField="tradingExperience"
            options={tradingExperienceOptions}
          />
        </div>
        <div className={sharedStyles.InfoRow}>
          <label className={sharedStyles.InfoLabel}>
            Trading type
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("trading_type")}
            ></span>
          </label>
          <SelectComponent
            setFormData={setCryptoTradingAccountInfo}
            formData={cryptoTradingAccountInfo}
            instanceId="trading-type-select"
            formField="tradingType"
            options={tradingTypeOptions}
          />
        </div>
        <div style={{marginTop: "150px"}}>
          <div className={sharedStyles.InfoRow}>
            <div className={styles.PreviousButton} onClick={() => setPhase(0)}>
              Previous
            </div>
            {sectionInfoValidated ? (
              <div
                className={styles.NextButton}
                onClick={() => submitCredentials()}
              >
                Connect
              </div>
            ) : (
              <div className={`${styles.NextButton} ${styles.Disabled}`}>
                Connect
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}