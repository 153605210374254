import styles from "./notificationButton.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllAlertsData, getCurrentTradingAccount } from "redux/selectors";
import { setAlertsData } from "redux/slices/alertSlice";
import { getAlertData } from "utils/requests";
import { useState, useEffect, useRef } from "react";
import { openModal } from "redux/slices/modalSlice";
import { insertNewAlert } from "utils/requests";
import chartKeyWithNameMap from "utils/constant/chartKeyNameMap";
import { alertTypeReasonTextMap } from 'utils/constant/index';
import generateTimeFormatForAlertFromUTC from "utils/helpers/generateTimeFormatForAlertFromUTC";
import { deleteAlertData, changeAlertReadStatus } from "utils/requests";
import { toggleAlertReadStatus } from "redux/slices/alertSlice";
import { Link, useNavigate } from "react-router-dom";
//constants
import * as modalTypes from "constants/modalTypes";

function AlertItem({ data, alertOnClickHandler }) {
  return (
    <li
      className={data.read ? styles.readNotificationItem : styles.unreadNotificationItem}
      onClick={() => alertOnClickHandler(data.chartKey, data)}
    >
      <div>
        <div className={styles.notificationTitle}>
          Title: {alertTypeReasonTextMap[data.alertType]}
        </div>
        <div>
          Time: { generateTimeFormatForAlertFromUTC(data.time)}
        </div>
        <div className={styles.notificationContent}>
          Name of analysis: {chartKeyWithNameMap[data.chartKey]}
        </div>
      </div>
      <div>
        {
          data.read?
            null : <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#06AFC9' }}></div>
        }
      </div>
    </li>
  );
}

function NotificationButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  let allAlertsData = useSelector(getAllAlertsData);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
    useState(false);
  const [isAlertDataChanged, setIsAlertDataChanged] = useState(false);
  const [selectedNotificationCategory, setSelectedNotificationCategory] = useState('alerts');
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsNotificationDropdownOpen(false);
      }
    }

    function onEscapeClick(e) {
      if (e.keyCode === 27) {
        setIsNotificationDropdownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener('keydown', onEscapeClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener('keydown', onEscapeClick);
    };
  }, [wrapperRef]);

  useEffect(() => {
    (async function () {
      const result = await getAlertData(
        currentTradingAccount?.trading_account_id
      );
      if (result.data) {
        dispatch(setAlertsData({ alertsData: result.data }));
        setIsAlertDataChanged(false);
      } else {
        console.log("fetching alert data error");
      }
    })();
  }, [dispatch, currentTradingAccount], isAlertDataChanged);

  const alertOnClickHandler = async (chartKey, selectedAlertData) => {
    setIsNotificationDropdownOpen(false);
    
    if (selectedAlertData.read) {
      return dispatch(
        openModal({
          displayedModal: modalTypes.ALERT_MODAL,
          displayedModalDataKey: chartKey,
          selectedAlertData: selectedAlertData,
        }),
      );
    }

    const result = await changeAlertReadStatus(selectedAlertData.alertId);
    if (result.data) {
      dispatch(
        toggleAlertReadStatus({ alertId: result.data.alertId, readStatus: result.data.read })
      );
      // setIsAlertDataChanged(true);
      return dispatch(
        openModal({
          displayedModal: modalTypes.ALERT_MODAL,
          displayedModalDataKey: chartKey,
          selectedAlertData: selectedAlertData,
        }),
      );
    } else {
      console.log("fetching data error")
    }
  };

  const seeAllHandler = () => {
    setIsNotificationDropdownOpen(false);
    return navigate('/dashboard/alert');
  }

  let unreadAlertData = [];
  for (let i = 0; i < allAlertsData.length -1; i++) {
    if (allAlertsData[i].read === false) {
      unreadAlertData.push(allAlertsData[i])
    }
  };

  const unreadAlertCount = allAlertsData.reduce((accumulator, currentValue, currentIndex) => {
    if (!allAlertsData[currentIndex].read) {
      return accumulator + 1;
    } else {
      return accumulator;
    }
  }, 0);

  const alertItems = allAlertsData.length > 10?
    [...allAlertsData].slice(allAlertsData.length - 10).sort((a, b) => b.time - a.time).map((data) => <AlertItem key={data.alertId} data={data} alertOnClickHandler={alertOnClickHandler}/>) :
    [...allAlertsData].sort((a, b) => (b.time - a.time)).map((data) => <AlertItem key={data.alertId} data={data} alertOnClickHandler={alertOnClickHandler}/>);

  return (
    <>
      <div className={styles.NotificationButtonContainer} ref={wrapperRef}>
        <div
          className={styles.BellButton}
          onClick={() =>
            setIsNotificationDropdownOpen(!isNotificationDropdownOpen)
          }
        >
          <span className={styles.htBell}></span>
          {unreadAlertCount !== 0 ? (
            <div className={styles.NotificationNumber}>
              {unreadAlertCount}
            </div>
          ) : null}
        </div>
        {isNotificationDropdownOpen ? (
          <ul className={styles.dropdownMenu}>
            <div className={styles.dropdownTitle}>Notification</div>
            <div className={styles.NotificationCategoryButtonContainer}>
              <div className={
                selectedNotificationCategory === 'all' ?
                `${styles.NotificationCategoryButton} ${styles.SelectedNotificationCategory}` :
                `${styles.NotificationCategoryButton} ${styles.Disabled}`

              }
              >All</div>
              <div className={
                selectedNotificationCategory === 'alerts' ?
                `${styles.NotificationCategoryButton} ${styles.SelectedNotificationCategory}` :
                styles.NotificationCategoryButton

              }>Alerts</div>
              <div className={`${styles.NotificationCategoryButton} ${styles.Disabled}`}>Messages</div>
              <div className={`${styles.NotificationCategoryButton} ${styles.Disabled}`}>Others</div>
            </div>
            <hr style={{width: '120%', marginLeft: '-10%', borderTop: 'none', borderBottom: '2px solid #ccc'}}></hr>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 60px'}}>
              <div className={styles.DropdownMenuSubTitle} style={{display: 'inline-block'}}>
                Alerts
              </div>
              <div style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => seeAllHandler()}>See All</div>
            </div>
            <hr style={{borderTop: 'none', borderBottom: '1px solid #ccc'}}></hr>
            {alertItems}
          </ul>
        ) : null}
      </div>
    </>
  );
}

export default NotificationButton;
