import styles from "./notificationButton.module.scss";
import { useState, useEffect, useRef } from "react";
import { insertNewAlert } from "utils/requests";
import chartKeyWithNameMap from "utils/constant/chartKeyNameMap";
import allAlertsData from "devData/alerts";
import SandboxAlertModal from "components/modals/sandboxAlertModal";

function AlertItem({ data, alertOnClickHandler, setIsModalOpen, setSelectedAlertData }) {
  return (
    <li
      className={
        data.read ? styles.readNotificationItem : styles.unreadNotificationItem
      }
      onClick={() => alertOnClickHandler(data.chartKey, data, setIsModalOpen, setSelectedAlertData)}
    >
      <div>
        <div className={styles.notificationTitle}>
          Title: {data.reason}
        </div>
        <div>
          Time: {data.time}
        </div>
        <div className={styles.notificationContent}>
          Name of analysis: {chartKeyWithNameMap[data.chartKey]}
        </div>
      </div>
      <div>
        {data.read ? null : (
          <div
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#06AFC9",
            }}
          ></div>
        )}
      </div>
    </li>
  );
}

function SandboxNotificationButton() {
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
    useState(false);
  const [isAlertDataChanged, setIsAlertDataChanged] = useState(false);
  const [selectedNotificationCategory, setSelectedNotificationCategory] =
    useState("alerts");
  const wrapperRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAlertData, setSelectedAlertData] = useState(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsNotificationDropdownOpen(false);
      }
    }

    function onEscapeClick(e) {
      if (e.keyCode === 27) {
        setIsNotificationDropdownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", onEscapeClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", onEscapeClick);
    };
  }, [wrapperRef]);


  const alertOnClickHandler = async (chartKey, selectedAlertData, setIsModalOpen) => {
    setIsNotificationDropdownOpen(false);
    setSelectedAlertData(selectedAlertData);
    selectedAlertData.read = true;
    setIsModalOpen(true);
  };

  let unreadAlertData = [];
  for (let i = 0; i < allAlertsData.length - 1; i++) {
    if (allAlertsData[i].read === false) {
      unreadAlertData.push(allAlertsData[i]);
    }
  }

  const alertItems =
    allAlertsData.length > 10
      ? [...allAlertsData]
          .slice(allAlertsData.length - 10)
          .sort((a, b) => b.time - a.time)
          .map((data) => (
            <AlertItem
              key={data.alertId}
              data={data}
              alertOnClickHandler={alertOnClickHandler}
              setIsModalOpen={setIsModalOpen}
              setSelectedAlertData={setSelectedAlertData}
            />
          ))
      : [...allAlertsData]
          .sort((a, b) => b.time - a.time)
          .map((data) => (
            <AlertItem
              key={data.alertId}
              data={data}
              alertOnClickHandler={alertOnClickHandler}
              setIsModalOpen={setIsModalOpen}
              setSelectedAlertData={setSelectedAlertData}
            />
          ));

  return (
    <>
      <div className={styles.NotificationButtonContainer} ref={wrapperRef}>
        <div
          className={styles.BellButton}
          onClick={() =>
            setIsNotificationDropdownOpen(!isNotificationDropdownOpen)
          }
        >
          <span className={styles.htBell}></span>
          {unreadAlertData.length !== 0 ? (
            <div className={styles.NotificationNumber}>
              {unreadAlertData.length}
            </div>
          ) : null}
        </div>
        {isNotificationDropdownOpen ? (
          <ul className={styles.dropdownMenu}>
            <div className={styles.dropdownTitle}>Notification</div>
            <div className={styles.NotificationCategoryButtonContainer}>
              <div
                className={
                  selectedNotificationCategory === "all"
                    ? `${styles.NotificationCategoryButton} ${styles.SelectedNotificationCategory}`
                    : `${styles.NotificationCategoryButton} ${styles.Disabled}`
                }
              >
                All
              </div>
              <div
                className={
                  selectedNotificationCategory === "alerts"
                    ? `${styles.NotificationCategoryButton} ${styles.SelectedNotificationCategory}`
                    : styles.NotificationCategoryButton
                }
              >
                Alerts
              </div>
              <div
                className={`${styles.NotificationCategoryButton} ${styles.Disabled}`}
              >
                Messages
              </div>
              <div
                className={`${styles.NotificationCategoryButton} ${styles.Disabled}`}
              >
                Others
              </div>
            </div>
            <hr
              style={{
                width: "120%",
                marginLeft: "-10%",
                borderTop: "none",
                borderBottom: "2px solid #ccc",
              }}
            ></hr>
            <div className={styles.DropdownMenuSubTitle}>Alerts</div>
            <hr
              style={{ borderTop: "none", borderBottom: "1px solid #ccc" }}
            ></hr>
            {alertItems}
          </ul>
        ) : null}
        {
          isModalOpen ? <SandboxAlertModal data={selectedAlertData} setIsModalOpen={setIsModalOpen}/> : null
        }
      </div>
    </>
  );
}

export default SandboxNotificationButton;
