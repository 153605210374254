import styles from "./tradeMedicInfoModal.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import alertsAreaMap from "devData/alertsAreaMap";
import Spinner from "UI/spinner";
// utils
import { fetchChartsData } from "utils/requests";
import { getChartsData, getCurrentTradingAccount } from "redux/selectors";
import { setChartsData } from "redux/slices/chartSlices";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import { createDataObjectForChart } from "utils/helpers/chart";
import createCorrespondChartForAlertModal from "utils/constant/createCorrespondChartForAlertModal";
import chartKeys from "utils/constant/chartKeys";
import tradeMedicInfoContent from "devData/tradeMedicInfoContent";

const ChartMode = {
  PIPS: "pips",
  PERCENTAGE: "percentage",
  NUMBER: "number",
};

function TradeMedicInfoModal({ close, selectedTradeMedicData }) {
  const dispatch = useDispatch();
  const chartsData = useSelector(
    getChartsData([selectedTradeMedicData.chart_key])
  );
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const [chartsDataError, setChartsDataError] = useState(false);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const chartsKeyArray = Object.values(chartKeys);

  useEffect(() => {
    (async function () {
      if (chartsData[selectedTradeMedicData.chart_key] === null) {
        const result = await fetchChartsData(
          currentTradingAccount.trading_account_id,
          chartsKeyArray
        );
        if (result.data) {
          dispatch(setChartsData({ chartsData: result.data }));
        } else {
          setChartsDataError(true);
        }
      }
    })();
  }, [
    chartsData,
    chartsKeyArray,
    currentTradingAccount,
    dispatch,
    selectedTradeMedicData.chart_key,
  ]);

  if (selectedTradeMedicData.issue_name === "Trade Medic") {
    return (
      <div className={styles.TradeMediGeneralInfo}>
        <div className={styles.ModalHeader}>
          <div className={styles.IssueName}>Trade Medic</div>
          <div className={styles.CrossIconButton}>
            <div
              className={`${htFonts.htCross} ${styles.CancelIcon}`}
              onClick={() => close()}
            ></div>
          </div>
        </div>
        <div className={styles.ModalContent}>
          Your trading issues reference to your trading behavior since account
          inception. For custom analyses on shorter timeframes, please refer to
          the according output charts. The issues described are not MECE
          (mutually exclusive & collectively exhaustive). For detailed
          explanation on the single issues or risk areas, please refer to the
          dedicated information provided for each item.
        </div>
      </div>
    );
  }
  console.log('issue_key', selectedTradeMedicData.issue_key);
  const selectedTradeMedicDataFurtherDetail = tradeMedicInfoContent[selectedTradeMedicData.issue_key].furtherDetail;

  if (
    !createIsChartsDataAvailable(chartsData, [
      selectedTradeMedicData.chart_key,
    ]) &&
    !chartsDataError
  ) {
    return (
      <div className={styles.alertModal}>
        <div style={{ minHeight: "500px", position: "relative" }}>
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  if (chartsDataError) {
    return (
      <div
        style={{ margin: "40px auto", fontWeight: "bold", color: "#4D4D4D" }}
      >
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  let chartDataObject = createDataObjectForChart(
    selectedTradeMedicData.chart_key,
    chartsData[selectedTradeMedicData.chart_key]
  );

  const chartProps = {
    key: chartDataObject.chart_key,
    chartTitle: chartDataObject.chartTitle,
    chartData: chartDataObject,
    hoverTextArray: chartDataObject.hoverTextArray,
    currentTimeFrame: currentTimeFrame,
    setCurrentTimeFrame: setCurrentTimeFrame,
    chartMode: chartMode,
    setChartMode: setChartMode,
    dualMode: true,
    hasTimeFrame: true,
    currentTradingAccount: currentTradingAccount,
  };

  let chart = createCorrespondChartForAlertModal(
    selectedTradeMedicData.chart_key,
    chartProps
  );

  let page;
  switch (alertsAreaMap[selectedTradeMedicData.chart_key]) {
    case "behavior":
      page = "behavioral";
      break;
    case "timing":
      page = "timing";
      break;
    case "risk":
      page = "risk-management";
      break;
    case "strategy":
      page = "strategy";
      break;
    case "cost":
      page = "costs";
      break;
    default:
  }

  let areaIcons = {
    behavioral: htFonts.htCoffee,
    timing: htFonts.htTiming,
    risk: htFonts.htAlert,
    strategy: htFonts.htChessCastle,
    cost: htFonts.htCoins,
  };

  return (
    <div className={styles.TradeMedicInfoModal}>
      <div className={styles.ModalHeader}>
        <div className={styles.IssueName}>
          Issue: {selectedTradeMedicData.issue_name}
        </div>
        <div className={styles.CrossIconButton}>
          <div
            className={`${htFonts.htCross} ${styles.CancelIcon}`}
            onClick={() => close()}
          ></div>
        </div>
      </div>
      <div className={styles.ModalContent}>
        <div className={styles.ContentLeft}>
          <strong className={styles.Subtitle}>Explanation:</strong>
          <div className={styles.DetailText}>
            {
              tradeMedicInfoContent[selectedTradeMedicData.issue_key]
                .explanation
            }
          </div>
          <strong className={styles.Subtitle}>Further information:</strong>
          {selectedTradeMedicDataFurtherDetail ? (
            <u>
              <div className={styles.FurtherInformation}>
                {selectedTradeMedicDataFurtherDetail.map((detail, index) => {
                  return (
                    <a href={detail.link} target="_blank" rel="noreferrer" key={`further detail ${index}`}>
                      {detail.content}
                    </a>
                  );
                })}
              </div>
            </u>
          ) : null}
        </div>
        <div className={styles.ContentRight}>
          <div className={styles.Chart}>{chart}</div>
          <div className={styles.linkBtn}>
            <Link
              to={`/dashboard/analysis/${page}`}
              id={styles.link}
              onClick={() => close()}
            >
              <div id={styles.linkContent}>
                <span
                  className={`${
                    areaIcons[alertsAreaMap[selectedTradeMedicData.chart_key]]
                  }`}
                  style={{
                    marginRight: "3px",
                    fontSize: "18px",
                    marginTop: "2px",
                  }}
                ></span>
                Go to analysis/{page}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TradeMedicInfoModal;
