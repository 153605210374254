const probabilityLossAfterLoss = {
  chartTitle: "Probability loss after loss",
  barValueKey: "percentageLoss",
  benchmark: 54.90,
  chartKey: "probability_loss_after_loss",
  999: [
    {
      time: new Date("2022-04"),
      percentageLoss: 57.1,
      trades: 57,
    },
    {
      time: new Date("2022-05"),
      percentageLoss: 61.1,
      trades: 92,
    },
    {
      time: new Date("2022-06"),
      percentageLoss: 51.6,
      trades: 81,
    },
    {
      time: new Date("2022-07"),
      percentageLoss: 52.8,
      trades: 52,
    },
    {
      time: new Date("2022-08"),
      percentageLoss: 54.4,
      trades: 29,
    },
    {
      time: new Date("2022-09"),
      percentageLoss: 58.2,
      trades: 59,
    },
    {
      time: new Date("2022-10"),
      percentageLoss: 53.7,
      trades: 71,
    },
    {
      time: new Date("2022-11"),
      percentageLoss: 51.8,
      trades: 50,
    },
    {
      time: new Date("2022-12"),
      percentageLoss: 55.3,
      trades: 54,
    },
    {
      time: new Date("2023-01"),
      percentageLoss: 58.6,
      trades: 55,
    },
    {
      time: new Date("2023-02"),
      percentageLoss: 51.8,
      trades: 70,
    },
    {
      time: new Date("2023-03"),
      percentageLoss: 57.1,
      trades: 98,
    },
    {
      time: new Date("2023-04"),
      percentageLoss: 50.2,
      trades: 140,
    }
  ]
};


export default probabilityLossAfterLoss;