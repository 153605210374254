import { Navigate } from "react-router-dom";
import Spinner from 'UI/spinner';

function SandboxProtectedRoute({ sandboxAuth, children }) {
  if (sandboxAuth === "AUTHENTICATED" ) {
    return children;
  }
  else if (sandboxAuth === "PENDING") {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spinner />
      </div>
    )
  }
  else {
    return <Navigate to="/" />
  }
}

export default SandboxProtectedRoute;