import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import styles from "./dashboard.module.scss";
import DashboardFooter from "components/footer/dashboardFooter";
import SandboxAnalysisIndex from "./analysis/index.page";
import SandboxOverviewIndex from "./overview/index.page";
import SandboxAlert from "./sandboxAlert.page";
import SandboxTradeMedic from "./sandboxTradeMedic.page";
import { fetchSandboxChartsData } from "utils/requests";
import chartKeys from 'utils/constant/chartKeys';
import { setSandboxChartsData } from "redux/slices/sandboxChartSlice";
import { useDispatch } from "react-redux";

function SandboxDashboard() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    const chartsKeyArray = Object.values(chartKeys);
    chartsKeyArray.push("spread_and_commission_comparison");
    (async function() {
      const result = await fetchSandboxChartsData(chartsKeyArray);
      if (result.data) {
        dispatch(
          setSandboxChartsData({
            chartsData: result.data
          })
        )
      } else {
        console.error('fetching data failed');
      }
    })();
  },[dispatch]);

  return (
    <>
      <div className={styles.Dashboard}>
        <Routes>
          <Route path="/analysis/*" element={<SandboxAnalysisIndex />}/>
          <Route path="/overview/*" element={<SandboxOverviewIndex />}/>
          <Route path="/alert/*" element={<SandboxAlert />}/>
          <Route path="/trade-medic/*" element={<SandboxTradeMedic />}/>
          <Route path="/" element={<Navigate to="/sandbox/dashboard/analysis/general" />} />
        </Routes>
      </div>
      <DashboardFooter />
    </>
  )
}

export default SandboxDashboard;