import styles from "./mobileAccountSelection.module.scss";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentTradingAccount, getUser } from "redux/selectors";
import { sendLogoutRequest } from "utils/requests";
import {
  setUser,
  setAuthenticationState,
  setCurrentTradingAccount,
} from "redux/slices/authenticationSlice";
import { authenticationStates } from "utils/constant";

function MobileAccountSelection() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  //night mode should be in react-redux
  const [isNightMode, setIsNightMode] = useState(false);
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const user = useSelector(getUser);
  const [initialPosition, setInitialPosition] = useState(0);

  const logout = async () => {
    const result = await sendLogoutRequest();

    if (result.message === "ACCOUNT_LOGGED_OUT") {
      dispatch(
        setAuthenticationState({
          authenticationState: authenticationStates.UNAUTHENTICATED,
        })
      );
      dispatch(
        setUser({
          user: null,
        })
      );
      dispatch(
        setCurrentTradingAccount({
          currentTradingAccount: null,
        })
      );
    }
  };

  function handleTouchStart(event) {
    setInitialPosition(event.touches[0].clientY);
  }

  function handleTouchMove(event) {
    const currentPosition = event.touches[0].clientY;
    const distance = currentPosition - initialPosition;

    if (distance > 100) {
      setIsOpen(!isOpen);
    }
  }

  return (
    <div className={styles.MobileAccountSelection}>
      <div
        className={styles.AvatarContainer}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          className={styles.Avatar}
          src={
            "https://previews.123rf.com/images/kritchanut/kritchanut1407/kritchanut140700337/29898197-woman-avatar-profile-picture-on-light-gray-background-vector-icon.jpg"
          }
          alt="Avatar"
        />
      </div>
      {isOpen ? (
        <div className={styles.MobileDropdown}>
          <div
            className={styles.Backdrop}
            onClick={() => setIsOpen(!isOpen)}
          ></div>
          <div
            className={styles.DropdownContent}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <hr className={styles.CloseLine}></hr>
            <div className={styles.CurrentAccount}>
              <div
                className={styles.AvatarContainer}
                onClick={() => setIsOpen(!isOpen)}
              >
                <img
                  className={styles.Avatar}
                  src={
                    "https://previews.123rf.com/images/kritchanut/kritchanut1407/kritchanut140700337/29898197-woman-avatar-profile-picture-on-light-gray-background-vector-icon.jpg"
                  }
                  alt="Avatar"
                />
              </div>
              {currentTradingAccount
                ? currentTradingAccount.portfolio_name
                : "-"}
            </div>
            <hr className={styles.SeperatedLine}></hr>
            <div className={styles.SwitchAccount}>
              <div style={{ color: "#047F92", fontWeight: "normal" }}>
                Switch account
              </div>
              {
                user.trading_accounts.map(account => 
                  <div
                    key={account.account_id}
                    className={
                      account.trading_account_id === currentTradingAccount.trading_account_id? 
                      styles.SelectedAccountOption: styles.AccountOption}>
                    <div className={styles.htWallet}>
                    {
                      account.verified? 
                      <div className={styles.VerifiedIconContainer}>
                        <span className={styles.VerifiedIcon}></span>
                      </div> : null
                    }
                    </div>
                    { account.portfolio_name }
                  </div>
                )
              }
            </div>
            <hr className={styles.SeperatedLine}></hr>
            <div className={styles.DropdownOption}>
              <div className={styles.htWalletSetting}></div>
              Trade account setting
            </div>
            <div className={styles.DropdownOption}>
              <div className={styles.htWalletAdd}></div>
              Add trade account
            </div>
            <hr className={styles.SeperatedLine}></hr>
            <div 
                className={styles.DropdownOption}
                onClick={() => setIsNightMode(!isNightMode)}>
                <div className={styles.htMoon}></div>
                Dark mode
                <div
                  className={styles.switch}>
                  <input 
                    type="checkbox" 
                    onChange={() => console.log('toggle is night mode')}
                    checked={isNightMode} />
                  <span 
                    className={`${styles.slider} ${styles.round}`}>
                  </span>
                </div>
              </div>
              <div className={styles.DropdownOption}>
                <div className={styles.htSupport}></div>
                Help & support</div>
              <div className={styles.Logout} onClick={() => logout()}>
                <div className={styles.htLogout}></div>
                Logout</div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MobileAccountSelection;
