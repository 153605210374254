import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getAuthenticationState } from 'redux/selectors';
import { authenticationStates } from '../../utils/constant';
import Spinner from 'UI/spinner';

function ProtectedRoute(props) {
  const authenticationState = useSelector(getAuthenticationState);

  if (authenticationState === authenticationStates.AUTHENTICATED ) {
    return props.children;
  }
  else if (authenticationState === authenticationStates.AUTHENTICATION_PENDING) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spinner />
      </div>
    )
  }
  else {
    return <Navigate to="/" />
  }
}

export default ProtectedRoute;