const drawVerticalLine = (ctx, x, y2, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x, 30);
  ctx.lineTo(x, y2);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawCircleOnBar = (ctx, x, y, strokeStyle, fillStyle) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle;
  ctx.fillStyle = fillStyle;
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
};

const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1-20, y+10);
  ctx.lineTo(x2, y+10);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawOnHoverText = (ctx, hoveredDataPoint, chartData, barValueKey) => {
  let index = hoveredDataPoint.dataIndex;
  let value = chartData[index][barValueKey];
  let trades = chartData[index].trades;
  // Measure text width
  let textStringWidth = 0;
  let timeObject = hoveredDataPoint.raw.x;
  let timeOffsetInMillSecs = (timeObject.getTimezoneOffset())*60*1000;
  let timestamp = (timeObject.getTime());
  let clientTime = new Date(timestamp+timeOffsetInMillSecs);
  let time = clientTime.toLocaleString("default", { year: "numeric", month: "short" });
  if (barValueKey === "percentage") {
    let profit = chartData[index].profit;
    let textString = `Time: ${time} Return: ${value} Trades: ${trades} Profit: ${profit}`;
    textStringWidth += ctx.measureText(textString).width;
  };
  if (barValueKey === "pips" || barValueKey === "mins") {
    let benchmark = chartData[index].benchmark;
    let textString = `Time: ${time} Return: ${value} Trades: ${trades}`;
    textStringWidth += ctx.measureText(textString).width;
  };
  if (barValueKey === "trades") {
    let benchmark = chartData[index].benchmark;
    let textString = `Time: ${time} Return: ${trades}`;
    textStringWidth += ctx.measureText(textString).width;
  }

  // Get start point
  const chartArea = hoveredDataPoint.chart.chartArea;
  const centerX = (chartArea.right + chartArea.left) / 2 -20;
  let currentX = centerX - textStringWidth / 2;
  
  ctx.save();
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "#FFFFFF";
  ctx.fillText("Time: ", currentX, 25);
  currentX += ctx.measureText("Time: ").width;
  ctx.font = "700 12px sans-serif";
  ctx.fillText(time, currentX, 25);
  currentX += (ctx.measureText(time).width + 20);
  ctx.font = "12px sans-serif";
  ctx.fillText("Return: ", currentX, 25);
  currentX += ctx.measureText("Return: ").width;
  if (barValueKey === "mins" || barValueKey === "trades") {
    ctx.fillStyle = "#047F92";
  } else {
    ctx.fillStyle = hoveredDataPoint.raw.y >= 0 ? "#56A07E" : "#C15357";
  }
  ctx.font = "700 12px sans-serif";
  let valueString;
  if (barValueKey === "trades"){
    valueString = value;
    ctx.fillText(valueString, currentX, 25);
  } else if (barValueKey === "percentage") {
    valueString = value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")+"%";
    ctx.fillText(valueString, currentX, 25);
  } else {
    valueString = value.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",")+barValueKey;
    ctx.fillText(valueString, currentX, 25);
  }
  currentX += (ctx.measureText(valueString).width +20);
  if (barValueKey !== "trades"){
    ctx.font = "12px sans-serif";
    ctx.fillStyle = "#FFFFFF";
    ctx.fillText("Trades: ", currentX, 25);
    currentX += ctx.measureText("Trades: ").width;
    ctx.font = "700 12px sans-serif";
    ctx.fillText(trades, currentX, 25);
    currentX += (ctx.measureText(trades).width +20);
    if (barValueKey === "percentage") {
      ctx.font = "12px sans-serif";
      ctx.fillStyle = "#FFFFFF";  
      ctx.fillText("Profit: ", currentX, 25);
      currentX += ctx.measureText("Profit: ").width;
      ctx.font = "700 12px sans-serif";
      ctx.fillStyle = hoveredDataPoint.raw.y >= 0 ? "#56A07E" : "#C15357";
      let profit = chartData[index].profit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      ctx.fillText(profit, currentX, 25);
    } else {
      ctx.font = "700 12px sans-serif";
      ctx.fillStyle = "#FFB700";
    }
  } else {
    ctx.font = "700 12px sans-serif";
    ctx.fillStyle = "#FFB700";
  }
}

const createHoverTextPlugin = (chartData, barValueKey) => {
  const hoverPlugin = {
    id: "performance_hover_text_plugin",
    beforeDraw: (chart) => {
      // disable original tooltips
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;

      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const pointElement = tooltip.dataPoints[0].element;
        const chartArea = chart.chartArea;

        drawVerticalLine(ctx, pointElement.x, chartArea.bottom, "#06AFC9");
        drawCircleOnBar(ctx, pointElement.x, pointElement.y, "#06AFC9", "white");
        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          0,
          "#00333B"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          0.5,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          20.5,
          "#06AFC9"
        );

        drawOnHoverText(ctx, tooltip.dataPoints[0], chartData, barValueKey);
      }
    }
  }
  return hoverPlugin
}

export default createHoverTextPlugin;