const getSandboxChartsData = (chartKeys) => {
  return (state) => {
    let chartsData = {};

    chartKeys.forEach(chartKey => {
      chartsData[chartKey] = state.sandboxChart[chartKey]
    });
    return chartsData;
  }
}

export default getSandboxChartsData;