const probabilityGainAfterGain = {
  chartTitle: "Probability gain after gain",
  barValueKey: "percentageGain",
  benchmark: 57.4,
  chartKey: "probability_gain_after_gain",
  999: [
    {
      time: new Date("2022-04"),
      percentageGain: 52.0,
      trades: 57,
    },
    {
      time: new Date("2022-05"),
      percentageGain: 49.9,
      trades: 92,
    },
    {
      time: new Date("2022-06"),
      percentageGain: 63.7,
      trades: 81,
    },
    {
      time: new Date("2022-07"),
      percentageGain: 57.6,
      trades: 52,
    },
    {
      time: new Date("2022-08"),
      percentageGain: 57.9,
      trades: 29,
    },
    {
      time: new Date("2022-09"),
      percentageGain: 62.0,
      trades: 59,
    },
    {
      time: new Date("2022-10"),
      percentageGain: 53.8,
      trades: 71,
    },
    {
      time: new Date("2022-11"),
      percentageGain: 58.6,
      trades: 50,
    },
    {
      time: new Date("2022-12"),
      percentageGain: 56.9,
      trades: 54,
    },
    {
      time: new Date("2023-01"),
      percentageGain: 60.7,
      trades: 55,
    },
    {
      time: new Date("2023-02"),
      percentageGain: 55.9,
      trades: 70,
    },
    {
      time: new Date("2023-03"),
      percentageGain: 54.5,
      trades: 98,
    },
    {
      time: new Date("2023-04"),
      percentageGain: 62.7,
      trades: 140,
    }
  ]
};


export default probabilityGainAfterGain;