const drawVerticalLine = (ctx, x, y2, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x, 33);
  ctx.lineTo(x, y2);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1, y+12);
  ctx.lineTo(x2+10, y+12);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};


const drawOnHoverText = (ctx, hoveredDataPoint, chartData) => {
  let index = hoveredDataPoint.dataIndex;
  let riskRatio = chartData[999].riskReturnRatio[index].num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let hitRatio = chartData[999].hitRatio[index].num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Measure text width
  let textStringWidth = 0;
  let timeObject = hoveredDataPoint.raw.x;
  let timeOffsetInMillSecs = (timeObject.getTimezoneOffset())*60*1000;
  let timestamp = (timeObject.getTime());
  let clientTime = new Date(timestamp+timeOffsetInMillSecs);
  let time = clientTime.toLocaleString("default", { year: "numeric", month: "short" });
  let textString = `Risk return ratio: ${riskRatio} Hit ratio: ${hitRatio}`;
  textStringWidth += ctx.measureText(textString).width;

  // Get start point
  const chartArea = hoveredDataPoint.chart.chartArea;
  const centerX = (chartArea.right + chartArea.left) / 2 -20;
  let currentX = centerX - textStringWidth / 2;

  ctx.save();
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "#FFFFFF";
  ctx.fillText("Return risk ratio: ", currentX, 25);
  currentX += ctx.measureText("Risk return ratio: ").width;
  ctx.fillStyle = "#00B5F1";
  ctx.font = "700 12px sans-serif";
  ctx.fillText(riskRatio, currentX, 25);
  currentX += ctx.measureText(riskRatio).width+10;
  ctx.font = "12px sans-serif";
  ctx.fillStyle = "#FFFFFF";
  ctx.fillText("Hit ratio", currentX, 25);
  currentX += ctx.measureText("Hit ratio: ").width;
  ctx.font = "700 12px sans-serif";
  ctx.fillStyle = "#00B5F1";
  ctx.fillText(`${hitRatio}%`, currentX, 25);
  currentX += ctx.measureText(hitRatio).width+10;

  //draw time string
  ctx.font = "10px sans-serif";
  ctx.textAlign = "center";
  ctx.fillStyle = "#06AFC9";
  ctx.fillText(time, (chartArea.left+chartArea.right)/2, 8)

  ctx.restore();
};


const createHoverTextPlugin = (chartData) => {
  const hoverPlugin = {
    id: "risk_return_hit_ratio_hover_text_plugin",
    beforeDraw: (chart) => {
      // disable original tooltips
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;

      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const pointElement = tooltip.dataPoints[0].element;
        const chartArea = chart.chartArea;
        const pointDatasetIndex = tooltip.dataPoints[0].datasetIndex;
        let middleXPositionOfTwoBars;
        if (pointDatasetIndex === 0) {
          middleXPositionOfTwoBars = pointElement.x+6.5;
        }
        if (pointDatasetIndex === 1) {
          middleXPositionOfTwoBars = pointElement.x-6.5;
        }

        drawVerticalLine(ctx, middleXPositionOfTwoBars, chartArea.bottom, "white");
        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          0,
          "white"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          0.5,
          "white"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          20.5,
          "white"
        );

        drawOnHoverText(ctx, tooltip.dataPoints[0], chartData);
      }
    }

  }

  return hoverPlugin
}

export default createHoverTextPlugin;