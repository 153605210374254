const drawdownOptions =  {
  animation: {
    duration: 0,
  },
  interaction: {
    mode: "x",
    intersect: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    autocolors: false,
  },
  layout: {
    padding: {
      bottom: 10,
      left: 30,
      top: 50,
      right: 20
    },
  },
  borderRadius: 5,
  maxBarThickness: 15,
  borderWidth: 1,
  scales: {
    y: {
      grace: '5%',
      grid: {
        color: (context) => {
          return '#4D4D4D'
        },
        borderDash: (a) => {
          return [1, 2];
        },
        // borderColor: '#4D4D4D'
        drawBorder: false,
        tickLength: -20,
        tickBorderDash: (a) => {
          return [1, 2];
        }
      },
      ticks: {
        color: "#999999",
        font: {
          size: 10,
          family: 'Noto Sans',
          weight: 700
        },
        labelOffset: -7,
        padding: 0,
        mirror: true,
        maxTicksLimit: 5,
        callback: function (value, index, ticks) {
          if (index === 0) {
            return [value?.toFixed(2), "%"];
          } else {
            return value?.toFixed(2)
          }
        }
      },
    },
    x: {
      position: "top",
      grid: {
        offset: false,
        color: '#4D4D4D',
        borderDash: [1, 2],
        borderColor: '#4D4D4D',
        borderWidth: 1,
        tickColor: "#4D4D4D",
        tickLength: 4,
      },
      ticks: {
        padding: 0,
        color: "#999999",
        font: {
          size: 10
        },
      },
    }
  },
};

export default drawdownOptions;