import styles from "./alertCard.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modalSlice';
//constants
import * as modalTypes from 'constants/modalTypes';
import { alertTypeReasonTextMap } from 'utils/constant/index';
import chartKeyWithNameMap from "utils/constant/chartKeyNameMap";
import generateTimeFormatForAlertFromUTC from "utils/helpers/generateTimeFormatForAlertFromUTC";

function AlertCard (props) {
  const { cardData, index } = props;
  const dispatch = useDispatch();

  let backgroundColor;
  if (index === 1) {
    backgroundColor = "#026C7C"
  } else {
    backgroundColor = "#047F92"
  }

  const alertOnClickHandler = () => {
    return dispatch(openModal({
      displayedModal: modalTypes.CHART_INFO_MODAL,
      displayedModalDataKey: cardData.chartKey
    }));
  }

  let reasonIcons = {
    0: htFonts.htNegChart,
    2: htFonts.htSpeedometer,
    1: htFonts.htAlertChart,
  };

  return (
    // <div className={styles.CardContainer} style={{backgroundColor: backgroundColor}} id={styles[`card${index}`]}>

    // for demo
    <div className={!cardData.read ? `${styles.CardContainer} ${styles.newAlert}` : styles.CardContainer} style={{backgroundColor: backgroundColor}} id={styles[`card${index}`]}>
      <div className={styles.CardHeader}>
        <div className={styles.IconContainer}>
          <span className={`${reasonIcons[cardData.alertType]}`}></span>
        </div>
        <div className={styles.Titles}>
          <strong>{alertTypeReasonTextMap[cardData.alertType]}</strong>
          <div style={{color: "#cccccc", fontSize: "11px"}}>{generateTimeFormatForAlertFromUTC(cardData.time)} Order ID:{cardData.orderID}</div>
        </div>
      </div>
      <div className={styles.CardBody}>
        <img src="https://peter-pan-tse.github.io/hoctrade.github.io/chart-th-001.png" alt="chart"></img>
        <div className={styles.BodyContent}>
          <strong>{chartKeyWithNameMap[cardData.chartKey]}</strong>
          <div>{cardData.msg}</div>
          <div className={styles.VideoBtn} onClick={() => alertOnClickHandler()}>
            <span className={htFonts.htVideo}></span>
            Watch video
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertCard;