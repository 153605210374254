import options from "./drawdown.options";

function createOptionsWithAnnotations(chartData) {
  const annotations = {};
  for (let i = 0; i < chartData.length; i++) {
    if (chartData[i].benchmark) {
      annotations[`line-${i}`] = {
        type: "line",
        yMin: chartData[i].benchmark,
        yMax: chartData[i].benchmark,
        xMin: (ctx) => {
          const chart = ctx.chart;
          const barElements = chart._metasets[0].data;
          if (barElements.length < 2) return;
          const barElement = barElements[i];
          const gridSpace = barElements[1].x - barElements[0].x;
          if (barElement.x && barElement.width) {
            const perc = barElements[i].width / gridSpace;
            return i - perc / 2;
          }
        },
        xMax: (ctx) => {
          const chart = ctx.chart;
          const barElements = chart._metasets[0].data;
          if (barElements.length < 2) return;
          const barElement = barElements[i];
          const gridSpace = barElements[1].x - barElements[0].x;
          if (barElement.x && barElement.width) {
            const perc = barElements[i].width / gridSpace;
            return i + perc / 2;
          }
        },
        borderColor: "#FFB700",
        borderWidth: 1
      };
      annotations[`point-${i}`] = {
        type: "point",
        xValue: i,
        yValue: chartData[i].benchmark,
        backgroundColor: "#FFCD00",
        borderColor: "#FFB700",
        radius: 2
      };
    }
  }

  options.plugins.annotation = {
    annotations
  }
  return options;
}

export default createOptionsWithAnnotations;