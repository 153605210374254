import { useState } from "react";
import { Link } from 'react-router-dom';
import MUITooltip from "@mui/material/Tooltip";

const ProgressBar = (props) => {
  const { data } = props;
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#001216",
    borderRadius: 5,
    marginBottom: 5,
    position: 'relative',
  };

  const fillerStyles = {
    width: `${data.percentage}%`,
    height: 20,
    backgroundColor: isHover ? data.hoverColor : data.backgroundColor,
    borderRadius: "inherit",
    textAlign: "left",
    transition: "all 0.3s ease",
    key: data.key,
  };

  const labelStyles = {
    fontSize: 12,
    position: "absolute",
    marginTop: -20,
    color: isHover ? "#FFFFFF" : data.fontColor,
    cursor: "pointer",
    transition: "all 0.3s ease",
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textAlign: 'left',
    paddingLeft: 5,
    boxSizing: 'border-box'
  };

  let linkPath;
  
  switch (data.key) {
    case "Break since last trade":
      linkPath = "/sandbox/dashboard/analysis/behavioral";
      break;
    case "Profit by Trade Length":
      linkPath = "/sandbox/dashboard/analysis/general";
      break;
    case "Trades per Day":
      linkPath = "/sandbox/dashboard/analysis/behavioral";
      break;
    case "Profit by weekday":
      linkPath = "/sandbox/dashboard/analysis/general";
      break;
    case "Profit after daily profit/ loss":
      linkPath = "/sandbox/dashboard/analysis/behavioral";
      break;
    case "Break after loss trade":
      linkPath = "/sandbox/dashboard/analysis/behavioral";
      break;
    case "Open positions in profit/ loss":
      linkPath = "/sandbox/dashboard/analysis/behavioral";
      break;
    default:
      linkPath = "/sandbox/dashboard/overview/account-overview";
  }

  return (
    <MUITooltip 
      title={`${data.key}: ${data.interval} (${data.pips}pips)`}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: 12,
            fontFamily: 'Noto Sans',
            backgroundColor: '#047F92',
            '& .MuiTooltip-arrow': {
              color: '#047F92'
            },
          },
        }
      }}
      >
      <Link to={`${linkPath}`}>
        <div
        style={containerStyles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
          <div style={fillerStyles}></div>
          <div style={labelStyles}>{data.key}</div>
      </div>
      </Link>
    </MUITooltip>
  );
};

export default ProgressBar;