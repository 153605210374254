import YouTube from "react-youtube";
import legendMap from "devData/legendMap";

import styles from "./chartInfoModal.module.scss";
import htFonts from "shared/htFont.module.scss";

function chartInfoModal(props) {
  const { close, modalContent } = props;

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const chartDescription = modalContent.chartDescription.includes('\n') ?
    modalContent.chartDescription.split('\n').map((paragraph) => {
      return <p>{paragraph}</p>
    }) :
    modalContent.chartDescription;

  return (
    <div className={styles.chartInfoModal}>
      <div className={styles.ChartInfoModalHeader}>
        <div className={styles.IconButton}>
          <div
            className={`${htFonts.htStar} ${styles.Icon} ${styles.D9D0BF}`}
          ></div>
        </div>
        <div className={styles.IconButton}>
          <div
            className={`${htFonts.htBell} ${styles.Icon} ${styles.D9D0BF}`}
          ></div>
        </div>
        <div className={styles.IconButton}>
          <div
            className={`${htFonts.htGlobe} ${styles.Icon} ${styles.C1272D}`}
          ></div>
        </div>
        <div className={styles.CrossIconButton}>
          <div
            className={`${htFonts.htCross} ${styles.CancelIcon}`}
            onClick={() => close()}
          ></div>
        </div>
      </div>
      <div className={styles.ChartInfoModalBody}>
        <div>
          {modalContent.legends.length !== 0 ? (
            <>
              <div className={styles.SectionTitle}>Chart legend</div>
              <div className={styles.LegendsContainer}>
                {modalContent.legends.map((legend) => {
                  return (
                    <div className={styles.Legend} key={legend}>
                      <img
                        className={styles.LegendImage}
                        src={legendMap[legend].imgSrc}
                        alt={legendMap[legend].label}
                      />
                      <div>{legendMap[legend].label}</div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
        <div>
          <div className={styles.SectionTitle}>{modalContent.chartTitle}</div>
          <div>{chartDescription}</div>
        </div>
        {modalContent.youtubeVideoId ? (
          <div>
            <div className={styles.SectionTitle}>Watch tutorial</div>
            <div className={styles.PlayerWrapper}>
              <YouTube
                videoId={modalContent.youtubeVideoId}
                opts={opts}
                className={styles.YoutubePlayer}
              />
            </div>
          </div>
        ) : null}
        {modalContent.youtubeVideoId ? (
          <div>
            <div className={styles.VideoButton}>
              <div className={`${htFonts.htVideo} ${styles.VideoIcon}`}></div>
              <a href="https://www.youtube.com/channel/UCbdMrO1l_fK06fThtLVtyJg" className={styles.VideoButtonText} target="_blank" rel="noreferrer nooopener">See all videos</a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default chartInfoModal;
