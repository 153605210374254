import { useLocation } from "react-router-dom";
//styling
import styles from "./breadcrumb.module.scss";

function Breadcrumb({isMobileSidebarOpen, setIsMobileSidebarOpen}) {
  const pathname = useLocation().pathname;
  const params = pathname.split("/");
  params.shift();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const breadcrumbText = params.map((param, index) => {
    if(index === 0) return <span key={param + index}>{capitalizeFirstLetter(param)}</span>
    if (index === params.length - 1) return <span key={param + index}> / <span className={styles.CurrentPage}>{capitalizeFirstLetter(param)}</span></span>
    return (
      <span key={param + index}> / {capitalizeFirstLetter(param)}</span>
    )
  })

  const onClickHamburgerMenu = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  return (
    <div className={styles.Breadcrumb}>
      <div className={styles.HamburgerMenu} onClick={() => onClickHamburgerMenu()}>
        <div className={styles.BarOne}></div>
        <div className={styles.BarTwo}></div>
        <div className={styles.BarThree}></div>
      </div>
      {breadcrumbText}
    </div>
  )
}

export default Breadcrumb;