function createHoverBarBorderColor(chartData, currentTimeFrame) {
  const intervals = chartData.interval;
  const sortedData = intervals
    .map((interval) => {
      return chartData[currentTimeFrame].data[interval];
    })
    .sort((a, b) => a.percentage - b.percentage);
  const [lowestData, secondLowestData] = [sortedData[0], sortedData[1]];

  const hoverBarColor = intervals.map((interval) => {
    let color;
    const intervalData = chartData[currentTimeFrame].data[interval];
    if (intervalData.percentage === lowestData.percentage) {
      color = "#009245";
    } else if (intervalData.percentage === secondLowestData.percentage) {
      color = "#009245";
    } else {
      color = "#BE1E2D";
    }

    const tradeCount = intervalData.trades;
    if (tradeCount) {
      if (tradeCount < 5) {
        return color = "#999999";
      } else if (tradeCount > 20) {
        return color;
      } else {
        return color;
      }
    } else {
      return color;
    }
    
  });
  return hoverBarColor;
}

export default createHoverBarBorderColor;