function generateChartsDataFormatForResponsiveTradeMedic(chartsData) {
  let convertedData = {};
  for (const chartKey in chartsData) {
    if (chartKey === "percentage_of_position_with_sl") {
      const intervals = ["1Y", "6M", "3M", "1M"];
      const percentageAvg = chartsData[chartKey][999][4].percentage;
      convertedData[chartKey] = {
        999: {
          data: {},
        },
        percentageAvg: percentageAvg,
      };

      for (let i = 0; i < intervals.length; i++) {
        console.log(chartsData[chartKey][999][i]);
        convertedData[chartKey][999]["data"][intervals[i]] = {
          percentage: chartsData[chartKey][999][i].percentage,
          interval: chartsData[chartKey][999][i].interval,
          percentageAvg: percentageAvg,
        };
      }
    } else if (
      chartKey === "max_risk_all_open_trades_last_trades" ||
      chartKey === "risk_per_trade_last_trades"
    ) {
      let intervals = [];
      Object.entries(chartsData[chartKey][999]).forEach(([key, value]) => {
        if (!intervals.includes(value.interval)) {
          intervals.push(value.interval);
        }
      });
      convertedData[chartKey] = {
        999: {
          data: {},
        },
        interval: intervals,
      };

      for (let i = 0; i < intervals.length; i++) {
        convertedData[chartKey][999]["data"][intervals[i]] = {
          percentage: chartsData[chartKey][999][i].percentage,
          interval: chartsData[chartKey][999][i].interval,
        };
      }
    } else {
      let intervals = [];
      Object.entries(chartsData[chartKey][999]).forEach(([key, value]) => {
        if (!intervals.includes(value.interval)) {
          intervals.push(value.interval);
        }
      });
      convertedData[chartKey] = {
        1: {
          data: {},
        },
        3: {
          data: {},
        },
        12: {
          data: {},
        },
        999: {
          data: {},
        },
        interval: intervals,
      };

      for (let i = 0; i < intervals.length; i++) {
        if (chartKey === "risk_after_losing_trades_per_trade") {
          convertedData[chartKey][1]["data"][intervals[i]] = {
            pips: chartsData[chartKey][1][i].pip,
            percentage: chartsData[chartKey][1][i].percentage,
            trades: chartsData[chartKey][1][i].number,
            profit: chartsData[chartKey][1][i].profit,
            interval: chartsData[chartKey][1][i].interval,
          };
          convertedData[chartKey][3]["data"][intervals[i]] = {
            pips: chartsData[chartKey][3][i].pip,
            percentage: chartsData[chartKey][3][i].percentage,
            trades: chartsData[chartKey][3][i].number,
            profit: chartsData[chartKey][3][i].profit,
            interval: chartsData[chartKey][3][i].interval,
          };
          convertedData[chartKey][12]["data"][intervals[i]] = {
            pips: chartsData[chartKey][12][i].pip,
            percentage: chartsData[chartKey][12][i].percentage,
            trades: chartsData[chartKey][12][i].number,
            profit: chartsData[chartKey][12][i].profit,
            interval: chartsData[chartKey][12][i].interval,
          };
          convertedData[chartKey][999]["data"][intervals[i]] = {
            pips: chartsData[chartKey][999][i].pip,
            percentage: chartsData[chartKey][999][i].percentage,
            trades: chartsData[chartKey][999][i].number,
            profit: chartsData[chartKey][999][i].profit,
            interval: chartsData[chartKey][999][i].interval,
          };
        } else {
          convertedData[chartKey][1]["data"][intervals[i]] = {
            pips: chartsData[chartKey][1][i].pip,
            percentage: chartsData[chartKey][1][i].rate,
            trades: chartsData[chartKey][1][i].number,
            profit: chartsData[chartKey][1][i].profit,
            interval: chartsData[chartKey][1][i].interval,
          };
          convertedData[chartKey][3]["data"][intervals[i]] = {
            pips: chartsData[chartKey][3][i].pip,
            percentage: chartsData[chartKey][3][i].rate,
            trades: chartsData[chartKey][3][i].number,
            profit: chartsData[chartKey][3][i].profit,
            interval: chartsData[chartKey][3][i].interval,
          };
          convertedData[chartKey][12]["data"][intervals[i]] = {
            pips: chartsData[chartKey][12][i].pip,
            percentage: chartsData[chartKey][12][i].rate,
            trades: chartsData[chartKey][12][i].number,
            profit: chartsData[chartKey][12][i].profit,
            interval: chartsData[chartKey][12][i].interval,
          };
          convertedData[chartKey][999]["data"][intervals[i]] = {
            pips: chartsData[chartKey][999][i].pip,
            percentage: chartsData[chartKey][999][i].rate,
            trades: chartsData[chartKey][999][i].number,
            profit: chartsData[chartKey][999][i].profit,
            interval: chartsData[chartKey][999][i].interval,
          };
        }
      }
    }
  }

  return convertedData;
}

export default generateChartsDataFormatForResponsiveTradeMedic;
