import { useState, useEffect } from "react";
import styles from "./alert.module.scss";
import htFonts from "shared/htFont.module.scss";
// import data from "devData/alerts";
import PaginationButtons from "components/tradeHistory/paginationButtons";
import useTable from "utils/useTable";
import FilterButtons from "components/alertPage/filterButtons";
import alertsAreaMap from "devData/alertsAreaMap";
import TableRow from "components/alertPage/tableRow";
// utils
import { getAlertData } from "utils/requests";
import { setAlertsData } from "redux/slices/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllAlertsData, getCurrentTradingAccount } from "redux/selectors";

function Alert() {
  const dispatch = useDispatch();
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);
  // const [alertData, setAlertData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { slice, range } = useTable(filteredData, page, rowsPerPage);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const [isAlertDataChanged, setIsAlertDataChanged] = useState(false);
  let alertsData = useSelector(getAllAlertsData);

  useEffect(() => {
    if (!alertsData.length) {
      (async function () {
        const result = await getAlertData(currentTradingAccount.trading_account_id);
        if (result.data) {
          dispatch(
            setAlertsData({alertsData: result.data})
          );
          setFilteredData(result.data.toReversed());
          setIsAlertDataChanged(false);
        } else {
          console.log("fetching data error");
        }
      })();
    }
  }, [dispatch, currentTradingAccount.trading_account_id, isAlertDataChanged]);

  useEffect(() => {
    function getFilteredData() {
      let tempData = [];
      // only has reason filter
      if (selectedReasons.length !== 0 && selectedAreas.length === 0) {
        alertsData.forEach((obj) => {
          if (selectedReasons.includes(obj.alertType)) {
            tempData.push(obj);
          }
        });
        setFilteredData(tempData.toReversed());
      }
      // only has area filter
      if (selectedAreas.length !== 0 && selectedReasons.length === 0) {
        alertsData.forEach((obj) => {
          if (selectedAreas.includes(alertsAreaMap[obj.chartKey])) {
            tempData.push(obj);
          }
        });

        setFilteredData(tempData.toReversed());
      }
      // has both reason and area fitler
      if (selectedAreas.length !== 0 && selectedReasons.length !== 0) {
        alertsData.forEach((obj) => {
          if (
            selectedAreas.includes(alertsAreaMap[obj.chartKey]) &&
            selectedReasons.includes(obj.alertType)
          ) {
            tempData.push(obj);
          }
        });
        setFilteredData(tempData.toReversed());
      }
      // no any filter
      if (selectedAreas.length === 0 && selectedReasons.length === 0) {
        setFilteredData(alertsData.toReversed());
      }
    }
    getFilteredData();
  }, [selectedAreas, selectedReasons, alertsData]);

  let numberOfUnreadAlert = 0;
  filteredData.forEach((item) => {
    if (!item.read) {
      numberOfUnreadAlert += 1;
    }
  });

  return (
    <>
      {isAlertDataChanged ? null : <div className={styles.alertContainer}>
      <div className={styles.alertPageHeader}>
        <FilterButtons
          selectedReasons={selectedReasons}
          setSelectedReasons={setSelectedReasons}
          selectedAreas={selectedAreas}
          setSelectedAreas={setSelectedAreas}
        />
        <div className={styles.headerRight}>
          <strong>{numberOfUnreadAlert} new alerts</strong> / Total:{" "}
          {filteredData.length}
        </div>
      </div>
      <table className={styles.alertTable}>
        <thead>
          <tr className={styles.titleRow}>
            <th>Time</th>
            <th>Analysis</th>
            <th>Category</th>
            <th>Alert Type</th>
            <th>Order</th>
            <th>
              <a
                id={styles.videosBtn}
                href="https://www.youtube.com/@hoc-trade/videos"
                target="_blank"
                rel="noreferrer"
              >
                <span className={htFonts.htVideo}></span>
                All videos
              </a>
            </th>
          </tr>
        </thead>
        <tbody className={styles.rowsContainer}>
          {slice.map((data, index) => {
            return <TableRow data={data} index={index} key={data.alertId} setIsAlertDataChanged={setIsAlertDataChanged}/>;
          })}
        </tbody>
      </table>
      <div className={styles.bottomContainer}>
        <PaginationButtons
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
      </div>
    </div>}
    </>
    
  );
}

export default Alert;
