import { resendSandboxKey } from "utils/requests";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./sandboxLogin.module.scss";
import htFonts from "shared/htFont.module.scss";
import logo from "../../assets/logo-w-text.svg";
import Spinner from "UI/spinner";

function ResendSandboxKey() {
  const [email, setEmail] = useState({
    email: "",
  });
  const [resendKeyErrorMessage, setResendKeyErrorMessage] = useState(null);
  const [resendKeySuccessMessage, setResendKeySuccessMessage] = useState(null);
  const [isResendFormPending, setIsResendFormPending] = useState(false);

  const onChangeEmailHandler = (field, newValue) => {
    const copiedEmail = { ...email };
    copiedEmail[field] = newValue;
    setEmail(copiedEmail);
  };

  const submitResendButton = email.email ? (
    <div
      className={styles.SubmitButton}
      onClick={(e) => submitResendKeyForm(e)}
    >
      Send
      <span className={`${htFonts.htLoginLogout} ${styles.LoginIcon}`}></span>
    </div>
  ) : (
    <div className={`${styles.SubmitButton} ${styles.DisabledButton}`}>
      Send
      <span className={`${htFonts.htLoginLogout} ${styles.LoginIcon}`}></span>
    </div>
  );

  const submitResendKeyForm = async (e) => {
    e.preventDefault();
    setIsResendFormPending(true);
    const result = await resendSandboxKey(email);
    if (result.message === "success") {
      setResendKeySuccessMessage(
        "Successfully resend sandboxy key. Please login with the key we sent to your registered email through the following link"
      );
      setResendKeyErrorMessage("");
      setIsResendFormPending(false);
    } else {
      setResendKeyErrorMessage(result.message);
      setResendKeySuccessMessage("");
      setIsResendFormPending(false);
    }
  };

  return (
    <div className={styles.SandboxContainer}>
      <div className={styles.SandboxHeader}>
        <img className={styles.Logo} src={logo} alt="logo" />
        <span className={styles.SandboxLabel}>SANDBOX</span>
      </div>
      <form className={styles.ResendKeyBox}>
        {resendKeySuccessMessage ? (
            <div style={{ color: "green", fontSize: "14px" }}>
              {resendKeySuccessMessage}
              <br />
              <br />
              <Link to="/sandbox/login">Login</Link>
            </div>
        ) : (
          <>
            <div className={styles.BoxTitle}>
              Welcome to hoc-trade sandbox. Please enter your registered email to
              receive sandbox key
            </div>
            {isResendFormPending ? (
              <div style={{ margin: "0 auto" }}>
                <Spinner />
              </div>
            ) : (
              <div className={styles.InputWrapper}>
                <div className={styles.InputLabel}>Email</div>
                <input
                  type="text"
                  value={email.email}
                  className={styles.InputBox}
                  onChange={(e) =>
                    onChangeEmailHandler("email", e.target.value)
                  }
                />
                {resendKeyErrorMessage ? (
                  <div className={styles.ErrorMessage}>
                    {resendKeyErrorMessage}
                  </div>
                ) : null}
                {submitResendButton}
                <div style={{ margin: "10px auto", width: "max-content" }}>
                  <Link to="/sandbox/login">Login</Link>
                </div>
              </div>
            )}
          </>
        )}
      </form>
    </div>
  );
}

export default ResendSandboxKey;
