import { createSlice } from '@reduxjs/toolkit';
import { chartKeys } from 'utils/constant';

const chartKeyValues = Object.values(chartKeys);
const initialChartState = {
  lastChartUpdatedAt: Date.now()
};
chartKeyValues.forEach(chartKey => initialChartState[chartKey] = null);

export const sandboxChartSlice = createSlice({
  name: "sandboxChart",
  initialState: initialChartState,
  reducers: {
    setSandboxChartsData: (state, action) => {
      return {
        ...state,
        ...action.payload.chartsData,
      }
    }
  }
});

export const {
  setSandboxChartsData
} = sandboxChartSlice.actions;

export const sandboxChartReducer = sandboxChartSlice.reducer;