import styles from "./subPageLayout.module.scss";
import { useState, useEffect, useMemo } from "react";
// components
import TopFivePatterns from "components/topFivePatterns";
import BarChart from "components/charts/crypto/bar.chart";
// utils
import { fetchCryptoChartsData } from "utils/requests";
import { getChartsData, getCurrentTradingAccount } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { chartKeys } from "utils/constant";
import { setChartsData } from "redux/slices/chartSlices";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import { createDataObjectForCryptoChart } from "utils/helpers/chart";

const ChartMode = {
  USD: "usd",
  PERCENTAGE: "percentage",
};

function General() {
  const [chartMode, setChartMode] = useState(ChartMode.USD);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartsDataError, setChartsDataError] = useState(false);
  const dispatch = useDispatch();
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const chartKeysThisPage = useMemo(
    () => [
      chartKeys.PROFIT_BY_DAYTIME_PER_TRADE,
      chartKeys.LONG_AND_SHORT_PER_TRADE,
      chartKeys.PROFIT_BY_SYMBOL_PER_TRADE,
      chartKeys.PROFIT_BY_TRADE_LENGTH_PER_TRADE,
      chartKeys.PROFIT_BY_WEEKDAY_PER_TRADE,
    ],
    []
  );
  
  useEffect(() => {
    (async function () {
      const result = await fetchCryptoChartsData(currentTradingAccount.trading_account_id, chartKeysThisPage);
      if (result.data) {
        dispatch(
          setChartsData({
            chartsData: result.data,
          })
        );
        setCurrentTimeFrame(1);
      } else {
        setChartsDataError(true);
      }
    })();
  }, [dispatch, chartKeysThisPage, currentTradingAccount]);

  const chartsData = useSelector(getChartsData(chartKeysThisPage));

  if (!createIsChartsDataAvailable(chartsData, chartKeysThisPage) && !chartsDataError) {
    return <div className={styles.Subpage}>Loading Data</div>;
  }

  if (chartsDataError) {
    return (
      <div style={{ margin: "40px auto", fontWeight: 'bold', color: "#4D4D4D" }}>
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  let chartDataObjects = {};
  chartKeysThisPage.forEach(chartKey => {
    chartDataObjects[chartKey] = createDataObjectForCryptoChart(chartKey, chartsData[chartKey]);
  });

  return (
    <div className={styles.Subpage}>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns />
      </div>
      <BarChart 
        key="profit-by-daytime-per-trade"
        chartTitle="Profit by daytime (per trade)"
        chartData={chartDataObjects[chartKeys.PROFIT_BY_DAYTIME_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects[chartKeys.PROFIT_BY_DAYTIME_PER_TRADE].hoverTextArray}
      />
      <BarChart
        key="Long & short (per trade)"
        chartTitle="Long & Short (per trade)"
        chartData={chartDataObjects[chartKeys.LONG_AND_SHORT_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.LONG_AND_SHORT_PER_TRADE].hoverTextArray
        }
      />
      <BarChart
        key="Profit By Symbol (Per trade)"
        chartTitle="Profit By Symbol (Per trade)"
        chartData={chartDataObjects[chartKeys.PROFIT_BY_SYMBOL_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.PROFIT_BY_SYMBOL_PER_TRADE].hoverTextArray
        }
      />
      <BarChart
        key="Profit By Trade Length (Per trade)"
        chartTitle="Profit By Trade Length (Per trade)"
        chartData={chartDataObjects[chartKeys.PROFIT_BY_TRADE_LENGTH_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.PROFIT_BY_TRADE_LENGTH_PER_TRADE].hoverTextArray
        }
      />
      <BarChart
        key="Profit by weekday (per trade)"
        chartTitle="Profit by weekday (per trade)"
        chartData={chartDataObjects[chartKeys.PROFIT_BY_WEEKDAY_PER_TRADE]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects[chartKeys.PROFIT_BY_WEEKDAY_PER_TRADE].hoverTextArray
        }
      />
    </div>
  );
}

export default General;
