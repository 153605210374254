import { useState } from "react";
import BarChart from "components/charts/bar.chart";
import BarChartWithEmptyTicks from "components/charts/barWithEmptyTicks.chart";
import TopFivePatterns from "components/topFivePatterns";
import styles from "../../../dashboard/analysis/general.module.scss";
// utils
import { createDataObjectForChart } from "utils/helpers/chart";
import { ChartMode } from "utils/constant/index";
import { getSandboxChartsData } from "redux/selectors";
import { useSelector } from "react-redux";

function SandboxStrategy({ chartKeysOfThePage, currentTradingAccount }) {
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const chartsData = useSelector(getSandboxChartsData(chartKeysOfThePage));
  let chartDataObjects = {};
  chartKeysOfThePage.forEach((chartKey) => {
    chartDataObjects[chartKey] = createDataObjectForChart(
      chartKey,
      chartsData[chartKey]
    );
  });

  return (
    <>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns chartData={chartDataObjects["top_5_patterns_strategic"]}/>
      </div> 
      <BarChartWithEmptyTicks 
        key="Profit high vs. low volatility"
        chartTitle="Profit high vs. low volatility"
        chartData={chartDataObjects["profit_high_vs_low_volatility"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_high_vs_low_volatility'].hoverTextArray} 
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart 
        key="Profit with(+) & against(-) short-term(ST) & medium-term(MT) trend"
        chartTitle="Profit with(+) & against(-) short-term(ST) & medium-term(MT) trend"
        chartData={chartDataObjects["profit_with_against_st_mt_trend"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_with_against_st_mt_trend'].hoverTextArray} 
        currentTradingAccount={currentTradingAccount}
      />
      <BarChartWithEmptyTicks 
        key="Profit when SL adjusted vs. profit when not"
        chartTitle="Profit when SL adjusted vs. profit when not"
        chartData={chartDataObjects["profit_when_sl_adjusted_vs_profit_when_not"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_when_sl_adjusted_vs_profit_when_not'].hoverTextArray} currentTradingAccount={currentTradingAccount}
      />
      <BarChartWithEmptyTicks 
        key="Profit if publishing trades"
        chartTitle="Profit if publishing trades"
        chartData={chartDataObjects["profit_if_publishing_trades"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_publishing_trades'].hoverTextArray} currentTradingAccount={currentTradingAccount}
      />
      <BarChartWithEmptyTicks 
        key="Profit after withdrawl(in pips)"
        chartTitle="Profit after withdrawl(in pips)"
        chartData={chartDataObjects["profit_after_withdrawal_in_pips"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={ChartMode.PIPS}
        chartMode={ChartMode.PIPS}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects['profit_after_withdrawal_in_pips'].hoverTextArray} currentTradingAccount={currentTradingAccount}
      />
      <BarChartWithEmptyTicks 
        key="Average payoff win vs. Loss trade"
        chartTitle="Average payoff win vs. Loss trade"
        chartData={chartDataObjects["average_payoff_win_vs_loss_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['average_payoff_win_vs_loss_trade'].hoverTextArray} currentTradingAccount={currentTradingAccount}
      />
      <BarChartWithEmptyTicks 
        key="Profit for trade during overbought/oversold"
        chartTitle="Profit for trade during overbought/oversold"
        chartData={chartDataObjects["profit_for_trade_during_overbought_oversold"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_for_trade_during_overbought_oversold'].hoverTextArray} currentTradingAccount={currentTradingAccount}
      />
    </>
  )
}

export default SandboxStrategy;