import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import styles from "./barChart.module.scss";
import ChartHeader from "./chartHeader";
import createBaseLineChartOptions from "./line.options";

// helpers
import createLineChartColor from "./helpers/createLineChartColor";

//plugins
import createBackgroundPlugin from "./plugins/createBackground.plugin";
import createOnHoverPlugin from "./plugins/hoverPositionWithSL.plugin";
// import createOnHoverPlugin from "./plugins/hover.plugin";
import xAxisButtonPlugin from "./plugins/xAxisTicks.plugin";
import createPosWithSLAreaPlugin from "./plugins/posWithSLArea.plugin";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

function LineChart(props) {
  const { chartTitle, chartData, hoverTextArray, chartMode } = props;
  
  if (chartData.noData) {
    return (
      <div className={styles.Chart}>
        <ChartHeader 
          chartTitle={chartTitle} 
          chartKey={chartData.chartKey} />
        <div className={styles.ChartButtonsContainer}>

        </div>
        <div className={styles.ChartContainer} style={{position: 'relative'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: 20,}}>
            Not sufficient data available yet
          </div>
        </div>
      </div>
    )
  }

  const hoverPlugin = createOnHoverPlugin(hoverTextArray[chartMode]);
  const backgroundPlugin = createBackgroundPlugin(true);
  const posWithSLAreaPlugin = createPosWithSLAreaPlugin(chartData);
  const lineChartColor = createLineChartColor(chartData);

  function createLabelAnnotation() {
    
    const baseOptions = createBaseLineChartOptions();
    const annotations = {};

    if (chartData.percentageAvg) {
      annotations["line1"] = {
        drawTime: "afterDraw",
        type: "line",
        yMin: chartData.percentageAvg,
        yMax: chartData.percentageAvg,
        borderColor: "white",
        borderWidth: 1,
        borderDash: [1, 3],
      };
      annotations["label1"] = {
        drawTime: "afterDraw",
        type: "label",
        xValue: 0.3,
        yValue: chartData.percentageAvg,
        backgroundColor: "white",
        content: ["Avg."],
        font: {
          size: 10,
        },
        height: 0.5,
        padding: {
          left: 3,
          right: 2,
          top: -1,
          bottom: -1,
        },
        xPadding: 2,
        borderRadius: 3,
      };

      baseOptions.plugins.annotation = { annotations };
    }
    // Benchmark annotation line
    if (chartData.benchmarkData) {
      annotations["line2"] = {
        drawTime: "afterDraw",
        type: "line",
        yMin: chartData.benchmarkData,
        yMax: chartData.benchmarkData,
        borderColor: "#FFB700",
        borderWidth: 1,
        borderDash: [1, 3],
      };

      annotations["label2"] = {
        drawTime: "afterDraw",
        type: "label",
        xValue: 2.5,
        yValue: chartData.benchmarkData,
        backgroundColor: "#FFB700",
        content: ["Benchmark"],
        font: {
          size: 10,
        },
        height: 0.5,
        padding: {
          left: 3,
          right: 2,
          top: -1,
          bottom: -1,
        },
        xPadding: 2,
        borderRadius: 3,
      };

      baseOptions.plugins.annotation = { annotations };
    }

    return baseOptions;
  }

  const data = {
    labels: chartData.interval,
    datasets: [
      {
        data: chartData.interval.map((interval) => {
          return {
            x: interval,
            ...chartData[999].data[interval],
          };
        }),
        backgroundColor: lineChartColor,
        tension: 0,
        borderWidth: 1,
        pointBorderWidth: 0.1,
        hoverBackgroundColor: "#FFFFFF",
        hoverBorderColor: "#FFFFFF",
        pointHoverRadius: 3,
        parsing: {
          yAxisKey: chartMode,
        },
        yAxisID: "y",
      },

      // Feed data to y axis right
      {
        data: chartData.interval.map((interval) => {

          return {
            x: interval,
            ...chartData[999].data[interval],
          };
        }),
        backgroundColor: lineChartColor,
        tension: 0,
        borderWidth: 1,
        pointBorderWidth: 0.1,
        hoverBackgroundColor: "#FFFFFF",
        hoverBorderColor: "#FFFFFF",
        pointHoverRadius: 3,
        parsing: {
          yAxisKey: chartMode,
        },
        yAxisID: "y1",
      },
    ],
  };

  return (
    <div className={styles.Chart}>
      <ChartHeader 
        chartTitle={chartTitle} 
        chartKey={chartData.chartKey} />
      <div className={styles.ChartButtonsContainer}></div>
      <div className={styles.ChartContainer}>
        <Line
          key={Math.random()}
          data={data}
          plugins={[
            backgroundPlugin,
            xAxisButtonPlugin,
            hoverPlugin,
            posWithSLAreaPlugin,
          ]}
          options={createLabelAnnotation()}
        />
      </div>
    </div>
  );
}

export default LineChart;
