import styles from "./advertisement.module.scss";
import zuluBanner from "../../assets/advertisementBanners/zuluBanner1.png";

function ZuluBanner() {
  return (
    <>
      <div className={styles.BannerContainer}>
        <a
          href="https://hoctrade21.zulutrade.com/signup"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={zuluBanner} alt="Banner"></img>
        </a>
      </div>
      <div className={styles.MobileBannerContainer}>
        <a
          href="https://hoctrade21.zulutrade.com/signup"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={zuluBanner} alt="Banner"></img>
        </a>
      </div>
    </>
  );
}

export default ZuluBanner;
