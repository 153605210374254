import { Routes, Route, Navigate } from "react-router-dom";
import { chartKeysByPage } from "utils/constant";
// components
import SandboxGeneral from "./general.page";
import SandboxBehavioral from "./behavioral.page";
import SandboxCosts from "./costs.page";
import SandboxRiskManagement from "./riskManagement.page";
import SandboxStrategy from "./strategy.page";
import SandboxTiming from "./timing.page";
import PageDataFetchingWrapper from "./pageDataFetchingWrapper";

function SandboxAnalysisIndex() {
  const currentTradingAccount = {
    account_id: 1234,
    account_publie: true,
    broker: "hoctrade",
    currency: "USD",
    portfolio_name: "sandbox",
    server: "test",
    status: "demo",
    trading_account_id: "hoctrade@test",
    user_id: 1,
  };

  return (
    <>
      <PageDataFetchingWrapper>
        <Routes>
          <Route
            path="/general"
            element={
              <SandboxGeneral
                chartKeysOfThePage={chartKeysByPage.general}
                currentTradingAccount={currentTradingAccount}
              />
            }
          />
          <Route
            path="/behavioral"
            element={
              <SandboxBehavioral
                chartKeysOfThePage={chartKeysByPage.behavioral}
                currentTradingAccount={currentTradingAccount}
              />
            }
          />
          <Route
            path="/costs"
            element={
              <SandboxCosts
                chartKeysOfThePage={chartKeysByPage.costs}
                currentTradingAccount={currentTradingAccount}
              />
            }
          />
          <Route
            path="/risk-management"
            element={
              <SandboxRiskManagement
                chartKeysOfThePage={chartKeysByPage['risk-management']}
                currentTradingAccount={currentTradingAccount}
              />
            }
          />
          <Route
            path="/strategy"
            element={
              <SandboxStrategy
                chartKeysOfThePage={chartKeysByPage.strategy}
                currentTradingAccount={currentTradingAccount}
              />
            }
          />
          <Route
            path="/timing"
            element={
              <SandboxTiming
                chartKeysOfThePage={chartKeysByPage.timing}
                currentTradingAccount={currentTradingAccount}
              />
            }
          />
          <Route path="/*" element={<Navigate to="/general" replace />} />
        </Routes>
      </PageDataFetchingWrapper>
    </>
  );
}

export default SandboxAnalysisIndex;
