import styles from "../../overview/accountOverview/alerts/alert.module.scss";
import htFonts from "shared/htFont.module.scss";
import { Link } from "react-router-dom";
import data from "devData/alerts";
import SandboxOverviewPageAlertCard from "./alertCard";
import { useDispatch } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
import { ALERT_INFO_MODAL } from 'constants/modalTypes';


function SandboxOverviewPageAlert() {
  const dispatch = useDispatch();

  const infoIconOnClickHandler = () => {
    dispatch(openModal({
      displayedModal: ALERT_INFO_MODAL
    }));
  };

  return (
    <div className={styles.AlertContainer}>
      <div className={styles.AlertHeader}>
        <div>Alert</div>
        <div className={styles.AlertHeaderRight}>
          <div
            className={`${htFonts.htInfo} ${styles.InfoIcon}`}
            onClick={() => infoIconOnClickHandler()}>
          </div>
          <Link to="/sandbox/dashboard/alert">
            <div className={styles.ViewAllBtn}>
              View all
              <span className={htFonts.htArrow}></span>
            </div>
          </Link>
        </div>
      </div>
      {
        data.map((data, index) => {
          if (index < 3) {
            return <SandboxOverviewPageAlertCard cardData={data} key={data.orderID} index={index} id={`styles.${index}`}/>
          } else {
            return null
          }
        })
      }
    </div>
  );
}

export default SandboxOverviewPageAlert;