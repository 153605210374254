import { Routes, Route, Navigate } from "react-router-dom";

import SandboxAccountOverview from "./sandboxAccountOverview.page";
import SandboxBasicStatistics from "./sandboxBasicStatistics.page";
import SandboxAdvancedStatistics from "./sandboxAdvancedStatistics.page";
import SandboxTradeHistory from "./sandboxTradeHistory.page";

function SandboxOverviewIndex() {
  return (
    <>
      <Routes>
        <Route path="/account-overview" element={<SandboxAccountOverview />}/>
        <Route path="/basic-stats" element={<SandboxBasicStatistics />}/>
        <Route path="/advanced-stats" element={<SandboxAdvancedStatistics />}/>
        <Route path="/trade-history" element={<SandboxTradeHistory />}/>
        <Route path="/*" element={<Navigate to="/account-overview" replace />}/>
      </Routes>
    </>
  )
}

export default SandboxOverviewIndex;