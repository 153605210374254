import { createSlice } from '@reduxjs/toolkit';
import { responsiveTradeMedicChartKeys } from 'utils/constant';

const chartKeyValues = Object.values(responsiveTradeMedicChartKeys);
const initialChartState = {
  lastChartUpdatedAt: Date.now(),
  isDataFetched: false,
  currentTradingAccount: {
    currency: null
  }
};
chartKeyValues.forEach(chartKey => initialChartState[chartKey] = null);

export const responsiveTradeMedicChartsDataSlice = createSlice({
  name: 'responsiveTradeMedicChartsData',
  initialState: initialChartState,
  reducers: {
    setResponsiveTradeMedicChartsData: (state, action) => {
      return {
        ...state,
        ...action.payload.responsiveTradeMedicChartsData,
      }
    },
    resetResponsiveTradeMedicChartsData: (state, action) => {
      const chartKeyValues = Object.values(responsiveTradeMedicChartKeys);
      const newState = {
        lastUpdatedAt: Date.now()
      };
      chartKeyValues.forEach(chartKey => newState[chartKey] = null);
      return newState;
    },
    setLastChartUpdateAt: (state, action) => {
      return {
        ...state,
        lastChartUpdatedAt: action.payload.lastChartUpdatedAt
      }
    },
    setIsDataFetched: (state, action) => {
      return {
        ...state,
        isDataFetched: action.payload.isDataFetched
      }
    },
  },
});

export const { 
  setResponsiveTradeMedicChartsData,
  resetResponsiveTradeMedicChartsData,
  setLastChartUpdateAt,
  setIsDataFetched,
  setCurrency,
} = responsiveTradeMedicChartsDataSlice.actions;

export const responsiveTradeMedicChartsDataReducer = responsiveTradeMedicChartsDataSlice.reducer;