import styles from './accountConnectionResult.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { closeModal } from 'redux/slices/modalSlice';

export default function AccountConnectionResult({
  accountConnectionResult,
  setPhase
}) {
  let content;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (accountConnectionResult === 'LINKED') {
    content = (
      <div>
        <div className={styles.SectionTitle}>Trading account</div>
        <div></div>
        <div className={styles.SectionDescription}>has been created successfully.</div>
        <div 
          className={styles.Button}
          onClick={() => {
            dispatch(closeModal(null));
            return navigate('/dashboard/overview/account-overview');
          }}>Done</div>
      </div>
    );
  }
  if (accountConnectionResult === 'FAILED') {
    content = (
      <div>
        <div className={styles.SectionTitle}>Failed to connect account.</div>
        <div className={styles.SectionDescription}>Please check your trading account credential</div>
        <div>
          <div
            className={styles.Button} 
            onClick={() => setPhase(1)}>Back</div>
          <div
            className={styles.Help}>Help</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Section}>
      { content }
    </div>
  );
}