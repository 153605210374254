import Navbar from "components/navbar/navbar";
import Sidebar from "components/sidebar/sidebar";
import Breadcrumb from "components/breadcrumb/breadcrumb";
import MobileSidebar from "components/sidebar/mobileSidebar";
import { useState } from "react";
//styles
import styles from "./generalLayout.module.scss";

function GeneralLayout(props) {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  return (
    <div className={styles.GeneralLayout}>
      <Navbar />
      <Sidebar />
      <div className={styles.Page}>
        <Breadcrumb
          isMobileSidebarOpen={isMobileSidebarOpen}
          setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        />
        <div className={styles.Content}>{props.children}</div>
      </div>
      {isMobileSidebarOpen ? (
        <MobileSidebar
          isMobileSidebarOpen={isMobileSidebarOpen}
          setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        />
      ) : null}
    </div>
  );
}

export default GeneralLayout;
