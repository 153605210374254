const drawStackedBarBackground = (isDarkMode) => {
  return {
    id: "draw_stacked_bar_background",
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext("2d");
      const chartArea = chart.chartArea;
      const barElements = chart._metasets[0].data;
      barElements.forEach(bar => {
        ctx.beginPath();
        ctx.fillStyle = '#034C57';
        ctx.fillRect(bar.x - bar.width / 2, 40, bar.width, chartArea.height - bar.height);
      });
    }
  }
}

export default drawStackedBarBackground;