import profit_by_daytime_per_trade from "./general/profit_by_daytime_per_trade";
import long_and_short_per_trade from './general/long_and_short_per_trade';
import profit_by_symbol_per_trade from "./general/profit_by_symbol_per_trade";
import profit_by_trade_length_per_trade from './general/profit_by_trade_length_per_trade';
import profit_by_weekday_per_trade from "./general/profit_by_weekday_per_trade";
// Behavior page
import trades_per_day_per_trade from "./behaviour/trades_per_day_per_trade";
import break_after_loss_trade_per_trade from "./behaviour/break_after_loss_trade_per_trade";
import break_since_last_trade_per_trade from "./behaviour/break_since_last_trade_per_trade";
import open_different_symbols_per_trade from "./behaviour/open_different_symbols_per_trade";
import open_position_in_profit_loss from "./behaviour/open_position_in_profit_loss";
import profit_after_daily_profit_loss from "./behaviour/profit_after_daily_profit_loss";
// Timing page
import profit_if_manual_stop_before_tp from "./timing/profit_if_manual_stop_before_tp";
import profit_if_sl_closer_further_away from "./timing/profit_if_sl_closer_further_away";
import profit_if_tp_close_further_away from "./timing/profit_if_tp_close_further_away";
import profit_if_trade_close_earlier_later from "./timing/profit_if_trade_close_earlier_later";
import profit_if_trade_open_earlier_later from "./timing/profit_if_trade_open_earlier_later";
import percent_of_trades_stopped_early_in_profit from "./timing/percent_of_trades_stopped_early_in_profit";
// Risk management page
import max_risk_all_open_trades_last_trades from "./riskManagement/max_risk_all_open_trades_last_trades";
import percentage_of_position_with_sl from "./riskManagement/percentage_of_position_with_sl";
import profit_if_adding_to_negative_positions from "./riskManagement/profit_if_adding_to_negative_positions";
import profit_secure_payoff_from_reverting_trades from "./riskManagement/profit_secure_payoff_from_reverting_trades";
import risk_after_losing_trades_open_trade from "./riskManagement/risk_after_losing_trades_open_trade";
import risk_after_losing_trades_per_trade from "./riskManagement/risk_after_losing_trades_per_trade";
import risk_per_trade_last_trades from "./riskManagement/risk_per_trade_last_trades";
// Strategy page
import profit_high_vs_low_volatility from "./strategy/profit_high_vs_low_volatility";
import profit_with_against_st_mt_trend from "./strategy/profit_with_against_st_mt_trend";
import profit_when_sl_adjusted_vs_profit_when_not from "./strategy/profit_when_sl_adjusted_vs_profit_when_not";
import profit_if_publishing_trades from "./strategy/profit_if_publishing_trades";
import profit_after_withdrawal_in_pips from "./strategy/profit_after_withdrawal_in_pips";
import average_payoff_win_vs_loss_trade from "./strategy/average_payoff_win_vs_loss_trade";
import profit_for_trade_during_overbought_oversold from "./strategy/profit_for_trade_during_overbought_oversold";
// Cost page
import spread_and_commission from "./cost/spread_and_commission";
import profit_of_conflicting_symbols from "./cost/profit_of_conflicting_symbols";

const chartDataObjectMap = {
  profit_by_daytime_per_trade,
  long_and_short_per_trade,
  profit_by_symbol_per_trade,
  profit_by_trade_length_per_trade,
  profit_by_weekday_per_trade,
  //Behaviour page
  trades_per_day_per_trade,
  break_after_loss_trade_per_trade,
  break_since_last_trade_per_trade,
  open_different_symbols_per_trade,
  open_position_in_profit_loss,
  profit_after_daily_profit_loss,
  //Timing page
  profit_if_manual_stop_before_tp,
  profit_if_sl_closer_further_away,
  profit_if_tp_close_further_away,
  profit_if_trade_close_earlier_later,
  profit_if_trade_open_earlier_later,
  percent_of_trades_stopped_early_in_profit,
  //Risk management page
  max_risk_all_open_trades_last_trades,
  percentage_of_position_with_sl,
  profit_if_adding_to_negative_positions,
  profit_secure_payoff_from_reverting_trades,
  risk_after_losing_trades_open_trade,
  risk_after_losing_trades_per_trade,
  risk_per_trade_last_trades,
  //Strategy page
  profit_high_vs_low_volatility,
  profit_with_against_st_mt_trend,
  profit_when_sl_adjusted_vs_profit_when_not,
  profit_if_publishing_trades,
  profit_after_withdrawal_in_pips,
  average_payoff_win_vs_loss_trade,
  profit_for_trade_during_overbought_oversold,
  //Cost page
  spread_and_commission,
  profit_of_conflicting_symbols
}

export default chartDataObjectMap;