const commissionComparisonData = {
  chartTitle: "Spread & commission",
  hoverTextArray: [
    {
      templateString: 'Saving potential: ',
      unit: 'USD',
      valueKey: 'save',
      textColor: true,
    },
    {
      templateString: '/',
      unit: '%',
      valueKey: 'diff',
      textColor: true,
    },
    {
      templateString: 'Costs: ',
      unit: 'USD',
      valueKey: 'number',
    },
    {
      templateString: 'Broker: ',
      valueKey: 'name',
    },
  ],
  createHoverTextArray: (currency) => {
    return [
      {
        templateString: 'Saving potential: ',
        unit: currency,
        valueKey: 'save',
        textColor: true,
      },
      {
        templateString: '/',
        unit: '%',
        valueKey: 'diff',
        textColor: true,
      },
      {
        templateString: 'Costs: ',
        unit: currency,
        valueKey: 'number',
      },
      {
        templateString: 'Broker: ',
        valueKey: 'name',
      },
    ]
  },
  chartKey: 'commission-comparison-chart',
  interval: [
    'Yearly',
    'Monthly',
  ],
}

export default commissionComparisonData;