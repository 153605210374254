const yZeroAxisPlugin = {
  id: 'z_zero_axis_plugin',
  beforeDatasetsDraw: (chart) => {
    const { scales, ctx, chartArea } = chart;
    ctx.beginPath();
    ctx.strokeStyle = "#4D4D4D";
    const zeroLine = scales.y._labelItems.find(label => label.label === '0');
    ctx.moveTo(
      zeroLine.translation[0],
      zeroLine.translation[1] + 7
    );
    ctx.lineTo(
      zeroLine.translation[0] + chartArea.width,
      zeroLine.translation[1] + 7
    );
    ctx.stroke();
    ctx.restore();
  }
}

export default yZeroAxisPlugin;