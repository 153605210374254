const averagePipsTrade = {
  chartTitle: "Average pips per trade",
  barValueKey: "pips",
  chartKey: "average_pips_per_trade",
  999: [
    {
      time: new Date("2022-04"),
      pips: 2.0,
      trades: 57,
      benchmark: 3
    },
    {
      time: new Date("2022-05"),
      pips: -1.2,
      trades: 92,
      benchmark: 3
    },
    {
      time: new Date("2022-06"),
      pips: 0.4,
      trades: 81,
      benchmark: 3
    },
    {
      time: new Date("2022-07"),
      pips: 2.9,
      trades: 52,
      benchmark: 3
    },
    {
      time: new Date("2022-08"),
      pips: 2.7,
      trades: 29,
      benchmark: 3
    },
    {
      time: new Date("2022-09"),
      pips: 1.5,
      trades: 59,
      benchmark: 3
    },
    {
      time: new Date("2022-10"),
      pips: -0.2,
      trades: 71,
      benchmark: 3
    },
    {
      time: new Date("2022-11"),
      pips: 2.6,
      trades: 50,
      benchmark: 3
    },
    {
      time: new Date("2022-12"),
      pips: 1.7,
      trades: 54,
      benchmark: 3
    },
    {
      time: new Date("2023-01"),
      pips: 2.0,
      trades: 55,
      benchmark: 3
    },
    {
      time: new Date("2023-02"),
      pips: 1.9,
      trades: 70,
      benchmark: 3
    },
    {
      time: new Date("2023-03"),
      pips: -0.1,
      trades: 98,
      benchmark: 3
    },
    {
      time: new Date("2023-04"),
      pips: 1.5,
      trades: 140,
      benchmark: 3
    }
  ]
};


export default averagePipsTrade;