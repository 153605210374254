const performanceByMonth = {
  chartTitle: "Performance by month",
  barValueKey: "percentage",
  chartKey: "performance_months",
  999: [
    {
      time: new Date("2022-04"),
      percentage: 8.33,
      trades: 57,
      profit: 649.59,
    },
    {
      time: new Date("2022-05"),
      percentage: -9.63,
      trades: 92,
      profit: -788.55,
    },
    {
      time: new Date("2022-06"),
      percentage: 5.92,
      trades: 81,
      profit: -486.59,
    },
    {
      time: new Date("2022-07"),
      percentage: 9.68,
      trades: 52,
      profit: 1888.77,
    },
    {
      time: new Date("2022-08"),
      percentage: 6.42,
      trades: 29,
      profit: -353.28,
    },
    {
      time: new Date("2022-09"),
      percentage: 7.31,
      trades: 59,
      profit: 1782.87,
    },
    {
      time: new Date("2022-10"),
      percentage: -1.82,
      trades: 71,
      profit: -208.96,
    },
    {
      time: new Date("2022-11"),
      percentage: 8.22,
      trades: 50,
      profit: 2928.48,
    },
    {
      time: new Date("2022-12"),
      percentage: 5.12,
      trades: 54,
      profit: -1374.08,
    },
    {
      time: new Date("2023-01"),
      percentage: 1,
      trades: 55,
      profit: 6.62,
    },
    {
      time: new Date("2023-02"),
      percentage: 4.44,
      trades: 70,
      profit: 571.08,
    },
    {
      time: new Date("2023-03"),
      percentage: -1,
      trades: 98,
      profit: -13.24,
    },
    {
      time: new Date("2023-04"),
      percentage: 11.73,
      trades: 140,
      profit: 1572.37,
    },
  ]
};

export default performanceByMonth;