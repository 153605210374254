import GeneralInfo from "components/overview/accountOverview/generalInfo";
import TradesPatternChart from "components/overview/accountOverview/tradesPattern/tradesPattern.chart";
import Donuts from "components/overview/accountOverview/crypto/donuts.chart";
import ProgressBars from "components/overview/accountOverview/crypto/progressBars";
import PerformanceOverTime from "components/overview/accountOverview/performanceOverTime/performanceOverTime";
import Drawdown from "components/overview/accountOverview/drawdown/drawdown.chart";
import Carousel from "components/overview/accountOverview/carousel/carousel";
import EToroBanner from "components/advertisements/eToro";
import ZuluBanner from "components/advertisements/zulu";
import RoboForex from "components/advertisements/roboForex";
import Alert from "components/overview/accountOverview/alerts/alert";
import CostComparison from "components/costComparison/costComparison";
//styling
import styles from "./accountOverview.module.scss";
import { useState, useEffect } from "react";
// import htFonts from "shared/htFont.module.scss";
//dummy data
import { drawdown } from "devData/chartsData";
import { generalInfo, tradesPattern, performanceOverTime } from "devData/cryptoChartsData";
import blogData from "devData/blogData/blogData";
import alerts from "devData/alerts";

function AccountOverview() {
  const [chartsData, setChartsData] = useState(null);

  useEffect(() => {
    //simulate getting data from backend
    setChartsData({
      generalInfo,
      tradesPattern,
      performanceOverTime,
      drawdown
    });
  }, []);

  if (!chartsData) {
    return <div className={styles.Subpage}>Loading Data</div>;
  }

  return (
    <div className={styles.overviewWrapper}>
      <div className={styles.leftColumn}>
        <GeneralInfo 
          key="General Info"
          data={chartsData["generalInfo"]}
        />
        <div className={styles.tradesPatternContainer}>
          <div className={styles.tradesPatternHeader}>
            <div className={styles.tradesPatternTitle}>
              {chartsData["tradesPattern"].chartTitle}
            </div>
            {/* <div className={styles.iconContainer}>
              <div className={styles.whiteBackgroundCircle}></div>
              <div
                  className={`${htFonts.htInfo} ${styles.InfoIcon}`}
                  // onClick={() => infoIconOnClickHandler()}>
                ></div>
            </div> */}
          </div>
          <div className={styles.tradesPatternBody}>
            <TradesPatternChart
              key="Trades pattern line chart"
              chartData={chartsData["tradesPattern"][999]}
              hasTimeFrame={true}
              dualMode={true}
            />
            <div className={styles.tradesPatternBodyRight}>
              <Donuts
                key="Trades pattern donut charts"
                chartData={chartsData["tradesPattern"].lastestData}
              />
              <div className={styles.progressBarsWrapper}>
                <ProgressBars
                  key="Trades pattern profit bar chart"
                  chartData={chartsData["tradesPattern"].lastestData.profit}
                  fontColor="#56A07E"
                  backgroundColor="#1E4B42"
                  hoverColor="#009245"
                />
                <ProgressBars
                  key="Trades pattern loss bar chart"
                  chartData={chartsData["tradesPattern"].lastestData.profit}
                  fontColor="#C15357"
                  backgroundColor="#443035"
                  hoverColor="#C1272D"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.chartsWrapper}>
          <PerformanceOverTime
            key="Performance Over Time"
            chartData={chartsData["performanceOverTime"]}
          />
          <Drawdown 
            key="Drawdown (balance)"
            chartData={chartsData["drawdown"]}
          />
        </div>
        <EToroBanner />
        <Carousel data={blogData}/>
        <ZuluBanner />
      </div>
      <div className={styles.rightColumn}>
        <Alert alertsData={alerts}/>
        <div>
          <CostComparison />
          <RoboForex />
        </div>
      </div>
    </div>
  );
}

export default AccountOverview;
