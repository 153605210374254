import { useState } from "react";
import styles from "./faqSection.module.scss";
import htFonts from "shared/htFont.module.scss";

function FaqSection() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className={styles.FaqSectionWrapper}>
      <div
        className={styles.SectionHeader}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className={styles.HeaderLeft}>
          {" "}
          <span className={htFonts.htInfo}></span>FAQ{" "}
        </div>
        <span className={htFonts.htAdd}></span>
      </div>
      {isDropdownOpen ? (
        <div className={styles.SectionContent}>
          <p>
            <strong>
              How are the results of this TradeMedic Report generated?
            </strong>
            <br />
            The results are fully automatically generated based on your historic
            trades. Smart algorithms analyze your trades, identify patterns &
            behaviors, interpret them, and derive the quantified effects shown
            in the report.
          </p>
          <p>
            <strong>
            Can I view the charts in different timeframes and dimensions?
            </strong>
            <br />
            Yes, you can view the charts in custom timeframes for more recent performances, alter between Pip & Return to derive position sizing effects, analyze number of trades, significances, benchmarks, risk effects, and more. These additional insights are available through Hoc-trade.
          </p>
          <p>
            <strong>
            Can I receive alerts if I perform trades that show issues?
            </strong>
            <br />
            Yes, Hoc-trade enables real-time alerts in case you exhibit trading actions indicative of behaving in one of your trading issues. Moreover, you can receive alerts in case risk management related actions are identified. All alerts can be customized based on your preferences.
          </p>
          <p>
            <strong>
            Are the TradeMedic Reports also available for accounts except MetaTrader 4?
            </strong>
            <br />
            Currently, the TradeMedic reports are only available for MetaTrader 4 users. However, we are already working on integrating additional trading platforms frequently used by traders. Stay tuned for more integrations.
          </p>
          <p>
            <strong>
            Are there additional analytics available?
            </strong>
            <br />
            There are many more charts available that outline trading behaviors, strategies, etc.. Moreover, advanced statistics, tracking tools, and in-depth explanations & videos on root causes of behaviors are available. These additional sources can be found on Hoc-trade.
          </p>
          <p>
            <strong>
            Why do I receive this report?
            </strong>
            <br />
            This report has been generated as a service by your broker. To inquire about the eligibility to receive this and future reports, please contact your broker.
          </p>
          <p>For further questions, please refer to our FAQ page <a href="https://trade-medic.hoc-trade.com/faq" style={{color: "blue", textDecoration: "underline",}}>here</a></p>
        </div>
      ) : null}
    </div>
  );
}

export default FaqSection;
