import createBarColor from "components/charts/helpers/createBarColor";
import createHoverBarColor from "components/charts/helpers/createHoverBarColor";
import createBarBorderColor from "components/charts/helpers/createBarBorderColor";
import createHoverBarBorderColor from "components/charts/helpers/createHoverBarBorderColor";
import { chartKeys } from 'utils/constant';

const max_risk_all_open_trades_last_trades = {
  chartTitle: "Max risk open",
  chartKey: chartKeys.MAX_RISK_ALL_OPEN_TRADES_LAST_TRADES,
  createBarColor: createBarColor,
  createHoverBarColor: createHoverBarColor,
  createBarBorderColor: createBarBorderColor,
  createHoverBarBorderColor: createHoverBarBorderColor,
  hoverTextArray: {
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        followBarColor: true,
      },
      {
        templateString: "B: ",
        valueKey: "benchmark",
        unit: "%",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
  },
  haspercentageBenchmark: true,
  interval: ["all time", "last 20", "last 50", "last 100", "last 200"],
}

export default max_risk_all_open_trades_last_trades;