const performanceOverTime = {
  chartTitle: 'Performance Over Time',
  999: [
    {
      time: new Date("2020-10"),
      percentage: -60,
      performancePercentage: -80,
      // performancePips: -5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2020-11"),
      percentage: -40,
      performancePercentage: -40,
      // performancePips: -5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2020-12"),
      percentage: 99.02,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-01"),
      percentage: 98.68,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-02"),
      percentage: 94.91,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-03"),
      percentage: 94.87,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-04"),
      percentage: 94.87,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-05"),
      percentage: 94.84,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-06"),
      percentage: -60,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-07"),
      percentage: -60,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-08"),
      percentage: -94.84,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
    {
      time: new Date("2021-09"),
      percentage: -94.84,
      performancePercentage: 80,
      // performancePips: 5065,
      equityTotal: 223560.55,
      equity: 258.25,
      duration: "9M",
      trades: 23
    },
  ]
}

export default performanceOverTime;