import styles from './authModalUI.module.scss';
import htFonts from "../../shared/htFont.module.scss";
import { useDispatch} from 'react-redux';
import { closeModal } from 'redux/slices/modalSlice';

export default function AuthModalUI(props) {
  const dispatch = useDispatch();

  const close = () => {
    return dispatch(closeModal());
  };


  return (
    <div className={styles.Modal}>
      <div className={styles.ModalHeader}>
        <div className={styles.htLogo}></div>
        <div className={styles.title}>hoc-trade</div>
        <div className={styles.CrossIconButton}>
          <div
            className={`${htFonts.htCross} ${styles.CancelIcon}`}
            onClick={() => close()}
          ></div>
        </div>
      </div>
      {props.children}
    </div>
  )
}