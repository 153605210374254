

const tradesPatternOptions = {
  // intersect: false,
  animation: {
    duration: 0,
  },
  interaction: {
    mode: "x",
    intersect: false,
  },
  // responsive: true,
  maintainAspectRatio: false,
  axes: {
    backgroundColor: "#4D4D4D",
  },
  borderWidth: 1,
  pointRadius: 0,
  pointHoverRadius: 0,
  scales: {
    y: {
      position: "left",
      grid: {
        color: (context) => {
          return "#4D4D4D";
        },
        borderDash: (a) => {
          return [1, 2];
        },
        drawBorder: false,
        tickLength: 0,
        tickBorderDash: (a) => {
          return [1, 2];
        },
      },
      beginAtZero: true,
      Min: 0,
      Max: 100,
      ticks: {
        autoSkip: false,
        color: "#999999",
        font: {
          size: 10,
          family: "Arial",
          weight: 700,
        },
        labelOffset: -7,
        padding: 5,
        mirror: true,
        stepSize: 10,
        callback: function (value, index, ticks) {
          switch (index) {
            case 1:
              return value;
            case 5:
              return value;
            case 10:
              return ["%", "100", ""];
            // case 10:
            //   return "%"+value;
            default:
              return "";
          }
        },
      },
    },
    y1: {
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      beginAtZero: true,
      Min: 0,
      Max: 100,
      ticks: {
        autoSkip: false,
        color: "#999999",
        font: {
          size: 10,
          family: "Arial",
          weight: 700,
        },
        labelOffset: -7,
        padding: 5,
        mirror: true,
        stepSize: 10,
        callback: function (value, index, ticks) {
          switch (index) {
            case 0:
              return "";
            case 1:
              return "10"
            case 5:
              return "50";
            case 10:
              return ["%", "100", ""];
            default:
              return " ";
          }
        },
      },
    },
    x: {
      grid: {
        offset: false,
        color: "#4D4D4D",
        borderDash: [1, 2],
        borderColor: "#4D4D4D",
        borderWidth: 1,
        tickColor: "#4D4D4D",
        tickLength: 4,
      },
      ticks: {
        padding: 0,
        color: "#999999",
        font: {
          size: 10,
        },
      },
    },
  },
  layout: {
    padding: {
      bottom: 10,
      left: 10,
      // right: 10,
      top: 30,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: false
  },
  devicePixelRatio: 2,
  categoryPercentage: 0.2,
};

export default tradesPatternOptions;
