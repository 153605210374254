export default function processWithBenchmarksCrypto(chartData) {
  const timeFrames = [1, 3, 12, 999];
  const result = JSON.parse(JSON.stringify(chartData));

  timeFrames.forEach(timeFrame => {
    const intervals = chartData.interval?? Object.keys(chartData[timeFrame].data);
    if (result[timeFrame]) {
      intervals.forEach(interval => {
        result[timeFrame].data[interval].usdBenchmark = chartData.benchmarks[interval].usd;
        result[timeFrame].data[interval].percentageBenchmark = chartData.benchmarks[interval].percentage;
      })
    }
  });
  result.createBarColor = chartData.createBarColor?? null;
  result.createBarBorderColor = chartData.createBarBorderColor?? null;
  result.createHoverBarColor = chartData.createHoverBarColor?? null;
  result.createHoverBarBorderColor = chartData.createHoverBarBorderColor?? null;
  result.hoverTextArray = chartData.hoverTextArray;
  result.createBarColorComparison = chartData.createBarColorComparison?? null;
  result.createHoverBarColorComparison = chartData.createHoverBarColorComparison?? null;
  result.createBarBorderColorComparison = chartData.createBarBorderColorComparison?? null;
  return result;
}