const createBackgroundPlugin = (isDarkMode) => {
  return {
    id: "custom_light_background",
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = isDarkMode? '#00333B' : '#EEE4D2';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  }
}

export default createBackgroundPlugin;