import { Bar } from "react-chartjs-2";

import ChartHeader from "../../../components/charts/chartHeader";
import styles from "./riskReturnHitRatio.module.scss";
import createOptions from "./riskReturnHitRatio.options";

//plugins
import xAxisPlugin from "./xAxis.plugin";
import createHoverTextPlugin from "./hoverText.plugin";

function RiskReturnHitRatio({ chartData }) {
  const { chartTitle } = chartData;

  let allTimeData = chartData[999];
  const hoverTextPlugin = createHoverTextPlugin(chartData);
  const options = createOptions(chartData);

  let allIntervals = allTimeData.riskReturnRatio.map((data) => {
    let time = typeof data.time === 'string'? new Date(data.time) : data.time;
    let timeOffsetInMillSecs = (time.getTimezoneOffset())*60*1000;
    let timestamp = (time.getTime());
    let clientTime = new Date(timestamp+timeOffsetInMillSecs);
    
    return clientTime;
  })

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval.getMonth() === 0) {
      return interval = interval.toLocaleString("default", { year: "numeric" })
    }
    
    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return interval = interval.toLocaleString("default", { month: "short" });
      } else {
        showInterval = true;
        return "";
      }
    }
    return interval;
  });

  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: allTimeData.riskReturnRatio.map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data.num
          }
        }),
        backgroundColor: "#0077C0",
        borderColor: "#333333",
        yAxisID: "y",
      },
      {
        data: allTimeData.hitRatio.map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data.num
          }
        }),
        backgroundColor: "#047F92",
        borderColor: "#333333",
        yAxisID: "y1",
      },
    ]
  }
  
  return (
    <div className={styles.ChartContainer}>
      <ChartHeader
        chartTitle={chartTitle}
        chartKey="risk_return_ratio_and_hit_ratio"
      />
      <div className={styles.Chart}>
        <Bar 
          data={data}
          options={options}
          plugins={[
            xAxisPlugin,
            hoverTextPlugin
          ]}
        />
      </div>
    </div>
  )
}

export default RiskReturnHitRatio;