import { Routes, Route, Navigate } from "react-router-dom";

import AccountOverview from "./accountOverview.page";
import BasicStatistics from "./basicStatistics.page";
import AdvancedStatistics from "./advancedStatistics.page";
import SubPageWrapper from "../analysis/SubPageWrapper";

import TradeHistory from "./tradeHistory.page";
import styles from '../analysis/SubPageWrapper/index.module.scss';
import { useLocation } from "react-router-dom";

function OverviewIndex() {
  const location = useLocation();
  const pathAndComponentPairs = [
    { path: '/basic-stats', Component: BasicStatistics },
    { path: '/trade-history', Component: TradeHistory },
    { path: '/advanced-stats', Component: AdvancedStatistics },
  ];

  let childrenComponent;
  if (location.pathname === '/dashboard/overview/account-overview') {
    childrenComponent = (
        <Routes>
          <Route 
            path="/account-overview" 
            element={
              <SubPageWrapper>
                <AccountOverview />
              </SubPageWrapper>
            } />
        </Routes>
    );
  } else {
    let routes = pathAndComponentPairs.map(({ path, Component }) => {
      if (path === '/trade-history') {
        return (
          <Route 
            key={path}
            path={path}
            element={
                <Component />
            } />
        )
      }

      return (
        <Route 
          key={path}
          path={path}
          element={
            <SubPageWrapper>
              <Component />
            </SubPageWrapper>
          } />
      );
    });
    childrenComponent = (
      <div className={styles.Subpage}>
        <Routes>
          { routes }
          <Route path="/*" element={<Navigate to="/account-overview" replace />}/>
        </Routes>
      </div>
    );
  }

  return (
    <>
      { childrenComponent }
    </>
  );
}

export default OverviewIndex;