import { Bar } from "react-chartjs-2";
import { useState } from "react";

import ChartHeader from "../../../components/charts/chartHeader";
import styles from "./bestWorstTrades.module.scss";
import options from "./bestWorstTrades.options";

//plugins
import xAxisPlugin from "./xAxis.plugin";
import createHoverTextPlugin from "./hoverText.plugin";

function BestWorstTrades({ chartData }) {
  let { chartTitle } = chartData;

  const [chartMode, setChartMode] = useState("pips");
  
  let allIntervals = chartData[999].bestTrades.map((data) => {
    let time = typeof data.time === 'string'? new Date(data.time) : data.time;
    let timeOffsetInMillSecs = (time.getTimezoneOffset()) * 60 * 1000;
    let timestamp = (time.getTime());
    let clientTime = new Date(timestamp+timeOffsetInMillSecs);
    
    return clientTime;
  })

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval.getMonth() === 0) {
      return interval = interval.toLocaleString("default", { year: "numeric" })
    }
    
    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return interval = interval.toLocaleString("default", { month: "short" });
      } else {
        showInterval = true;
        return "";
      }
    }
    return interval;
  });

  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: chartData[999].bestTrades.map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data[chartMode],
            ...data
          };
        }),
        backgroundColor: "#56A07E",
      },
      {
        data: chartData[999].worstTrades.map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data[chartMode],
            ...data
          };
        }),
        backgroundColor: "#C15357",
      },
    ]
  }

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader
        chartTitle={chartTitle}
        chartKey="best_worst_trades"
      />
      <div className={styles.ChartButtonsContainer}>
        <div className={styles.ModeButtonsContainer}>
          <div
            className={
              chartMode === "pips"
                ? `${styles.DualModeButton} ${styles.Selected}`
                : styles.DualModeButton
            }
            id={styles.Pips}
            onClick={() => setChartMode("pips")}
          >
            Pips
          </div>
          <div
            className={
              chartMode === "percentage"
                ? `${styles.DualModeButton} ${styles.Selected}`
                : styles.DualModeButton
            }
            id={styles.Percentage}
            onClick={() => setChartMode("percentage")}
          >
            %
          </div>
        </div>
      </div>
      <div className={styles.Chart}>
        <Bar 
          data={data}
          options={options}
          plugins={[
            xAxisPlugin,
            createHoverTextPlugin(chartMode)
            // hoverTextPlugin
          ]}
        />
      </div>
    </div>
  );
}

export default BestWorstTrades;
