import { chartKeys } from 'utils/constant';

const profit_for_trade_during_overbought_oversold = {
  chartTitle:
    "Profit for trade during overbought/oversold",
  chartKey: chartKeys.PROFIT_FOR_TRADE_DURING_OVERBOUGHT_OVERSOLD,
  hoverTextArray: {
    pips: [
      {
        templateString: "",
        valueKey: "pips",
        unit: "pips",
        // textColor: function (hoveredData) {
        //   return hoveredData.pips >= 0 ? "#56A07E" : "#C15357";
        // },
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        // textColor: function(hoveredData) {
        //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
        // },
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "pipsBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        // textColor: function (hoveredData) {
        //   return hoveredData.percentage >= 0 ? "#56A07E" : "#C15357";
        // },
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        // textColor: function(hoveredData) {
        //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
        // },
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "percentageBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
  },
  createHoverTextArray: (currency) => {
    return {
      pips: [
        {
          templateString: "",
          valueKey: "pips",
          unit: "pips",
          // textColor: function (hoveredData) {
          //   return hoveredData.pips >= 0 ? "#56A07E" : "#C15357";
          // },
          followBarColor: true,
        },
        {
          templateString: "",
          valueKey: "profit",
          unit: currency,
          // textColor: function(hoveredData) {
          //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
          // },
          followBarColor: true,
        },
        {
          templateString: "Trades: ",
          valueKey: "trades",
        },
        {
          templateString: "B: ",
          valueKey: "pipsBenchmark",
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        },
      ],
      percentage: [
        {
          templateString: "",
          valueKey: "percentage",
          unit: "%",
          // textColor: function (hoveredData) {
          //   return hoveredData.percentage >= 0 ? "#56A07E" : "#C15357";
          // },
          followBarColor: true,
        },
        {
          templateString: "",
          valueKey: "profit",
          unit: currency,
          // textColor: function(hoveredData) {
          //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
          // },
          followBarColor: true,
        },
        {
          templateString: "Trades: ",
          valueKey: "trades",
        },
        {
          templateString: "B: ",
          valueKey: "percentageBenchmark",
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        },
      ],
    }
  },
  haspercentageBenchmark: true,
}

export default profit_for_trade_during_overbought_oversold;