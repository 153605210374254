const averageTradesPerDay = {
  chartTitle: "Average trades per day",
  barValueKey: "trades",
  chartKey: "average_trades_per_day",
  999: [
    {
      time: new Date("2022-04"),
      trades: 2.6,
      benchmark: 7
    },
    {
      time: new Date("2022-05"),
      trades: 4.2,
      benchmark: 7
    },
    {
      time: new Date("2022-06"),
      trades: 3.7,
      benchmark: 7
    },
    {
      time: new Date("2022-07"),
      trades: 2.4,
      benchmark: 7
    },
    {
      time: new Date("2022-08"),
      trades: 1.3,
      benchmark: 7
    },
    {
      time: new Date("2022-09"),
      trades: 2.7,
      benchmark: 7
    },
    {
      time: new Date("2022-10"),
      trades: 3.2,
      benchmark: 7
    },
    {
      time: new Date("2022-11"),
      trades: 2.3,
      benchmark: 7
    },
    {
      time: new Date("2022-12"),
      trades: 2.5,
      benchmark: 7
    },
    {
      time: new Date("2023-01"),
      trades: 2.5,
      benchmark: 7
    },
    {
      time: new Date("2023-02"),
      trades: 3.2,
      benchmark: 7
    },
    {
      time: new Date("2023-03"),
      trades: 4.5,
      benchmark: 7
    },
    {
      time: new Date("2023-04"),
      trades: 6.4,
      benchmark: 7
    }
  ]
};

export default averageTradesPerDay;