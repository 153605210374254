import { chartKeys } from 'utils/constant';
import store from 'redux/store';

const profit_if_tp_closer_further_away = {
  chartTitle: "Profit if TP close/further away",
  chartKey: chartKeys.PROFIT_IF_TP_CLOSER_FURTHER_AWAY,
  hoverTextArray: {
    'pips': [
      {
        templateString: '',
        valueKey: 'pips',
        unit: 'pips',
        textColor: function(hoveredData) {
          return hoveredData.pips >= 0? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        },
        unit: store.getState().authentication.currentTradingAccount?.currency,
        unit: "USD"
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      // {
      //   templateString: 'B: ',
      //   valueKey: 'pipsBenchmark',
      //   createLabelColor: function(hoveredData) {
      //     return "#FFB700";
      //   },
      //   textColor: function(hoveredData) {
      //     return '#FFB700';
      //   }
      // }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        },
        unit: 'USD',
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      // {
      //   templateString: 'B: ',
      //   valueKey: 'percentageBenchmark',
      //   createLabelColor: function(hoveredData) {
      //     return "#FFB700";
      //   },
      //   textColor: function(hoveredData) {
      //     return '#FFB700';
      //   }
      // }
    ]
  },
  createHoverTextArray: (currency) => {
    return {
      'pips': [
        {
          templateString: '',
          valueKey: 'pips',
          unit: 'pips',
          textColor: function(hoveredData) {
            return hoveredData.pips >= 0? "#56A07E" : "#C15357"
          }
        },
        {
          templateString: '',
          valueKey: 'profit',
          textColor: function(hoveredData) {
            return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
          },
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'pipsBenchmark',
          createLabelColor: function(hoveredData) {
            return "#FFB700";
          },
          textColor: function(hoveredData) {
            return '#FFB700';
          }
        }
      ],
      'percentage': [
        {
          templateString: '',
          valueKey: 'percentage',
          unit: '%',
          textColor: function(hoveredData) {
            return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
          }
        },
        {
          templateString: '',
          valueKey: 'profit',
          textColor: function(hoveredData) {
            return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
          },
          unit: 'USD',
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'percentageBenchmark',
          createLabelColor: function(hoveredData) {
            return "#FFB700";
          },
          textColor: function(hoveredData) {
            return '#FFB700';
          }
        }
      ]
    }
  },
  interval: function(chartData) {
    return chartData.interval;
  },
  // interval: [
  //   '-10pip',
  //   '-5pip',
  //   '-3pip',
  //   '-1pip',
  //   'as-is',
  //   '1pip',
  //   '3pip',
  //   '5pip',
  //   '10pip'
  // ],
}

export default profit_if_tp_closer_further_away;