import { chartKeys } from 'utils/constant';

const profit_if_adding_to_negative_positions = {
  chartTitle: "Profit if adding to negative position(s)",
  chartKey: chartKeys.PROFIT_IF_ADDING_TO_NEGATIVE_POSITIONS,
  hoverTextArray: {
    usd: [
      {
        templateString: "",
        valueKey: "usd",
        unit: "USD/trade",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
    ],
  },
  interval: ["average", "1", "2", "3", "4-5", "5+"],
}

export default profit_if_adding_to_negative_positions;