import styles from "./subPageLayout.module.scss";
import { useState, useEffect, useMemo } from "react";
// components
import TopFivePatterns from "components/topFivePatterns";
import BarChart from "components/charts/crypto/bar.chart";
import ProfitIfManualBeforeTPChart from 'components/charts/crypto/profitIfManualBeforeTP.chart';
import PercentOfTradeStoppedEarlyProfit from 'components/charts/percentOfTradeStoppedEarlyInProfit.chart';
// utils
import { fetchCryptoChartsData } from "utils/requests";
import { getChartsData, getCurrentTradingAccount } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { chartKeys } from "utils/constant";
import { setChartsData } from "redux/slices/chartSlices";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import { createDataObjectForCryptoChart } from "utils/helpers/chart";


const ChartMode = {
  USD: "usd",
  PERCENTAGE: "percentage",
};

function Timing() {
  const [chartMode, setChartMode] = useState(ChartMode.USD);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartsDataError, setChartsDataError] = useState(false);
  const dispatch = useDispatch();
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const chartKeysThisPage = useMemo(
    () => [
      chartKeys.PROFIT_IF_TRADE_OPEN_EARLIER_LATER,
      chartKeys.PROFIT_IF_TRADE_CLOSE_EARLIER_LATER,
      chartKeys.PROFIT_IF_TP_CLOSE_FURTHER_AWAY,
      chartKeys.PROFIT_IF_SL_CLOSER_FURTHER_AWAY,
      chartKeys.PROFIT_IF_MANUAL_STOP_BEFORE_TP,
      chartKeys.PERCENT_OF_TRADES_STOPPED_EARLY_IN_PROFIT,
    ],
    []
  );

  const chartsData = useSelector(getChartsData(chartKeysThisPage));

  useEffect(() => {
    (async function () {
      const result = await fetchCryptoChartsData(currentTradingAccount.trading_account_id, chartKeysThisPage);
      if (result.data) {
        dispatch(
          setChartsData({
            chartsData: result.data,
          })
        );
        setCurrentTimeFrame(1);
      } else {
        setChartsDataError(true);
      }
    })();
  }, [dispatch, chartKeysThisPage, currentTradingAccount]);

  if (!createIsChartsDataAvailable(chartsData, chartKeysThisPage) && !chartsDataError) {
    return <div className={styles.Subpage}>Loading Data</div>;
  }

  if (chartsDataError) {
    return (
      <div style={{ margin: "40px auto", fontWeight: 'bold', color: "#4D4D4D" }}>
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  let chartDataObjects = {};
  chartKeysThisPage.forEach(chartKey => {
    chartDataObjects[chartKey] = createDataObjectForCryptoChart(chartKey, chartsData[chartKey]);
  });


  return (
    <div className={styles.Subpage}>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns />
      </div>
      <BarChart 
        key="Profit if trade open earlier/later"
        chartTitle="Profit if trade open earlier/later"
        chartData={chartDataObjects["profit_if_trade_open_earlier_later"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_trade_open_earlier_later'].hoverTextArray} />
      <BarChart 
        key="Profit if trade close earlier/later"
        chartTitle="Profit if trade close earlier/later"
        chartData={chartDataObjects["profit_if_trade_close_earlier_later"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_trade_close_earlier_later'].hoverTextArray} />
      <BarChart 
        key="Profit if TP close/further away"
        chartTitle="Profit if TP close/further away"
        chartData={chartDataObjects["profit_if_tp_close_further_away"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_tp_close_further_away'].hoverTextArray} />
      <BarChart 
        key="Profit if SL closer/further away"
        chartTitle="Profit if SL closer/further away"
        chartData={chartDataObjects["profit_if_sl_closer_further_away"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true} 
        hoverTextArray={chartDataObjects['profit_if_sl_closer_further_away'].hoverTextArray} />
      <ProfitIfManualBeforeTPChart 
        key="Profit if manual stop before TP"
        chartTitle="Profit if manual stop before TP"
        chartData={chartDataObjects["profit_if_manual_stop_before_tp"]}
        hasTimeFrame={false}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_if_manual_stop_before_tp'].hoverTextArray} />
      <PercentOfTradeStoppedEarlyProfit 
        key="Percent of trades stopped early in profit"
        chartTitle="Percent of trades stopped early in profit"
        chartData={chartDataObjects["percent_of_trades_stopped_early_in_profit"]}
        hasTimeFrame={false}
        currentTimeFrame={'999'}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={'percentage'}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects['percent_of_trades_stopped_early_in_profit'].hoverTextArray} />
    </div>
  );
}

export default Timing;