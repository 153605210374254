import styles from "./eightPointAssessment.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useDispatch } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
import * as modalTypes from "constants/modalTypes";
import { EIGHT_POINT_ASSESSMENT } from "constants/index";
import Speedometer from "./speedometer";
import { Link, useLocation } from "react-router-dom";

function EightPointAssessment() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;
  const infoIconOnClickHandler = () => {
    return dispatch(
      openModal({
        displayedModal: modalTypes.CHART_INFO_MODAL,
        displayedModalDataKey: EIGHT_POINT_ASSESSMENT,
      })
    );
  };

  const data = [
    {
      assessmentName: "Behavioral errors",
      severityScore: 7,
    },
    {
      assessmentName: "Emotional errors",
      severityScore: 7,
    },
    {
      assessmentName: "Strategic errors",
      severityScore: 6,
    },
    {
      assessmentName: "Trade execution",
      severityScore: 9,
    },
    {
      assessmentName: "Stop accuracy",
      severityScore: 4,
    },
    {
      assessmentName: "Trade timing",
      severityScore: 9,
    },
    {
      assessmentName: "Risk Management",
      severityScore: 4,
    },
    {
      assessmentName: "Over-stimulation",
      severityScore: 7,
    },
  ];

  return (
    <div className={styles.eightPointAssessmentWrapper}>
      <div className={styles.eightPointAssessmentInfoHeader}>
        <div>Discover your trading issues</div>
        <div
          className={`${htFonts.htInfo} ${styles.InfoIcon}`}
          onClick={() => infoIconOnClickHandler()}
        ></div>
      </div>
      <div className={styles.eightPointAssessmentBody}>
        {
          data.map((data) => {
            return (
              <Speedometer key={data.assessmentName} data={data} />
            )
          })
        }
      </div>
      <div className={styles.eightPointAssessmentFooter}>
        <div className={styles.eightPointAssessmentFooterLeft}>
          <strong>Severity</strong>
          <span className={styles.GreenDot}></span>Low
          <span className={styles.RedDot}></span>High
        </div>
        <Link to={ pathname.includes('sandbox') ? "/sandbox/dashboard/trade-medic" : "/dashboard/trade-medic"}>
          <span className={htFonts.htMedbox}></span>See details
        </Link>
      </div>
    </div>
  );
}

export default EightPointAssessment;
