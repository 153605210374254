import styles from "./progressBar.module.scss";

function ProgressBar({ highPips, lowPips, executedPips }) {
  let completed = (Math.abs(lowPips) / (highPips - lowPips))*100;
  let circlePosition = (Math.abs(executedPips) / (highPips - lowPips))*100;
  
  function getCircleColor() {
    if (circlePosition === completed) {
      return "#999999";
    } 
    if (circlePosition < completed) {
      return "#C1272D";
    }  
    if (circlePosition > completed) {
      return "#009245";
    }
  };
  let circleColor = getCircleColor();

  const containerStyles = {
    height: 10,
    width: "60%",
    backgroundColor: "#56A07E",
    borderRadius: 3,
    marginLeft: 5,
    marginRight: 5,
    position: "relative"
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "#C15357",
    borderTopLeftRadius: "inherit",
    borderBottomLeftRadius: "inherit",
  };

  const labelStyles = {
    color: "white",
    position: "absolute",
    bottom: -5,
    left: `calc(${completed}% - 3.5px)`,
  };

  const executedPipsCircle = {
    height: 10,
    width: 10,
    backgroundColor: circleColor,
    borderRadius: "50%",
    border: "2px solid white",
    position: "absolute",
    bottom: -2,
    left: `calc(${circlePosition}% - 6.5px)`,
  };

  return (
    <div className={styles.progressBarContainer}>
      <div style={{color: "#C15357"}}>{lowPips}</div>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          {/* <span style={labelStyles}>|</span> */}
        </div>
        <span style={labelStyles}>|</span>
        <div style={executedPipsCircle}></div>
      </div>
      <div style={{color: "#009245"}}>+{highPips}</div>
    </div>
  );
}

export default ProgressBar;
