import styles from "./accountSelection.module.scss";
import { useState } from "react";

function SandboxAccountSelection() {
  const [isOpen, setIsOpen] = useState(false);
  const [isNightMode, setIsNightMode] = useState(false);

  const logout = () => {
    sessionStorage.clear("sandboxToken");
    window.location.reload(true);
  }

  return (
    <>
      <div className={styles.AccountSelection}>
        <div className={styles.AvatarContainer}>
          <img
            className={styles.Avatar}
            src={
              "https://hoctrade.s3.us-west-1.amazonaws.com/hoctrade_logo_for_profile.png"
            }
            alt="Avatar"
          />
        </div>
        <div className={styles.Dropdown}>
          <div className={styles.WalletIconContainer}>
            <div className={styles.htWallet} id={styles.Wallet}></div>
            <div className={styles.VerifiedIconContainer}>
              <span className={styles.VerifiedIcon}></span>
            </div>
          </div>
          <span className={styles.CurrentAccount}>
            Sandbox
          </span>
          {
            <div
              className={
                isOpen
                  ? `${styles.AccountList} ${styles.isOpen}`
                  : styles.AccountList
              }
            >
              <hr className={styles.SeparateLine} />
              <div className={styles.SwitchAccount}>Switch trade Account</div>
                <div
                  className={styles.SelectedAccountOption}
                >
                  <div className={styles.htWallet}>
                      <div className={styles.VerifiedIconContainer}>
                        <span className={styles.VerifiedIcon}></span>
                      </div>
                  </div>
                  Sandbox
                </div>
              <hr className={styles.SeparateLine} />
              <div
                className={styles.DropdownOption}
                onClick={() => setIsNightMode(!isNightMode)}
              >
                <div className={styles.htMoon}></div>
                Dark mode
                <div className={styles.switch}>
                  <input
                    type="checkbox"
                    onChange={() => console.log("toggle is night mode")}
                    checked={isNightMode}
                  />
                  <span className={`${styles.slider} ${styles.round}`}></span>
                </div>
              </div>
              <div className={styles.DropdownOption}>
                <div className={styles.htSupport}></div>
                Help & support
              </div>
              <div className={styles.Logout} onClick={() => logout()}>
                <div className={styles.htLogout}></div>
                Logout
              </div>
            </div>
          }
        </div>
        <div
          className={isOpen ? styles.CloseButton : styles.OpenButton}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <span className={styles.TriangleFlip}></span>
          ) : (
            <span className={styles.Triangle}></span>
          )}
        </div>
      </div>
      {
      isOpen?
        <div style={{ position: 'fixed', top: 0, left: 0, height: '100%', width: '100%' }}
          onClick={() => setIsOpen(false)}></div>
        : null
    }
    </>
  );
}

export default SandboxAccountSelection;
