import { chartKeys } from 'utils/constant';

const open_different_symbols_per_trade = {
  chartTitle: 'Open different symbols (per trade)',
  chartKey: chartKeys.OPEN_DIFFERENT_SYMBOLS_PER_TRADE,
  hoverTextArray: {
    'pips': [
      {
        templateString: '',
        valueKey: 'pips',
        unit: 'pips',
        // textColor: function(hoveredData) {
        //   return hoveredData.pips >= 0? "#56A07E" : "#C15357"
        // }
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        // textColor: function(hoveredData) {
        //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
        // },
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'pipsBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        // textColor: function(hoveredData) {
        //   return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        // }
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        // textColor: function(hoveredData) {
        //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
        // },
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ]
  },
  createHoverTextArray: (currency) => {
    return {
      'pips': [
        {
          templateString: '',
          valueKey: 'pips',
          unit: 'pips',
          // textColor: function(hoveredData) {
          //   return hoveredData.pips >= 0? "#56A07E" : "#C15357"
          // }
          followBarColor: true,
        },
        {
          templateString: '',
          valueKey: 'profit',
          // textColor: function(hoveredData) {
          //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
          // },
          followBarColor: true,
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'pipsBenchmark',
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        }
      ],
      'percentage': [
        {
          templateString: '',
          valueKey: 'percentage',
          unit: '%',
          // textColor: function(hoveredData) {
          //   return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
          // }
          followBarColor: true,
        },
        {
          templateString: '',
          valueKey: 'profit',
          // textColor: function(hoveredData) {
          //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
          // },
          followBarColor: true,
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'percentageBenchmark',
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        }
      ]
    }
  },
  interval: [
    '1',
    '2',
    '3',
    '4-5',
    '5-10',
    '10+'
  ],
}

export default open_different_symbols_per_trade