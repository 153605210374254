// Draw solid y=0 line
const drawSolidZeroYLine = (ctx, chartArea, pointZeroPosY) => {
  ctx.beginPath()
  ctx.moveTo(chartArea.left, pointZeroPosY);
  ctx.lineTo(chartArea.right, pointZeroPosY);
  ctx.strokeStyle = "#4D4D4D";
  ctx.lineWidth = 2;
  ctx.stroke();
}

const drawSolidYAxisZeroPlugin = () => {
  const solidYAxisZeroPlugin = {
    id: "solid_y_axis_zero_plugin",
    afterDraw: (chart) => {
      const chartArea = chart.chartArea;
      const pointZero = 0;
      const yScale = chart.scales.y;
      const pointZeroPosY = yScale.getPixelForValue(pointZero);
      const ctx = chart.ctx;

      drawSolidZeroYLine(ctx, chartArea, pointZeroPosY);
    }
  }
  return solidYAxisZeroPlugin;
}

export default drawSolidYAxisZeroPlugin;