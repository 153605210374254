import { createSlice } from '@reduxjs/toolkit';

const initialTradeHistoryState = {
  tradeHistoryData: [],
};

export const tradeHistorySlice = createSlice({
  name: 'tradeHistory',
  initialState: initialTradeHistoryState,
  reducers: {
    setTradeHistory: (state, action) => {
      return [
        ...action.payload.tradeHistoryData
      ]
    }
  },
});

export const { 
  setTradeHistory,
} = tradeHistorySlice.actions;

export const tradeHistoryReducer = tradeHistorySlice.reducer;