import styles from "./costs.module.scss";
import { useState, useEffect, useMemo } from "react";
// components
import BarChartWithEmptyTicks from "components/charts/crypto/barWithEmptyTicks.chart";
import CostComparison from "components/costComparison/costComparison";
import SpreadAndCommission from "components/charts/spreadAndCommission.chart";
import RoboForex from "components/advertisements/roboForex";
// utils
import { fetchCryptoChartsData } from "utils/requests";
import { getChartsData, getCurrentTradingAccount } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { chartKeys } from "utils/constant";
import { setChartsData } from "redux/slices/chartSlices";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import { createDataObjectForCryptoChart } from "utils/helpers/chart";

const ChartMode = {
  USD: "usd",
  PERCENTAGE: "percentage",
  NUMBER: "number",
};

function Costs() {
  const [chartMode, setChartMode] = useState(ChartMode.USD);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartsDataError, setChartsDataError] = useState(false);
  const dispatch = useDispatch();
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const chartKeysThisPage = useMemo(
    () => [
      chartKeys.SPREAD_AND_COMMISSION,
      chartKeys.PROFIT_OF_CONFLICTING_SYMBOLS,
    ],
    []
  );

  const chartsData = useSelector(getChartsData(chartKeysThisPage));

  useEffect(() => {
    (async function () {
      const result = await fetchCryptoChartsData(
        currentTradingAccount.trading_account_id,
        chartKeysThisPage
      );
      if (result.data) {
        dispatch(
          setChartsData({
            chartsData: result.data,
          })
        );
        setCurrentTimeFrame(1);
      } else {
        setChartsDataError(true);
      }
    })();
  }, [dispatch, chartKeysThisPage, currentTradingAccount]);

  if (
    !createIsChartsDataAvailable(chartsData, chartKeysThisPage) &&
    !chartsDataError
  ) {
    return <div className={styles.Subpage}>Loading Data</div>;
  }

  if (chartsDataError) {
    return (
      <div
        style={{ margin: "40px auto", fontWeight: "bold", color: "#4D4D4D" }}
      >
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  let chartDataObjects = {};
  chartKeysThisPage.forEach((chartKey) => {
    chartDataObjects[chartKey] = createDataObjectForCryptoChart(
      chartKey,
      chartsData[chartKey]
    );
  });

  return (
    <div className={styles.Subpage}>
      <SpreadAndCommission
        key="Spread & commission"
        chartTitle="Spread & commission"
        chartData={chartDataObjects["spread_and_commission"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.NUMBER}
        chartMode={ChartMode.NUMBER}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={
          chartDataObjects["spread_and_commission"].hoverTextArray
        }
      />
      <BarChartWithEmptyTicks
        key="Profit of conflicting symbols"
        chartTitle="Profit of conflicting symbols"
        chartData={chartDataObjects["profit_of_conflicting_symbols"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["profit_of_conflicting_symbols"].hoverTextArray
        }
      />
      <div className={styles.CostSection}>
        <CostComparison />
        <RoboForex />
      </div>
    </div>
  );
}

export default Costs;
