import styles from "./riskReport.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
//constants
import * as modalTypes from "constants/modalTypes";

function RiskReport({ riskReportIssues, isSandboxAccount }) {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const tradeMedicInfoOnclickHander = (e, issue) => {
    e.preventDefault();
    if (!isSandboxAccount) {
      return dispatch(
        openModal({
          displayedModal: modalTypes.TRADE_MEDIC_INFO_MODAL,
          selectedTradeMedicData: issue,
        })
      );
    } else {
      return dispatch(
        openModal({
          displayedModal: modalTypes.SANDBOX_TRADE_MEDIC_INFO_MODAL,
          selectedTradeMedicData: issue,
        })
      );
    }
  };

  return (
    <div className={styles.RiskReportSectionWrapper}>
      <div
        className={styles.SectionHeader}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className={styles.HeaderLeft}>
          <span className={htFonts.htAlertChart}></span>Risk report
        </div>{" "}
        <span className={htFonts.htAdd}></span>
      </div>
      {isDropdownOpen ? (
        <table className={styles.RiskReportTable}>
          <thead className={styles.Thead}>
            <tr>
              <td>Risk area</td>
              <td></td>
              <td className={styles.LastCell}>Further details</td>
            </tr>
          </thead>
          <tbody className={styles.TableBody}>
            {riskReportIssues.map((issue) => {
              return (
                <tr key={issue.issue_name} className={styles.TableRow}>
                  <td>
                    <strong>{issue.issue_name}</strong>
                  </td>
                  <td>
                    <div className={styles.MidCellContentContainer}>
                    {issue.medic_icon !== 0 ? (
                      <span
                        className={`${htFonts.htAlert} ${styles.WarningIcon}`}
                      ></span>
                    ) : (
                      <span
                        className={`${htFonts.htCheck} ${styles.CheckIcon}`}
                      ></span>
                    )}
                    {issue.text}</div>
                  </td>
                  <td className={styles.LastCell}>
                    <span
                      className={htFonts.htInfo}
                      onClick={(e) => tradeMedicInfoOnclickHander(e, issue)}
                    ></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </div>
  );
}

export default RiskReport;
