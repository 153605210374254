import styles from "./sandboxLogin.module.scss";
import htFonts from "shared/htFont.module.scss";
import logo from "../../assets/logo-w-text.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { sendSandboxLoginCredential } from "utils/requests";
import { useNavigate } from "react-router-dom";

function SandboxLogin({
  setSandboxAuth,
  sandboxAuth,
  errorMessage,
  setErrorMessage,
}) {
  const navigate = useNavigate();
  const [sandboxCredential, setSandboxCredential] = useState({
    key: "",
  });

  if (sandboxAuth === "AUTHENTICATED") {
    return navigate("/sandbox/dashboard/overview/account-overview");
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setSandboxAuth("PENDING");
    const result = await sendSandboxLoginCredential(sandboxCredential);
    if (result.message === "success") {
      sessionStorage.setItem("sandboxToken", result.data);
      setSandboxAuth("AUTHENTICATED");
      return navigate("/sandbox/dashboard/overview/account-overview");
    } else {
      setSandboxAuth("UNAUTHENTICATED");
      setErrorMessage(result.error);
    }
  };

  const onChangeHandler = (field, newValue) => {
    const copiedSandboxCredential = { ...sandboxCredential };
    copiedSandboxCredential[field] = newValue;
    setSandboxCredential(copiedSandboxCredential);
  };

  const submitButton = sandboxCredential.key ? (
    <div className={styles.SubmitButton} onClick={(e) => submitForm(e)}>
      Start
      <span className={`${htFonts.htLoginLogout} ${styles.LoginIcon}`}></span>
    </div>
  ) : (
    <div className={`${styles.SubmitButton} ${styles.DisabledButton}`}>
      Start
      <span className={`${htFonts.htLoginLogout} ${styles.LoginIcon}`}></span>
    </div>
  );

  return (
    <div className={styles.SandboxContainer}>
      <div className={styles.SandboxHeader}>
        <a href="https://www.hoc-trade.com/">
          <img className={styles.Logo} src={logo} alt="logo" />
        </a>
        <span className={styles.SandboxLabel}>SANDBOX</span>
      </div>
      <form className={styles.LoginBox}>
        <div className={styles.BoxTitle}>
          Welcome to hoc-trade sandbox. Please enter the KEY from the message we
          have sent through your registered email.
        </div>
        <div className={styles.InputWrapper}>
          <div className={styles.InputLabel}>Key</div>
          <input
            placeholder="Abcd123vrTH456Rv789"
            type="text"
            value={sandboxCredential.key}
            className={styles.InputBox}
            onChange={(e) => onChangeHandler("key", e.target.value)}
          />
          {errorMessage ? (
            <div className={styles.ErrorMessage}>{errorMessage}</div>
          ) : null}
          <small>
            Didn't receive the KEY?{" "}
            <Link to="/resend-sandbox-key">Click here to resend</Link>
          </small>
          {submitButton}
        </div>
      </form>
    </div>
  );
}

export default SandboxLogin;
