import Spinner from "UI/spinner";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {  getSandboxChartsData } from "redux/selectors";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import styles from './index.module.scss';
import { setSandboxChartsData } from "redux/slices/sandboxChartSlice";
import { fetchSandboxChartsData } from "utils/requests";
import { createDataObjectForChart } from "utils/helpers/chart";
import chartKeys from 'utils/constant/chartKeys';

function PageDataFetchingWrapper({ children }) {
  const chartsKeyArray = Object.values(chartKeys);
  const dispatch = useDispatch();
  const [chartsDataError, setChartsDataError] = useState(false);
  let isChartsDataFetched = true;
    
  const chartsData = useSelector(getSandboxChartsData(chartsKeyArray));
  for (let chartKey in chartsData) {
    if (chartsData[chartKey] === null) {
      isChartsDataFetched = false;
    }
  }

  useEffect(() => {
    if (isChartsDataFetched === false) {
      (async function () {
        const result = await fetchSandboxChartsData(chartsKeyArray);
        if (result.data) {
          dispatch(
            setSandboxChartsData({
              chartsData: result.data,
            })
          );
        } else {
          setChartsDataError(true);
        }
      })();
    }
  }, [dispatch, isChartsDataFetched, chartsKeyArray]);

  if (!createIsChartsDataAvailable(chartsData, chartsKeyArray) && !chartsDataError) {
    return (
      <div className={styles.Subpage}>
        <div style={{position: "absolute", top: '50%', left: '50%', transform: "translate(-50%, -50%)"}}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (chartsDataError) {
    return (
      <div style={{ margin: "40px auto", fontWeight: 'bold', color: "#4D4D4D" }}>
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  if (isChartsDataFetched) {
    let chartDataObjects = {};
    chartsKeyArray.forEach(chartKey => {
      chartDataObjects[chartKey] = createDataObjectForChart(chartKey, chartsData[chartKey]);
    });

    return (
      <div className={styles.Subpage}>
        {React.cloneElement(children, { chartKeysOfThePage: chartsKeyArray })}
      </div>
    );
  }
}

export default PageDataFetchingWrapper;