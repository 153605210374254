import createDiagonalPattern from "./createDiagonalPattern";

function createBarColor(chartData, currentTimeFrame) {
  const intervals = chartData.interval;
  if (!chartData[currentTimeFrame].data) return;
  const sortedData = intervals
    .map((interval) => {
      return chartData[currentTimeFrame].data[interval];
    })
    .sort((a, b) => a.percentage - b.percentage);
  const [lowestData, secondLowestData] = [sortedData[0], sortedData[1]];
  
  const barColor = intervals.map((interval) => {
    let color;
    const intervalData = chartData[currentTimeFrame].data[interval];
    if (intervalData.percentage === lowestData.percentage) {
      color = "#56A07E";
    } else if (intervalData.percentage === secondLowestData.percentage) {
      color = "#56A07E";
    } else {
      color = "#C15357";
    }

    const tradeCount = intervalData.trades;
    if (tradeCount) {
      if (tradeCount < 5) {
        return createDiagonalPattern("#CCCCCC");
      } else if (tradeCount > 20) {
        return color;
      } else {
        return createDiagonalPattern(color);
      }
    } else {
      return color;
    }
    
  });
  return barColor;
}

export default createBarColor;
