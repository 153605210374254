const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1+10, y);
  ctx.lineTo(x2-10, y);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawOnHoverText = (ctx, hoveredDataPoint, chartData, barValueKey) => {
  let label = hoveredDataPoint.label;
  let value = hoveredDataPoint.raw.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Measure text width
  let textStringWidth = 0;
  let textString = `Symbol: ${label} Share: ${value}%`;
  textStringWidth += ctx.measureText(textString).width;

  // Get start point
  const chartArea = hoveredDataPoint.chart.chartArea;
  const centerX = (chartArea.right + chartArea.left) / 2 -20;
  let currentX = centerX - textStringWidth / 2;
  
  ctx.save();
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "#FFFFFF";
  ctx.fillText("Symbol: ", currentX, 25);
  currentX += ctx.measureText("Symbol: ").width;
  ctx.font = "700 12px sans-serif";
  ctx.fillText(label, currentX, 25);
  currentX += (ctx.measureText(label).width + 20);
  ctx.font = "12px sans-serif";
  ctx.fillText("Share: ", currentX, 25);
  currentX += ctx.measureText("Share: ").width;
  ctx.font = "700 12px sans-serif";
  ctx.fillText(value+"%", currentX, 25);
}

const createHoverTextPlugin = (chartData, barValueKey) => {
  const hoverPlugin = {
    id: "performance_hover_text_plugin",
    beforeDraw: (chart) => {
      // disable original tooltips
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;

      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;

        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          0,
          "#00333B"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          10,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          30,
          "#06AFC9"
        );

        drawOnHoverText(ctx, tooltip.dataPoints[0], chartData, barValueKey);
      }
    }
  }
  return hoverPlugin
}

export default createHoverTextPlugin;