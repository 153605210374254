const getResponsiveTradeMedicChartsData = (chartKeys) => {

  return (state) => {
    if (chartKeys === "currentTradingAccount") {
      return state.responsiveTradeMedicChartsData.currentTradingAccount
    };

    let chartsData = {};
    chartKeys.forEach(chartKey => {
      chartsData[chartKey] = state.responsiveTradeMedicChartsData[chartKey]
    });
    return chartsData;
  };
}

export default getResponsiveTradeMedicChartsData;