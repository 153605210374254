import { createSlice } from '@reduxjs/toolkit';

const initialTradeMedicState = [];

export const tradeMedicSlice = createSlice({
  name: 'tradeMedicData',
  initialState: initialTradeMedicState,
  reducers: {
    setTradeMedicData: (state, action) => {
      return [
        // ...state,
        ...action.payload.tradeMedicData
      ]
    }
  },
});

export const { 
  setTradeMedicData
} = tradeMedicSlice.actions;

export const tradeMedicReducer = tradeMedicSlice.reducer;