import { chartKeys } from 'utils/constant';

const percentage_of_position_with_sl = {
  chartTitle: "% of positions with SL",
  chartKey: chartKeys.PERCENTAGE_OF_POSITION_WITH_SL,
  hoverTextArray: {
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        textColor: function (hoveredData) {
          return hoveredData.percentage > hoveredData.percentageAvg
            ? "#56A07E"
            : "#C15357";
        },
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
    ],
  },
  interval: ["1M", "3M", "6M", "1Y"],
  percentageAvg: 74,
}

export default percentage_of_position_with_sl;