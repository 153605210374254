import { chartKeys } from 'utils/constant';

const profit_by_symbol_per_trade = {
  chartTitle: "Profit By Symbol (per trade)",
  chartKey: chartKeys.PROFIT_BY_SYMBOL_PER_TRADE,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit:'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },

    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: 'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ]
  },
  createIntervals: (chartData, currentTimeFrame) => {
    const sortedIntervalData = Object
      .keys(chartData[currentTimeFrame].data)
      .map(interval => chartData[currentTimeFrame].data[interval])
      .sort((a, b) => b.pips - a.pips)

    const intervals = sortedIntervalData.map((intervalData) => intervalData.interval);
    return intervals;
  }
  // interval: [
  //   'CADCHF',
  //   "EURUSD",
  //   "GBPUSD",
  //   "USDCHF",
  //   "USDJPY"
  // ],
}

export default profit_by_symbol_per_trade;