import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartHeader from "../../../charts/chartHeader";
import styles from "./drawdownChart.module.scss";
// import options from "./drawdown.options";
import createOptionsWithAnnotations from "./createOptionsWithAnnotations";
// plugin
import createHoverTextPlugin from "../drawdownHover";
import xAxisTopPlugin from "./xAxisTop.plugin";
import createBackgroundPlugin from "../../../charts/plugins/createBackground.plugin";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Drawdown(props) {
  const { chartData } = props;
  const optionsWithAnnotations = createOptionsWithAnnotations(chartData[999])
  const hoverTextPlugin = createHoverTextPlugin();
  const backgroundPlugin = createBackgroundPlugin(true);
  
  let allIntervals = chartData[999].map((data) => {
    let time = typeof data.time === 'string'? new Date(data.time) : data.time;
    let timeOffsetInMillSecs = (time.getTimezoneOffset())*60*1000;
    let timestamp = (time.getTime());
    let clientTime = new Date(timestamp+timeOffsetInMillSecs);
    
    return clientTime;
  })

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval.getMonth() === 0) {
      return interval = interval.toLocaleString("default", { year: "numeric" })
    }
    
    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return interval = interval.toLocaleString("default", { month: "short" });
      } else {
        showInterval = true;
        return "";
      }
    }
    return interval;
  });

  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: chartData[999].map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data.percentage
          }
        }),
        backgroundColor: "#C15357",
        borderColor: "#333333",
      }
    ]
  }

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader
        chartTitle={chartData.chartTitle}
        chartKey="drawdown"
      />
      <div className={styles.Chart}>
        <Bar 
          key={Math.random()}
          data={data}
          options={optionsWithAnnotations}
          plugins={[
            backgroundPlugin,
            xAxisTopPlugin,
            hoverTextPlugin
          ]}
        />
      </div>
    </div>
  );
}

export default Drawdown;
