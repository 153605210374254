import GeneralInfo from "components/overview/accountOverview/generalInfo";
import TradesPatternChart from "components/overview/accountOverview/tradesPattern/tradesPattern.chart";
import Donuts from "components/overview/accountOverview/tradesPattern/donuts.chart";
import ProgressBars from "components/overview/accountOverview/tradesPattern/progressBars";
import PerformanceOverTime from "components/overview/accountOverview/performanceOverTime/performanceOverTime";
import Drawdown from "components/overview/accountOverview/drawdown/drawdown.chart";
import Carousel from "components/overview/accountOverview/carousel/carousel";
import EToroBanner from "components/advertisements/eToro";
import ZuluBanner from "components/advertisements/zulu";
import RoboForex from "components/advertisements/roboForex";
import Alert from "components/overview/accountOverview/alerts/alert";
import CostComparison from "components/costComparison/costComparison";
import ChartHeader from "components/charts/chartHeader";
import EightPointAssessment from "components/overview/accountOverview/eightPointAssessment/eightPointAssessment";
//styling
import styles from "./accountOverview.module.scss";
// import htFonts from "shared/htFont.module.scss";
import blogData from "devData/blogData/blogData";
import ChartNoDataWrapper from "components/charts/chartNoDataWrapper";

function AccountOverview({ chartDataObjects }) {
  return (
    <div className={styles.overviewWrapper}>
      <>
        <div className={styles.leftColumn}>
          <GeneralInfo 
            key="General Info"
            data={chartDataObjects["general_info"]}
          />
          <EightPointAssessment />
          <div className={styles.tradesPatternContainer}>
            <div className={styles.tradesPatternHeader}>
              <ChartHeader         
                chartTitle={"Trades made in profit/loss making patterns"} 
                chartKey={'trades_made_in_profit_loss_making_patterns'}
              />
            </div>
            <div className={styles.tradesPatternBody}>
              <TradesPatternChart
                key="Trades pattern line chart"
                chartData={chartDataObjects["trades_in_patterns_timeview"]}
                hasTimeFrame={true}
                dualMode={true}
              />
              <div className={styles.tradesPatternBodyRight}>
                <Donuts
                  key="Trades pattern donut charts"
                  chartData={chartDataObjects["trades_in_patterns_count"]}
                />
                <div className={styles.progressBarsWrapper}>
                  <ProgressBars
                    key="Trades pattern profit bar chart"
                    data={chartDataObjects["top_5_patterns_overall"]}
                    fontColor="#56A07E"
                    backgroundColor="#1E4B42"
                    hoverColor="#009245"
                    type="profit"
                  />
                  <ProgressBars
                    key="Trades pattern loss bar chart"
                    data={chartDataObjects["top_5_patterns_overall"]}
                    fontColor="#C15357"
                    backgroundColor="#443035"
                    hoverColor="#C1272D"
                    type="loss"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.chartsWrapper}>
            <ChartNoDataWrapper chartData={chartDataObjects['performance_over_time']}>
              <PerformanceOverTime
                key="Performance Over Time"
              />
            </ChartNoDataWrapper>
            <ChartNoDataWrapper chartData={chartDataObjects['drawdown_balance']}>
              <Drawdown key="Drawdown (balance)" />
            </ChartNoDataWrapper>
          </div>
          <EToroBanner />
          <Carousel data={blogData}/>
          <ZuluBanner />
        </div>
        <div className={styles.rightColumn}>
          <Alert />
          <div>
            <CostComparison />
            <RoboForex />
          </div>
        </div>
      </>
    </div>
  );
}

export default AccountOverview;
