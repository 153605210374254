import htFont from 'shared/htFont.module.scss';
import styles from './index.module.scss';
import { useState } from 'react';

function InputWithDynamicLabel({
  inputTitle,
  value,
  onChangeHandler,
  type,
  placeholder
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={styles.InputWrapper}>
      <div>
      {
        value?
          <div className={styles.InputLabel}>{inputTitle}</div> : null
      }
      {
        type === "PASSWORD"?
          showPassword?
            <input 
              placeholder={placeholder}
              type="text"
              className={value? styles.ActualInput : styles.InputTemplate }
              value={value} 
              onChange={(e) => onChangeHandler(e.target.value)} /> :
            <input 
              placeholder={placeholder}
              type="password"
              className={value? styles.ActualInput : styles.InputTemplate }
              value={value} 
              onChange={(e) => onChangeHandler(e.target.value)} />
        : <input 
            placeholder={placeholder}
            type="text"
            className={value? styles.ActualInput : styles.InputTemplate }
            value={value} 
            onChange={(e) => onChangeHandler(e.target.value)} />
      }
      </div>
      {
        type === "PASSWORD"? 
          showPassword?
            <span 
              onClick={() => setShowPassword(false)}
              className={`${htFont.htEyeOpened} ${styles.ActiveIcon}`}></span> 
          : <span 
              onClick={() => setShowPassword(true)}
              className={`${htFont.htEyeClosed} ${styles.Icon}`}></span>
        : null
      }
    </div>
  )
}

export default InputWithDynamicLabel;