const alertsAreaMap = {
  profit_by_daytime_per_trade: "general",
  long_and_short_per_trade: "general",
  profit_by_symbol_per_trade: "general",
  profit_by_trade_length_per_trade: "general",
  profit_by_weekday_per_trade: "general",
  trades_per_day_per_trade: "behavior",
  break_since_last_trade_per_trade: "behavior",
  break_after_loss_trade_per_trade: "behavior",
  open_different_symbols_per_trade: "behavior",
  open_position_in_profit_loss: "behavior",
  profit_after_daily_profit_loss: "behavior",
  profit_if_trade_open_earlier_later: "timing",
  profit_if_trade_close_earlier_later: "timing",
  profit_if_tp_closer_further_away: "timing",
  profit_if_sl_closer_further_away: "timing",
  profit_if_manual_stop_before_tp: "timing",
  percent_of_trades_stopped_early_in_profit: "timing",
  risk_after_losing_trades_per_trade: "risk",
  risk_after_losing_trades_open_trade: "risk",
  profit_if_adding_to_negative_positions: "risk",
  profit_secure_payoff_from_reverting_trades: "risk",
  risk_per_trade_last_trades: "risk",
  max_risk_all_open_trades_last_trades: "risk",
  percentage_of_position_with_sl: "risk",
  profit_high_vs_low_volatility: "strategy",
  profit_with_against_st_mt_trend: "strategy",
  profit_when_sl_adjusted_vs_profit_when_not: "strategy",
  profit_if_publishing_trades: "strategy",
  profit_after_withdrawal_in_pips: "strategy",
  average_payoff_win_vs_loss_trade: "strategy",
  profit_for_trade_during_overbought_oversold: "strategy",
  spread_and_commission: "cost",
  profit_of_conflicting_symbols: "cost"
}

export default alertsAreaMap;