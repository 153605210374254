import createBarColor from "components/charts/helpers/createBarColor";
import createHoverBarColor from "components/charts/helpers/createHoverBarColor";
import createBarBorderColor from "components/charts/helpers/createBarBorderColor";
import createHoverBarBorderColor from "components/charts/helpers/createHoverBarBorderColor";
import { chartKeys } from 'utils/constant';

const risk_after_losing_trades_open_trade = {
  chartTitle: "Risk after losing trade(s) (open trades)",
  chartKey: chartKeys.RISK_AFTER_LOSING_TRADES_OPEN_TRADE,
  createBarColor: createBarColor,
  createHoverBarColor: createHoverBarColor,
  createBarBorderColor: createBarBorderColor,
  createHoverBarBorderColor: createHoverBarBorderColor,
  hoverTextArray: {
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        // textColor: function(hoveredData) {
        //   return hoveredData.profit >= 0? "#56A07E" : "#C15357"
        // },
        followBarColor: true,
        unit: "USD"
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "benchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
  },
  haspercentageBenchmark: true,
  interval: ["1", "2", "3", "4-5", "6-10", "10+"],
}

export default risk_after_losing_trades_open_trade;