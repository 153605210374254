import { Link } from "react-router-dom";
import styles from "./sidebarHoverMenu.module.scss";
import fonts from "shared/htFont.module.scss";
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import "./sidebar.css";

/**
 * 
 * @param {object} props 
 * @param {array} props.items
 * @param {object} props.items[0]
 * @param {string} props.items[0].label
 * @param {string} props.items[0].path
 */
function SidebarHoverMenu(props) {
  const location = useLocation();

  const { menuTitle, items } = props;
  return (
    <CSSTransition 
      mountOnEnter
      unmountOnExit
      in={Boolean(props.menuTitle)} timeout={600} classNames="hover-menu">
      <div className={styles.HoverMenu}>
        <div className={styles.MenuTitle}>{menuTitle}</div>
        {
          items.map(item => (
            <div 
              className={
                location.pathname === item.path? 
                `${styles.ItemContainer} ${styles.Selected}` : `${styles.ItemContainer}`
              } key={item.path}>
              <Link to={item.path} key={item.path}>
                <div 
                  className={`${fonts[item.iconClassName]} ${styles.ItemIcon}`}></div>
                <div className={styles.Label}>{item.label}</div>
              </Link>
            </div>
          ))
        }
      </div>
    </CSSTransition>
  )
}

export default SidebarHoverMenu;