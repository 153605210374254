import { useState } from "react";
import BarChartWithEmptyTicks from "components/charts/barWithEmptyTicks.chart";
import SpreadAndCommission from "components/charts/spreadAndCommission.chart";
import SandboxCostComparison from "components/sandbox/sandboxCostComparison";
import RoboForex from "components/advertisements/roboForex";
import styles from "../../../dashboard/analysis/general.module.scss";
// utils
import { createDataObjectForChart } from "utils/helpers/chart";
import { ChartMode } from "utils/constant/index";
import { getSandboxChartsData } from "redux/selectors";
import { useSelector } from "react-redux";

function SandboxCosts({ chartKeysOfThePage, currentTradingAccount }) {
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const chartsData = useSelector(getSandboxChartsData(chartKeysOfThePage));
  let chartDataObjects = {};
  chartKeysOfThePage.forEach((chartKey) => {
    chartDataObjects[chartKey] = createDataObjectForChart(
      chartKey,
      chartsData[chartKey]
    );
  });

  return (
    <>
      <SpreadAndCommission
        key="Spread & commission"
        chartTitle="Spread & commission"
        chartData={chartDataObjects["spread_and_commission"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.NUMBER}
        chartMode={ChartMode.NUMBER}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={
          chartDataObjects["spread_and_commission"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChartWithEmptyTicks
        key="Profit of conflicting symbols"
        chartTitle="Profit of conflicting symbols"
        chartData={chartDataObjects["profit_of_conflicting_symbols"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["profit_of_conflicting_symbols"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <div className={styles.CostSection}>
        <SandboxCostComparison />
        <RoboForex />
      </div>
    </>
  )
}

export default SandboxCosts;