const bestWorstTrades = {
  chartTitle: "Best/worst trades",
  999: {
    bestTrades: [
      {
        time: new Date("2022-04"),
        pips: 33.1,
        percentage: 2.1,
      },
      {
        time: new Date("2022-05"),
        pips: 35.5,
        percentage: 2.3,
      },
      {
        time: new Date("2022-06"),
        pips: 88.3,
        percentage: 5.8,
      },
      {
        time: new Date("2022-07"),
        pips: 41.4,
        percentage: 2.7,
      },
      {
        time: new Date("2022-08"),
        pips: 64.3,
        percentage: 5.3,
      },
      {
        time: new Date("2022-09"),
        pips: 43.9,
        percentage: 2.9,
      },
      {
        time: new Date("2022-10"),
        pips: 63.9,
        percentage: 4.3,
      },
      {
        time: new Date("2022-11"),
        pips: 72.4,
        percentage: 4.9,
      },
      {
        time: new Date("2022-12"),
        pips: 38.4,
        percentage: 2.6,
      },
      {
        time: new Date("2023-01"),
        pips: 66.4,
        percentage: 4.4,
      },
      {
        time: new Date("2023-02"),
        pips: 48.5,
        percentage: 3.2,
      },
      {
        time: new Date("2023-03"),
        pips: 39.4,
        percentage: 2.7,
      },
      {
        time: new Date("2023-04"),
        pips: 98.5,
        percentage: 6.6,
      },
    ],
    worstTrades: [
      {
        time: new Date("2022-04"),
        pips: -28.1,
        percentage: -1.9,
      },
      {
        time: new Date("2022-05"),
        pips: -201.3,
        percentage: -9.4,
      },
      {
        time: new Date("2022-06"),
        pips: -51.3,
        percentage: -3.4,
      },
      {
        time: new Date("2022-07"),
        pips: -38.5,
        percentage: -2.6,
      },
      {
        time: new Date("2022-08"),
        pips: -55.8,
        percentage: -3.7,
      },
      {
        time: new Date("2022-09"),
        pips: -29.5,
        percentage: -1.9,
      },
      {
        time: new Date("2022-10"),
        pips: -53.9,
        percentage: -3.5,
      },
      {
        time: new Date("2022-11"),
        pips: -48.5,
        percentage: -3.2,
      },
      {
        time: new Date("2022-12"),
        pips: -75.5,
        percentage: -5.1,
      },
      {
        time: new Date("2023-01"),
        pips: -39.4,
        percentage: -2.7,
      },
      {
        time: new Date("2023-02"),
        pips: -28.4,
        percentage: -1.9,
      },
      {
        time: new Date("2023-03"),
        pips: -33.5,
        percentage: -2.3,
      },
      {
        time: new Date("2023-04"),
        pips: -29.7,
        percentage: -2,
      },
    ],
  },
};


export default bestWorstTrades;