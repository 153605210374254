import styles from "../costComparison/costComparison.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'redux/slices/modalSlice';
//utils
import { getSandboxChartsData, getSelectedBroker } from "redux/selectors";
//constants
import detailArrow from "assets/arrow-detail.svg";
//constants
import * as modalTypes from "constants/modalTypes";

function SandboxCostComparison() {
  const logoSvgs = require.context(
    "../../assets/landingPage/ht-broker-logos",
    true,
    /\.svg$/
  );
  const allSvgFilepaths = logoSvgs.keys();
  const logos = allSvgFilepaths.map((path) => logoSvgs(path));
  const dispatch = useDispatch();
  const selectedBroker = useSelector(getSelectedBroker);
  const chartsData = useSelector(
    getSandboxChartsData(["spread_and_commission_comparison"])
  );
  const spreadCommissionComparisonData =
    chartsData["spread_and_commission_comparison"];

  if (
    spreadCommissionComparisonData === "user_no_data" ||
    !spreadCommissionComparisonData
  ) {
    return (
      <div className={styles.ComparisonSection}>
        <div className={styles.CostSection}>
          <div className={styles.InfoBlock}>
            <strong>Cost comparison</strong>
          </div>
          <div className={styles.Description}>No Available Data.</div>
        </div>
      </div>
    );
  }

  const compareBrokerOnClickHandler = (broker, brokersData) => {
    const copiedSelectedBroker = [...selectedBroker];
    copiedSelectedBroker.push(broker);
    return dispatch(
      openModal({
        displayedModal: modalTypes.SANDBOX_COMMISSION_COMPARISON_MODAL,
        selectedBroker: copiedSelectedBroker,
        sortedBrokersData: brokersData,
      })
    );
  };

  let brokerComparisonSections = null;
  let brokersData = [];
  if (spreadCommissionComparisonData && spreadCommissionComparisonData !== 'user_no_data') {
    const brokers = spreadCommissionComparisonData.brokers;
    brokers.forEach((broker) => {
      let brokerData = {};
      brokerData.key = broker;
      brokerData.Yearly = spreadCommissionComparisonData[999].data['Yearly'][broker].number;
      brokerData.Monthly = spreadCommissionComparisonData[999].data['Monthly'][broker].number;
      brokerData.saveYearly = spreadCommissionComparisonData[999].data['Yearly'][broker].save;
      brokerData.saveMonthly = spreadCommissionComparisonData[999].data['Monthly'][broker].save;
      brokerData.diffYearly = spreadCommissionComparisonData[999].data['Yearly'][broker].diff;
      brokerData.diffMonthly = spreadCommissionComparisonData[999].data['Monthly'][broker].diff;
      brokerData.name = spreadCommissionComparisonData[999].data['Yearly'][broker].name;
      brokersData.push(brokerData);
    });
    brokersData.sort((a, b) => a.Yearly - b.Yearly);
    brokerComparisonSections = brokersData.map((brokerData, index) => {
      return (
        <div
          key={brokerData.key}
          className={styles.BrokerSection}
          id={styles[`broker-${index}`]}>
          <div className={styles.BlockLeft}>
            <img className={styles.LogoImg} src={logos.filter(logoPath => logoPath.includes(brokerData.key.toLowerCase()))[0]} alt="broker's logo" />
            {
              brokerData.name
            }
          </div>
          <div className={styles.BlockRight}>
            <div className={styles.SavePercentage}>
              <div className={styles.SaveTitle}>
                Save
              </div>
              <div className={styles.SavedNumber}>
                {
                  `${brokerData.diffYearly.toFixed(2)}%`
                }
              </div>
            </div>
            <button
              className={styles.btn}
              onClick={() =>
                compareBrokerOnClickHandler(brokerData.key, brokersData)
              }
            >
              Compare <img src={detailArrow} alt="arrow img" />
            </button>
          </div>
        </div>
      );
    });
  }

  const savingMessage = brokersData[0]?.saveYearly > 0?
    `You can save up ${brokersData[0].saveYearly.toFixed(2)} USD yearly on spreads and commission.` :
    "Congratulations, you are using the cheapest broker!"

  return (
    <div className={styles.ComparisonSection}>
      <div className={styles.CostSection}>
        <div className={styles.InfoBlock}>
          <strong>Cost comparison</strong>
        </div>
        <div className={styles.Description}>
          <strong>Make a comparison now!</strong> <br />
          <br />
          {savingMessage}
        </div>
        {brokerComparisonSections}
      </div>
    </div>
  );
}

export default SandboxCostComparison;
