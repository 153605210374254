import createDiagonalPattern from "./createDiagonalPattern";

function createGeneralBarColor(
  chartData,
  currentTimeFrame,
  hasTimeFrame,
  chartMode
) {
  const interval = chartData.interval?? Object.keys(chartData[currentTimeFrame].data);
  const generalBarColor = interval.map((interval) => {
    if (hasTimeFrame && currentTimeFrame) {
      const tradeCount = chartData[currentTimeFrame].data[interval]?.trades;
      if (tradeCount < 5) {
        return createDiagonalPattern("#CCCCCC");
      } else if (tradeCount > 20) {
        return chartData[currentTimeFrame].data[interval][chartMode] >= 0
          ? "#56A07E"
          : "#C15357";
      } else {
        if (chartData[currentTimeFrame].data[interval]) {
          return chartData[currentTimeFrame].data?.[interval][chartMode] >= 0
            ? createDiagonalPattern("#56A07E")
            : createDiagonalPattern("#C15357");
        }

      }
    } else {
      return chartData[999].data[interval][chartMode] > 0
        ? "#56A07E"
        : "#C15357";
    }
  });
  return generalBarColor;
}

export default createGeneralBarColor;
