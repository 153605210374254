import './App.scss';
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "components/protectedRoute/protectedRoute";
import Dashboard from "pages/dashboard/dashboard.page";
import GeneralLayout from "UI/layout/generalLayout";
import ModalManager from 'components/modalManager';
import { useDispatch, useSelector } from 'react-redux';
import { getAllChartsInfoModalContent } from 'redux/slices/modalSlice';
import chartsInfoModalContent from 'devData/chartsInfoModalContent';
import Landing from 'pages/index.js';
import { useEffect } from 'react';
import { authenticationStates } from 'utils/constant';
import { setAuthenticationState, setCurrentTradingAccount, setUser } from 'redux/slices/authenticationSlice';
import { sendAuthRequest } from 'utils/requests';
import { createSocketConnection, connectedSocket } from 'utils/socket';
import { fetchChartsData } from "utils/requests";
import { getCurrentTradingAccount, getUser, getAuthenticationState } from "redux/selectors";
import { setChartsData } from "redux/slices/chartSlices";
import ErrorBoundary from './ErrorBoundary';
import useInitFbSDK from "utils/initFacebookSDK";
import Login from 'pages/loginAndRegister';
import Sandbox from 'pages/sandbox/sandbox';
import ResendSandboxKey from 'pages/sandbox/resendSandboxKey';
import TradeMedicResponsive from 'pages/tradeMedicResponsive';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

/**
 * 
 */
async function useInit(dispatch, currentTradingAccount) {
  useEffect(() => {
    (async function() {
      if (currentTradingAccount) {
        const result = await fetchChartsData(currentTradingAccount.trading_account_id, ['spread_and_commission_comparison']);
        dispatch(setChartsData({
          chartsData: result.data,
        }));
      }
    })();

  }, [currentTradingAccount, dispatch])

  await setTimeout(() => {
    dispatch(getAllChartsInfoModalContent(chartsInfoModalContent));
  }, 1000)
}

function useAuth() {
  const dispatch = useDispatch();
  useEffect(() => {
    (async function() {
      const result = await sendAuthRequest();
      if (result.message === "UNAUTHENTICATED" || result.message === "EMAIL_NOT_VERIFIED") {
        dispatch(setAuthenticationState({
          authenticationState: authenticationStates.UNAUTHENTICATED
        }));
      } 
      if (result.message === "AUTHENTICATED") {
        // await createSocketConnection(result.data.user_id, dispatch);
        dispatch(setAuthenticationState({
          authenticationState: authenticationStates.AUTHENTICATED
        }));
        dispatch(setUser({
          user: result.data
        }));
        if (result.data.trading_accounts.length) {
          dispatch(setCurrentTradingAccount({
            currentTradingAccount: result.data.trading_accounts[0]
          }));
        }
      }
    })();
  }, [dispatch])
}

/* 
App level will identity if the user is authenticated and authorized.
It contains Dashboard (including all authorized pages) and pages that don't need authorization.
*/
function App() {
  const dispatch = useDispatch();
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  // const alertsData = useSelector(getAllAlertsData);
  useInit(dispatch, currentTradingAccount);
  useAuth();
  useInitFbSDK();

  const [ authenticationState, user ] = [ useSelector(getAuthenticationState), useSelector(getUser)];

  useEffect(() => {
    if (authenticationState === authenticationStates.AUTHENTICATED && user && currentTradingAccount && !connectedSocket) {
      createSocketConnection(user.user_id, currentTradingAccount?.trading_account_id, dispatch);
    }
    if (authenticationState === authenticationState.AUTHENTICATED && user) {
      
    }
  }, [authenticationState, user, dispatch, currentTradingAccount]);

  // testing for future trading account change, not sure 
  // const changeTradingAccount = () => {
  //   connectedSocket.emit('tradingAccountChange', { tradingAccountId: '123'})
  // }

  return (
    <ErrorBoundary>
      <div className="App">
        <ModalManager />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Login />} />
          <Route path="/reset-password" element={<Login />} />
          <Route path="/sandbox/*" element={<Sandbox />} />
          <Route path="/resend-sandbox-key" element={<ResendSandboxKey />} />
          <Route path="/dashboard/*" element={
            <ProtectedRoute>
              <GeneralLayout>
                <Dashboard />
              </GeneralLayout>
            </ProtectedRoute>
          } />
          <Route path="/trade-medic-responsive/*" element={<TradeMedicResponsive />} />
          <Route path="/*" element={
              <Navigate to="/" replace/>
            } />
        </Routes>
      </div>
    </ErrorBoundary>
  );
}

export default App;