import styles from "./alert.module.scss";
import htFonts from "shared/htFont.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllAlertsData } from "redux/selectors";
import { useDispatch } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
import { ALERT_INFO_MODAL } from 'constants/modalTypes';

import AlertCard from "./alertCard";

function Alert() {
  let allAlertsData = useSelector(getAllAlertsData);
  const dispatch = useDispatch();
  
  const alertsDataToBeDisplayed = allAlertsData.slice(
    allAlertsData.length - 3,
    allAlertsData.length
  );

  let allAlertsDataElements = [];
  for (let i = 0; i < alertsDataToBeDisplayed.length; i++) {
    allAlertsDataElements.push(
      alertsDataToBeDisplayed[
        alertsDataToBeDisplayed.length - (i + 1)
      ]
    )
  }

  const infoIconOnClickHandler = () => {
    dispatch(openModal({
      displayedModal: ALERT_INFO_MODAL
    }));
  }

  return (
    <div className={styles.AlertContainer}>
      <div className={styles.AlertHeader}>
        <div>Alert</div>
        <div className={styles.AlertHeaderRight}>
          <div
            className={`${htFonts.htInfo} ${styles.InfoIcon}`}
            onClick={() => infoIconOnClickHandler()}>
          </div>
          <Link to="/dashboard/alert">
            <div className={styles.ViewAllBtn}>
              View all
              <span className={htFonts.htArrow}></span>
            </div>
          </Link>
        </div>
      </div>
      {
        allAlertsDataElements.map((data, index) => {
          if (index < 3) {
            return <AlertCard cardData={data} key={data.alertId} index={index} id={`styles.${index}`}/>
          } else {
            return null
          }
        })
      }
    </div>
  );
}

export default Alert;
