import { chartKeys } from 'utils/constant';

const average_trade_length = {
  chartTitle: 'Average Trade Length',
  chartKey: chartKeys.AVERAGE_TRADE_LENGTH,
  hoverTextArray: [
    {
      templateString: "Time:",
      valueKey: "percentage",
      textColor: function (hoveredData) {
        return hoveredData.percentage >= 0 ? "#56A07E" : "#C15357";
      },
    },
    {
      templateString: "Duration: ",
      valueKey: "trades",
    },
    {
      templateString: "Trades: ",
      valueKey: "trades",
    },
  ],
}

export default average_trade_length;