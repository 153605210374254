const getChartsData = (chartKeys) => {

  return (state) => {
    let chartsData = {};
    chartKeys.forEach(chartKey => {
      chartsData[chartKey] = state.chart[chartKey]
    });
    return chartsData;
  }
}

export default getChartsData;
