import { chartKeys } from 'utils/constant';

const spread_and_commission = {
  chartTitle: "Spread & Commission",
  chartKey: chartKeys.SPREAD_AND_COMMISSION,
  hoverTextArray: {
    number: [
      {
        templateString: '',
        valueKey: 'number',
        unit: 'USD',
      },
    ],
  },
  interval: [
    'Yearly',
    'Monthly',
  ],
}

export default spread_and_commission;