import { useState, useRef, useEffect } from "react";
import styles from "./barChart.module.scss";
import MUITooltip from '@mui/material/Tooltip';
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modalSlice';
//styling
import htFonts from "shared/htFont.module.scss";
//constants
import * as modalTypes from 'constants/modalTypes';

function ChartHeader(props) {
  const {
    chartTitle,
    chartKey
  } = props;
  const dispatch = useDispatch();
  const [bookmarked, setBookmarked] = useState(false);
  const [alerted, setAlerted] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [showTooltipOnHover, setShowTooltipOnHover] = useState(false);

  const textElementRef = useRef();

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => window.removeEventListener('resize', compareSize);
  }, []);

  function compareSize() {
    const compare = textElementRef.current.scrollHeight > textElementRef.current.clientHeight;
    setShowTooltipOnHover(compare);
  }

  const infoIconOnClickHandler = () => {
    return dispatch(openModal({
      displayedModal: modalTypes.CHART_INFO_MODAL,
      displayedModalDataKey: chartKey
    }));
  }

  return (
    <div className={styles.ChartHeader}>
      <div className={styles.ChartTitleContainer}>
        <MUITooltip 
          title={chartTitle}
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: 'Noto Sans',
                backgroundColor: '#047F92',
                '& .MuiTooltip-arrow': {
                  color: '#047F92'
                },
              },
            }
          }}
          disableHoverListener={!showTooltipOnHover}>
          <div className={styles.ChartTitle} ref={textElementRef}>
            {chartTitle}
          </div>
        </MUITooltip>
      </div>
      <div className={styles.MinorIconsContainer}>
        {/* <MUITooltip 
          title="Bookmark" 
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: 'Noto Sans',
                backgroundColor: '#047F92',
                '& .MuiTooltip-arrow': {
                  color: '#047F92'
                },
              },
            }
          }}>
          <div 
            className={
              bookmarked? 
              `${htFonts.htStar} ${styles.TitleIcon} ${styles.Selected}` :
              `${htFonts.htStar} ${styles.TitleIcon}`
            }
            onClick={() => setBookmarked(!bookmarked)}>
          </div>
        </MUITooltip>
        <MUITooltip 
          title="Alert" 
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: 'Noto Sans',
                backgroundColor: '#047F92',
                '& .MuiTooltip-arrow': {
                  color: '#047F92'
                },
              },
            }
          }}>
          <div 
            className={
              alerted?
              `${htFonts.htBell} ${styles.TitleIcon} ${styles.Selected}` :
              `${htFonts.htBell} ${styles.TitleIcon}`
            }
            onClick={() => setAlerted(!alerted)}>
          </div>
        </MUITooltip>
        <MUITooltip 
          title="Public" 
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: 'Noto Sans',
                backgroundColor: '#047F92',
                '& .MuiTooltip-arrow': {
                  color: '#047F92'
                },
              },
            }
          }}>
          <div 
            className={
              isPublic? 
              `${htFonts.htGlobe} ${styles.TitleIcon} ${styles.Selected}` :
              `${htFonts.htGlobe} ${styles.TitleIcon}`
            }
            onClick={() => setIsPublic(!isPublic)}>
          </div>
        </MUITooltip> */}
        <div
          className={styles.IconContainer}
          style={{ gridColumn: '4/5'}}>
          <div className={styles.WhiteBackgroundCircle}></div>
            <MUITooltip 
              title="Information" 
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 12,
                    fontFamily: 'Noto Sans',
                    backgroundColor: '#047F92',
                    '& .MuiTooltip-arrow': {
                      color: '#047F92'
                    },
                  },
                }
              }}>
              <div 
                className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
                id={styles.InfoIcon}
                onClick={() => infoIconOnClickHandler()}>
              </div>
            </MUITooltip>
        </div>
      </div>
    </div>
  )
};

export default ChartHeader;