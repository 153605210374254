let tradeHistory = [];
for (let i = 0; i<365; i++) {
  let openDate = new Date();
  let closeDate = new Date();
  openDate.setDate(openDate.getDate() - i);
  closeDate.setDate(closeDate.getDate() - i+1);
  let order = Math.floor(Math.random() * 9000000000) + 1000000000;

  // options for time formate
  let options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hourCycle: "h24"
  }
  let formattedOpenData = openDate.toLocaleString("default", options);
  let formattedCloseDate = closeDate.toLocaleString("default", options);
  let highPips = Math.floor(Math.random()*50);
  let lowPips = -(Math.floor(Math.random()*10));
  let executedPricePips = Math.floor(Math.random() * (highPips - lowPips + 1)) + lowPips;

  tradeHistory[i] = {
    order: order,
    openDate: formattedOpenData,
    closeDate: formattedCloseDate,
    tradeLength: "24h0m0s",
    symbol: "EUR/USE",
    action: "sell",
    lots: 0.05,
    priceOpen: 1.23564,
    priceClose: 1.23859,
    highPips: highPips,
    lowPips: lowPips,
    executedPips: executedPricePips,
    commission: 0.35,
    swap: 0,
    profitUSD: 4.23,
    profitPips: 41
  }
}

export default tradeHistory;