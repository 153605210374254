import "./carousel.css";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import nextBtn from "../../../../assets/next.svg";
import prevBtn from "../../../../assets/prev.svg";

export default function Carousel(props) {
  const [sliderRef, setSliderRef] = useState(null);

  const { data } = props;
  let settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <img
        src={prevBtn}
        alt="Previous Button"
        className="left-btn"
        onClick={sliderRef?.slickPrev}
      />
      <Slider {...settings} ref={setSliderRef}>
        {data.map((data, index) => {
          return (
            <a href={`https://hoc-trade.com/blogs/${data.id}`} target="_blank" rel="noreferrer" key={data.id}>
              <div className="card" key={index}>
                <div className="card-top">
                  <img
                    src={data.coverPhoto}
                    alt="blog"
                    className="card-image"
                  />
                </div>
                <div className="card-bottom">
                  <div className="card-title">{data.title}</div>
                </div>
              </div>
            </a>
          );
        })}
      </Slider>
      <img
        src={nextBtn}
        alt="Next Button"
        className="right-btn"
        onClick={sliderRef?.slickNext}
      />
    </div>
  );
}
