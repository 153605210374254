import { useEffect, useRef, useState } from "react";
import MUITooltip from '@mui/material/Tooltip';
import { useDispatch } from "react-redux";
import { openModal } from 'redux/slices/modalSlice';

import styles from "./topFivePatterns.module.scss";
import htFonts from "shared/htFont.module.scss";
import icon from "../assets/top5Icon.png";

//constants
import * as modalTypes from 'constants/modalTypes';

//components
import MobileTopFivePatternsBars from "./mobileTopFivePatterns/mobileTopFivePatternsBars";

function TopFivePatterns({ chartData }) {
  const data = chartData[12]?.data;
  const listRef = useRef([]);
  const textRef = useRef([]);
  const dispatch = useDispatch();
  const [listRefState, setListRefState] = useState([]);
  const [textRefState, setTextRefState] = useState([]);
  const [hoveredBar, setHoveredBar] = useState(null);
  const barRef = useRef({
    textRefHeights: null,
    listRefHeights: null
  });

  useEffect(() => {
    setListRefState(listRef.current);
    setTextRefState(textRef.current);
    // barRef.current.textRefHeights = textRef.current.map((div) => div.clientHeight);
    // barRef.current.listRefHeights = listRef.current.map((div) => div.clientHeight);
  }, [listRef, textRef]);

  useEffect(() => {
    barRef.current.textRefHeights = textRef.current.map((div) => div.clientHeight);
    barRef.current.listRefHeights = listRef.current.map((div) => div.clientHeight);
  }, [])
  
  function textHigherThanBar(index) {
    const { textRefHeights, listRefHeights } = barRef.current;
    if (!textRefHeights[index] || !listRefHeights[index]) {
      return false;
    }
    return textRefHeights[index] > listRefHeights[index];
  }

  let bars = [];

  if (data === "no_user_data" || !data) {
    bars = (
      <div className={styles.NoDataAvailable}>
        Not sufficient data available yet
      </div>
    );
  } else {
    if (listRefState.length && textRefState.length) {
      bars = data.map((barData, index) => {
        const barStyle = {
          height: `${barData.value}px`
        };
        if (textHigherThanBar(index)) {
          const textBoxStyle = {
            marginTop: `${- ( textRef.current[index].clientHeight + 2 )}px`,
            color: '#00333B',
            textAlign: barData.type === 'profit'? 'left' : 'right',
            fontWeight: 600
          };
          const hoveredTextBoxStyle = {
            color: 'white',
            backgroundColor: barData.type === 'profit'? '#009245' : '#93272D',
            top: barData.value,
            display: hoveredBar === index? 'block' : 'none',
            textAlign: barData.type === 'profit'? 'left' : 'right',
            borderRadius: barData.type === 'profit'? '0 10px 10px 10px' : '10px 0 10px 10px',
            right: barData.type === 'profit'? null : 0
          };
          return (
            <li 
              className={`${styles.TopFiveBar} ${styles[barData.type]}`} 
              key={barData.text} 
              style={barStyle}
              ref={el => listRef.current[index] = el}
              onMouseEnter={() => setHoveredBar(index)}
              onMouseLeave={() => setHoveredBar(null)}>
              <div 
                className={styles.BarText}
                ref={el => textRef.current[index] = el}
                style={textBoxStyle}>
                {barData.text}
              </div>
              <div
                className={
                  hoveredBar === index? 
                  `${styles.HoveredBarText} ${styles.IsHovered}` :
                  `${styles.HoveredBarText}`
                }
                style={hoveredTextBoxStyle}>
                {/* <img src={icon} alt="icon"/> */}
                <div className={styles.hoverTextArray}>
                  <img src={icon} alt="icon" />
                  {
                    barData.hoveredBarText.map(text =>
                      <div key={text} className={styles.HoveredBarTextLIne}>{text}</div>
                    )
                  }
                </div>
              </div>
            </li>
          );
        } else {
          const textBoxStyle = {
            color: 'white',
            fontWeight: '600',
            margin: '5px 5px',
            textAlign: barData.type === 'profit'? 'left' : 'right'
          };
          const hoveredTextBoxStyle = {
            color: 'white',
            backgroundColor: barData.type === 'profit'? '#009245' : '#93272D',
            top: barData.value,
            textAlign: barData.type === 'profit'? 'left' : 'right',
            borderRadius: barData.type === 'profit'? '0 10px 10px 10px' : '10px 0 10px 10px',
            right: barData.type === 'profit'? null : 0,
          };
          return (
            <li 
              className={`${styles.TopFiveBar} ${styles[barData.type]}`} 
              key={barData.text} 
              style={barStyle}
              ref={el => listRef.current[index] = el}
              onMouseEnter={() => setHoveredBar(index)}
              onMouseLeave={() => setHoveredBar(null)}>
              <div 
                className={styles.BarText}
                ref={el => textRef.current[index] = el} 
                style={textBoxStyle}>
                {barData.text}
              </div>
              <div 
                className={
                  hoveredBar === index? 
                  `${styles.HoveredBarText} ${styles.IsHovered}` :
                  `${styles.HoveredBarText}`
                }
                style={hoveredTextBoxStyle}>
                <div className={styles.hoverTextArray}>
                  <img src={icon} alt="icon" />
                  {
                    barData.hoveredBarText.map(text =>
                      <div key={text} className={styles.HoveredBarTextLIne}>{text}</div>
                    )
                  }
                </div>
              </div>
            </li>
          );
        };
      })
    } else {
      bars = data.map((barData, index) => {
        const barStyle = {
          height: `${barData.value}px`,
          opacity: 0
        };

        const textBoxStyle = {
          margin: '5px',
          color: '#00333B',
          textAlign: barData.type === 'profit'? 'left' : 'right',
          fontWeight: 600
        };
        return (
          <li 
            className={`${styles.TopFiveBar} ${styles[barData.type]}`} 
            key={barData.text} 
            style={barStyle}
            ref={el => listRef.current[index] = el}>
            <div ref={el => textRef.current[index] = el} style={textBoxStyle}>
              {barData.text}
            </div>
          </li>
        );
        }
      );
    }
  }



  return (
    <div className={styles.TopFiveChartBox}>
      <div className={styles.TopFiveChartHeader}>
        <div className={styles.TopFiveChartTitle}>Top 5 profit making and loss making patterns</div>
        <div className={styles.IconContainer}>
          <div className={styles.WhiteBackgroundCircle}></div>
          <MUITooltip 
          title="Information" 
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: 'Noto Sans',
                backgroundColor: '#047F92',
                '& .MuiTooltip-arrow': {
                  color: '#047F92'
                },
              },
            }
          }}>
            <div 
              className={`${htFonts.htInfo} ${styles.Icon}`}
              onClick={() => {
                dispatch(openModal({
                  displayedModal: modalTypes.CHART_INFO_MODAL,
                  displayedModalDataKey: 'top-five-patterns'
                }))
              }}>
            </div>
          </MUITooltip>
        </div>
      </div>
      <div className={styles.TopFiveChartBody}>
        <ul className={styles.BarsContainer}>
          {bars}
        </ul>
        <div className={styles.MobileBarsContainer}>
          <MobileTopFivePatternsBars data={data}/>
        </div>
      </div>
    </div>
  )
}

export default TopFivePatterns;