import createBaseBarChartOptions from "../percentageSymbolBarChart.options";

function createBarChartOptions(chartData, chartMode) {
  const baseBarChartOptions = createBaseBarChartOptions();
  if (chartData.benchmarks) {
    const annotations = {};
    const benchmarks = chartData.benchmarks;
    const interval = chartData.interval;
    interval.forEach((key, index) => {
      annotations[`line-${index}`] = {
        type: "line",
        yMin: benchmarks[key][chartMode],
        yMax: benchmarks[key][chartMode],
        xMin: (ctx) => {
          const chart = ctx.chart;
          const barElements = chart._metasets[0].data;
          const barElement = barElements[index];
          const gridSpace = barElements[1].x - barElements[0].x;
          if (barElement.x && barElement.width) {
            const perc = barElements[index].width / gridSpace;
            return index - perc / 2;
          }
        },
        xMax: (ctx) => {
          const chart = ctx.chart;
          const barElements = chart._metasets[0].data;
          const barElement = barElements[index];
          const gridSpace = barElements[1].x - barElements[0].x;
          if (barElement.x && barElement.width) {
            const perc = barElements[index].width / gridSpace;
            return index + perc / 2;
          }
        }, 
        borderColor: "#FFB700",
        borderWidth: 1
      };
      annotations[`point-${index}`] = {
        type: "point",
        xValue: index,
        yValue: benchmarks[key][chartMode],
        backgroundColor: "#FFCD00",
        borderColor: "#FFB700",
        radius: 2
      };
    });
    baseBarChartOptions.plugins.annotation = {
      annotations
    };
  } else {
    baseBarChartOptions.plugins.annotation = {};
  }

  return baseBarChartOptions;
}

export default createBarChartOptions;