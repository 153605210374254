import { useState } from "react";
import { Link } from 'react-router-dom';
import MUITooltip from "@mui/material/Tooltip";

const chartLinkMap = {
  "profit_by_daytime_per_trade": "/dashboard/analysis/general",
  "long_and_short_per_trade": "/dashboard/analysis/general",
  "profit_by_symbol_per_trade": "/dashboard/analysis/general",
  "profit_by_trade_length_per_trade": "/dashboard/analysis/general",
  "profit_by_weekday_per_trade": "/dashboard/analysis/general",
  "trades_per_day_per_trade": "/dashboard/analysis/behavioral",
  "break_since_last_trade_per_trade": "/dashboard/analysis/behavioral",
  "break_after_loss_trade_per_trade": "/dashboard/analysis/behavioral",
  "open_different_symbols_per_trade": "/dashboard/analysis/behavioral",
  "open_position_in_profit_loss": "/dashboard/analysis/behavioral",
  "profit_after_daily_profit_loss": "/dashboard/analysis/behavioral",
  "profit_if_trade_open_earlier_later": "/dashboard/analysis/timing",
  "profit_if_trade_close_earlier_later": "/dashboard/analysis/timing",
  "profit_if_tp_closer_further_away": "/dashboard/analysis/timing",
  "profit_if_sl_closer_further_away": "/dashboard/analysis/timing",
  "profit_if_adding_to_negative_positions": "DCA into loss (#trades open)",
  "profit_high_vs_low_volatility": "/dashboard/analysis/strategic",
  "profit_with_against_st_mt_trend": "/dashboard/analysis/strategic",
  "profit_if_publishing_trades": "/dashboard/analysis/strategic",
  "profit_after_withdrawal_in_pips": "/dashboard/analysis/strategic",
  "profit_for_trade_during_overbought_oversold": "/dashboard/analysis/strategic"
};

const ProgressBar = (props) => {
  const { data } = props;
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#001216",
    borderRadius: 5,
    marginBottom: 5,
    position: 'relative',
  };

  const fillerStyles = {
    width: `${data.value}%`,
    height: 20,
    backgroundColor: isHover ? data.hoverColor : data.backgroundColor,
    borderRadius: "inherit",
    textAlign: "left",
    transition: "all 0.3s ease",
    key: data.key,
  };

  const labelStyles = {
    fontSize: 12,
    position: "absolute",
    marginTop: -20,
    color: isHover ? "#FFFFFF" : data.fontColor,
    cursor: "pointer",
    transition: "all 0.3s ease",
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textAlign: 'left',
    paddingLeft: 5,
    boxSizing: 'border-box'
  };

  let linkPath;
  
  switch (data.key) {
    case "Break since last trade":
      linkPath = "/dashboard/analysis/behavioral";
      break;
    case "Profit by Trade Length":
      linkPath = "/dashboard/analysis/general";
      break;
    case "Trades per Day":
      linkPath = "/dashboard/analysis/behavioral";
      break;
    case "Profit by weekday":
      linkPath = "/dashboard/analysis/general";
      break;
    case "Profit after daily profit/ loss":
      linkPath = "/dashboard/analysis/behavioral";
      break;
    case "Break after loss trade":
      linkPath = "/dashboard/analysis/behavioral";
      break;
    case "Open positions in profit/ loss":
      linkPath = "/dashboard/analysis/behavioral";
      break;
    default:
      linkPath = "/dashboard/overview/account-overview";
  }

  return (
    <MUITooltip 
      title={`${data.hoveredBarText[0]} ${data.hoveredBarText[1]}`}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: 12,
            fontFamily: 'Noto Sans',
            backgroundColor: '#047F92',
            '& .MuiTooltip-arrow': {
              color: '#047F92'
            },
          },
        }
      }}
      >
      <Link to={chartLinkMap[data.chartKey]}>
        <div
        style={containerStyles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
          <div style={fillerStyles}></div>
          <div style={labelStyles}>{data.text}</div>
      </div>
      </Link>
    </MUITooltip>
  );
};

export default ProgressBar;
