import Spinner from 'UI/spinner';
import styles from './connectingSection.module.scss';

export default function connectingSection() {
  return (
    <div className={styles.Section}>
      <div className={styles.SectionTitle}>connecting...</div>
      <div className={styles.SectionDescription}>
        Please wait while we are connecting your trading account to hoc-trade. It takes a few seconds.
      </div>
      <Spinner />
    </div>
  )
}