import styles from "./issueCard.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useDispatch } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
//constants
import * as modalTypes from "constants/modalTypes";

function IssueCard({ criticalIssue, index, isSandboxAccount }) {
  const dispatch = useDispatch();

  const tradeMedicInfoOnclickHandler = (e, criticalIssue) => {
    e.preventDefault();

    if (!isSandboxAccount) {
      return dispatch(
        openModal({
          displayedModal: modalTypes.RESPONSIVE_TRADE_MEDIC_INFO_MODAL,
          selectedTradeMedicData: criticalIssue
        })
      )
    }
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className={styles.IssueCardWrapper}>
      <div className={styles.IssueCardTitle}>
        {index + 1}. {criticalIssue.issue_name}
      </div>
      <div className={styles.IssueCardContent}>
        <div className={styles.SeverityText}>
          Severity: {Math.round(criticalIssue.severity)}%
        </div>
        <div className={styles.SeverityBar}>
          <div
            className={styles.ServityBarDot}
            style={{ right: `calc(100% - ${criticalIssue.severity}%)` }}
          ></div>
        </div>
        <div className={styles.PerformanceEffectText}>Performance effect</div>
        <div className={styles.PerformanceEffectValue}>
          {numberWithCommas(criticalIssue.performance_effect.toFixed(2))} USD
        </div>
        <div className={styles.DetailButton} onClick={(e) => tradeMedicInfoOnclickHandler(e, criticalIssue)}>
          <span className={htFonts.htInfo}></span>Further details
        </div>
      </div>
    </div>
  );
}

export default IssueCard;