import { Link, useLocation } from "react-router-dom";
import styles from "./navbar.module.scss";
import SandboxMobileAccountSelection from "./mobileSandboxAccountSelection";
import SandboxAccountSelection from "./sandboxAccountSelection";
import SandboxNotificationButton from "./sandboxNotificationButton";

function SandboxNavbar() {
  const location = useLocation();
  const firstLayer = location.pathname.split("/")[1];

  const links = [
    {
      to: "sandbox/dashboard",
      text: "Dashboard"
    },
    // PROD TODO: ENABLE IT IN THE FUTURE
    // {
    //   to: "scoreboard",
    //   text: "Scoreboard"
    // }
  ];

  return (
    <nav className={styles.Navbar}>
      <div className={styles.Logo}>
        <span className={styles.htLogo}></span>
      </div>
      <div className={styles.Links}>
        {
          links.map(link => 
            <Link to={`/${link.to}`} key={link.text}>
              <div className={firstLayer === link.to? styles.SelectedLink: styles.Link}>
                {link.text}
              </div>
            </Link>  
          )
        }
      </div>
      <div className={styles.RightMenu}>
        <SandboxAccountSelection />
        <SandboxNotificationButton />
        <SandboxMobileAccountSelection />
        {
          // TODO: enable this in the future version
        }
        {/* <div>
          <div className={styles.SearchButton}>
            <span className={styles.htSearch}></span>
          </div>
        </div>
        <div>
          <div className={styles.BellButton}>
            <span className={styles.htBell}></span>
            <div className={styles.NotificationNumber}>5</div>
          </div>
        </div> */}
      </div>
    </nav>
  )
}

export default SandboxNavbar;