import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import styles from "./balance.module.scss";
import ChartHeader from "../../../charts/chartHeader";
import balanceOptions from "./balance.options";
import getGradient from "./getGradient";
// plugins
import xAxisPlugin from "../../accountOverview/xAxis.plugin";
import createBackgroundPlugin from "components/charts/plugins/createBackground.plugin";
import createOnHoverTextPlugin from "./onHoverText.plugin";
import drawSolidYAxisZeroPlugin from "./drawSolidYaxisZero.plugin";


ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

function Balance(props) {
  const { chartData } = props;
  const backgroundPlugin = createBackgroundPlugin(true);
  const hoverTextPlugin = createOnHoverTextPlugin(chartData);
  const solidYAxisZeroPlugin = drawSolidYAxisZeroPlugin();

  let lastDayData = chartData[999][chartData[999].length - 1];
  let scoreBoardLabelColor =
    lastDayData.equityChange >= 0 ? "#009245" : "#C15357";

  let allIntervals = chartData[999].map((data) => {
    let time = data.time;
    if (typeof time === 'string') {
      time = new Date(time);
    }
    let timeOffsetInMillSecs = time.getTimezoneOffset() * 60 * 1000;
    let timestamp = time.getTime();
    let clientTime = new Date(timestamp + timeOffsetInMillSecs);

    return clientTime;
  });

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval.getMonth() === 0) {
      return (interval = interval.toLocaleString("default", {
        year: "numeric",
      }));
    }

    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return (interval = interval.toLocaleString("default", {
          month: "short",
        }));
      } else {
        showInterval = true;
        return "";
      }
    }
    return interval;
  });

  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        type: "line",
        label: "Dataset 1",
        data: chartData[999].map((data) => {
          return data.equity;
        }),
        borderColor: "#047F92",
        borderWidth: 1,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea, "4, 127, 146");
        },
        fill: "origin",
        yAxisID: "y",
      },
      {
        type: "bar",
        label: "Dataset 2",
        data: chartData[999].map((data) => {
          return data.depositWithdrawal;
        }),
        backgroundColor: chartData[999].map((data) => {
          if (data.depositWithdrawal > 0) {
            return "#56A07E";
          } else {
            return "#C15357";
          }
        }),
        borderColor: chartData[999].map((data) => {
          if (data.depositWithdrawal > 0) {
            return "#56A07E";
          } else {
            return "#C15357";
          }
        }),
        borderWidth: 0,
        borderRadius: 5,
        maxBarThickness: 5,
      },
      // feed data to y1
      {
        type: "line",
        label: "Dataset 1",
        data: chartData[999].map((data) => {
          return data.equity;
        }),
        // borderWidth: 2,
        // fill: false,
        yAxisID: "y1",
      },
    ],
  };

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader
        chartTitle={chartData.chartTitle}
        chartKey="balance_currency"
      />
      <div className={styles.Chart}>
        <Chart
          type="bar"
          data={data}
          options={balanceOptions}
          plugins={[xAxisPlugin, backgroundPlugin, hoverTextPlugin, solidYAxisZeroPlugin]}
        />
      </div>
      <div className={styles.Scoreboard}>
        <div className={styles.Left}>
          <div className={styles.Title}>Equity</div>
          <div className={styles.Equity}>{lastDayData.equity.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD</div>
          <div
            className={styles.EquityChange}
            style={{ backgroundColor: scoreBoardLabelColor }}
          >
            {lastDayData.equityChange.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD
          </div>
        </div>
        <div className={styles.Right}>
          <div className={styles.Title}>Age</div>
          <div className={styles.Duration}>{lastDayData.duration}</div>
        </div>
      </div>
    </div>
  );
}

export default Balance;
