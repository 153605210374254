const performancePipsOptions = {
  animation: {
    duration: 0,
  },
  interaction: {
    mode: "x",
    intersect: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 0,
  pointRadius: 0,
  pointHoverRadius: 0,
  plugins: {
    legend: {
      display: false,
    },
    // tooltip: false,
  },
  layout: {
    padding: {
      bottom: 10,
      left: 10,
      top: 50,
    },
  },
  scales: {
    y: {
      position: "left",
      grid: {
        color: (context) => {
          return "#4D4D4D";
        },
        borderDash: (a) => {
          return [1, 2];
        },
        drawBorder: false,
        tickLength: 0,
        tickBorderDash: (a) => {
          return [1, 2];
        },
      },
      ticks: {
        color: "#999999",
        font: {
          size: 10,
          family: "Arial",
          weight: 700,
        },
        labelOffset: -7,
        padding: 0,
        mirror: true,
        // callback: function (value, index, ticks) {
        //   if (value === 0) {
        //     return value;
        //   } else if (index === ticks.length - 1) {
        //     return value+"%";
        //   } else if (index === 0) {
        //     return value+"%";
        //   } else {
        //     return "";
        //   }
        // },
      },
    },
    y1: {
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        color: "#999999",
        font: {
          size: 10,
          family: "Arial",
          weight: 700,
        },
        labelOffset: -7,
        padding: 0,
        mirror: true,
        // callback: function (value, index, ticks) {
        //   if (value === 0) {
        //     return value;
        //   } else if (index === ticks.length - 1) {
        //     return value+"%";
        //   } else if (index === 0) {
        //     return value+"%";
        //   } else {
        //     return "";
        //   }
        // },
      },
    },
    x: {
      grid: {
        offset: false,
        color: "#4D4D4D",
        borderDash: [1, 2],
        borderColor: "#4D4D4D",
        borderWidth: 1,
        tickColor: "#4D4D4D",
        tickLength: 4,
      },
      ticks: {
        padding: 0,
        color: "#999999",
        font: {
          size: 10,
        },
      },
    },
  },
};

export default performancePipsOptions;