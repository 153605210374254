import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartHeader from "../chartHeader";

//styling
import styles from "./barChart.module.scss";
//plugins
import createBackgroundPlugin from "../plugins/createBackground.plugin";
import createOnHoverPlugin from "./plugins/hover.plugin";
import xAxisButtonPlugin from "../plugins/xAxisTicks.plugin";
import yZeroAxisPlugin from "../plugins/yZeroAxis.plugin";
// import createBarChartOptions from './barChart.options';
//helpers
import createBarChartOptionsEmptyTicks from "../helpers/createBarChartOptionsEmptyTicks";
import createGeneralBarBorderColor from "../helpers/createGeneralBarBorderColor";
import createGeneralBarColor from "../helpers/createGeneralBarColor";
import createGeneralHoverBorderColor from "../helpers/createGeneralHoverBorderColor";
import createGeneralHoverBarColor from "../helpers/createGeneralHoverBarColor";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

/**
 *
 * @param {object} props
 * @property {boolean} dualMode default: false, if true will display pips button and % button for switching
 * @property {boolean} hasTimeFrame
 *
 */

const ChartMode = {
  USD: "usd",
  PERCENTAGE: "percentage",
};

function BarChartWithEmptyTicks(props) {
  const {
    dualMode,
    chartTitle,
    chartData,
    hasTimeFrame,
    hoverTextArray,
    chartMode,
    setChartMode,
    currentTimeFrame,
    setCurrentTimeFrame,
  } = props;
  const backgroundPlugin = createBackgroundPlugin(true);
  const barColor = chartData.createBarColorComparison? 
    currentTimeFrame? 
      chartData.createBarColorComparison(chartData, currentTimeFrame, chartMode)
    : null : null;
  const barBorderColor = chartData.createBarBorderColorComparison? 
    currentTimeFrame? 
    chartData.createBarBorderColorComparison(chartData, currentTimeFrame, chartMode)
    : null : null;
  const hoverBarColor = chartData.createHoverBarColorComparison? 
    currentTimeFrame?
    chartData.createHoverBarColorComparison(chartData, currentTimeFrame, chartMode)
    : null : null;
  const generalBarBorderColor = createGeneralBarBorderColor(
    chartData,
    currentTimeFrame,
    hasTimeFrame,
    chartMode
  );
  const generalBarColor = createGeneralBarColor(
    chartData,
    currentTimeFrame,
    hasTimeFrame,
    chartMode
  );
  const generalHoverBarColor = createGeneralHoverBarColor(
    chartData,
    currentTimeFrame,
    hasTimeFrame,
    chartMode
  );
  const generalHoverBorderColor = createGeneralHoverBorderColor(
    chartData,
    currentTimeFrame,
    hasTimeFrame,
    chartMode
  );

  const hoverTextPlugin = createOnHoverPlugin(hoverTextArray[chartMode]);
  const intervals = chartData.interval?? Object.keys(chartData[currentTimeFrame].data);
  const labelsArray = intervals.map((interval) => {
    return interval
  })

  labelsArray.unshift("");
  labelsArray.push("");

  const data = {
    labels: labelsArray,
    datasets: [
      {
        data: intervals.map((interval) => {
          if (hasTimeFrame && currentTimeFrame) {
            return {
              x: interval,
              ...chartData[currentTimeFrame].data[interval],
            };
          } else {
            return {
              x: interval,
              ...chartData[999].data[interval],
            };
          }
        }),
        borderWidth: 1,
        borderColor: barBorderColor ? barBorderColor : generalBarBorderColor,
        backgroundColor: barColor ? barColor : generalBarColor,
        maxBarThickness: 40,
        borderRadius: 5,
        minBarLength: 1,
        hoverBackgroundColor: hoverBarColor
          ? hoverBarColor
          : generalHoverBarColor,
        hoverBorderColor: hoverBarColor
          ? hoverBarColor
          : generalHoverBorderColor,
        parsing: {
          yAxisKey: chartMode,
        },
      },
    ],
  };

  return (
    <div className={styles.Chart}>
      <ChartHeader chartTitle={chartTitle} chartKey={chartData.chartKey} />
      <div className={styles.ChartButtonsContainer}>
        {dualMode ? (
          <div className={styles.ModeButtonsContainer}>
            <div
              className={
                chartMode === ChartMode.USD
                  ? `${styles.DualModeButton} ${styles.Selected}`
                  : styles.DualModeButton
              }
              id={styles.Pips}
              onClick={() => setChartMode(ChartMode.USD)}
            >
              USD
            </div>
            <div
              className={
                chartMode === ChartMode.PERCENTAGE
                  ? `${styles.DualModeButton} ${styles.Selected}`
                  : styles.DualModeButton
              }
              id={styles.Percentage}
              onClick={() => setChartMode(ChartMode.PERCENTAGE)}
            >
              %
            </div>
          </div>
        ) : null}
        {hasTimeFrame ? (
          <div className={styles.TimeButtonsContainer}>
            <div
              className={
                currentTimeFrame === 1
                  ? `${styles.TimeButton} ${styles.Selected}`
                  : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(1)}
            >
              1m
            </div>
            <div
              className={
                currentTimeFrame === 3
                  ? `${styles.TimeButton} ${styles.Selected}`
                  : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(3)}
            >
              3m
            </div>
            <div
              className={
                currentTimeFrame === 12
                  ? `${styles.TimeButton} ${styles.Selected}`
                  : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(12)}
            >
              1y
            </div>
            <div
              className={
                currentTimeFrame === 999
                  ? `${styles.TimeButton} ${styles.Selected}`
                  : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(999)}
            >
              all
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.ChartContainer}>
        <Bar
          key={Math.random()}
          options={
            createBarChartOptionsEmptyTicks(chartData, chartMode)
            // barChartOptions
          }
          data={data}
          plugins={[
            backgroundPlugin,
            xAxisButtonPlugin,
            yZeroAxisPlugin,
            hoverTextPlugin,
          ]}
        />
      </div>
    </div>
  );
}

export default BarChartWithEmptyTicks;
