import styles from "./searchBar.module.scss";
import searchIcon from "../../assets/tradeHistory/searchIcon.png";

function SearchBar({ searchKeyword, setSearchKeyword, setPage, page, selectedPeriodData }) {
  
  const handleSubmit = (e) => {
    e.preventDefault();

    for (let i = 0; i < selectedPeriodData.length; i++) {
      if (selectedPeriodData[i].order.toString() === searchKeyword) {
        let quo = Math.floor(i/10);

        setPage(quo+1);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.searchBarContainer}>
        <input
            type="text"
            id="search-by-order-id"
            placeholder="Search by order ID"
            onChange={e => setSearchKeyword(e.target.value)}
        />
        <button type="submit">
          <img src={searchIcon} alt="search icon" />
        </button>
    </form>
  )
}

export default SearchBar;