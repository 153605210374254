const chartsInfoModalContent = {
  // general page
  profit_by_daytime_per_trade: {
    legends: [
      "benchmark",
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
    ],
    chartTitle: "Profit By Daytime",
    chartDescription:
      "The average payoff (PIPS or % return) per trade in case the trades the Stop Loss (SL) would have been a few pips closer for further away from compared to the actual SL level set in the trade.",
    youtubeVideoId: "jHkX5l14KZA",
  },
  profit_by_symbol_per_trade: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant"],
    chartTitle: "Profit by symbol",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) by symbol traded. Hoc-trade covers a wide range of symbols, however it may be the case that a symbol you are trading is not covered and hence would not show in the analysis. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category.",
    youtubeVideoId: "q2XCX9nshYA",
  },
  long_and_short_per_trade: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant", "benchmark"],
    chartTitle: "Long & Short (per trade)",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) of buy (long) and sell (short) trades. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category.",
    youtubeVideoId: "tqQV74nKk60",
  },
  profit_by_trade_length_per_trade: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant", "benchmark"],
    chartTitle: "Profit By Trade Length (Per trade)",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) depending how long the trade is. The categories will be pre-specified depending on the trading style (for scalpers, the categories cover a shorter duration, while for daytraders and swing traders there are longer durations presented. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category.",
    youtubeVideoId: "RhI0RNPWlTQ",
  },
  profit_by_weekday_per_trade: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant", "benchmark"],
    chartTitle: "Profit by weekday (per trade)",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) by the day of the week. The opening time of the trade is used to classify the trade to the day of the week. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category, and weekday is based on time zone: UTC +0.",
    youtubeVideoId: "8DndWRRf3tw",
  },
  // behavior page
  trades_per_day_per_trade: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Trades per day (per trade)",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) depending on how many trades per day have been performed by the trader. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)",
    youtubeVideoId: "Jk_piKsx51k",
  },
  break_since_last_trade_per_trade: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Break since last trade",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) depending on how many trades per day have been performed by the trader. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)",
    youtubeVideoId: "HVvdNJrabIQ",
  },
  break_after_loss_trade_per_trade: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Break after loss trade (per trade)",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) per trade depending on how long the break has been since the last trade has been closed. The trade is only counted for the analysis in case the last trade has been a loss trade and the trades are classified into the different categories based on the time period between closing of the last loss trade and opening time of the new trade. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)",
    youtubeVideoId: "k6wLrJ8MCsY",
  },
  open_different_symbols_per_trade: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Open different symbols (per trade)",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) depending on how many different symbols have been open at the time when entering the trade. Multiple trades in the same symbol do only count as one symbol. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)",
    youtubeVideoId: "Le7-nXxpoKA",
  },
  open_position_in_profit_loss: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Open Position In Profit/Loss",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) depending the current open profit/ loss in other trades at the time of trade opening. In case the trader had 2 open positions when entering a new trade, and both positions were 1.5% in profit each, the new trade would be classified in the categtory 2-4%. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)",
    youtubeVideoId: "v57l1L3jGiY",
  },
  profit_after_daily_profit_loss: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Profit After Daily Profit/Loss",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) depending on the profit/ loss the trader has accumulated during that day so for (only closed positions). In case the trader had closed already 2 positions when entering a new trade, and both positions were 1.5% in profit each, the new trade would be classified in the categtory 2-5%. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)",
    youtubeVideoId: "o-VVYfiIcrE",
  },
  // risk page
  risk_after_losing_trades_per_trade: {
    legends: [
      "lowerRisk",
      "higherRisk",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Risk after losing trade(s) (per trade)",
    chartDescription:
      "The analysis shows the risk per trade (measured through the loss in case SL is hit) depending on the amount of consecutive loss trades at the time of trade opening. In case no SL is set in the trade, the trade will not be counted for the analysis. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "Vj3SDQmNnFI",
  },
  risk_after_losing_trades_open_trade: {
    legends: [
      "lowerRisk",
      "higherRisk",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Risk after losing trade(s) (all open trades)",
    chartDescription:
      "The analysis shows the risk per trade (measured through the loss in case SL is hit) depending on the amount of open trades currently in loss at the time of trade opening. In case no SL is set in the trade, the trade will not be counted for the analysis. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "Vj3SDQmNnFI",
  },
  profit_if_adding_to_negative_positions: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant"],
    chartTitle: "Profit if adding to negative position(s)",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) per trade depending on the number of open positions currently in loss at the time of trade opening. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "w6pOcRuQCEY",
  },
  profit_secure_payoff_from_reverting_trades: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant"],
    chartTitle: "Profit secure, payoff from reverting trades",
    chartDescription:
      "The analysis shows the average payoff (either in pips or return (%) depending on your selection) per trade in case the trader would have set the SL to 0 (open price) when reaching a certain profit level in the trade. For example: For the category 10 pips, the trade is simulated to have a SL at 0 pips profit as soon as the trade reaches a profit level of 10 pips. If the trade reaches the 0 pip level, the trade will have a payoff equal 0. If the TP of the trade is reached first, the payoff will be equal to the TP level. The outcome is compared to the actual payoff, hence a positive result in a category would indicate, that the trade would have had a better outcome compared to the actual payoff from the trade. In case a certain profit level is never reached or there is no SL & TP set in the trade, it will not be included in the analysis. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "h7ccxzeBdyI",
  },
  risk_per_trade_last_trades: {
    legends: ["lowerRisk", "higherRisk", "benchmark"],
    chartTitle: "Risk per trade (last trades)",
    chartDescription:
      'The analysis "risk per trade" shows the average risk (measured through the loss in case SL is hit) the user took during the last trades (different categories with number of trades shown). Please note: In case no SL was set in the trade, it is not included in the analysis. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).',
    youtubeVideoId: "mZt465PM7LY",
  },
  max_risk_all_open_trades_last_trades: {
    legends: ["lowerRisk", "higherRisk", "benchmark"],
    chartTitle: "Max. risk all open trades (last trades)",
    chartDescription:
      'The analysis "max. risk all open trades" shows the maximum risk (measured through the loss in case SL is hit) the user took during the last trades (different categories with number of trades shown) based on all simultaneously open positions. Please note: In case no SL was set in the trade, it is not included in the analysis. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).',
    youtubeVideoId: "mZt465PM7LY",
  },
  percentage_of_position_with_sl: {
    legends: ["benchmark"],
    chartTitle: "% of positions with SL",
    chartDescription:
      "The analysis shows the % of positions in which the user places a Stopp Loss (SL). Please note, it is measured at closing time of the positions whether a SL was used or not. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "-eFPq5rR8OY",
  },
  // strategy page
  profit_high_vs_low_volatility: {
    legends: [
      "higherProfit",
      "lowerProfit",
      "limitedSignificance",
      "insignificant",
      "benchmark"
    ],
    chartTitle: "Profit high vs. low volatility",
    chartDescription:
      'In the analysis "Profit high vs. low volatility", the average return per trade depending on the level of volatility at the time of trade opening is measured. Each trade is classified whether it has been opened during a period of high or low volatility (measured through indicators). Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category.',
    youtubeVideoId: "1opjzA7VaxE",
  },
  profit_with_against_st_mt_trend: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Profit with(+) & against(-) short-term(ST) & medium-term(MT) trend",
    chartDescription:
      "The analysis shows the average return depending on whether the user's trades match the short-term trend (ST) and the medium-term (MT) trend. At the time of trade opening, the market trend is analysed (indicators) to be either bullish, side, or baerish. In case the trader opens a long trade and the market is bullish, the trade matches the trend (+), however if the market is sideways or baerish when a long trade is opened, the trade does not match the underlying market trend (-). Both, the short-term and medium-term market trend is analysed and the trade is classified into the respective category. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "dAanYFk5FHE",
  },
  profit_when_sl_adjusted_vs_profit_when_not: {
    legends: [
      "higherProfit",
      "lowerProfit",
      "limitedSignificance",
      "insignificant",
      "benchmark"
    ],
    chartTitle: "Profit when SL adjusted vs. profit when not",
    chartDescription:
      "The analysis shows the average return if the Stop Loss (SL) in a trade has been adjusted vs. if the SL in a trade has not been adjusted. Please note: The adjustment of the SL must be minimum 2 mins after setting an SL, otherwise it will not be counted. Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category.",
    youtubeVideoId: "NPxYJO7U8q0",
  },
  profit_if_publishing_trades: {
    legends: [
      "higherProfit",
      "lowerProfit",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Profit if publishing trades",
    chartDescription:
      "The analysis measures the average return per trade when the user decided the publish the own trades on hoc-trade vs. the average return per trade before the user decided to publish the trades on hoc-trade (or after in case profile is set to private again). Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "5xC-Pzk5uLU",
  },
  profit_after_withdrawal_in_pips: {
    legends: [
      "higherProfit",
      "lowerProfit",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Profit before withdrawal (in pips)",
    chartDescription:
      "The analysis measures the average return per trade after a withdrawal has been performed on the account (average of the 50 trades after withdrawal) vs. average return per trade for all trades. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "qRzuEyWFnd8",
  },
  average_payoff_win_vs_loss_trade: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Average payoff win vs. loss trade",
    chartDescription:
      "The analysis measures the average return per trade for loss trades (negative return) and for profitable trade (positve return). Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "BAfRS4_hrMU",
  },
  profit_for_trade_during_overbought_oversold: {
    legends: [
      "profit",
      "loss",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Profit during overbought/ oversold market",
    chartDescription:
      'The analysis measures the average return per trade depending on whether the market is overbought or oversold at the time of trade opening and whether the trade direction chosen by the trader is along or against that market indicated trend of overbought or oversold. Along means: the market is overbought, and the trader chooses to open a sell position, or the market oversold and the trader chooses to open a buy position. Against means: the market is overbought, however the trader still opens a buy position, or the market is oversold, and the trader still opens a sell position. I’m case the market is neither overbought nor oversold at the point of trade open, the trade is classified as "in between".',
    youtubeVideoId: "5evR0pFKQW0",
  },
  //timing page
  profit_if_trade_open_earlier_later: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant", "benchmark"],
    chartTitle: "Profit if trade open earlier/later",
    chartDescription:
      'The analysis shows the average payoff (either in pips or return (%) depending on your selection) per trade in case the trades would have been opened slightly later or earlier. For example, the average payoff per trade is calculated in case all of the trade would have been opened 1 min earlier for the category "-1". "As-is" describes the average payoff per trade based on the actual trade as it has been executed. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).',
    youtubeVideoId: "c9OV5Nhx5pA",
  },
  profit_if_trade_close_earlier_later: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant", "benchmark"],
    chartTitle: "Profit if trade close earlier/later",
    chartDescription:
      'later or earlier. For example, the average payoff per trade is calculated in case all of the trade would have been closed 1 min earlier for the category "-1". "As-is" describes the average payoff per trade based on the actual trade as it has been executed. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).',
    youtubeVideoId: "c9OV5Nhx5pA",
  },
  profit_if_tp_closer_further_away: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant", "benchmark"],
    chartTitle: "Profit if TP closer/further away",
    chartDescription:
      'The analysis shows the average payoff (either in pips or return (%) depending on your selection) per trade in case the trades the Take Profit (TP) would have been a few pips closer for further away from compared to the actual TP level set in the trade. In case there is no TP set in a trade, it will not be counted for the analysis."As-is" describes the average payoff from the trade as it has been executed. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).',
    youtubeVideoId: "DGcvZQZEg0g",
  },
  profit_if_sl_closer_further_away: {
    legends: ["profit", "loss", "limitedSignificance", "insignificant", "benchmark"],
    chartTitle: "Profit if SL closer/further away",
    chartDescription:
      'The analysis shows the average payoff (either in pips or return (%) depending on your selection) per trade in case the trades the Stopp Loss (SL) would have been a few pips closer for further away from compared to the actual SL level set in the trade. In case there is no SL set in a trade, it will not be counted for the analysis."As-is" describes the average payoff from the trade as it has been executed. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).',
    youtubeVideoId: "DGcvZQZEg0g",
  },
  profit_if_manual_stop_before_tp: {
    legends: ["higherProfit", "lowerProfit", "limitedSignificance", "insignificant", "benchmark"],
    chartTitle: "Profit if manual stop before TP",
    chartDescription:
      'The analysis measures the average return for trades which are in profit, but are manually closed vs. what woud have been the return for those trade in case they would not have been manually closed at that point. A SL and TP has to be set for the trade, otherwise it will not be included in the analysis. Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category.',
    youtubeVideoId: 'AqzXSXRAyoQ'
  },
  percent_of_trades_stopped_early_in_profit: {
    legends: ["benchmark"],
    chartTitle: "% of trades stopped early in profit",
    chartDescription: "The analysis measures the % of trades which have been stopped early in profit (trade needs to be in profit when closed manually). Additionally, it is measured how high the % of manual stops of trades in profit is when there has been a loss trade beforehand (last trade). Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)",
    youtubeVideoId: 'AqzXSXRAyoQ'
  },
  // costs
  spread_and_commission: {
    legends: [],
    chartTitle: "Spread & commission",
    chartDescription:
      "The analysis shows the total trading costs which were incurred by the trader, which composes both spread and commission. The total costs are calculated based in the lot size and the amount of trades per symbol traded.",
    youtubeVideoId: "cnmtF7VThW0",
  },
  profit_of_conflicting_symbols: {
    legends: [
      "higherProfit",
      "lowerProfit",
      "limitedSignificance",
      "insignificant",
      "benchmark",
    ],
    chartTitle: "Profit of conflicting symbols",
    chartDescription:
      "The analysis measures the average return per trade in case there are conflicting positions open vs. if there are no conflicting positions opened. A conflicting position is defined as a simulatenous long and short direction in the same asset (e.g. EURUSD long and EURGBP short open at the same time). Please note: Return (%) is calculated as the trade outcome divided by the balance at the time of trade opening and the transparency of the graph is stronger in case few trades fall into the respective category. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification).",
    youtubeVideoId: "lRdWwKYbW-s",
  },
  "commission-comparison-chart": {
    legends: ["currentCommission", "lowerCommission", "lowestCommission"],
    chartTitle: "Spread & commission comparison",
    chartDescription:
      "The analysis shows the total trading costs which were incurred by the trader, which composes both spread and commission, compared to the costs which the user would have faced at other brokers for the same trades. Please note, not all symbols might be included in the assessment and there is no guarantee that the trading costs at other brokers will match the shown in the future.",
    youtubeVideoId: "jd34def60a8",
  },
  "top-five-patterns": {
    legends: [],
    chartTitle: "Top five profit making and loss making patterns",
    chartDescription:
      "The Top5 profit-and loss-making patterns gives an overview & quick navigation to the major patterns observed in the respective analysis category. The chart summarizes the 5 strongest patterns (in pips or absolute value) observed for the charts presented on this page, both in terms of positive and negative performance based on trades from the previous 1 year.",
    youtubeVideoId: null,
  },
  //account overview
  "performance-over-time": {
    legends: [],
    chartTitle: "Performance over time",
    chartDescription: `"Performance over time" shows the time weighted return of the account. It measures the performance of a dollar invested in the system since inception. The time weighted return eliminates cash inflows and outflows from the calculation. Each trade's return is calculated by dividing the profit of the trade by the balance at the opening of the trade.`,
    youtubeVideoId: null,
  },
  drawdown: {
    legends: ["benchmark"],
    chartTitle: "Drawdown (balance)",
    chartDescription: `"Drawdown balance" shows the maximum drawdown of the balance in percent. The drawdown is presented in a monthly view, however refers to an all time drawdown. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)`,
    youtubeVideoId: null,
  },
  general_info: {
    legends: [],
    chartTitle: 'General Info',
    chartDescription: "The general info gives you a quick overview of your trading performance. Profit in %: Based on the time-weighted profit adjusting for deposits and withdrawals Pips, currency values, and drawdown refer to the absolute values since account inception"
  },
  eight_point_assessment: {
    legends: [],
    chartTitle: 'The 8 point issue area assessment',
    chartDescription: `The 8 point issue area assessment provides a high level overview which areas of your trading show the most severe (negative) impact on your trading performance. 

    The output of this analysis is applicable to guide you to the areas of your trading which require a strong focus from you, however not to work single issues. The impact of risk issues has not been quantified, but is derived based on the number of risk issues detected in your trading.
    
    All details can be accessed through the single issue analytics in your Trade Medic report. Note that the performance effect of trading issues is not mutually exclusive & collectively exhaustive (MECE).`
  },
  'trades_made_in_profit_loss_making_patterns': {
    legends: [],
    chartTitle: 'Trades made in profit loss making patterns',
    chartDescription: `The trades made in profit/ loss making patterns charts give you a quick overview on the number of patterns detected for you, the development over time, how many trades fell into patterns, and which patterns are most significant for you.
    \n
    The line chart presents you either the number of trades or lots that fell into profit, loss, or insignificant patterns over time. Thereby, you can detect whether you have improved your trades in loss making patterns over time. Ideally you will want the number of profit-making patterns to increase, which means you traded more in your areas of strength and less in your areas of weakness. Reducing your position size for trades that predominantly fall into loss-making patterns will be visible in the “lots” view.
    Note that one trade can fall into multiple profit, loss, or insignificant patterns. The number of trades and lots for profit, loss, and insignificant patterns is shown as a percentage of total, not absolute values.
    \n
    Your absolute number of profit, loss, and insignificant patterns shows the current count of patterns identified for you. Moreover, you will see the number of pips and profit (in currency) resulting from those patterns. 
    Note that one trade can fall in multiple patterns, therefore the pip and currency values will be counted for each single pattern for every trade.
    `,
  },
  // basic statistics
  balance_currency: {
    legends: ["balance", "deposit", "withDrawal"],
    chartTitle: "Balance (currency)",
    chartDescription: `"Balance (currency) shows the development of the trader's balance (in account's currency) over time in the line chart. The bars represent cash inflow (positive) and cash outflows (negative) e.g. due to deposits or withdrawals`,
    youtubeVideoId: null,
  },
  performance_pips: {
    legends: [],
    chartTitle: "Performance (pips)",
    chartDescription: `"Performance (pips)" shows the cumulative result in pips by the trader over the past 12 months. Please note, the number of pips refers to the all-time performance by the trader, however only max. 12 months are presented in the chart`,
    youtubeVideoId: null,
  },
  symbols: {
    legends: [],
    chartTitle: "Symbols",
    chartDescription: `"Symbols" shows the percentage of trades performed in by symbol during the whole period of trading on that account`,
    youtubeVideoId: null,
  },
  "performance_months": {
    legends: [],
    chartTitle: "Performance by month",
    chartDescription: `"Performance by month" shows the time-weighted return by month of the trader. Time-weighted return measures the performance of a dollar invested in the system since inception. The time weighted return eliminates cash inflows and outflows from the calculation. Each trade's return is calculated by dividing the profit of the trade by the balance at the opening of the trade. Please note, a maximum of 12 months is shown in this chart`,
    youtubeVideoId: null,
  },
  "average_pips_per_trade": {
    legends: [],
    chartTitle: "Average pips per trade",
    chartDescription: `"Average pips/trade" shows the average result of pips per trade in the respective month. Pips per trade is calculated by dividing the total amount of pips in a month by the total number of trades during that month. A maximum of 12 months is shown in this chart. The benchmark (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)`,
    youtubeVideoId: null,
  },
  "average_trade_length": {
    legends: [],
    chartTitle: "Average trade length (mins)",
    chartDescription: `"Average trade length" shows the average length per trade during a the respective month. The average length per trade is caluclated by dividing the total trade length of all trades during that month by the number of trades performed during that month. A maximum of 12 months in shown in this chart. The benchmarkt (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)`,
    youtubeVideoId: null,
  },
  "average_trades_per_day": {
    legends: [],
    chartTitle: "Average trades per day",
    chartDescription: `"Average trades per day" shows the average number of trades per day during a the respective month. The number of trades per day is caluclated by dividing the total amount of trades during that month by the number of trades performed during that month. For caluclation purposes, an average number of possible trading days is used per month (5 per week). A maximum of 12 months in shown in this chart. The benchmarkt (line) refers to all profitable traders (showing a net profit) sharing a similar trading style (refer to classification)`,
    youtubeVideoId: null,
  },
  //advanced statistics
  "risk_return_ratio_and_hit_ratio": {
    legends: ["benchmarkRRR", "benchmarkHR", "returnRiskRatio", "hitRatio"],
    chartTitle: "Risk return ratio & hit ratio",
    chartDescription: `"Risk return ratio" shows the average risk return ratio per trade during a month of trading. The risk is measured as the amount of potential loss in case the SL of the trade is hit, and return in the potential return achieved in case the TP is hit. Please note, the risk return ratio here does not refer to the actual outcome of the trade and TP & SL are referenced at the time of closing of the trade. In case no SL or TP is set in a trade, it will not be considered for this analysis.`,
    youtubeVideoId: null,
  },
  "best_worst_trades": {
    legends: ["profit", "loss"],
    chartTitle: "Best/worst trades",
    chartDescription: `The analysis shows your best and worst trade either in pips or return in the respective month. Please note: return is calculated as the trade outcome divided by the balance at the time of trade opening. The trades for pips and return may differ in case a best trade in terms of pips was different to the best trade in terms of return (%)`,
    youtubeVideoId: null,
  },
  "probability_gain_after_gain": {
    legends: ["probabilityAverageHitRateGreen"],
    chartTitle: "Probability gain after gain",
    chartDescription: `"Probability gain after gain" describes the probability of having a winning trade in case the previous trade was a winning trade as well (positive result). The average describes to overall probability of having a winning trade. Please note: The reference for the previous trade is based on the most recently closed trade.`,
    youtubeVideoId: null,
  },
  "probability_loss_after_loss": {
    legends: ["probabilityAverageHitRateRed"],
    chartTitle: "Probability loss after loss",
    chartDescription: `"Probability loss after loss" describes the probability of having a loss trade in case the previous trade was a loss trade as well (positive result). The average describes to overall probability of having a loss trade. Please note: The reference for the previous trade is based on the most recently closed trade.`,
    youtubeVideoId: null,
  },
  // trade history page table
  "trade_history_table": {
    legends: [],
    chartTitle: "Trade-history",
    chartDescription: `Please note that we retrieve your trade history from the exchange/ broker you are using and that we cannot be held liable for any information shown in the table.
    "High/ low" refers to the maximum during the time the trade was open.`
  },
  "sharp_ratio": {
    legends: [],
    chartTitle: "Sharp Ratio",
    chartDescription: `The sharpe ratio describes the average return earned by a trading system in excess of the risk-free rate (risk-free rate currently assumed to be 0). The higher the value, the higher the return.`
  },
  "zValue": {
    legends: [],
    chartTitle: "Z Value",
    chartDescription: `Z-value describes the randomness of a trading system. The further away the value is from 0, the higher the dependence. In case the Z-value is negative, there is a positive dependence (loss followed by a loss; profit followed by a profit), while a positive Z-value indicated a negative dependance (profit followed by a loss; loss followed by a profit).`
  },
  "ahpr": {
    legends: [],
    chartTitle: "ahpr",
    chartDescription: "AHPR stands for Average Holding Period Return and describes average profit per trade. In case the AHPR is below 1, the trading system on average loses, while an AHPR above 1 indicates an average profit."
  },
  "ghpr": {
    legends: [],
    chartTitle: "ghpr",
    chartDescription: "Similar to the AHPR, the GHPR indicates the profit of the trading system as well. In comparison to the AHPR, the GHPR includes reinvestments from the previous trades. In case the GHPR is below 1, this indicates a loss of the system, while an GHPR above 1 indicates a profit of the system."
  }
};

export default chartsInfoModalContent;
