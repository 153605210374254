import { Line } from "react-chartjs-2";
import styles from "./performancePips.module.scss";
import options from "./performancePips.options";
import ChartHeader from "../../../charts/chartHeader";
import { Link } from "react-router-dom";
import htFonts from "shared/htFont.module.scss";

// plugins
import createPerformancePipsPlugin from "./performancePips.plugin";
import createBackgroundPlugin from "components/charts/plugins/createBackground.plugin";
import xAxisPlugin from "../../accountOverview/xAxis.plugin";
import createPerformancePipsHoverTextPlugin from "./performancePipsHoverText.plugin";

const PerformancePips = ({ chartData }) => {
  const performancePipsPlugin = createPerformancePipsPlugin();
  const backgroundPlugin = createBackgroundPlugin(true);
  const hoverTextPlugin = createPerformancePipsHoverTextPlugin(chartData);
  const allTimeData = chartData[999];

  let scoreboardData = allTimeData[allTimeData.length - 1];
  let allIntervals = allTimeData.map((data) => {
    let { time } = data;
    if (typeof time === 'string') {
      time = new Date(time);
    }
    let timeOffsetInMillSecs = time.getTimezoneOffset() * 60 * 1000;
    let timestamp = time.getTime();
    let clientTime = new Date(timestamp + timeOffsetInMillSecs);
    return clientTime;
  });

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval.getMonth() === 0) {
      return (interval = interval.toLocaleString("default", {
        year: "numeric",
      }));
    }

    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return (interval = interval.toLocaleString("default", {
          month: "short",
        }));
      } else {
        showInterval = true;
        return "";
      }
    }
    return interval;
  });

  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: allTimeData.map((data) => {
          const { time, performancePips, equity, trades } = data;
          return {
            x: typeof time === 'string'? new Date(time) : time,
            performancePips,
            equity,
            trades,
          };
        }),
        yAxisId: "y",
        parsing: {
          yAxisKey: "performancePips"
        }
      },
      // feed data to right y axis
      {
        data: chartData[999].map((data) => {
          const { time, performancePips } = data;
          return {
            x: typeof time === 'string'? new Date(time) : time,
            y: performancePips
          };
        }),
        yAxisID: "y1",
      }
    ]
  };

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader 
        chartTitle={chartData.chartTitle}
        chartKey="performance_pips"
      />
      <div className={styles.Chart}>
        <Line
          key={Math.random()}
          data={data}
          options={options}
          plugins={[
            performancePipsPlugin,
            backgroundPlugin,
            xAxisPlugin,
            hoverTextPlugin
          ]}
        />
      </div>
      <div className={styles.Scoreboard}>
        <div className={styles.ScoreboardLeft}>
          <div className={styles.columnOne}>
            <div style={{color: "#06AFC9"}}>Pips</div>
            <div>{scoreboardData?.performancePips.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          </div>
          <div className={styles.columnTwo}>
            <div style={{color: "#06AFC9"}}>Equity</div>
            <div>{scoreboardData?.equityTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD</div>
            <div className={styles.Label}>{scoreboardData?.equity.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD</div>
          </div>
        </div>
        <div className={styles.ScoreboardRight}>
          <div style={{color: "#06AFC9"}}>Age</div>
          <div>{scoreboardData?.duration}</div>
          <Link to="/dashboard/overview/account-overview" style={{ textDecoration: 'none' }}>
            <div className={styles.ScoreboardBtn}>
              <span className={htFonts.htMedal}></span>
              Scoreboard
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PerformancePips;