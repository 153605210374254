function createGeneralBarBorderColor(
  chartData,
  currentTimeFrame,
  hasTimeFrame,
  chartMode
) {
  const interval = chartData.interval?? Object.keys(chartData[currentTimeFrame].data);
  const generalBarBorderColor = interval.map((interval) => {
    let color;
    if (hasTimeFrame && currentTimeFrame) {
      const tradeCount = chartData[currentTimeFrame].data[interval]?.trades;
      if (tradeCount < 5) color = "#CCCCCC";
      if (tradeCount >= 5)
        chartData[currentTimeFrame].data[interval][chartMode] >= 0
          ? (color = "#56A07E")
          : (color = "#C15357");
    } else {
      const tradeCount = chartData[999].data[interval].trades;
      if (tradeCount < 5) color = "#CCCCCC";
      if (tradeCount >= 5)
        chartData[999].data[interval][chartMode] >= 0
          ? (color = "#56A07E")
          : (color = "#C15357");
    }
    return color;
  });
  return generalBarBorderColor;
}

export default createGeneralBarBorderColor;
