import createDiagonalPattern from "./createDiagonalPattern";

function createGeneralHoverBarColor(
  chartData,
  currentTimeFrame,
  hasTimeFrame,
  chartMode
) {
  const intervals = chartData.interval?? Object.keys(chartData[currentTimeFrame].data);

  const generalHoverBarColor = intervals.map((interval) => {
    if (hasTimeFrame && currentTimeFrame) {
      const tradeCount = chartData[currentTimeFrame].data[interval]?.trades;
      if (tradeCount < 5) {
        return createDiagonalPattern("#999999");
      } else if (tradeCount > 20) {
        return chartData[currentTimeFrame].data[interval][chartMode] >= 0
          ? "#009245"
          : "#BE1E2D";
      } else {
        return chartData[currentTimeFrame].data[interval]?.[chartMode] >= 0
          ? createDiagonalPattern("#009245")
          : createDiagonalPattern("#BE1E2D");
      }
    } else {
      return chartData[999].data[interval][chartMode] > 0
        ? "#56A07E"
        : "#C15357";
    }
  });
  return generalHoverBarColor;
}

export default createGeneralHoverBarColor;
