import styles from "../signUpModal.module.scss";
import { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import { sendNewEmailVerificationCode } from 'utils/requests';
import { API_BASE_URL } from "utils/constant/environment";

// assset
import createAccountArrow from "assets/createAccountArrow.svg";

function ConfirmedEmail(props) {
  // const setSignUpPhase = props.setSignUpPhase;
  const emailVerificationCredential = props.emailVerificationCredential;
  const setEmailVerificationCredential = props.setEmailVerificationCredential;
  const email = props.email;
  const setSignUpPhase = props.setSignUpPhase;
  const [errorMessage, setEmailMessage] = useState(null);
  const [inputCode, setInputCode] = useState("");
  const [countDownValue, setCountDownValue] = useState(60);
  const [hasFourDigitsCode, setHasFourDigitsCode] = useState(false);
  
  useEffect(() => {
    let interval;
    if (countDownValue > 0) {
      interval = setInterval(() => {
        setCountDownValue(countDownValue - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [countDownValue]);

  async function handleSubmit(e) {
    e.preventDefault();
    const resp = await fetch(
      `${API_BASE_URL}/auth/email-verification`,
      {
        method: "POST",
        body: JSON.stringify({
          ...emailVerificationCredential,
          verificationCodeInput: inputCode,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const respJson = await resp.json();
    if (respJson.message === "success") {
      setSignUpPhase(4);
    } else {
      setEmailMessage(respJson.error);
    }
  }

  async function resendEmailVerificationCode(email) {
    const result = await sendNewEmailVerificationCode(email);
    setEmailVerificationCredential(result.data);
    setCountDownValue(60);
  }

  return (
    <div className={styles.ConfirmedEmailSection}>
      <div className={styles.ConfirmedEmailTitle}>
        We have sent you the verification code through email.
        <br /> Please enter the 4-digit code.
        <div>Please also check your spam or junk mail folder if you cannot find the email.</div>
      </div>
      <div className={styles.PinInputContainer}>
        <PinInput
          length={4}
          initialValue=""
          onChange={(value, index) => {
            if (value.length === 4) {
              setHasFourDigitsCode(true);
              setEmailMessage("");
            } else {
              setHasFourDigitsCode(false);
              setEmailMessage("");
            }
          }}
          type="numeric"
          inputMode="number"
          style={{
            alignContent: "center",
            width: 'fit-content',
            margin: 'auto'
          }}
          inputStyle={{
            borderColor: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            marginRight: "10px",
            fontSize: "20px",
            fontWeight: "700",
          }}
          onComplete={(value, index) => {
            setHasFourDigitsCode(true);
            setInputCode(value);
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </div>
      {errorMessage ? (
        <div style={{ color: "red", width: "70%", fontSize: "12px", marginLeft: "50px", paddingTop: "20px"}}>
          {errorMessage}
        </div>
      ) : (
        <></>
      )}
      <div className={styles.confirmSection}>
        <div className={styles.ConfirmEmailButtonContainer}>
          <div className={styles.ResendEmailSection}>
            <div>Did not receive your code?</div>
            {
              countDownValue === 0?
              <div onClick={() => resendEmailVerificationCode(email)} className={styles.ResendButton}>
                <u><strong>Resend</strong></u>
              </div> : 
              <div style={{ color: '#999999', fontWeight: 'bold' }}>
                Send again in {countDownValue}s
              </div>
            }
          </div>
          {
            <button
              id={!(hasFourDigitsCode && !errorMessage) ? styles.confirmBtn : styles.disabledConfirmBtn}
              type="submit"
              disabled={!(hasFourDigitsCode && !errorMessage)}
              onClick={(e) => handleSubmit(e)} >
              Confirm email
              <img src={createAccountArrow} alt="arrow icon" />
            </button>

          //   hasFourDigitsCode && !errorMessage ? 
          //   <button
          //   id={styles.confirmBtn}
          //   type="submit"
          //   onClick={(e) => handleSubmit(e)}
          // >
          //   Confirm email
          //   <img src={createAccountArrow} alt="arrow icon" />
          // </button> : <button
          //   id={styles.disabledConfirmBtn}
          //   type="submit"
          //   disabled
          // >
          //   Confirm email
          //   <img src={createAccountArrow} alt="arrow icon" />
          // </button>
          }
        </div>
      </div>
    </div>
  );
}

export default ConfirmedEmail;
