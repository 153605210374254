const performanceOverTime = {
  chartTitle: 'Performance Over Time',
  999: [
    {
      time: new Date("2022-04"),
      percentage: 1.43,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2022-05"),
      percentage: 8.34,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2022-06"),
      percentage: 2.91,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2022-07"),
      percentage: 6.49,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2022-08"),
      percentage: 13.33,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2022-09"),
      percentage: 21.61,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2022-10"),
      percentage: 19.40,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2022-11"),
      percentage: 29.22,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2022-12"),
      percentage: 35.84,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2023-01"),
      percentage: 35.91,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2023-02"),
      percentage: 41.95,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2023-03"),
      percentage: 41.81,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
    {
      time: new Date("2023-04"),
      percentage: 58.44,
      performancePercentage: 54.15,
      performancePips: 2236.49,
      equityTotal: 15050.39,
      equity: 43.77,
      duration: "1Y 6M",
      trades: 23
    },
  ]
}

export default performanceOverTime;