import { useState, useEffect } from "react";
import styles from "./advancedStatistics.module.scss";
import ChartNoDataWrapper from "components/charts/chartNoDataWrapper";

// components
import KeyStatistics from "components/advancedStatic/keyStatistics/keyStatistics";
import RiskReturnHitRatio from "components/advancedStatic/riskReturnHitRatio/riskReturnHitRatio.chart";
import BestWorstTrades from "components/advancedStatic/bestWorstTrades/bestWorstTrades.chart";
import ProbabilityBarChart from "components/advancedStatic/probabilityBarCharts/probabilityBar.chart";

function AdvancedStatistics({ chartDataObjects }) {
  return (
    <div className={styles.Subpage}>
      <KeyStatistics data={chartDataObjects["key_statistics"]} />
      <div className={styles.ChartsContainer}>
        <ChartNoDataWrapper chartData={chartDataObjects['return_risk_ratio_hit_ratio']} >
          <RiskReturnHitRatio
            key="Risk return ratio & hit ratio"
            chartTitle="Risk return ratio & hit ratio" />
        </ChartNoDataWrapper>
        <ChartNoDataWrapper chartData={chartDataObjects['best_worst_trade']} >
          <BestWorstTrades 
            key="Best/worst trades"
            chartTitle="Best/worst trades" />
        </ChartNoDataWrapper>
        <ChartNoDataWrapper chartData={chartDataObjects['probability_gain_after_gain']} >
          <ProbabilityBarChart 
            key="Probability gain after gain"
            chartTitle="Probability gain after gain"
            barValueKey="percentageGain" />
        </ChartNoDataWrapper>
        <ChartNoDataWrapper chartData={chartDataObjects['probability_loss_after_loss']} >
          <ProbabilityBarChart 
            key="Probability loss after loss"
            chartTitle="Probability loss after loss"
            barValueKey="percentageLoss" />
        </ChartNoDataWrapper>
      </div>
    </div>
  );
}

export default AdvancedStatistics;
