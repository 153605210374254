import { chartKeys } from 'utils/constant';

const percent_of_trades_stopped_early_in_profit = {
  chartTitle: "Percent of trades stopped early in profit",
  chartKey: chartKeys.PERCENT_OF_TRADES_STOPPED_EARLY_IN_PROFIT,
  hoverTextArray: {
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        textColor: function(hoveredData, data) {
          const anotherBar = data.filter(dataPoint => dataPoint.interval !== hoveredData.interval);
          return hoveredData.percentage <= anotherBar[0].percentage? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ]
  },
  interval: [
    'Loss trade before',
    'Average',
  ],
  createBarColor: function(chartData) {
    const lostTradeBefore = chartData['999']['data']['Loss trade before'];
    const average = chartData['999']['data']['Average'];
    
    if (lostTradeBefore > average) {
      return ['#C15357', '#56A07E'];
    } else {
      return ['#56A07E', '#C15357']
    }
  },
  createHoverBarColor: function (chartData) {
    const lostTradeBefore = chartData['999']['data']['Loss trade before'];
    const average = chartData['999']['data']['Average'];
    
    if (lostTradeBefore > average) {
      return ["#BE1E2D", "#009245"];
    } else {
      return ["#009245", "#BE1E2D"];
    }
  }
}

export default percent_of_trades_stopped_early_in_profit;