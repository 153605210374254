const bestWorstTrades = {
  chartTitle: "Best/worst trades",
  999: {
    bestTrades: [
      {
        time: new Date("2020-10"),
        percentage: 25,
        usd: 7,
      },
      {
        time: new Date("2020-11"),
        percentage: 55,
        usd: 7
      },
      {
        time: new Date("2020-12"),
        percentage: 30,
        usd: 8
      },
      {
        time: new Date("2021-01"),
        percentage: 60,
        usd: 14
      },
      {
        time: new Date("2021-02"),
        percentage: 62,
        usd: 6
      },
      {
        time: new Date("2021-03"),
        percentage: 43,
        usd: 13
      },
      {
        time: new Date("2021-04"),
        percentage: 15,
        usd: 11
      },
      {
        time: new Date("2021-05"),
        percentage: 52,
        usd: 13
      },
      {
        time: new Date("2021-06"),
        percentage: 70,
        usd: 12
      },
      {
        time: new Date("2021-07"),
        percentage: 60,
        usd: 11
      },
      {
        time: new Date("2021-08"),
        percentage: 40,
        usd: 8
      },
      {
        time: new Date("2021-09"),
        percentage: 52,
        usd: 11
      },
    ],
    worstTrades: [
      {
        time: new Date("2020-10"),
        percentage: 25,
        usd: 12
      },
      {
        time: new Date("2020-11"),
        percentage: 55,
        usd: 14
      },
      {
        time: new Date("2020-12"),
        percentage: 30,
        usd: 12
      },
      {
        time: new Date("2021-01"),
        percentage: 60,
        usd: 13
      },
      {
        time: new Date("2021-02"),
        percentage: 62,
        usd: 18
      },
      {
        time: new Date("2021-03"),
        percentage: 43,
        usd: 16
      },
      {
        time: new Date("2021-04"),
        percentage: 15,
        usd: 7
      },
      {
        time: new Date("2021-05"),
        percentage: 52,
        usd: 14
      },
      {
        time: new Date("2021-06"),
        percentage: 70,
        usd: 14
      },
      {
        time: new Date("2021-07"),
        percentage: 60,
        usd: 7
      },
      {
        time: new Date("2021-08"),
        percentage: 40,
        usd: 14
      },
      {
        time: new Date("2021-09"),
        percentage: 52,
        usd: 8
      },
    ]
  }
}

export default bestWorstTrades;