import { useState } from "react";
import BarChart from "components/charts/bar.chart";
//styling
import styles from "./general.module.scss";
import TopFivePatterns from "components/topFivePatterns";
// utils
import { createDataObjectForChart } from "utils/helpers/chart";
import { getChartsData } from "redux/selectors";
import { useSelector } from "react-redux";
import { ChartMode } from "utils/constant/index";

function Behavioral({ chartKeysOfThePage, chartDataObjects, currentTradingAccount }) {
  const [chartMode, setChartMode] = useState(ChartMode.PIPS);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const chartsData = useSelector(getChartsData(chartKeysOfThePage));

  return (
    <>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns
          chartData={chartDataObjects["top_5_patterns_behavior"]}
        />
      </div>
      <BarChart
        key="Trades per day (per trade)"
        chartTitle="Trades per day (per trade)"
        chartData={chartDataObjects["trades_per_day_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["trades_per_day_per_trade"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Break since last trade (per trade)"
        chartTitle="Break since last trade (per trade)"
        chartData={chartDataObjects["break_since_last_trade_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["break_since_last_trade_per_trade"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Break after loss trade (per trade)"
        chartTitle="Break after loss trade (per trade)"
        chartData={chartDataObjects["break_after_loss_trade_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["break_after_loss_trade_per_trade"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Open different symbols (per trade)"
        chartTitle="Open different symbols (per trade)"
        chartData={chartDataObjects["open_different_symbols_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["open_different_symbols_per_trade"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Open position in profit/loss"
        chartTitle="Open position in profit/loss"
        chartData={chartDataObjects["open_position_in_profit_loss"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["open_position_in_profit_loss"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Profit after daily profit/loss"
        chartTitle="Profit after daily profit/loss"
        chartData={chartDataObjects["profit_after_daily_profit_loss"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["profit_after_daily_profit_loss"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
    </>
  );
}

export default Behavioral;
