import styles from "./accountTypeSelection.module.scss";
import forexCoin from "assets/forex-coin.svg";
import cryptoCoin from "assets/crypto-coin.svg";

function AccountTypeSelection({setAccountType}) {
  return (
    <div className={styles.SelectionContainer}>
      <div className={styles.SectionTitle}>Add trading account</div>
      <div className={styles.SectionDescription}>Choose what type of trading account you are going to add.</div>
      <div className={styles.SelectionBtnContainer}>
        <div onClick={() => setAccountType("forex")} className={styles.AccountTypeBtn}><img src={forexCoin} alt="forex coin"></img>Forex trading account</div>
        <div onClick={() => setAccountType("crypto")} className={styles.AccountTypeBtn}>
        <img src={cryptoCoin} alt="crypto coin"></img>Crypto trading account
        </div>
      </div>
    </div>
  );
}

export default AccountTypeSelection;
