import { Line } from "react-chartjs-2";
import styles from "./tradesPatternChart.module.scss";
import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
//plugins
import createBackgroundPlugin from "components/charts/plugins/createBackground.plugin";
import xAxisPlugin from "../xAxis.plugin";
// import createColorGradient from "./colorGradient.plugin";
import options from "./tradesPattern.options";
import 'chart.js/auto';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function TradesPatternChart(props) {
  let { chartData, dualMode, hasTimeFrame } = props;
  const [chartMode, setChartMode] = useState("trades");
  const [currentTimeFrame, setCurrentTimeFrame] = useState(3);
  if (chartData.noData) {
    return (
      <div style={{
        backgroundColor: '#00272E',
        borderBottomLeftRadius: 20,
        color: 'white',
        height: '100%',
        lineHeight: '300px',
        fontSize: '1.1rem'
      }}>
        Not Sufficient Data available yet
      </div>
    )
  }

  chartData = chartData[999];

  // const colorGradientPlugin = createColorGradient();

  function getGradient(ctx, chartArea, color) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      gradient.addColorStop(0, `rgba(${color}, 0)`);
      gradient.addColorStop(1, `rgba(${color}, 0.5)`);
    }

    return gradient;
  }

  let profitData = chartData.profit;
  let lossData = chartData.loss;
  let insiginificantData = chartData.insiginificant;

  let selectedProfitData = getSelectedPeriodData(profitData);
  let selectedLossData = getSelectedPeriodData(lossData);
  let selectedInsiginificantData = getSelectedPeriodData(insiginificantData);

  function getSelectedPeriodData(dataArray) {
    let lastDate = dataArray[dataArray.length - 1].date;
    let selectedPeriodData = [];
    dataArray.map((obj) => {
      let selectedDate = new Date(lastDate);
      let date = typeof obj.date === 'string'? new Date(obj.date) : obj.date;
      if (currentTimeFrame === 3) {
        selectedDate.setMonth(selectedDate.getMonth() - 3);
        if (selectedDate - date<= 0) {
          selectedPeriodData.push(obj);
        }
      }
      if (currentTimeFrame === 6) {
        selectedDate.setMonth(selectedDate.getMonth() - 6);

        if (selectedDate - date <= 0) {
          selectedPeriodData.push(obj);
        }
      }
      if (currentTimeFrame === 12) {
        selectedDate.setMonth(selectedDate.getMonth() - 12);

        if (selectedDate - date <= 0) {
          selectedPeriodData.push(obj);
        }
      }
      if (currentTimeFrame === 999) {
        selectedPeriodData = dataArray;
      }
      return obj;
    });
    return selectedPeriodData;
  }


  const backgroundPlugin = createBackgroundPlugin(true);

  let allIntervals = selectedProfitData.map((obj) => {
    let date = obj.date;
    if (typeof date === 'string') {
      date = new Date(obj.date);
    }
    let interval;

    if (date.getDate() === 1) {
      interval = date.toLocaleString("default", { month: "short" });
    } else {
      interval = null;
    }
    return interval;
  });

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return interval;
      } else {
        showInterval = true;
        return null;
      }
    }
    return interval;
  });

  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: selectedProfitData.map((obj) => {
          return {
            x: typeof obj.date === 'string' ? new Date(obj.date) : obj.date,
            y: obj[chartMode],
          };
        }),
        borderColor: "#56A07E",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea, "86,160,126");
        },
        fill: "stack",
        yAxisId: "y",
      },
      {
        data: selectedLossData.map((obj) => {
          return {
            x: typeof obj.date === 'string' ? new Date(obj.date) : obj.date,
            y: obj[chartMode],
          };
        }),
        borderColor: "#C15357",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea, "193,83,87");
        },
        fill: "stack",
        yAxisId: "y",
      },
      {
        data: selectedInsiginificantData.map((obj) => {
          return {
            x: typeof obj.date === 'string' ? new Date(obj.date) : obj.date,
            y: obj[chartMode],
          };
        }),
        borderColor: "#999999",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea, "153,153,153");
        },
        fill: "stack",
        yAxisId: "y",
      },
      // feed data to right y axis
      {
        data: insiginificantData.map((obj) => {
          return {
            x: typeof obj.date === 'string' ? new Date(obj.date) : obj.date,
            y: obj[chartMode],
          };
        }),
        yAxisID: "y1",
      },
    ],
  };

  return (
    <div className={styles.ChartContainer}>
      <div className={styles.ChartButtonsContainer}>
        {dualMode ? (
          <div className={styles.ModeButtonsContainer}>
            <div
              className={
                chartMode === "trades"
                  ? `${styles.DualModeButton} ${styles.Selected}`
                  : styles.DualModeButton
              }
              id={styles.Trades}
              onClick={() => setChartMode("trades")}
            >
              Trades
            </div>
            <div
              className={
                chartMode === "lots"
                  ? `${styles.DualModeButton} ${styles.Selected}`
                  : styles.DualModeButton
              }
              id={styles.Lots}
              onClick={() => setChartMode("lots")}
            >
              Lots
            </div>
          </div>
        ) : null}
        {hasTimeFrame ? (
          <div className={styles.TimeButtonsContainer}>
            <div
              className={
                currentTimeFrame === 3
                  ? `${styles.TimeButton} ${styles.Selected}`
                  : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(3)}
            >
              3m
            </div>
            <div
              className={
                currentTimeFrame === 6
                  ? `${styles.TimeButton} ${styles.Selected}`
                  : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(6)}
            >
              6m
            </div>
            <div
              className={
                currentTimeFrame === 12
                  ? `${styles.TimeButton} ${styles.Selected}`
                  : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(12)}
            >
              1yr
            </div>
            <div
              className={
                currentTimeFrame === 999
                  ? `${styles.TimeButton} ${styles.Selected}`
                  : `${styles.TimeButton}`
              }
              onClick={() => setCurrentTimeFrame(999)}
            >
              all
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.LineChartContainer}>
        <Line
          key={Math.random()}
          data={data}
          plugins={[
            backgroundPlugin,
            xAxisPlugin,
            // colorGradientPlugin
          ]}
          options={options}
        />
      </div>
    </div>
  );
}

export default TradesPatternChart;
