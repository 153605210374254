import createBarColorComparison from "components/charts/helpers/createBarColorComparison";
import createHoverBarColorComparison from "components/charts/helpers/createHoverBarColorComparison";
import createBarBorderColorComparison from "components/charts/helpers/createBarBorderColorComparison";
import { chartKeys } from 'utils/constant';

const profit_when_sl_adjusted_vs_profit_when_not = {
  chartTitle: "Profit when SL adjusted vs. profit when not",
  chartKey: chartKeys.PROFIT_WHEN_SL_ADJUSTED_VS_PROFIT_WHEN_NOT,
  createBarColorComparison: createBarColorComparison,
  createHoverBarColorComparison: createHoverBarColorComparison,
  createBarBorderColorComparison: createBarBorderColorComparison,
  hoverTextArray: {
    usd: [
      {
        templateString: "",
        valueKey: "usd",
        unit: "USD/trade",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
    ],
  },
  interval: ["with adjustment", "without adjustment"],
}

export default profit_when_sl_adjusted_vs_profit_when_not;