import SelectComponent from "./SelectComponent";
import styles from "./tradingSoftwareSelection.module.scss";
import htFonts from "shared/htFont.module.scss";
import serverList from 'utils/serverList.json';

export default function TradingSoftwareSelection({
  tradingAccountInformation,
  setTradingAccountInformation,
  setPhase,
  setInfoContentKey,
}) {
  const tradingSoftwareOptions = [
    {
      label: "MetaTrader 4",
      value: "mt4",
    },
  ];

  const brokerOptions = Object.keys(serverList).map(broker => ({
    label: broker,
    value: broker
  }));
  let serverOptions = [];
  if (tradingAccountInformation.broker) {
    serverOptions = serverList[tradingAccountInformation.broker.value].map(server => ({
      label: server.server,
      value: server.server,
      status: server.type
    }));
  }

  function validateSectionInfo() {
    let passed = true;
    const fields = ["tradingSoftware", "broker", "server"];
    fields.forEach((field) => {
      if (!tradingAccountInformation[field]) {
        passed = false;
      }
    });
    return passed;
  }

  const sectionInfoValidated = validateSectionInfo();

  const onServerSelect = (value) => {
    setTradingAccountInformation({
      ...value,
      status: value.server.status
    });
  }

  return (
    <div className={styles.SelectionContainer}>
      <div className={styles.SectionTitle}>Add a trading account</div>
      <div className={styles.SectionDescription}>
        Take a moment to setup your trading account. Please enter all fields.
      </div>
      <form className={styles.Form}>
        <div className={styles.InfoRow}>
          <label className={styles.InfoLabel}>
            Your trading software
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("your_trading_software")}
            ></span>
          </label>
          <SelectComponent
            setFormData={setTradingAccountInformation}
            formData={tradingAccountInformation}
            instanceId="trading-software-select"
            formField="tradingSoftware"
            options={tradingSoftwareOptions}
          />
        </div>
        {tradingAccountInformation.tradingSoftware ? (
          <>
            <div className={styles.InfoRow}>
              <label className={styles.InfoLabel}>
                Your broker
                <span
                  className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
                  id={styles.InfoIcon}
                  onClick={() => setInfoContentKey("your_broker")}
                ></span>
              </label>
              <SelectComponent
                setFormData={(value) => setTradingAccountInformation({...value, server: ""})}
                formData={tradingAccountInformation}
                instanceId="broker-select"
                formField="broker"
                options={brokerOptions}
              />
            </div>
            <div className={styles.InfoRow}>
              <label className={styles.InfoLabel}>
                Your server
                <span
                  className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
                  id={styles.InfoIcon}
                  onClick={() => setInfoContentKey("your_server")}
                ></span>
              </label>
              <SelectComponent
                setFormData={(value) => onServerSelect(value)}
                formData={tradingAccountInformation}
                instanceId="server-select"
                formField="server"
                options={serverOptions}
              />
            </div>
            {sectionInfoValidated ? (
              <div className={styles.NextButton} onClick={() => setPhase(1)}>
                Next
              </div>
            ) : (
              <div className={`${styles.NextButton} ${styles.Disabled}`}>
                Next
              </div>
            )}
          </>
        ) : null}
      </form>
    </div>
  );
}
