import SelectComponent from "./SelectComponent";
import sharedStyles from "./shared.module.scss";
import styles from "./credentialSelection.module.scss";
import htFonts from "shared/htFont.module.scss";

export default function credentialSelection({
  tradingAccountInformation,
  setTradingAccountInformation,
  setPhase,
  submitCredentials,
  setInfoContentKey,
}) {
  const tradingExperienceOptions = [
    {
      label: "< 6 months",
      value: "< 6 moths",
    },
    {
      label: "6 - 12 months",
      value: "6 - 12 months",
    },
    {
      label: "12 - 36 months",
      value: "12 - 36 months",
    },
    {
      label: "> 36 months",
      value: "> 36 months",
    },
  ];

  const tradingTypeOptions = [
    {
      label: "Manual",
      value: "Manual",
    },
    {
      label: "Trading robot (Expert Advisor)",
      value: "Trading robot (Expert Advisor)",
    },
  ];

  const validateCredentialSection = () => {
    let passed = true;
    const fields = [
      "loginId",
      "investorPassword",
      "portfolioName",
      "tradingExperience",
      "tradingType",
    ];
    fields.forEach((field) => {
      if (!tradingAccountInformation[field]) {
        passed = false;
      }
    });
    return passed;
  };

  const sectionInfoValidated = validateCredentialSection();

  const onInputChange = (field, newValue) => {
    const copiedTradingAccountInformation = { ...tradingAccountInformation };
    copiedTradingAccountInformation[field] = newValue;
    setTradingAccountInformation(copiedTradingAccountInformation);
  };

  const onCheckboxChange = (field) => {
    const copiedTradingAccountInformation = { ...tradingAccountInformation };
    copiedTradingAccountInformation[field] =
      !copiedTradingAccountInformation[field];
    setTradingAccountInformation(copiedTradingAccountInformation);
  };

  return (
    <div>
      <form className={styles.Form}>
        <div className={sharedStyles.InfoRow}>
          <label className={sharedStyles.InfoLabel}>
            Login-ID
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("login_id")}
            ></span>
          </label>
          <input
            className={`${sharedStyles.InfoInput} ${styles.Input}`}
            value={tradingAccountInformation["loginId"]}
            onChange={(e) => onInputChange("loginId", e.target.value)}
          />
        </div>
        <div className={sharedStyles.InfoRow}>
          <label className={sharedStyles.InfoLabel}>
            Investor Password
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("investor_password")}
            ></span>
          </label>
          <input
            className={`${sharedStyles.InfoInput} ${styles.Input}`}
            value={tradingAccountInformation["investorPassword"]}
            onChange={(e) => onInputChange("investorPassword", e.target.value)}
          />
        </div>
        <div className={sharedStyles.InfoRow}>
          <label className={sharedStyles.InfoLabel}>
            Portfolio name
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("portfolio_name")}
            ></span>
          </label>
          <input
            className={`${sharedStyles.InfoInput} ${styles.Input}`}
            value={tradingAccountInformation["portfolioName"]}
            onChange={(e) => onInputChange("portfolioName", e.target.value)}
          />
        </div>
        <div className={`${sharedStyles.InfoRow} ${styles.CheckboxRow}`}>
          <input
            type="checkbox"
            checked={tradingAccountInformation["accountPublic"]}
            onChange={(e) => onCheckboxChange("accountPublic")}
          />
          <div>
            <div className={styles.CheckboxLabel}>
              Set this account as public
            </div>
            <div className={styles.CheckboxDescription}>
              For a public account, other people will be able to see your
              trading performance.
            </div>
          </div>
        </div>
        <div className={sharedStyles.InfoRow}>
          <label className={sharedStyles.InfoLabel}>
            Level of trading experience
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("trading_experience")}
            ></span>
          </label>
          <SelectComponent
            setFormData={setTradingAccountInformation}
            formData={tradingAccountInformation}
            instanceId="trading-experience-select"
            formField="tradingExperience"
            options={tradingExperienceOptions}
          />
        </div>
        <div className={sharedStyles.InfoRow}>
          <label className={sharedStyles.InfoLabel}>
            Trading type
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("trading_type")}
            ></span>
          </label>
          <SelectComponent
            setFormData={setTradingAccountInformation}
            formData={tradingAccountInformation}
            instanceId="trading-type-select"
            formField="tradingType"
            options={tradingTypeOptions}
          />
        </div>
        <div className={sharedStyles.InfoRow}>
          <div className={styles.PreviousButton} onClick={() => setPhase(0)}>
            Previous
          </div>
          {sectionInfoValidated ? (
            <div
              className={styles.NextButton}
              onClick={() => submitCredentials()}
            >
              Connect
            </div>
          ) : (
            <div className={`${styles.NextButton} ${styles.Disabled}`}>
              Connect
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
