const drawdown = {
  chartTitle: "Drawdown (balance)",
  999: [
    {
      time: new Date("2022-04"),
      percentage: 29.11,
    },
    {
      time: new Date("2022-05"),
      percentage: 34.82,
    },
    {
      time: new Date("2022-06"),
      percentage: 34.82,
    },
    {
      time: new Date("2022-07"),
      percentage: 34.82,
    },
    {
      time: new Date("2022-08"),
      percentage: 34.82,
    },
    {
      time: new Date("2022-09"),
      percentage: 34.82,
    },
    {
      time: new Date("2022-10"),
      percentage: 34.82,
    },
    {
      time: new Date("2022-11"),
      percentage: 34.82,
    },
    {
      time: new Date("2022-12"),
      percentage: 34.82,
    },
    {
      time: new Date("2023-01"),
      percentage: 34.82,
    },
    {
      time: new Date("2023-02"),
      percentage: 38.28,
    },
    {
      time: new Date("2023-03"),
      percentage: 38.28,
    },
    {
      time: new Date("2023-04"),
      percentage: 38.28,
    },
  ],
  sandboxMode: true
};

export default drawdown;