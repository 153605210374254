import { useEffect, useState } from "react";
import AuthModalUI from "UI/authModalUI";
import { sendTradingAccountCredential, sendCryptoTradingAccountCredential } from "utils/requests";
import { useDispatch } from "react-redux";
import {
  setUser,
  setCurrentTradingAccount,
} from "redux/slices/authenticationSlice";
// phases
import TradingSoftwareSelection from "./tradingSoftwareSelection";
import CredentialSection from "./credentialSelection";
import ConnectingSection from "./connectingSection";
import AccountConnectionResult from "./accountConnectionResult";
import ProgressDots from "UI/progressDots";
// crypto phases
import CryptoTradingSoftwareSelection from "./addCryptoTradingAccountModal/cryptoTradingSoftwareSelection";
import CryptoCredentialSection from "./addCryptoTradingAccountModal/cryptoCredentialSection";
//
import AccountTypeSelection from "./accountTypeSelection";
// info modal
import InputInfo from "./inputInfo";

export default function AddTradeAccount() {
  const [currentPhase, setPhase] = useState(0);
  const [tradingAccountInformation, setTradingAccountInformation] = useState({
    tradingSoftware: "",
    broker: "",
    server: "",
    loginId: "",
    investorPassword: "",
    portfolioName: "",
    tradingExperience: "",
    tradingType: "",
    accountPublic: false,
    status: "",
  });
  const [accountConnectionResult, setAccountConnectionResult] =
    useState("INIT");
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  // crypto
  const [accountType, setAccountType] = useState("");
  const [cryptoTradingAccountInfo, setCryptoTradingAccountInfo] = useState({
    tradingSoftware: "",
    apiKey: "",
    apiSecret: "",
    portfolioName: "",
    tradingExperience: "",
    tradingType: "",
  });
  const [infoContentKey, setInfoContentKey] = useState("");

  const submitCredentials = async () => {
    setIsPending(true);
    const data = {
      Broker: tradingAccountInformation.broker.value,
      Server: tradingAccountInformation.server.value,
      AccountId: tradingAccountInformation.loginId,
      Password: tradingAccountInformation.investorPassword,
      portfolioName: tradingAccountInformation.portfolioName,
      tradingExperience: tradingAccountInformation.tradingExperience.value,
      tradingType: tradingAccountInformation.tradingType.value,
      accountPublic: tradingAccountInformation.accountPublic,
      tradingSoftware: tradingAccountInformation.tradingSoftware.value,
      status: tradingAccountInformation.status
    };

    const result = await sendTradingAccountCredential(data);
    if (result.message === "TRADING_ACCOUNT_ADDED") {
      setAccountConnectionResult("LINKED");
      setIsPending(false);
      dispatch(
        setUser({
          user: result.data.user,
        })
      );
      if (result.data.user.trading_accounts.length === 1) {
        dispatch(
          setCurrentTradingAccount({
            currentTradingAccount: result.data.user.trading_accounts[0],
          })
        );
      }
      setPhase(2);
    } else {
      setAccountConnectionResult("FAILED");
      setIsPending(false);
      setPhase(2);
    }
  };

  const submitCryptoCredentials = async () => {
    setIsPending(true);
    const data = {
      tradingSoftware: cryptoTradingAccountInfo.tradingSoftware,
      apiKey: cryptoTradingAccountInfo.apiKey,
      apiSecret: cryptoTradingAccountInfo.apiSecret,
      portfolioName: cryptoTradingAccountInfo.portfolioName,
      tradingExperience: cryptoTradingAccountInfo.tradingExperience.value,
      tradingType: cryptoTradingAccountInfo.tradingType.value,
    };

    const result = await sendCryptoTradingAccountCredential(data);
    if (result.message === "TRADING_ACCOUNT_ADDED") {
      setAccountConnectionResult("LINKED");
      setIsPending(false);
      dispatch(
        setUser({
          user: result.data.user,
        })
      );
      if (result.data.user.trading_accounts.length === 1) {
        dispatch(
          setCurrentTradingAccount({
            currentTradingAccount: result.data.user.trading_accounts[0],
          })
        );
      }
      setPhase(2);
    } else {
      setAccountConnectionResult("FAILED");
      setIsPending(false);
      setPhase(2);
    }
  };

  let phaseComponents;
  if (accountType === "forex") {
    phaseComponents = [
      <TradingSoftwareSelection
        tradingAccountInformation={tradingAccountInformation}
        setTradingAccountInformation={setTradingAccountInformation}
        setPhase={setPhase}
        setInfoContentKey={setInfoContentKey}
      />,
      <CredentialSection
        tradingAccountInformation={tradingAccountInformation}
        setTradingAccountInformation={setTradingAccountInformation}
        setPhase={setPhase}
        setAccountConnectionResult={setAccountConnectionResult}
        setIsPending={setIsPending}
        submitCredentials={submitCredentials}
        setInfoContentKey={setInfoContentKey}
      />,
      <AccountConnectionResult
        setPhase={setPhase}
        accountConnectionResult={accountConnectionResult}
      />,
    ];
  } else if (accountType === "crypto") {
    phaseComponents = [
      <CryptoTradingSoftwareSelection
        cryptoTradingAccountInfo={cryptoTradingAccountInfo}
        setCryptoTradingAccountInfo={setCryptoTradingAccountInfo}
        setPhase={setPhase}
        setInfoContentKey={setInfoContentKey}
      />,
      <CryptoCredentialSection
        cryptoTradingAccountInfo={cryptoTradingAccountInfo}
        setCryptoTradingAccountInfo={setCryptoTradingAccountInfo}
        setPhase={setPhase}
        submitCredentials={submitCryptoCredentials}
        setInfoContentKey={setInfoContentKey}
      />,
      <AccountConnectionResult
        setPhase={setPhase}
        accountConnectionResult={accountConnectionResult}
      />,
    ];
  } else {
    phaseComponents = null;
  }

  return (
    <>
      {infoContentKey ? (
        <InputInfo
          infoContentKey={infoContentKey}
          setInfoContentKey={setInfoContentKey}
        />
      ) : (
        <AuthModalUI>
          <ProgressDots currentPhase={currentPhase} phases={[0, 1, 2]} />
          {accountType !== "" ? (
            <>
              {isPending ? (
                <ConnectingSection setPhase={setPhase} />
              ) : (
                phaseComponents[currentPhase]
              )}
            </>
          ) : (
            <AccountTypeSelection setAccountType={setAccountType} />
          )}
        </AuthModalUI>
      )}
    </>
  );
}
