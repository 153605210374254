const drawVerticalLine = (ctx, x, y2, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x, 10);
  ctx.lineTo(x, y2);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawCircleOnBar = (ctx, x, y, strokeStyle, fillStyle) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle;
  ctx.fillStyle = fillStyle;
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
};

const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1, y);
  ctx.lineTo(x2, y);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const measureTextWidth = (ctx, textArray, hoveredDataPoint) => {
  let textStringWidth = 0;
  textArray.forEach((object, index) => {
    if (!hoveredDataPoint.raw[object.valueKey]) {
      return;
    }
    if (index === textArray.length - 1) {
      const a = object.templateString;
      let b;
      if (object["unit"]) {
        b = `${hoveredDataPoint.raw[object.valueKey]}${object["unit"]}`;
      } else {
        b = `${hoveredDataPoint.raw[object.valueKey]}`;
      }
      textStringWidth += ctx.measureText(a + b).width;
    } else {
      const a = object.templateString;
      let b;
      if (object["unit"]) {
        b = `${hoveredDataPoint.raw[object.valueKey]}${object["unit"]}`;
      } else {
        b = `${hoveredDataPoint.raw[object.valueKey]}}`;
      }
      textStringWidth += ctx.measureText(a + b).width + 15;
    }
  })
  return textStringWidth;
}

const drawOnHoverText = (textArray, ctx, hoveredDataPoint) => {
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "white";
  const textStringWidth = measureTextWidth(ctx, textArray, hoveredDataPoint);
  const chartArea = hoveredDataPoint.chart.chartArea;
  const centerX = (chartArea.right + chartArea.left) / 2;
  let currentX = centerX - textStringWidth / 2;

  for (let i = 0; i < textArray.length; i++) {
    const textObject = textArray[i];
    if (!hoveredDataPoint.raw[textObject.valueKey]) {
      continue;
    }
    ctx.fillText(textObject.templateString, currentX, 15);
    currentX += ctx.measureText(textObject.templateString).width;

    let textString = `${hoveredDataPoint.raw[textObject.valueKey].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    if (textObject['unit']) {
      textString += `${textObject['unit']}`;
    }

    if (textObject["textColor"]) {
      ctx.save();
      ctx.fillStyle = hoveredDataPoint.raw.color;
      ctx.font = "700 12px Noto Sans";
      ctx.fillText(textString, currentX, 15);
      currentX += ctx.measureText(textString).width;
      ctx.restore();
    } else {
      ctx.save();
      ctx.font = "700 12px Noto Sans";
      ctx.fillText(textString, currentX, 15);
      currentX += ctx.measureText(textString).width;
      ctx.restore();
    }
    if (i === 0) {
      currentX += 0;
    } else {
      currentX += 15;
    }
  }
}

const createOnHoverPlugin = (hoverTextArray) => {
  const onHoverPlugin = {
    id: "on_bar_hover_plugin",
    beforeDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
      if (tooltip._active.length) {
      }
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const barElement = tooltip.dataPoints[0].element;

        const chartArea = chart.chartArea;
        ctx.save();
        drawVerticalLine(ctx, barElement.x, chartArea.bottom, "#06AFC9");
        drawCircleOnBar(ctx, barElement.x, barElement.y, "#06AFC9", "white");
        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          20,
          "#00333B"
        );

        drawHorizontalLines(
          ctx,
          chartArea.left - 20,
          chartArea.left + chartArea.width,
          0.5,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left - 20,
          chartArea.left + chartArea.width,
          20.5,
          "#06AFC9"
        );
        drawOnHoverText(hoverTextArray, ctx, tooltip.dataPoints[0])
        ctx.restore();
      }
    },
  };

  return onHoverPlugin;
};

export default createOnHoverPlugin;