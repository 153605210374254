import cryptoChartDataObjectMap from "utils/constant/cryptoChartDataObjectMap";
import processWithBenchmarksCrypto from './processWithBenchmarksCrypto';

export default function createDataObjectForCryptoChart(chartKey, chartData) {
  let chartDataObject = {
    ...cryptoChartDataObjectMap[chartKey],
    ...chartData
  };
  if (chartData.benchmarks) {
    chartDataObject = processWithBenchmarksCrypto(chartDataObject);
  }

  return chartDataObject;
}