import styles from "./tradeMedicContent.module.scss";
import htFonts from "shared/htFont.module.scss";
import IssueCard from "components/tradeMedicResponsive/issueCard";
import FullReport from "components/tradeMedicResponsive/fullReport";
import RiskReport from "components/tradeMedicResponsive/riskReport";
import AreaSeverityItem from "components/tradeMedicResponsive/areaSeverityItem";
import FaqSection from "components/tradeMedicResponsive/faqSection";
// import Spinner from "UI/spinner";
import { useDispatch } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
//constants
import * as modalTypes from "constants/modalTypes";

//responsive
import yamarketsLogo from "../../assets/logo-yamarkets.png";
import hocTradeLogo from "../../assets/hoc-trade-logo-green.png";

function TradeMedicContent({ tradeMedicData }) {
  const dispatch = useDispatch();
  const tradeMedicTableData = tradeMedicData.tradeMedic;
  const areaSeverityData = tradeMedicData.tradeMedicAreaSeverity;
  const tradeAccountUniqueID = tradeMedicData.tradingAccountUniqueId;
  const accountIdStringArray = tradeAccountUniqueID.split(":");
  const areaSeverityItems = Object.keys(areaSeverityData).map(key => <AreaSeverityItem areaName={key} data={areaSeverityData[key]} key={key} />)

  let riskReportIssues = [];
  let fullReportIssues = [];
  for (const key in tradeMedicTableData) {
    if (tradeMedicTableData[key].issue_type === 1) {
      riskReportIssues.push(tradeMedicTableData[key]);
    } else {
      fullReportIssues.push(tradeMedicTableData[key]);
    }
  };

  fullReportIssues.sort((a, b) => {
    return a.sequence - b.sequence;
  });
  const criticalIssues = fullReportIssues.slice(0, 4);

  const onClickGeneralInfoHandler = (e) => {
    e.preventDefault();
    return dispatch(
      openModal({
        displayedModal: modalTypes.RESPONSIVE_TRADE_MEDIC_INFO_MODAL,
        selectedTradeMedicData: {
          issue_key: "general",
          issue_name: "Trade Medic",
          chart_key: "",
          account_id: "",
          issue_type: 0,
          sequence: 0,
          severity: 0,
          performance_effect: 0,
        },
      })
    );
  };

  const onClickEightPointsAssessmentInfoHandler = (e) => {
    e.preventDefault();
    return dispatch(
      openModal({
        displayedModal: modalTypes.RESPONSIVE_TRADE_MEDIC_INFO_MODAL,
        selectedTradeMedicData: {
          issue_key: "eight-points-assessment",
          issue_name: "The 8 point issue area assessment",
          chart_key: "",
          account_id: "",
          issue_type: 0,
          sequence: 0,
          severity: 0,
          performance_effect: 0,
        },
      })
    );
  };

  return (
    <div className={styles.TradeMedicContentWrapper}>
      <div className={styles.TradeMedicContentHeader}>
        <div className={styles.LogoBox}>
          <img
            src={yamarketsLogo}
            alt="broker-logo"
            className={styles.Logo}
          ></img>
          <div className={styles.PowerBy}>
            Powered by&nbsp;&nbsp;
            <img
              src={hocTradeLogo}
              alt="hoc-trade-logo"
              className={styles.HocTradeLogo}
            ></img>
          </div>
        </div>
        <div className={styles.SessionBox}>
          <div className={styles.SessionText}>
            <strong>Account ID</strong>
          </div>
          <div className={styles.SessionText}>{accountIdStringArray[1]}</div>
          <div className={styles.SessionText}>
            <strong>Last updated</strong>
          </div>
          <div className={styles.SessionText}>{tradeMedicData.updateTime}</div>
        </div>
      </div>
      <div className={styles.TradeMedicMainContent}>
        <div className={styles.Disclaimer}>
          <strong>Disclaimer: </strong>Please be cautious interpreting the
          presented information and always do your own research. None of the
          information provided should be considered financial advice, it may
          have errors, it is not mutually exclusive nor collectively exhaustive,
          and we cannot in any way be held liable for any losses or influences
          of those information on your trading.
        </div>
        <div className={styles.TradeMedicTableWrapper}>
          <div className={styles.TableTitle}>
            <div className={styles.TitleLeft}>
              <span className={htFonts.htMedbox}></span>
              Your trade medic report
            </div>
            <div
              className={styles.InfoIconContainer}
              onClick={(e) => onClickGeneralInfoHandler(e)}
            >
              <span className={htFonts.htInfo}></span>
            </div>
          </div>
          <div className={styles.Top4IssuesWrapper}>
            <div className={styles.Top4IssuesTitle}>
              <span className={htFonts.htStar}></span>
              TOP 4 critical issues
            </div>
            <div className={styles.IssueCardsWrapper}>
              {criticalIssues.map((issue, index) => {
                return (
                  <IssueCard
                    criticalIssue={issue}
                    index={index}
                    key={`issue-card-${issue.issue_name}`}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.AreaSeverityWrapper}>
            <div className={styles.SectionHeader}>
              <div className={styles.SectionTitle}>
                Discover your trading issues
              </div>
              <div
                className={styles.InfoIconContainer}
                onClick={(e) => onClickEightPointsAssessmentInfoHandler(e)}
              >
                <span className={htFonts.htInfo}></span>
              </div>
            </div>
            <div className={styles.SectionContent}>
              {areaSeverityItems}
            </div>
            <div className={styles.SectionFooter}>
              <strong>Severity:</strong>
              <span className={styles.GreenCircle}></span>Low
              <span className={styles.RedCircle}></span>High
            </div>
          </div>
          <FullReport fullReportIssues={fullReportIssues} />
          <RiskReport riskReportIssues={riskReportIssues} />
          <FaqSection />
          <div className={styles.Filler}></div>
        </div>
      </div>
      <div className={styles.TradeMedicFooter}>
          <p>HIGH RISK WARNING: Foreign exchange and other CFD trading carries a high level of risk and may not be suitable for everyone. The usage of leverage creates additional risk and loss exposure. Your investment objectives, experience level and risk tolerance have to be taken into consideration before you decide to trade foreign exchange or other CFD products. You could lose some or all of your entire investment. Do not invest money that you cannot afford to lose and always use an appropriate risk management. It’s important to educate yourself on the risks associated with foreign exchange trading, and seek professional advice from an independent financial or tax advisor in case of any questions. Any data and information is provided ‘as is’ solely for informational purposes, and is not intended for trading purposes or advice. Past performance, which are presented on this website or you find somewhere else, is not indicative of future results.</p>
        </div>
    </div>
  );
}

export default TradeMedicContent;
