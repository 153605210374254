import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
//reducers
import { modalReducer } from 'redux/slices/modalSlice';
import { authenticationReducer } from './slices/authenticationSlice';
import { chartReducer } from './slices/chartSlices';
import { alertReducer } from './slices/alertSlice';
import { sandboxChartReducer } from './slices/sandboxChartSlice';
import { tradeMedicReducer } from './slices/tradeMedicSlice';
import { sandboxTradeMedicReducer } from './slices/sandboxTradeMedicSlice';
import { tradeHistoryReducer } from './slices/tradeHistorySlice';
import { responsiveTradeMedicChartsDataReducer } from './slices/responsiveTradeMedicChartsDataSlice';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

export default configureStore({
  reducer: {
    modal: modalReducer,
    authentication: authenticationReducer,
    chart: chartReducer,
    alert: alertReducer,
    sandboxChart: sandboxChartReducer,
    tradeMedicData: tradeMedicReducer,
    sandboxTradeMedicData: sandboxTradeMedicReducer,
    tradeHistory: tradeHistoryReducer,
    responsiveTradeMedicChartsData: responsiveTradeMedicChartsDataReducer
  },
}, composedEnhancer);