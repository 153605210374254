const riskReturnHitRatioOptions = (chartData) => {
  return {
    animation: {
      duration: 0,
    },
    interaction: {
      mode: "x",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      autocolors: false,
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: chartData.riskReturnRatioBenchmark,
            yMax: chartData.riskReturnRatioBenchmark,
            borderColor: '#FFB700',
            borderWidth: 1,
            borderDash: [1,2],
            yScaleID: "y",
          },
          label1: {
            drawTime: "afterDraw",
            type: "label",
            xValue: 0,
            yValue: chartData.riskReturnRatioBenchmark,
            backgroundColor: "#FFB700",
            content: ["RRR"],
            font: {
              size: 10,
            },
            height: 0.5,
            padding: {
              left: 3,
              right: 2,
              top: -1,
              bottom: -1,
            },
            borderRadius: 3,
            yScaleID: "y",
          },
          line2: {
            type: 'line',
            yMin: chartData.hitRatioBenchmark,
            yMax: chartData.hitRatioBenchmark,
            borderColor: '#F15A24',
            borderWidth: 1,
            borderDash: [1,2],
            yScaleID: "y1"
          },
          label2: {
            drawTime: "afterDraw",
            type: "label",
            xValue: chartData[999].hitRatio.length-1,
            yValue: chartData.hitRatioBenchmark,
            backgroundColor: "#F15A24",
            color: "white",
            content: ["HR"],
            font: {
              size: 10,
            },
            height: 0.5,
            padding: {
              left: 3,
              right: 2,
              top: -1,
              bottom: -1,
            },
            borderRadius: 3,
            yScaleID: "y1"
          },
        }
      }
    },
    layout: {
      padding: {
        bottom: 5,
        left: 10,
        top: 60,
        // right: 10
      },
    },
    borderRadius: 3,
    maxBarThickness: 10,
    borderWidth: 1,
    scales: {
      y: {
        grid: {
          color: (context) => {
            return '#4D4D4D'
          },
          borderDash: (a) => {
            return [1, 2];
          },
          // borderColor: '#4D4D4D'
          drawBorder: false,
          tickLength: -1,
          tickBorderDash: (a) => {
            return [1, 2];
          }
        },
        ticks: {
          color: "#0077C0",
          font: {
            size: 10,
            family: 'Noto Sans',
            weight: 700
          },
          labelOffset: -7,
          padding: 0,
          mirror: true,
          maxTicksLimit: 5,
          callback: function (value, index, ticks) {
            if (index === ticks.length-1) {
              return ["Return risk ratio", value, ""];
            } else if (index === 1 || index === 3) {
              return ""
            } else {
              return value
            }
          }
        },
      },
      y1: {
        max: 100,
        min: 0,
        position: "right",
        grid: {    
          tickLength: 0,
        },
        ticks: {
          color: "#047F92",
          font: {
            size: 10,
            family: "Noto Sans",
            weight: 700,
          },
          labelOffset: -7,
          padding: 10,
          mirror: true,
          maxTicksLimit: 5,
          callback: function (value, index, ticks) {
            if (value === 0) {
              return value;
            } else if (index === ticks.length - 1) {
              return ["Hit ratio", "%", value];
            } else if (index === 1) {
              return value;
            } else {
              return "";
            }
          },
        },
      },
      x: {
        grid: {
          offset: false,
          color: '#4D4D4D',
          borderDash: [1, 2],
          borderColor: '#4D4D4D',
          borderWidth: 1,
          tickColor: "#4D4D4D",
          tickLength: 4,
        },
        ticks: {
          padding: 0,
          color: "#999999",
          font: {
            size: 10
          },
        },
      }
    },
  }
};

export default riskReturnHitRatioOptions;