import styles from "./signUpModal.module.scss";

import { useState } from "react";
import AuthModalUI from "UI/authModalUI";
// Body component
import SignUpMethods from "./auth/signUpMethods";
import SignUpForm from "./auth/signUpForm";
import Captcha from "./auth/captcha";
import ConfirmedEmail from "./auth/confirmedEmail";
import RegisterCompleted from './auth/registerCompleted';
import Spinner from "UI/spinner";

function SignUpModal() {
  const [currentPhase, setSignUpPhase] = useState(1);
  const [signUpData, setSignUpData] = useState(null);
  const [emailVerificationCredential, setEmailVerificationCredential] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);

  let body = null;
  
  switch (currentPhase) {
    case 0:
      body = <SignUpMethods setSignUpPhase={setSignUpPhase} />;
      break;
    case 1:
      body = <SignUpForm 
        setSignUpPhase={setSignUpPhase} 
        setSignUpData={setSignUpData}
        errorMessage={errorMessage}/>;
      break;
    case 2:
      body = <Captcha 
        setSignUpPhase={setSignUpPhase} 
        signUpData={signUpData} 
        setEmailVerificationCredential={setEmailVerificationCredential}
        setErrorMessage={setErrorMessage} 
        setIsPending={setIsPending} />;
      break;
    case 3:
      body = <ConfirmedEmail 
        emailVerificationCredential={emailVerificationCredential}
        email={signUpData.email}
        setEmailVerificationCredential={setEmailVerificationCredential}
        setSignUpPhase={setSignUpPhase} />
      break;
    case 4:
      body = <RegisterCompleted />
      break;
    default:
      body = <div>Page loading...</div>;
  }

  let phaseSections = null;
  // Set the elements of phases to 4. Will need to add more if we have more phases.
  let phases = [0, 1, 2, 3];
  if (currentPhase !== 0) {
    phaseSections = phases.map((phase) => {
      if (phase < currentPhase) {
        return (
          <div
            key={phase}
            id={`styles${phase}`}
            style={{
              backgroundColor: "#047F92",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          ></div>
        );
      } else {
        return (
          <div
            key={phase}
            id={`styles${phase}`}
            style={{
              backgroundColor: "#999999",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          ></div>
        );
      }
    });
  }

  return (
    <div>
      {
        isPending? 
        <Spinner /> : 
        <>
          <div className={styles.phaseSection}>{phaseSections}</div>
          { body }
        </>
      }
    </div>
  );
}

export default SignUpModal;
