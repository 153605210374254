import { useState, useEffect } from 'react';
import styles from "../../../dashboard/overview/basicStatistics.module.scss";
// dummy data
import {
  balance,
  performancePips,
  symbols,
  performanceByMonth,
  averagePipsTrade,
  averageTradeLength,
  averageTradesPerDay
} from "devData/chartsData/index";
// components
import Balance from 'components/overview/basicStatic/balance/balance.chart';
import PerformancePips from 'components/overview/basicStatic/performancePips/performancePips';
import Symbols from 'components/overview/basicStatic/symbols/symbols';
import BarChart from 'components/overview/basicStatic/barChart/barChart';
import Spinner from 'UI/spinner';

function SandboxBasicStatistics() {
  const [chartsData, setChartsData] = useState(null);

  useEffect(() => {
    setChartsData({
      balance,
      performancePips,
      symbols,
      performanceByMonth,
      averagePipsTrade,
      averageTradeLength,
      averageTradesPerDay    
    });
  }, []);

  if (!chartsData) {
    return (
      <div className={styles.Subpage}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.Subpage}>
      <Balance 
        key="Balance (USD)"
        chartTitle="Balance (USD)"
        chartData={chartsData["balance"]}
      />
      <PerformancePips 
        key="Performance (pips)"
        chartTitle="Performance (pips)"
        chartData={chartsData["performancePips"]}
      />
      <Symbols 
        key="Symbols"
        chartTitle="Symbols"
        chartData={chartsData["symbols"]}
      />
      <BarChart 
        key="Performance by month"
        chartTitle="Performance by month"
        barValueKey="percentage"
        chartData={chartsData["performanceByMonth"]}        
      />
      <BarChart 
        key="Average pips per trade"
        chartTitle="Average pips per trade"
        barValueKey="pips"
        chartData={chartsData["averagePipsTrade"]}        
      />
      <BarChart 
        key="Average trade length (mins)"
        chartTitle="Average trade length (mins)"
        barValueKey="mins"
        chartData={chartsData["averageTradeLength"]}        
      />
      <BarChart 
        key="Average trades per day"
        chartTitle="Average trades per day"
        barValueKey="trades"
        chartData={chartsData["averageTradesPerDay"]}        
      />
    </div>
  )
}

export default SandboxBasicStatistics;