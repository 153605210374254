import { chartKeys } from 'utils/constant';

const profit_by_trade_length_per_trade = {
  chartTitle: "Profit By Symbol (per trade)",
  chartKey: chartKeys.PROFIT_BY_TRADE_LENGTH_PER_TRADE,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit:'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit:'USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ]
  },
  interval: [
    "0m-5m",
    "5m-15m",
    "15m-1h",
    "1h-3h",
    "3h-1d",
    "1d+"
  ],
}

export default profit_by_trade_length_per_trade;