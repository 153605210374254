import createBarColorComparison from "components/charts/helpers/createBarColorComparison";
import createHoverBarColorComparison from "components/charts/helpers/createHoverBarColorComparison";
import createBarBorderColorComparison from "components/charts/helpers/createBarBorderColorComparison";
import { chartKeys } from 'utils/constant';

const profit_after_withdrawal_in_pips = {
  chartTitle: "Profit after withdraw",
  createBarColorComparison: createBarColorComparison,
  createHoverBarColorComparison: createHoverBarColorComparison,
  createBarBorderColorComparison: createBarBorderColorComparison,
  chartKey: chartKeys.PROFIT_AFTER_WITHDRAWAL_IN_PIPS,
  hoverTextArray: {
    usd: [
      {
        templateString: "",
        valueKey: "usd",
        unit: "USD/trade",
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "usdBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        },
        unit: ""
      },
    ],
  },
  haspercentageBenchmark: true,
  interval: ["average", "after withdrawl"],
}

export default profit_after_withdrawal_in_pips;