const drawVerticalLine = (ctx, x, y2, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x, 20);
  ctx.lineTo(x, y2);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawCircleOnBar = (ctx, x, y, strokeStyle, fillStyle) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle;
  ctx.fillStyle = fillStyle;
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
};

const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1-15, y);
  ctx.lineTo(x2, y);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawOnHoverText = (ctx, hoveredDataPoint) => { 
  // Measure text width
  let textStringWidth = 0;
  let timeObject = hoveredDataPoint.raw.x;
  let timeOffsetInMillSecs = (timeObject.getTimezoneOffset())*60*1000;
  let timestamp = (timeObject.getTime());
  let clientTime = new Date(timestamp+timeOffsetInMillSecs);
  let time = clientTime.toLocaleString("default", { year: "numeric", month: "short" });
  let profit = hoveredDataPoint.raw.y;
  let unit;
  let profitInUnit;
  if (profit === hoveredDataPoint.raw.percentage) {
    unit = "%";
    profitInUnit = profit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  if (profit === hoveredDataPoint.raw.usd) {
    unit = "USD";
    profitInUnit = profit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  let textString = `Time: ${time} Profit: ${profit}${unit}`;
  textStringWidth += ctx.measureText(textString).width;
  let hoveredDataColor = hoveredDataPoint.dataset.backgroundColor;

  // Get start point
  const chartArea = hoveredDataPoint.chart.chartArea;
  const centerX = (chartArea.right + chartArea.left) / 2 -20;
  let currentX = centerX - textStringWidth / 2;

  ctx.save();
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "#FFFFFF";
  ctx.fillText("Time: ", currentX, 15);
  currentX += ctx.measureText("Time: ").width;
  ctx.font = "700 12px sans-serif";
  ctx.fillText(time, currentX, 15);
  currentX += ctx.measureText(time).width + 20;
  ctx.font = "12px sans-serif";
  ctx.fillText("Profit: ", currentX, 15);
  currentX += ctx.measureText("Profit: ").width;
  ctx.font = "700 12px sans-serif";
  ctx.fillStyle = hoveredDataColor;
  ctx.fillText(profitInUnit+unit, currentX, 15);
  currentX += ctx.measureText(profit).width;
};

const createHoverTextPlugin = () => {
  const hoverPlugin = {
    id: "risk_return_hit_ratio_hover_text_plugin",
    beforeDraw: (chart) => {
      // disable original tooltips
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;

      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const pointElement = tooltip.dataPoints[0].element;
        const chartArea = chart.chartArea;

        drawVerticalLine(ctx, pointElement.x, chartArea.bottom, "#06AFC9");
        drawCircleOnBar(ctx, pointElement.x, pointElement.y, "#06AFC9", "white");
        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          0,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          0.5,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          20.5,
          "#06AFC9"
        );

        drawOnHoverText(ctx, tooltip.dataPoints[0]);
      }
    }

  }

  return hoverPlugin
}

export default createHoverTextPlugin;