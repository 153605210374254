import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartHeader from "../../../charts/chartHeader";
import styles from "./barChart.module.scss";
import createOptions from "./bar.options";
import createBarColors from "./createBarColor";

//plugins
import xAxisPlugin from "./xAxis.plugin";
import createBackgroundPlugin from "components/charts/plugins/createBackground.plugin";
import createHoverTextPlugin from "./hoverText.plugin";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function BarChart(props) {
  const { chartData, barValueKey } = props;
  const backgroundPlugin = createBackgroundPlugin(true);
  const options = createOptions(barValueKey, chartData[999]);
  const barColor = createBarColors(barValueKey, chartData[999]);
  const hoverTextPlugin = createHoverTextPlugin(chartData[999], barValueKey);

  let allIntervals = chartData[999].map((data) => {
    let time = typeof data.time === 'string'? new Date(data.time) : data.time;
    let timeOffsetInMillSecs = (time.getTimezoneOffset())*60*1000;
    let timestamp = (time.getTime());
    let clientTime = new Date(timestamp+timeOffsetInMillSecs);
    
    return clientTime;
  })

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval.getMonth() === 0) {
      return interval = interval.toLocaleString("default", { year: "numeric" })
    }
    
    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return interval = interval.toLocaleString("default", { month: "short" });
      } else {
        showInterval = true;
        return "";
      }
    }
    return interval;
  });

  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: chartData[999].map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data[barValueKey]
          }
        }),
        yAxisId: "y",
        backgroundColor: barColor,
      },
    ]

  }

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader
        chartTitle={chartData.chartTitle}
        chartKey={chartData.chartKey}
      />
      <div className={styles.Chart}>
        <Bar 
          key={Math.random()}
          data={data}
          options={options}
          plugins={[
            backgroundPlugin,
            xAxisPlugin,
            hoverTextPlugin
          ]}
        />
      </div>
    </div>
  )
}

export default BarChart;