import styles from "./advertisement.module.scss";
import roboForexBanner from "../../assets/advertisementBanners/roboForexBanner1.png";

function RoboForex() {
  return (
    <>
      <div className={styles.BannerContainer}>
        <a
          href="https://www.roboforex.com/forex-trading/assets/stocks/?utm_source=domain&utm_medium=affbanerimg&utm_content=size250_250&utm_campaign=affrtrader&a=urud"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={roboForexBanner} alt="Banner"></img>
        </a>
      </div>
      <div className={styles.MobileBannerContainer} style={{marginTop: "20px"}}>
        <a
          href="https://www.roboforex.com/forex-trading/assets/stocks/?utm_source=domain&utm_medium=affbanerimg&utm_content=size250_250&utm_campaign=affrtrader&a=urud"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={roboForexBanner} alt="Banner"></img>
        </a>
      </div>
    </>
  );
}

export default RoboForex;
