import createDiagonalPattern from "./createDiagonalPattern";

// create the bar color for two-bar chart, the lower one is red and the higher one is green.
function createBarColorComparison(chartData, currentTimeFrame, chartMode) {
  const intervals = chartData.interval;

  let highValue;
  intervals.forEach(interval => {
    const barData = chartData[currentTimeFrame].data[interval];
    if (!highValue || barData[chartMode] > highValue) {
      highValue = barData[chartMode];
    }
  });

  const barColors = intervals.map(interval => {
    const barData = chartData[currentTimeFrame].data[interval];
    const tradeCount = barData.trades;

    if (tradeCount < 5 ) {
      return createDiagonalPattern("#CCCCCC");
    } else if (tradeCount > 20) {
      if (barData[chartMode] === highValue) {
        //high value color
        return '#56A07E';
      } else {
        // low value color
        return '#C15357';
      }
    } else {
      if (barData[chartMode] === highValue) {
        //high value color
        return createDiagonalPattern('#56A07E')
      } else {
        // low value color
        return createDiagonalPattern('#C15357')
      }
    }
  });

  return barColors;
}

export default createBarColorComparison;
