const chartKeys = {
  //General page
  PROFIT_BY_DAYTIME_PER_TRADE: "profit_by_daytime_per_trade",
  LONG_AND_SHORT_PER_TRADE: "long_and_short_per_trade",
  PROFIT_BY_SYMBOL_PER_TRADE: "profit_by_symbol_per_trade",
  PROFIT_BY_TRADE_LENGTH_PER_TRADE: "profit_by_trade_length_per_trade",
  PROFIT_BY_WEEKDAY_PER_TRADE: "profit_by_weekday_per_trade",
  //Behavior page
  TRADES_PER_DAY_PER_TRADE: "trades_per_day_per_trade",
  BREAK_SINCE_LAST_TRADE_PER_TRADE: "break_since_last_trade_per_trade",
  BREAK_AFTER_LOSS_TRADE_PER_TRADE: "break_after_loss_trade_per_trade",
  OPEN_DIFFERENT_SYMBOLS_PER_TRADE: "open_different_symbols_per_trade",
  OPEN_POSITION_IN_PROFIT_LOSS: "open_position_in_profit_loss",
  PROFIT_AFTER_DAILY_PROFIT_LOSS: "profit_after_daily_profit_loss",
  //Timing page
  PROFIT_IF_TRADE_OPEN_EARLIER_LATER: "profit_if_trade_open_earlier_later",
  PROFIT_IF_TRADE_CLOSE_EARLIER_LATER: "profit_if_trade_close_earlier_later",
  PROFIT_IF_TP_CLOSER_FURTHER_AWAY: "profit_if_tp_closer_further_away",
  PROFIT_IF_SL_CLOSER_FURTHER_AWAY: "profit_if_sl_closer_further_away",
  PROFIT_IF_MANUAL_STOP_BEFORE_TP: "profit_if_manual_stop_before_tp",
  PERCENT_OF_TRADES_STOPPED_EARLY_IN_PROFIT: "percent_of_trades_stopped_early_in_profit",
  //Risk Management page
  RISK_AFTER_LOSING_TRADES_PER_TRADE: "risk_after_losing_trades_per_trade",
  RISK_AFTER_LOSING_TRADES_OPEN_TRADE: "risk_after_losing_trades_open_trade",
  PROFIT_IF_ADDING_TO_NEGATIVE_POSITIONS: "profit_if_adding_to_negative_positions",
  PROFIT_SECURE_PAYOFF_FROM_REVERTING_TRADES: "profit_secure_payoff_from_reverting_trades",
  RISK_PER_TRADE_LAST_TRADES: "risk_per_trade_last_trades",
  MAX_RISK_ALL_OPEN_TRADES_LAST_TRADES: "max_risk_all_open_trades_last_trades",
  PERCENTAGE_OF_POSITION_WITH_SL: "percentage_of_position_with_sl",
  //Strategy page
  PROFIT_HIGH_VS_LOW_VOLATILITY: "profit_high_vs_low_volatility",
  PROFIT_WITH_AGAINST_ST_MT_TREND: "profit_with_against_st_mt_trend",
  PROFIT_WHEN_SL_ADJUSTED_VS_PROFIT_WHEN_NOT: "profit_when_sl_adjusted_vs_profit_when_not",
  PROFIT_IF_PUBLISHING_TRADES: "profit_if_publishing_trades",
  PROFIT_AFTER_WITHDRAWAL_IN_PIPS: "profit_after_withdrawal_in_pips",
  AVERAGE_PAYOFF_WIN_VS_LOSS_TRADE: "average_payoff_win_vs_loss_trade",
  PROFIT_FOR_TRADE_DURING_OVERBOUGHT_OVERSOLD: "profit_for_trade_during_overbought_oversold",
  //Cost page
  SPREAD_AND_COMMISSION: "spread_and_commission",
  PROFIT_OF_CONFLICTING_SYMBOLS: "profit_of_conflicting_symbols",
  // top five patterns
  TOP_FIVE_PATTERNS_GENERAL: "top_5_patterns_general",
  TOP_FIVE_PATTERNS_BEHAVIOR: "top_5_patterns_behavior",
  TOP_FIVE_PATTERNS_TIMING: "top_5_patterns_timing",
  TOP_FIVE_PATTERNS_STRATEGIC: "top_5_patterns_strategic",
  TOP_FIVE_PATTERNS_OVERALL: "top_5_patterns_overall",
  // account overview
  GENERAL_INFO: "general_info",
  PERFORMANCE_OVER_TIME: "performance_over_time",
  DRAWDOWN_BALANCE: "drawdown_balance",
  TRADES_IN_PATTERNS_TIMEVIEW: 'trades_in_patterns_timeview',
  TRADES_IN_PATTERNS_COUNT: 'trades_in_patterns_count',
  // EIGHT_POINT_ASSESSMENT: 'eight_point_assessment',
  // basic statistics
  BALANCE_CURRENCY: 'balance_currency',
  PERFORMANCE_PIPS: 'performance_pips',
  SYMBOLS: 'symbols',
  PERFORMANCE_BY_MONTHS: 'performance_months',
  AVERAGE_TRADE_LENGTH: 'average_trade_length',
  AVERAGE_PIPS_PER_TRADE: 'average_pips_per_trade',
  AVERAGE_TRADES_PER_DAY: 'average_trades_per_day',
  // advanced statistics
  KEY_STATISTICS: 'key_statistics',
  RETURN_RISK_RATIO_HIT_RATIO: 'return_risk_ratio_hit_ratio',
  BEST_WORST_TRADE: 'best_worst_trade',
  PROBABILITY_GAIN_AFTER_GAIN: 'probability_gain_after_gain',
  PROBABILITY_LOSS_AFTER_LOSS: 'probability_loss_after_loss',
}

export default chartKeys;