import emailImg from 'assets/emailLogo.svg';
import googleImg from 'assets/googleLogo.svg';
import facebookImg from 'assets/facebookLogo.svg';
import styles from "../signUpModal.module.scss";


function SignUpMethods(props) {
  const setSignUpPhase = props.setSignUpPhase;

  const signUpWithEmail = () => {
    setSignUpPhase(1);
  }

  return (
    <div className={styles.SignUpBody}>
      <div className={styles.content}>
        <strong style={{fontSize: "16px"}}>Register as hoc-trade member</strong><br />
        Choose a method to sign up. It takes only about 2 minutes to complete.
      </div>
      <button className={styles.emailBtn} onClick={() => signUpWithEmail()}>
        <img src={emailImg} alt="email-icon" />
        Sign up with email
      </button>
      <button className={styles.googleBtn}>
        <img src={googleImg} alt="google-icon" />
        Sign up with Google
      </button>
      <button className={styles.fbBtn}>
        <img src={facebookImg} alt="fb-icon"/>
        Sign up with Facebook
      </button>
      <div className={styles.DirectToSignIn} style={{ marginTop: 10}}>
        Already registered?{" "}
        <a href="/login" className={styles.SignInButton}>
          <strong>SIGN IN</strong>
        </a>
      </div>
    </div>
  )
}

export default SignUpMethods;