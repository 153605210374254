import styles from "./filterButtons.module.scss";
import htFonts from "shared/htFont.module.scss";
import MUITooltip from "@mui/material/Tooltip";

function FilterButtons({
  selectedReasons,
  setSelectedReasons,
  selectedAreas,
  setSelectedAreas,
}) {
  function onClickFilterReasonBtn(keyword) {
    if (selectedReasons.includes(keyword)) {
      selectedReasons.splice(selectedReasons.indexOf(keyword), 1);
      setSelectedReasons([...selectedReasons]);
    } else {
      setSelectedReasons([...selectedReasons, keyword]);
    }
  }

  function onClickFilterAreaBtn(keyword) {
    if (selectedAreas.includes(keyword)) {
      selectedAreas.splice(selectedAreas.indexOf(keyword), 1);
      setSelectedAreas([...selectedAreas]);
    } else {
      setSelectedAreas([...selectedAreas, keyword]);
    }
  }

  let selectedFilterBtnStyling = {
    backgroundColor: "#06AFC9",
    color: "white",
  };

  return (
    <div className={styles.headerLeft}>
      <div className={styles.buttonsContainer}>
        <div className={styles.filterTitle}>Alert type:</div>
        <div
          className={styles.filterBtn}
          id={styles.btnAll}
          style={selectedReasons.length === 0 ? selectedFilterBtnStyling : null}
          onClick={() => setSelectedReasons([])}
        >
          All
        </div>
        <MUITooltip
          title="New loss making pattern"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htNegChart} ${styles.filterBtn}`}
            onClick={() => onClickFilterReasonBtn(0)}
            style={
              selectedReasons.includes(0)
                ? selectedFilterBtnStyling
                : null
            }
          ></div>
        </MUITooltip>
        <MUITooltip
          title="Risky behavior"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htSpeedometer} ${styles.filterBtn}`}
            onClick={() => onClickFilterReasonBtn(2)}
            style={
              selectedReasons.includes(2)
                ? selectedFilterBtnStyling
                : null
            }
          ></div>
        </MUITooltip>
        <MUITooltip
          title="Action in loss-making pattern"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htAlertChart} ${styles.filterBtn}`}
            onClick={() => onClickFilterReasonBtn(1)}
            style={
              selectedReasons.includes(1)
                ? selectedFilterBtnStyling
                : null
            }
          ></div>
        </MUITooltip>
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.filterTitle}>Analysis type:</div>
        <div
          className={styles.filterBtn}
          id={styles.btnAll}
          style={selectedAreas.length === 0 ? selectedFilterBtnStyling : null}
          onClick={() => setSelectedAreas([])}
        >
          All
        </div>
        <MUITooltip
          title="General"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htBarChart} ${styles.filterBtn}`}
            onClick={() => onClickFilterAreaBtn("general")}
            style={
              selectedAreas.includes("general")
                ? selectedFilterBtnStyling
                : null
            }
          ></div>
        </MUITooltip>
        <MUITooltip
          title="Behavior"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htCoffee} ${styles.filterBtn}`}
            onClick={() => onClickFilterAreaBtn("behavior")}
            style={
              selectedAreas.includes("behavior")
                ? selectedFilterBtnStyling
                : null
            }
          ></div>
        </MUITooltip>
        <MUITooltip
          title="Timing"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htTiming} ${styles.filterBtn}`}
            onClick={() => onClickFilterAreaBtn("timing")}
            style={
              selectedAreas.includes("timing") ? selectedFilterBtnStyling : null
            }
          ></div>
        </MUITooltip>
        <MUITooltip
          title="Risk management"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htAlert} ${styles.filterBtn}`}
            onClick={() => onClickFilterAreaBtn("risk")}
            style={
              selectedAreas.includes("risk") ? selectedFilterBtnStyling : null
            }
          ></div>
        </MUITooltip>
        <MUITooltip
          title="Strategy"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htChessCastle} ${styles.filterBtn}`}
            onClick={() => onClickFilterAreaBtn("strategy")}
            style={
              selectedAreas.includes("strategy")
                ? selectedFilterBtnStyling
                : null
            }
          ></div>
        </MUITooltip>
        <MUITooltip
          title="Cost"
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 12,
                fontFamily: "Noto Sans",
                backgroundColor: "#047F92",
                "& .MuiTooltip-arrow": {
                  color: "#047F92",
                },
              },
            },
          }}
        >
          <div
            className={`${htFonts.htCoins} ${styles.filterBtn}`}
            onClick={() => onClickFilterAreaBtn("cost")}
            style={
              selectedAreas.includes("cost") ? selectedFilterBtnStyling : null
            }
          ></div>
        </MUITooltip>
      </div>
    </div>
  );
}

export default FilterButtons;
