import createBarColor from "components/charts/helpers/createBarColor";
import createHoverBarColor from "components/charts/helpers/createHoverBarColor";
import createBarBorderColor from "components/charts/helpers/createBarBorderColor";
import createHoverBarBorderColor from "components/charts/helpers/createHoverBarBorderColor";
import { chartKeys } from 'utils/constant';

const risk_per_trade_last_trades = {
  chartTitle: "Risk per trade",
  chartKey: chartKeys.RISK_PER_TRADE_LAST_TRADES,
  createBarColor: createBarColor,
  createHoverBarColor: createHoverBarColor,
  createBarBorderColor: createBarBorderColor,
  createHoverBarBorderColor: createHoverBarBorderColor,
  hoverTextArray: {
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        followBarColor: true,
      },
      {
        templateString: "B: ",
        valueKey: "percentageBenchmark",
        unit: "%",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
  },
  haspercentageBenchmark: true,
  interval: ["average", "last 20", "last 50", "last 100", "last 200"],
}

export default risk_per_trade_last_trades;