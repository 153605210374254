import { chartKeys } from 'utils/constant';

const profit_with_against_st_mt_trend = {
  chartTitle:
    "Profit with(+) & against(-) short-term(ST) & medium-term(MT) trend",
  chartKey: chartKeys.PROFIT_WITH_AGAINST_ST_MT_TREND,
  hoverTextArray: {
    pips: [
      {
        templateString: "",
        valueKey: "pips",
        unit: "pips",
        // textColor: function (hoveredData) {
        //   return hoveredData.pips >= 0 ? "#56A07E" : "red";
        // },
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "pipsBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        // textColor: function (hoveredData) {
        //   return hoveredData.percentage >= 0 ? "#56A07E" : "red";
        // },
        followBarColor: true,
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
        followBarColor: true,
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "B: ",
        valueKey: "percentageBenchmark",
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      },
    ],
  },
  createHoverTextArray: (currency) => {
    return {
      pips: [
        {
          templateString: "",
          valueKey: "pips",
          unit: "pips",
          // textColor: function (hoveredData) {
          //   return hoveredData.pips >= 0 ? "#56A07E" : "red";
          // },
          followBarColor: true,
        },
        {
          templateString: "",
          valueKey: "profit",
          unit: currency,
          followBarColor: true,
        },
        {
          templateString: "Trades: ",
          valueKey: "trades",
        },
        {
          templateString: "B: ",
          valueKey: "pipsBenchmark",
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        },
      ],
      percentage: [
        {
          templateString: "",
          valueKey: "percentage",
          unit: "%",
          // textColor: function (hoveredData) {
          //   return hoveredData.percentage >= 0 ? "#56A07E" : "red";
          // },
          followBarColor: true,
        },
        {
          templateString: "",
          valueKey: "profit",
          unit: currency,
          followBarColor: true,
        },
        {
          templateString: "Trades: ",
          valueKey: "trades",
        },
        {
          templateString: "B: ",
          valueKey: "percentageBenchmark",
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        },
      ],
    }
  },
  haspercentageBenchmark: true,
  interval: [
    'average',
    'ST+/MT+',
    'ST+/MT-',
    'ST-/MT+',
    'ST-/MT-'
  ],
}

export default profit_with_against_st_mt_trend;