const createLineChartColor = (chartData) => {
  const percentageAvg = chartData.percentageAvg;

  let result = [];
  chartData.interval.forEach((interval, index) => {
    let pointValue = chartData[999].data[interval].percentage;
    if (pointValue > percentageAvg) {
      return result.push("rgba(86, 160, 126, 1)");
    } else if (pointValue < percentageAvg) {
      return result.push("rgba(193, 83, 87, 1)");
    } else {
      if (index === 0) {
        return result.push("rgba(86, 160, 126, 1)");
      } else {
        return result.push(result[index - 1]);
      }
    }
  });

  return result;
};

export default createLineChartColor;
