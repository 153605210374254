import styles from "../../dashboard/tradeMedic.module.scss";
import htFonts from "shared/htFont.module.scss";
import IssueCard from "components/tradeMedic/issueCard";
import FullReport from "components/tradeMedic/fullReport";
import RiskReport from "components/tradeMedic/riskReport";
import Spinner from "UI/spinner";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
import { useEffect } from "react";
//constants
import * as modalTypes from "constants/modalTypes";
//utils
import {
  getSandboxTradeMedicData,
} from "redux/selectors";
import { getTradeMedicData } from "utils/requests";
import { setSandboxTradeMedicData } from "redux/slices/sandboxTradeMedicSlice";

function SandboxTradeMedic() {
  const dispatch = useDispatch();
  const tradeMedicData = useSelector(getSandboxTradeMedicData);

  useEffect(() => {
    (async function () {
      const currentTradingAccount = {
        account_id: 1234,
        account_publie: true,
        broker: "hoctrade",
        currency: "usd",
        portfolio_name: "sandbox",
        server: "test",
        status: "demo",
        trading_account_id: "hoctrade@test",
        user_id: 1,
      };
      if (tradeMedicData.length === 0) {
        const result = await getTradeMedicData(
          currentTradingAccount.trading_account_id
        );
        if (result.statusCode === 200) {
          dispatch(
            setSandboxTradeMedicData({
              sandboxTradeMedicData: result.data,
            })
          );
        }
      }
    })();
  }, [dispatch, tradeMedicData]);

  let riskReportIssues = [];
  let fullReportIssues = [];
  for (let i = 0; i < tradeMedicData.length; i++) {
    if (tradeMedicData[i].issue_type === 1) {
      riskReportIssues.push(tradeMedicData[i]);
    } else {
      fullReportIssues.push(tradeMedicData[i]);
    }
  }

  fullReportIssues.sort((a, b) => {
    return a.sequence - b.sequence;
  });
  const criticalIssues = fullReportIssues.slice(0, 4);

  const onClickGeneralInfoHandler = (e) => {
    e.preventDefault();
    return dispatch(
      openModal({
        displayedModal: modalTypes.TRADE_MEDIC_INFO_MODAL,
        selectedTradeMedicData: {
          issue_key: "general",
          issue_name: "Trade Medic",
          chart_key: "",
          account_id: "",
          issue_type: 0,
          sequence: 0,
          severity: 0,
          performance_effect: 0,
        },
      })
    );
  };
  if (tradeMedicData.length === 0) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.TradeMedicWrapper}>
      <div className={styles.TradeMedicTableWrapper}>
        <div className={styles.TableTitle}>
          <div className={styles.TitleLeft}>
            <span className={htFonts.htMedbox}></span>
            Your trade medic report
          </div>
          <div
            className={styles.InfoIconContainer}
            onClick={(e) => onClickGeneralInfoHandler(e)}
          >
            <span className={htFonts.htInfo}></span>
          </div>
        </div>
        <div className={styles.Top4IssuesWrapper}>
          <div className={styles.Top4IssuesTitle}>
            <span className={htFonts.htStar}></span>
            TOP 4 critical issues
          </div>
          <div className={styles.IssueCardsWrapper}>
            {criticalIssues.map((issue, index) => {
              return (
                <IssueCard
                  criticalIssue={issue}
                  index={index}
                  key={issue.issue_key}
                  isSandboxAccount={true}
                />
              );
            })}
          </div>
        </div>
        <FullReport fullReportIssues={fullReportIssues} isSandboxAccount={true}/>
        <RiskReport riskReportIssues={riskReportIssues} isSandboxAccount={true}/>
      </div>
      <div className={styles.Disclaimer}>
        <strong>DISCLAIMER</strong>: Please be cautious interpreting the
        presented information and always do your own research. None of the
        information provided should be considered financial advice, it may have
        errors, it is not mutually exclusive nor collectively exhaustive, and we
        cannot in any way be held liable for any losses or influences of those
        information on your trading.
      </div>
    </div>
  );
}

export default SandboxTradeMedic;