import { createSlice } from '@reduxjs/toolkit';
import { authenticationStates } from 'utils/constant';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    authenticationState: authenticationStates.AUTHENTICATION_PENDING,
    // authenticationState: authenticationStates.AUTHENTICATED,
    user: null,
    // user: {
    //   email: 'juiyigung@hotmail.com',
    //   trading_accounts: [
    //     {
    //       id: 12219038125,
    //       portfolioName: 'johnny'
    //     }
    //   ],
    //   avatar_url: "https://previews.123rf.com/images/kritchanut/kritchanut1407/kritchanut140700337/29898197-woman-avatar-profile-picture-on-light-gray-background-vector-icon.jpg", 
    // },
    // currentTradingAccount: {
    //   portfolioName: 'johnny',
    // },
    currentTradingAccount: null
  },
  reducers: {
    setAuthenticationState: (state, action) => {
      state.authenticationState = action.payload.authenticationState;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    setCurrentTradingAccount: (state, action) => {
      state.currentTradingAccount = action.payload.currentTradingAccount;
    },
    setLogOut: (state, action) => {
      state.user = null;
      state.currentTradingAccount = null;
      state.authenticationState = authenticationStates.UNAUTHENTICATED;
    }
  },
});

export const { 
  setAuthenticationState,
  setUser,
  setCurrentTradingAccount,
  setLogOut
} = authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;