const averageProfitPerTrade = {
  chartTitle: "Average profit per trade",
  barValueKey: "usd",
  999: [
    {
      time: new Date("2020-10"),
      usd: 1,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2020-11"),
      usd: 2,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2020-12"),
      usd: 1.5,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-01"),
      usd: 2.3,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-02"),
      usd: 2.9,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-03"),
      usd: 1.5,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-04"),
      usd: 0.5,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-05"),
      usd: 2,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-06"),
      usd: 2.3,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-07"),
      usd: 2.5,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-08"),
      usd: 2.8,
      trades: 19,
      benchmark: 3
    },
    {
      time: new Date("2021-09"),
      usd: 2,
      trades: 19,
      benchmark: 3
    },
  ]
}

export default averageProfitPerTrade;