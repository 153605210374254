import { chartKeys } from 'utils/constant';

const spread_and_commission = {
  chartTitle: "Spread & Commission",
  chartKey: chartKeys.SPREAD_AND_COMMISSION,
  hoverTextArray: {
    number: [
      {
        templateString: 'Number: ',
        valueKey: 'number',
        unit: 'USD',
      },
    ],
  },
  createHoverTextArray: (currency) => {
    return {
      number: [
        {
          templateString: 'Spread & Commission: ',
          valueKey: 'number',
          unit: currency,
        },
      ],
    }
  },
  interval: [
    'Yearly',
    'Monthly',
  ],
}

export default spread_and_commission;