import { chartKeys } from 'utils/constant';

const performance_pips = {
  chartTitle: 'Performance Pips',
  chartKey: chartKeys.PERFORMANCE_PIPS,
  hoverTextArray: {
    'textArray': [
      {
        title: 'Return: ',
        valueKey: 'performancePips',
        unit: 'pips',
        createTextColor: function(hoveredPointRawData, chartData) {
          return hoveredPointRawData.y > 0? '#C15357': '#56A07E';
        },
        createValueText: function(value) {
          if (value) {
            return value.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      },
      {
        title: 'Trades: ',
        valueKey: 'trades',
        unit: '',
      },
      {
        title: 'Equity: ',
        valueKey: 'equity',
        unit: 'USD',
      }
    ]
  }
}

export default performance_pips;