import React from 'react';
import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.ErrorPage}>
          <div className={styles.ErrorMessageContainer}>
            <h1>Something Went Wrong.</h1>
            <h2>Please contact our support:</h2>
            <h2>info@hoc-trade.com</h2>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;