import styles from "./fullReport.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "redux/slices/modalSlice";
//constants
import * as modalTypes from "constants/modalTypes";

function FullReport({ fullReportIssues, isSandboxAccount }) {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const tradeMedicInfoOnclickHander = (e, issue) => {
    e.preventDefault();
    if (!isSandboxAccount) {
      return dispatch(
        openModal({
          displayedModal: modalTypes.TRADE_MEDIC_INFO_MODAL,
          selectedTradeMedicData: issue,
        })
      );
    } else {
      return dispatch(
        openModal({
          displayedModal: modalTypes.SANDBOX_TRADE_MEDIC_INFO_MODAL,
          selectedTradeMedicData: issue,
        })
      );
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className={styles.FullReportSectionWrapper}>
      <div
        className={styles.SectionHeader}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className={styles.HeaderLeft}>
          {" "}
          <span className={htFonts.htClipboard}></span>Full report{" "}
        </div>
        <span className={htFonts.htAdd}></span>
      </div>
      {isDropdownOpen ? (
        <table className={styles.FullReportTable}>
          <thead className={styles.Thead}>
            <tr>
              <td>Issue</td>
              <td>Severity</td>
              <td></td>
              <td>Performance effect</td>
              <td className={styles.LastCell}>Further details</td>
            </tr>
          </thead>
          <tbody className={styles.TableBody}>
            {fullReportIssues.map((issue) => {
              return (
                <tr key={issue.issue_name} className={styles.TableRow}>
                  <td>{issue.issue_name}</td>
                  <td>
                    <div className={styles.SeverityBar}>
                      {issue.severity !== 100 ? (
                        <div
                          className={styles.SeverityBarDot}
                          style={{
                            left: `calc(${issue.severity}% - 4px)`,
                          }}
                        ></div>
                      ) : (
                        <div
                          className={styles.SeverityBarDot}
                          style={{
                            left: `calc(${issue.severity}% - 16px)`,
                          }}
                        ></div>
                      )}
                    </div>
                  </td>
                  <td></td>
                  <td
                    style={
                      issue.performance_effect < 0
                        ? { color: "#c1272d" }
                        : { color: "#009245" }
                    }
                  >
                    {numberWithCommas(issue.performance_effect)} USD
                  </td>
                  <td className={styles.LastCell}>
                    <span
                      className={htFonts.htInfo}
                      onClick={(e) => tradeMedicInfoOnclickHander(e, issue)}
                    ></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </div>
  );
}

export default FullReport;
