import styles from "./cryptoTradingSoftwareSelection.module.scss";
import SelectComponent from "../SelectComponent";
import htFonts from "shared/htFont.module.scss";

export default function CryptoTradingSoftwareSelection({
  cryptoTradingAccountInfo,
  setCryptoTradingAccountInfo,
  setPhase,
  setInfoContentKey,
}) {
  const tradingSoftwareOptions = [
    {
      label: "crypto software",
      value: "crypto software",
    },
  ];

  function validateSectionInfo() {
    let passed = true;
    const fields = ["tradingSoftware", "apiKey", "apiSecret"];
    fields.forEach((field) => {
      if (!cryptoTradingAccountInfo[field]) {
        passed = false;
      }
    });
    return passed;
  }

  const sectionInfoValidated = validateSectionInfo();

  return (
    <div className={styles.SelectionContainer}>
      <div className={styles.SectionTitle}>Add a trading account</div>
      <div className={styles.SectionDescription}>
        Take a moment to setup your trading account. Please enter all fields.
      </div>
      <form className={styles.Form}>
        <div className={styles.InfoRow}>
          <label className={styles.InfoLabel}>
            Your trading software
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("your_trading_software")}
            ></span>
          </label>
          <SelectComponent
            setFormData={setCryptoTradingAccountInfo}
            formData={cryptoTradingAccountInfo}
            instanceId="trading-software-select"
            formField="tradingSoftware"
            options={tradingSoftwareOptions}
          />
        </div>
        <div className={styles.InfoRow}>
          <label className={styles.InfoLabel}>
            Your API key
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("api_key")}
            ></span>
          </label>
          <input
            className={styles.InfoInput}
            type="text"
            value={cryptoTradingAccountInfo.apiKey}
            onChange={(e) => {
              setCryptoTradingAccountInfo({
                ...cryptoTradingAccountInfo,
                apiKey: e.target.value,
              });
            }}
          ></input>
        </div>
        <div className={styles.InfoRow}>
          <label className={styles.InfoLabel}>
            Your API secret
            <span
              className={`${htFonts.htInfo} ${styles.TitleIcon} ${styles.InfoIcon}`}
              id={styles.InfoIcon}
              onClick={() => setInfoContentKey("api_secret")}
            ></span>
          </label>
          <input
            className={styles.InfoInput}
            type="text"
            value={cryptoTradingAccountInfo.apiSecret}
            onChange={(e) => {
              setCryptoTradingAccountInfo({
                ...cryptoTradingAccountInfo,
                apiSecret: e.target.value,
              });
            }}
          ></input>
        </div>
        {sectionInfoValidated ? (
          <div className={styles.NextButton} onClick={() => setPhase(1)}>
            Next
          </div>
        ) : (
          <div className={`${styles.NextButton} ${styles.Disabled}`}>Next</div>
        )}
      </form>
    </div>
  );
}
