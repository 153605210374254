import { getSelectedBroker, getSortedBrokersData } from 'redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import CommissionComparison from 'components/charts/commissionComparison.chart';
import { updateModalData, closeModal } from 'redux/slices/modalSlice';
import detailArrow from 'assets/arrow-detail.svg';
import { getChartsData } from "redux/selectors";
import { createDataObjectForChart } from "utils/helpers/chart";

//styles
import styles from './commissionComparisonModal.module.scss';
import htFonts from 'shared/htFont.module.scss';

function CommissionComparisonModal() {
  const dispatch = useDispatch();
  const chartData = useSelector(getChartsData(['spread_and_commission_comparison']));
  const selectedBroker = useSelector(getSelectedBroker);
  const sortedBrokersData = useSelector(getSortedBrokersData);
  const chartDataObject = createDataObjectForChart("spread_and_commission_comparison", chartData['spread_and_commission_comparison']);
  const currentBrokerData = chartDataObject.currentBroker;

  const close = () => {
    return dispatch(closeModal())
  }

  const checkboxOnClickHandler = (broker) => {
    const copiedSelectedBroker = [...selectedBroker];
    if (selectedBroker.includes(broker)) {
      const brokerIndex = selectedBroker.indexOf(broker);
      copiedSelectedBroker.splice(brokerIndex, 1);
      if (copiedSelectedBroker.length < 1) return;
    } else {
      copiedSelectedBroker.push(broker);
    }
    dispatch(updateModalData({
      selectedBroker: copiedSelectedBroker
    }));
  }

  let sortedDataWithColor = [];
  sortedBrokersData.forEach((brokerData, index) => {
    let dataWithColor = {};
    dataWithColor.key = brokerData.key
    dataWithColor.Yearly = brokerData.Yearly;
    dataWithColor.Monthly = brokerData.Monthly;
    dataWithColor.name = brokerData.name;
    dataWithColor.saveYearly = brokerData.saveYearly;
    dataWithColor.saveMonthly = brokerData.saveMonthly;
    dataWithColor.diffYearly = brokerData.diffYearly;
    dataWithColor.diffMonthly = brokerData.diffMonthly;
    dataWithColor.color = brokerData.Yearly > currentBrokerData.Yearly ? '#c15357' : '#56A07E';
    sortedDataWithColor.push(dataWithColor);
  })

  return (
    <div className={styles.CommissionComparisonModal}>
      <div className={styles.CommissionComparisonModalHeader}>
        <div className={styles.title}>Cost Comparison</div>
        <div className={styles.CrossIconButton}>
          <div
            className={`${htFonts.htCross} ${styles.CancelIcon}`}
            onClick={() => close()}></div>
        </div>
      </div>
      <div className={styles.CommissionComparisonModalBody}>
        <div className={styles.CommissionComparisonModalChart}>
          <CommissionComparison
            currentBrokerData={currentBrokerData}
            selectedBroker={selectedBroker}
            sortedDataWithColor={sortedDataWithColor}
            chartTitle={'Cost Comparison'}
            chartKey={'Cost Comparison'}
            hoverTextArray={chartDataObject.hoverTextArray}
          />
        </div>
        <div className={styles.BrokerCheckboxes}>
          <div className={styles.CurrentBroker}>
            <div style={{width: "20px", height: "20px"}}> </div>
            <div style={{
              backgroundColor: "#ffb700", 
              width: "15px", 
              height: "15px", 
              borderRadius: "50%"}}>
            </div>
            Your current broker
          </div>
          {
            sortedDataWithColor.map(brokerData => (
              <label key={brokerData.key} className={styles.BrokerCheckbox}>
                <input
                  className={styles.checkbox}
                  type="checkbox" 
                  checked={selectedBroker.includes(brokerData.name)}
                  onChange={(e) => checkboxOnClickHandler(brokerData.name)}>  
                </input>
                <div style={{
                  backgroundColor: brokerData.color, 
                  width: "15px", 
                  height: "15px", 
                  borderRadius: "50%"}}>
                </div>
                <div className={styles.broker}>
                  {brokerData.name}
                </div>
                <button className={styles.btn}>
                  Details <img src={detailArrow} alt="arrow img"/>
                </button>
              </label>
            ))
          }
        </div>
      </div> 
    </div>
  )
}

export default CommissionComparisonModal;