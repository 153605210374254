import styles from "./progressBars.module.scss";
import ProgressBar from "./progressBar";

const ProgressBars = (props) => {
  const { chartData, fontColor, backgroundColor, hoverColor } = props;

  let usd = chartData.usd;
  let breakSinceLastTrade = (chartData.breakSinceLastTrade / usd) * 100;
  let riskPerTrade = (chartData.riskPerTrade / usd) * 100;
  let profitBySymbol = (chartData.profitBySymbol / usd) * 100;
  let positionWithSL = (chartData.positionWithSL / usd) * 100;
  let profitIfTradeOpen = (chartData.profitIfTradeOpen / usd) * 100;

  let barData = [
    {
      key: "Break Since Last Trade",
      backgroundColor: backgroundColor,
      hoverColor: hoverColor,
      percentage: breakSinceLastTrade,
      fontColor: fontColor
    },
    {
      key: "Risk per trade",
      backgroundColor: backgroundColor,
      hoverColor: hoverColor,
      percentage: riskPerTrade,
      fontColor: fontColor
    },
    {
      key: "Profit by symbol",
      backgroundColor: backgroundColor,
      hoverColor: hoverColor,
      percentage: profitBySymbol,
      fontColor: fontColor
    },
    {
      key: "% of positions with SL",
      backgroundColor: backgroundColor,
      hoverColor: hoverColor,
      percentage: positionWithSL,
      fontColor: fontColor
    },
    {
      key: "Profit if trade open",
      backgroundColor: backgroundColor,
      hoverColor: hoverColor,
      percentage: profitIfTradeOpen,
      fontColor: fontColor
    },
  ];

  return (
    <div className={styles.progressBarWrapper}>
      {
        barData.map((data) => {
          return <ProgressBar key={data.key} data={data}/>
        })
      }
    </div>
  );
};

export default ProgressBars;
