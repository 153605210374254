import profit_by_daytime_per_trade from "./profit_by_daytime_per_trade";
import long_and_short_per_trade from './long_and_short_per_trade';
import profit_by_symbol_per_trade from "./profit_by_symbol_per_trade";
import profit_by_trade_length_per_trade from './profit_by_trade_length_per_trade';
import profit_by_weekday_per_trade from "./profit_by_weekday_per_trade";
// Behavior page
import trades_per_day_per_trade from "./behaviourPage/trades_per_day_per_trade";
import break_after_loss_trade_per_trade from "./behaviourPage/break_after_loss_trade_per_trade";
import break_since_last_trade_per_trade from "./behaviourPage/break_since_last_trade_per_trade";
import open_different_symbols_per_trade from "./behaviourPage/open_different_symbols_per_trade";
import open_position_in_profit_loss from "./behaviourPage/open_position_in_profit_loss";
import profit_after_daily_profit_loss from "./behaviourPage/profit_after_daily_profit_loss";
// Timing page
import profit_if_manual_stop_before_tp from "./timingPage/profit_if_manual_stop_before_tp";
import profit_if_sl_closer_further_away from "./timingPage/profit_if_sl_closer_further_away";
import profit_if_tp_closer_further_away from "./timingPage/profit_if_tp_closer_further_away";
import profit_if_trade_close_earlier_later from "./timingPage/profit_if_trade_close_earlier_later";
import profit_if_trade_open_earlier_later from "./timingPage/profit_if_trade_open_earlier_later";
import percent_of_trades_stopped_early_in_profit from "./timingPage/percent_of_trades_stopped_early_in_profit";
// Risk management page
import max_risk_all_open_trades_last_trades from "./riskManagementPage/max_risk_all_open_trades_last_trades";
import percentage_of_position_with_sl from "./riskManagementPage/percentage_of_position_with_sl";
import profit_if_adding_to_negative_positions from "./riskManagementPage/profit_if_adding_to_negative_positions";
import profit_secure_payoff_from_reverting_trades from "./riskManagementPage/profit_secure_payoff_from_reverting_trades";
import risk_after_losing_trades_open_trade from "./riskManagementPage/risk_after_losing_trades_open_trade";
import risk_after_losing_trades_per_trade from "./riskManagementPage/risk_after_losing_trades_per_trade";
import risk_per_trade_last_trades from "./riskManagementPage/risk_per_trade_last_trades";
// Strategy page
import profit_high_vs_low_volatility from "./strategyPage/profit_high_vs_low_volatility";
import profit_with_against_st_mt_trend from "./strategyPage/profit_with_against_st_mt_trend";
import profit_when_sl_adjusted_vs_profit_when_not from "./strategyPage/profit_when_sl_adjusted_vs_profit_when_not";
import profit_if_publishing_trades from "./strategyPage/profit_if_publishing_trades";
import profit_after_withdrawal_in_pips from "./strategyPage/profit_after_withdrawal_in_pips";
import average_payoff_win_vs_loss_trade from "./strategyPage/average_payoff_win_vs_loss_trade";
import profit_for_trade_during_overbought_oversold from "./strategyPage/profit_for_trade_during_overbought_oversold";
// Cost page
import spread_and_commission from "./costPage/spread_and_commission";
import profit_of_conflicting_symbols from "./costPage/profit_of_conflicting_symbols";

import spread_and_commission_comparison from './spread_and_commission_comparison';

// account overview
import performance_over_time from './accountOverviewPage/performance_over_time';
import drawdown_balance from './accountOverviewPage/drawdown_balance';
import trades_in_patterns_timeview from './accountOverviewPage/trades_in_patterns_timeview';

// basic statistic page
import balance_currency from "./basicStatisticPage/balance_currency";
import performance_pips from "./basicStatisticPage/performance_pips";
import symbols from './basicStatisticPage/symbols';
import performance_months from "./basicStatisticPage/performance_months";
import average_pips_per_trade from "./basicStatisticPage/average_pips_per_trade";
import average_trade_length from "./basicStatisticPage/average_trade_length";
import average_trades_per_day from "./basicStatisticPage/average_trades_per_day";

// advanced statistic page
import key_statistics from "./advancedStatisticPage/key_statistics";
import best_worst_trade from "./advancedStatisticPage/best_worst_trade";
import probability_gain_after_gain from "./advancedStatisticPage/probability_gain_after_gain";
import probability_loss_after_loss from "./advancedStatisticPage/probability_loss_after_loss";
import return_risk_ratio_hit_ratio from "./advancedStatisticPage/return_risk_ratio_hit_ratio";

const chartDataObjectMap = {
  profit_by_daytime_per_trade,
  long_and_short_per_trade,
  profit_by_symbol_per_trade,
  profit_by_trade_length_per_trade,
  profit_by_weekday_per_trade,
  //Behaviour page
  trades_per_day_per_trade,
  break_after_loss_trade_per_trade,
  break_since_last_trade_per_trade,
  open_different_symbols_per_trade,
  open_position_in_profit_loss,
  profit_after_daily_profit_loss,
  //Timing page
  profit_if_manual_stop_before_tp,
  profit_if_sl_closer_further_away,
  profit_if_tp_closer_further_away,
  profit_if_trade_close_earlier_later,
  profit_if_trade_open_earlier_later,
  percent_of_trades_stopped_early_in_profit,
  //Risk management page
  max_risk_all_open_trades_last_trades,
  percentage_of_position_with_sl,
  profit_if_adding_to_negative_positions,
  profit_secure_payoff_from_reverting_trades,
  risk_after_losing_trades_open_trade,
  risk_after_losing_trades_per_trade,
  risk_per_trade_last_trades,
  //Strategy page
  profit_high_vs_low_volatility,
  profit_with_against_st_mt_trend,
  profit_when_sl_adjusted_vs_profit_when_not,
  profit_if_publishing_trades,
  profit_after_withdrawal_in_pips,
  average_payoff_win_vs_loss_trade,
  profit_for_trade_during_overbought_oversold,
  //Cost page
  spread_and_commission,
  profit_of_conflicting_symbols,
  spread_and_commission_comparison,
  // account overview
  performance_over_time,
  drawdown_balance,
  trades_in_patterns_timeview,
  //basic statistic
  balance_currency,
  performance_pips,
  symbols,
  performance_pips,
  performance_months,
  average_trade_length,
  average_pips_per_trade,
  average_trades_per_day,
  // advanced statistic
  key_statistics,
  best_worst_trade,
  probability_gain_after_gain,
  probability_loss_after_loss,
  return_risk_ratio_hit_ratio,
}

export default chartDataObjectMap;