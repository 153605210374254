import React, { Children } from 'react';
import styles from './chartNoDataWrapper.module.scss';
import ChartHeader from './chartHeader';

function ChartNoDataWrapper({ chartData, children }) {
  const { chartTitle } = chartData;
  if (chartData.noData) {
    return (
      <div className={styles.Chart}>
        <ChartHeader 
          chartTitle={chartTitle} 
          chartKey={chartData.chartKey} />
        <div className={styles.ChartContainer} style={{position: 'relative'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: 20,}}>
            Not sufficient data available yet
          </div>
        </div>
      </div>
    )
  }

  const renderChildren = () => {
    return Children.map(children, (child) => {
      return React.cloneElement(child, {
        chartData,
        props: { ...child.props }
      });
    })
  };

  return (
    <>
      { renderChildren() }
    </>
  );
}

export default ChartNoDataWrapper;