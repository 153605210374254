const drawVerticalLine = (ctx, x, y2, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x, 0);
  ctx.lineTo(x, y2);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawCircleOnBar = (ctx, x, y, strokeStyle, fillStyle) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle;
  ctx.fillStyle = fillStyle;
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
};

const drawHoverTextRect = (ctx, x, y, rectWidth, rectHeight, fillStyle) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, rectWidth, rectHeight);
};

const drawHorizontalLines = (ctx, x1, x2, y, strokeStyle) => {
  ctx.beginPath();
  ctx.moveTo(x1, y);
  ctx.lineTo(x2, y);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const measureTextWidth = (ctx, textArray, hoveredDataPoint) => {
  let textStringWidth = 0;
  textArray.forEach((object, index) => {
    if (index === textArray.length - 1) {
      const a = object.templateString;
      let b;
      if (object["unit"]) {
        b = `${hoveredDataPoint.raw[object.valueKey]} ${object["unit"]}`;
      } else {
        b = `${hoveredDataPoint.raw[object.valueKey]}`;
      }
      textStringWidth += ctx.measureText(a + b).width;
    } else {
      const a = object.templateString;
      let b;
      if (object["unit"]) {
        b = `${hoveredDataPoint.raw[object.valueKey]} ${object["unit"]}`;
      } else {
        b = `${hoveredDataPoint.raw[object.valueKey]}}`;
      }
      textStringWidth += ctx.measureText(a + b).width + 15;
    }
  });
  return textStringWidth;
};

/**
 *
 * @param {Text[]} textArray
 * @param {object} textArray.element
 * @property { string } textArray.element.templateString
 * @property { string } textArray.element.valueKey
 * @property { string } textArray.element.unit?
 * @property { string } textArray.element.textColor?
 *
 */
const drawOnHoverText = (textArray, ctx, hoveredDataPoint) => {
  ctx.font = "12px sans-serif";
  ctx.textAlign = "left";
  ctx.fillStyle = "white";
  const chartArea = hoveredDataPoint.chart.chartArea;
  const textStringWidth = measureTextWidth(ctx, textArray, hoveredDataPoint);
  let centerX = (chartArea.right + chartArea.left) / 2;
  let currentX = centerX - textStringWidth / 2;

  for (let i = 0; i < textArray.length; i++) {
    const textObject = textArray[i];
    ctx.fillText(textObject.templateString, currentX, 15);
    currentX += ctx.measureText(textObject.templateString).width;

    if (textObject["textColor"]) {
      ctx.save();
      ctx.fillStyle = textObject.textColor(hoveredDataPoint.raw);
      ctx.font = "700 12px Noto Sans";
      const textString = textObject["unit"]
        ? `${hoveredDataPoint.raw[textObject.valueKey].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${textObject["unit"]}`
        : `${hoveredDataPoint.raw[textObject.valueKey].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      ctx.fillText(textString, currentX, 15);
      currentX += ctx.measureText(textString).width;
      ctx.restore();
    } else {
      ctx.save();
      ctx.font = "700 12px Noto Sans";
      const textString = textObject["unit"]
        ? `${hoveredDataPoint.raw[textObject.valueKey].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${textObject["unit"]}`
        : `${hoveredDataPoint.raw[textObject.valueKey].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      ctx.fillText(textString, currentX, 15);
      currentX += ctx.measureText(textString).width;
      ctx.restore();
    }
    if (i !== textArray.length - 1) {
      currentX += 15;
    }
  }
};

const createOnHoverPlugin = (hoverTextArray) => {
  const onHoverPlugin = {
    id: "on_bar_hover_plugin",
    // hide default tooltip
    beforeDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
      if (tooltip._active.length) {
      }
    },
    afterDraw: (chart) => {
      const tooltip = chart.tooltip;
      tooltip.opacity = 0;
      if (tooltip._active.length) {
        const ctx = chart.ctx;
        const pointElement = tooltip.dataPoints[0].element;
        const chartArea = chart.chartArea;

        drawVerticalLine(ctx, pointElement.x, chartArea.bottom, "#06AFC9");
        drawCircleOnBar(
          ctx,
          pointElement.x,
          pointElement.y,
          "#06AFC9",
          "#FFFFFF"
        );
        drawHoverTextRect(
          ctx,
          chartArea.left - 15,
          0,
          chartArea.width + 15,
          20,
          "#00333B"
        );

        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          0.5,
          "#06AFC9"
        );
        drawHorizontalLines(
          ctx,
          chartArea.left,
          chartArea.left + chartArea.width,
          20.5,
          "#06AFC9"
        );

        drawOnHoverText(hoverTextArray, ctx, tooltip.dataPoints[0]);
      }
    },
  };

  return onHoverPlugin;
};

export default createOnHoverPlugin;
