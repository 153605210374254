import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import SandboxCommissionComparisonChartHeader from './sandboxCommissionComparisonChartHeader';

//styling
import styles from "./commissionComparisonChart.module.scss";

//plugins
import createBackgroundPlugin from "../charts/plugins/createBackground.plugin";
import xAxisButtonPlugin from "../charts/plugins/xAxisTicks.plugin";
import xAxisCenterPlugin from "../charts/plugins/xCenterTicks.plugin";
import createOnHoverPlugin from "./plugins/costComparisonModalHover.plugin";


// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   annotationPlugin
// );

function createRightSideYAxisWithTicks() {
  return {
    id: 'right_side_y_axis',
    beforeDatasetDraw: (chart) => {
      const { ctx } = chart;
      const { ticks } = chart.scales.y;
      const { right } = chart.chartArea;
      ctx.restore();
      ctx.save();
      ctx.textAlign = 'right';
      ctx.fillStyle = '#999999';
      ctx.font = "700 10px Noto Sans";

      ticks.forEach((tick) => {
        const rightTickY = chart.scales.y.getPixelForValue(tick.value);

        if (Array.isArray(tick.label)) {
          tick.label.forEach((text, index) => {
            if (text) {
              ctx.fillText(text, right, rightTickY - 5 - (10 * (tick.label.length - index - 2 )));
            }
          });
        } else {
          ctx.fillText(tick.label, right, rightTickY - 5);
        }
      });
      ctx.restore();
    }
  }
}

/**
 * 
 * @param {object} props 
 * @property {boolean} dualMode default: false, if true will display pips button and % button for switching 
 * @property {boolean} hasTimeFrame
 *
 */

function SandboxCommissionComparison(props) {
  const {
    currentBrokerData,
    selectedBroker,
    sortedDataWithColor,
    chartTitle,
    hoverTextArray
  } = props;

  const hoverTextPlugin = createOnHoverPlugin(hoverTextArray);
  const backgroundPlugin = createBackgroundPlugin(true);

  let selectedBrokerData = [];
  const chartIntervals = ['Monthly', 'Yearly'];

  selectedBroker.forEach(broker => {
    let targetBrokerData = sortedDataWithColor.filter(brokerData => brokerData.name === broker);
    targetBrokerData = targetBrokerData[0];
    const convertedBrokerData = [];
    chartIntervals.forEach(interval => {
      convertedBrokerData.push({
        x: interval,
        name: targetBrokerData.name,
        number: targetBrokerData[interval].toFixed(2),
        save: targetBrokerData[`save${interval}`].toFixed(2),
        color: targetBrokerData.color,
        diff: targetBrokerData[`diff${interval}`].toFixed(2)
      });
    });
    selectedBrokerData.push(convertedBrokerData);
  });

  const convertedCurrentBrokerData = chartIntervals.map(interval => ({
    x: interval,
    name: currentBrokerData.name,
    number: currentBrokerData[interval].toFixed(2),
    color: "#C15357",
    isCurrentBroker: true
  }));
  const data = {
    labels: ['Yearly', 'Monthly'],
    datasets: [
      {
        data: convertedCurrentBrokerData,
        borderWidth: 1,
        borderColor: "#ffb700",
        backgroundColor: "#ffb700",
        maxBarThickness: 40,
        barPercentage: 1,
        borderRadius: 5,
        minBarLength: 1,
        categoryPercentage: (selectedBroker.length + 1) * 0.2,
        yAxisID: "y",
        parsing: {
          yAxisKey: 'number'
        }
      },
    ]
  }

  selectedBrokerData.forEach((brokerData, index) => {
    data.datasets.push({
      data: brokerData,
      borderWidth: 1,
      borderColor: convertedCurrentBrokerData[0].number > brokerData[0].number? '#56a07e' : '#c15357',
      backgroundColor: convertedCurrentBrokerData[0].number > brokerData[0].number? '#56a07e' : '#c15357',
      maxBarThickness: 40,
      barPercentage: 1,
      borderRadius: 5,
      minBarLength: 1,
      categoryPercentage: (selectedBroker.length + 1) * 0.2,
      yAxisID: "y",
      parsing: {
        yAxisKey: 'number'
      }
    });
  });

  const options = {
    interaction: {
      mode: 'x',
      intersect: false
    },
    responsive: true,
    axes: {
      backgroundColor: '#4D4D4D',
    },
    scales: {
      y: {
        position: 'left',
        grace: '5%',
        type: 'linear',
        grid: {
          color: (context) => {
            return '#4D4D4D';
          },
          borderDash: (a) => {
            return [1, 2];
          },
          drawBorder: false,
          tickLength: -20,
          tickBorderDash: (a) => {
            return [1, 2];
          }
        },
        ticks: {
          color: "#999999",
          font: {
            size: 10,
            family: 'Noto Sans',
            weight: 700
          },
          labelOffset: -7,
          padding: 0,
          mirror: true,
          maxTicksLimit: 5,
          callback: function (value, index, ticks) {
            let lastLabel = ticks[ticks.length - 1];
            if (value === lastLabel.value) {
              return ["USD", lastLabel.value, ""];
            } else {
              return value;
            }
          }
        }
      },
      x: {
        grid: {
          offset: false,
          color: '#4D4D4D',
          borderDash: [1, 2],
          borderColor: '#4D4D4D',
          borderWidth: 1,
          tickColor: "#4D4D4D",
          tickLength: 4,
        },
        ticks: {
          padding: 0,
          color: "#999999",
          font: {
            size: 10
          }
        }
      }
    },
    layout: {
      padding: {
        bottom: 10,
        left: 30,
        right: 10,
        top: 50
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    devicePixelRatio: 2,
  }

  const rightYAxisPlugin = createRightSideYAxisWithTicks();

  return (
    <div className={styles.Chart}>
      <SandboxCommissionComparisonChartHeader
        chartTitle={chartTitle} />
      <div className={styles.hoverTextArray}></div>
      <Bar 
        // className={styles.ChartContainer}
        key={Math.random()}
        data={data}
        options={options}
        plugins={[
          backgroundPlugin,
          xAxisButtonPlugin,
          xAxisCenterPlugin,
          hoverTextPlugin,
          rightYAxisPlugin
        ]}
      />
    </div>
  )
}


export default SandboxCommissionComparison;