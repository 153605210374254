const xAxisCenterPlugin = {
  id: "custom_light_background",
  afterDraw: (chart) => {
    const { ctx, scales } = chart;
    const labelItemOne = scales.x._labelItems[0];
    const labelItemTwo = scales.x._labelItems[1];
    ctx.strokeStyle = "#4D4D4D";
    ctx.strokeWidth = '1px';
    ctx.beginPath();
    //draw vertical ticks
    ctx.moveTo(
      (labelItemOne.translation[0] + labelItemTwo.translation[0]) / 2,
      labelItemOne.translation[1] - 4
    )
    ctx.lineTo(
      (labelItemOne.translation[0] + labelItemTwo.translation[0]) / 2,
      50
    )
    ctx.stroke();
    //draw extra vertical ticks
    ctx.moveTo(
      (labelItemOne.translation[0] + labelItemTwo.translation[0]) / 2,
      labelItemOne.translation[1] - 4
    )
    ctx.lineTo(
      (labelItemOne.translation[0] + labelItemTwo.translation[0]) / 2,
      chart.height - 4
    )
    ctx.stroke();

  }
}

export default xAxisCenterPlugin;