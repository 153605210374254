import styles from './alertInfoModal.module.scss';
import htFonts from "shared/htFont.module.scss";
import { useDispatch } from 'react-redux';
import { closeModal } from 'redux/slices/modalSlice';

export default function AlertInfoModal() {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(closeModal());
  }

  return (
    <div className={styles.AlertInfoModal}>
      <div className={styles.CrossIconButton}>
        <div
          className={`${htFonts.htCross} ${styles.CancelIcon}`}
          onClick={() => close()}
        ></div>
      </div>
      <div className={styles.ContentContainer}>
        
      <div>Our alerts are targeted to be close to real-time. The alerts provide you an additional resource to improve your trading performance by receiving warning based on your real-time action.</div>
      <br/>
      <div>All previous alerts can be found by navigating to the "view all" section or through the alert field on the left navigation bar.</div>
      <br />
      <div>Traders can receive 3 types of alerts:</div>
      <div>1) Action in loss-making pattern - Those alerts describe that you have just acted in a previously identified loss-making pattern again, for example by opening a new trade or adjusting your current trade.</div>
      <div>2) New loss-making pattern identified - Those alerts describe that we just identified a new significant loss-making pattern for you.</div>
      <div>3) Risky behavior - Those alerts describe that we have just identified a deviation from your previous risk behavior.</div>
      <br />
      <div>In general, the alerts are based on your past 1 year trading history and a minimum number of trades have to be performed in order for us to regard your trading patterns as significant. No alerts will be send in case the behavior is not regarded as significant. For risk alerts we apply a different set of timeviews and significances.</div>
      <br />
      <div>Please note that alerts, just as any other information on this application, should in no case be regarded as financial advise and we cannot be held liable for any actions or losses incurred by you based on the information presented.</div>
      </div>
    </div>
  )
}