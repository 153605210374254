import { useState } from "react";
import BarChart from "components/charts/bar.chart";
import BarWithPercentageSymbolYAxis from "components/charts/barWithPercentageSymbolYAxis.chart";
import PositionWithSL from "components/charts/positionWithSL.chart";

// utils
import { createDataObjectForChart } from "utils/helpers/chart";
import { getChartsData } from "redux/selectors";
import { useSelector } from "react-redux";
import { ChartMode } from 'utils/constant/index';

function RiskManagement({ chartKeysOfThePage, currentTradingAccount }) {
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartMode, setChartMode] = useState(ChartMode.PERCENTAGE);

  const chartsData = useSelector(getChartsData(chartKeysOfThePage));

  let chartDataObjects = {};
  chartKeysOfThePage.forEach(chartKey => {
    chartDataObjects[chartKey] = createDataObjectForChart(chartKey, chartsData[chartKey]);
  });

  return (
    <>
      <BarWithPercentageSymbolYAxis
        key="Risk after losing trade(s) (per trade)"
        chartTitle="Risk after losing trade(s) (per trade)"
        chartData={chartDataObjects["risk_after_losing_trades_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        chartMode={ChartMode.PERCENTAGE}
        dualMode={false}
        hoverTextArray={chartDataObjects["risk_after_losing_trades_per_trade"].hoverTextArray}
        currentTradingAccount={currentTradingAccount}
      />
      <BarWithPercentageSymbolYAxis
        key="Risk after losing trade(s) (open trade)"
        chartTitle="Risk after losing trade(s) (open trade)"
        chartData={chartDataObjects["risk_after_losing_trades_open_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        chartMode={ChartMode.PERCENTAGE}
        dualMode={false}
        hoverTextArray={chartDataObjects["risk_after_losing_trades_open_trade"].hoverTextArray}
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Profit if adding to negative position(s)"
        chartTitle="Profit if adding to negative position(s)"
        chartData={chartDataObjects["profit_if_adding_to_negative_positions"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["profit_if_adding_to_negative_positions"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarChart
        key="Profit secure, payoff from reverting trades"
        chartTitle="Profit secure, payoff from reverting trades"
        chartData={chartDataObjects["profit_secure_payoff_from_reverting_trades"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["profit_secure_payoff_from_reverting_trades"].hoverTextArray
        }
        currentTradingAccount={currentTradingAccount}
      />
      <BarWithPercentageSymbolYAxis
        key="Risk per trade (last trades)"
        chartTitle="Risk per trade (last trades)"
        chartData={chartDataObjects["risk_per_trade_last_trades"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.PERCENTAGE}
        chartMode={ChartMode.PERCENTAGE}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects["risk_per_trade_last_trades"].hoverTextArray}
        currentTradingAccount={currentTradingAccount}
      />
      <BarWithPercentageSymbolYAxis
        key="Max. risk all open trades (last trades)"
        chartTitle="Max. risk all open trades (last trades)"
        chartData={chartDataObjects["max_risk_all_open_trades_last_trades"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.PERCENTAGE}
        chartMode={ChartMode.PERCENTAGE}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects["max_risk_all_open_trades_last_trades"].hoverTextArray}
        currentTradingAccount={currentTradingAccount}
      />
      <PositionWithSL
        key="% of positions with SL"
        chartTitle="% of positions with SL"
        chartData={chartDataObjects["percentage_of_position_with_sl"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.PERCENTAGE}
        chartMode={ChartMode.PERCENTAGE}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects["percentage_of_position_with_sl"].hoverTextArray}
      />
    </>
  );
}

export default RiskManagement;
