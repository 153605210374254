const tradeMedicInfoContent = {
  overtrading: {
    explanation: `Over trading describes the behavior of performing a large number of trades during a short amount of time. Oftentimes, over trading leads to an underperformance in your trades due to an emotion-based entry and trade management, lack of focus on individual trades, or trading non-ideal trade set-ups. The effect of over trading on your trading result is derived by measuring your performance depending on the number of trades you performed during a day. A large number of trades during a day may indicate over trading. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Gambler's Fallacy`,
        link: "https://hoc-trade.com/blogs/are-you-victim-to-the-gamblers-fallacy-in-trading"
      }
    ]
  },
  revenge_trading: {
    explanation: `Revenge trading describes the attempt to recover losses quicky after they have been incurred. Revenge trading can negatively impact your trading performance due to the emotional nature of trade entries and trade management as well as an oftentimes perceived higher winning likelihood after losses with adverse effects on a traders' risk management. The effect of revenge trading on your performance is derived by measuring your performance depending on how long you waited to enter a new trade after incurring a loss. For details, please refer to your personal output chart.`,
  },
  catch_a_falling_knife: {
    explanation: `Catching a falling knife describes the behavior of buying an asset that just experienced a sharp price drop. Traders tend to see a good opportunity to gain from a price reversal and may feel FOMO (fear of missing out) on this price move. However, spontanous trade entries without a pre-defined set-up and underestimating the strong momentum from sharp price drops oftentimes results in losses if trying to catch the falling knife. Your performance effect is derived by identifying trades in which you traded against a strongly overbought or oversold market. For details, please refer to your personal output chart.`,
  },
  cut_profits_early: {
    explanation: `Cutting profits early describes the behavior of closing your trade in profit before it reaches your Take Profit (TP) level. Traders tend to close their winning trades early in fear of losing their unrealized gains, a human tendency closely connected to loss aversion and well described in Nobel Prize winning Prospect Theory. The performance effect is derived by simulating the profit of your manually closed profit trades in case you did nothing and let it reach your original TP or SL. The result is compared to your actual profit for those trades. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Prospect Theory`,
        link: "https://hoc-trade.com/blogs/how-can-nobel-prize-winning-prospect-theory-explain-our-toxic-trading-behavior"
      },
      {
        content: `Pessimism Bias`,
        link: "https://hoc-trade.com/blogs/does-the-pessimism-bias-hurt-your-trading-performance-trading-psychology"
      },
    ]
  },
  news_trading: {
    explanation: `News trading describes the behavior of actively trading during news that influence the price of the asset. Whether actively seeking to trade news or not, many traders tend to try scalping some profits during these high volatility environments. However, given the fast and strong price movements, traders are at elevated risk in case of slow decision-making, failed risk management, or due to high slippage. Your performance effect is derived by comparing your performance during high vs. low volatility times. For details, please refer to your personal output chart.`,
  },
  fail_to_cut_losses: {
    explanation: `Failing to cut losses describes the behavior of adding risk to a loss trade instead of cutting your losses and moving on. Many traders struggle to cut their losses early, thereby incurring large losses with single trades. This human behavioral tendency can well be explained by Nobel Price winning Prospect Theory. The performance effect is derived by comparing your trade outcome in which you adjusted your Stop Loss (SL) further away from entry price while the trade was in loss vs. the simulated trade outcome if you would have not done this. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Prospect Theory`,
        link: "https://hoc-trade.com/blogs/how-can-nobel-prize-winning-prospect-theory-explain-our-toxic-trading-behavior"
      },
    ]
  },
  fighting_the_trend: {
    explanation: `Fighting the trend describes the behavior of trading against the current trend environment. Some traders tend to enter the market with a predetermined view of the market going long or short and consecutively fail to adjust their view in case the market is behaving differently that day. The effect may intensify from the perception that "I am right" and the market is wrong, oftentimes causing losses for the trader. The performance effect of fighting the trend is derived by analysing the profit of trades against the short-and medium-term market trend. For details, please refer to your personal output chart.`,
  },
  weekend_trading: {
    explanation: `Weekend trading describes trading on Saturdays and Sundays. Some traders feel the urge to also trade on weekends, which may have negative performance effects due to a different market environment with fewer market participants, higher spreads and increased slippage. Moreover, weekend trading may also limit the rest times for the trader and negatively impact the ability to focus. Your performance effect is derived by measuring your trading profits for trades opened on the weekend. For details, please refer to your personal output chart.`,
  },
  distracted_trading: {
    explanation: `Distracted trading describes active trading during times in which you do not have your full focus on trading. Many traders tend to also trade while they perform other tasks at the same time, which due to the highly complex nature of trading can negatively influence their trading performance. Your performance effect is derived by identifying patterns of certain times during the day in which you show weak performance, which may likely be caused by distraction (regular activities such as work). Note that distraction may not be the only cause for this. For details, please refer to your personal output chart.`,
  },
  doubling_down: {
    explanation: `Doubling down, or also Dollar Cost Averaging (DCAing) describes the behavior of adding another position to your loss trade in order to receive a better average entry price. In active trading, many traders tend to double down on their trades instead of accepting the loss, thereby hoping that a small price move in their favor enables them to exit their position without loss. However, they increase their risk significantly, potentially leading to large losses in case of unfavorable price action. Your performance effect is derived by summing up the profit of trades that qualify as doubling-down. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Prospect Theory`,
        link: "https://hoc-trade.com/blogs/how-can-nobel-prize-winning-prospect-theory-explain-our-toxic-trading-behavior"
      },
    ]
  },
  overoptimism: {
    explanation: `Overoptimism describes the behavior of setting profit targets that may be overly optimistic. The behavior should not be mistaken for overconfidence. Overoptimism may lead the trader to set Take Profits (TPs) that are unlikely to be reached, thereby failing to realize profits. The ongoing thought of realizing those large profits may impede a traders' ability to judge objectively. Your performance effect is derived by comparing your actual profits vs. the simulated profits if you would set your TPs closer to your entry price. For details, please refer to your personal output chart.`,
  },
  failed_derisking: {
    explanation: `Failed de-risking describes the behavior of not securing your trade at optimum levels by setting the Stop Loss to breakeven. Some traders tend to de-risk their trades at minimal gains already, thereby getting stopped-out in a large number of trades. Other traders do not de-risk their trades at all or at very late stages, thereby being at risk of losing their unrealized gains. Your performance effect is derived by analysing your optimum level of de-risking your trade and calculating the difference to your actual performance. For details, please refer to your personal output chart.`,
  },
  impatient_entries: {
    explanation: `Impatient entries describes an early entry into a trade in which the exact price point has not been reached yet. Especially reversal traders may be negetively affected by impatient trade entries as the full reversal has not yet developed, however they tend to feel FOMO (fear of missing out) to the trade set-up they have been patiently waiting for. Thereby, they achieve a less ideal entry price. Your performance effect is derived by comparing your actual profits vs. the simulated profits if you would enter the trade slightly later. For details, please refer to your personal output chart.`,
  },
  trade_without_break: {
    explanation: `Trade without break describes the behavior to have no or only very short breaks in between your trades. Trade preparation and review take a lot of time for traders, and oftentimes traders have to wait patiently for their right trading set-ups. Trades without or only brief breaks are an indicator for impatience, taking non-ideal set-ups, and missing preparation, oftentimes leading to a worse outcome. Your performance effect is derived by measuring your trade profit depending on the break length before the trade. For details, please refer to your personal output chart.`,
  },
  overcomplexity: {
    explanation: `Over-complexity describes the behavior of traders to focus on a large number of assets at the same time. Trading is a demanding task with a variety of information that have to be processed by the trader simultaneously. Exceeding a certain complexity threshold, for example by trading many assets at the same time, can negatively affect a trader's ability to make well-informed and objective decisions. Your performance effect is derived by measuring your profit depending on how many symbols you trade at the same time. For details, please refer to your personal output chart.`,
  },
  impatient_exits: {
    explanation: `Impatient exits describe the behavior of closing your trades too early. Many traders tend to feel the urge to close their trades slightly earlier than anticipated, especially if the price shows a correction just before hitting the TP or a strong move towards the SL. Additionally, brief moments of little price movement may motivate the trader to close the trade, while the orignial trade set-up is still in play. Your performance effect is derived by comparing your actual profits vs. the simulated profits if you would exit the trade slightly later. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Pessimism Bias`,
        link: "https://hoc-trade.com/blogs/does-the-pessimism-bias-hurt-your-trading-performance-trading-psychology"
      },
    ]
  },
  too_tight_stops: {
    explanation: `Too tight stops describes the behavior of setting overly tight Stop Losses (SLs). Some traders tend to set very tight Stop Losses with larger position size, thereby giving the trade only very limited room to breath. Moreover, market noise may cause stops to be triggered and trading fees become proportionally much more relevant. Your performance effect is derived by comparing your actual profits vs. the simulated profits if you would place your SL further away from the entry level. For details, please refer to your personal output chart.`,
  },
  too_wide_stops: {
    explanation: `Too wide stops describes the behavior of setting overly far away Stop Losses (SLs). Some traders tend to set wide Stop Losses with the motivation to reduce the likelihood of them being triggered, however the technical trade set-up would not require such wide Stop Loss. Thereby, these traders always incur an additional loss on top of their trade set-up not working out in this specific trade. Your performance effect is derived by comparing your actual profits vs. the simulated profits if you would place your SL closer to the entry level. For details, please refer to your personal output chart.`,
  },
  emotional_trading: {
    explanation: `Emotional trading describes the behavior of having emotions affecting the trading decisions, such as entries, exits, or trade management. Trading emotions may be caused by a number of reasons, one of the major ones being currently open positions in strong profit or loss. Emotional trading is characterized by subjective decision making and a strong tendency to act in known behavioral biases. Your performance effect is derived by identiying underperformance in case of strong open losses or profits at the time of a new trade. For details, please refer to your personal output chart.`,
  },
  anxious_trade_entries: {
    explanation: `Anxious trade entries describes the behavior of being slightly late when opening a new trade. Some traders tend to overthink a trade entry or wait for too many confirmations of the trade set-up. Thereby, those traders miss the ideal entry point with a negative effect on their trade profit. Your performance effect is derived by comparing your actual profits vs. the simulated profits if you would enter the trade slightly earlier. For details, please refer to your personal output chart. Please be cautious: With earlier trade entries you have less information available, potentially entering trades you otherwise wouldn't have `,
  },
  anxious_trade_exits: {
    explanation: `Anxious trade exits describes the behavior of being slightly late when closing a trade. Some traders tend to wait for an unfavorable price reversal in their profit- and/ or loss-taking, thereby limiting their profits or growing their losses. Your performance effect is derived by comparing your actual profits vs. the simulated profits if you would exit the trade slightly earlier. For details, please refer to your personal output chart. Please be cautious: With earlier trade exits you have less information available, potentially exiting trades you otherwise wouldn't have `,
  },
  overpaying_fees: {
    explanation: `Overpaying fees is applicable if you could save on trading fees (spread & commission) in case trading at a different broker/ exchange performing the exact same trades and position sizes. The difference in fees directly improves your trading profitability. Brokers/ exchanges oftentimes have steep differences in fees for different assets. Your exact trading behavior influences the optimized choice. Your performance effect is derived by comparing the fees at your current broker/ exchange to other brokers/ exchanges. Note there might be exclusive discount included only applicable through hoc-trade.`,
  },
  inefficient_hedging: {
    explanation: `Inefficient hedging describes the behavior of hedging parts or full positions, while the same exposure could be achieved trading a different symbol. Many traders tend to purely act on chart set-ups without considering the exposures they take, thereby increasing their trading fees. For example, EURUSD long & GBPUSD short hedges the USD exposure, being EUR long & GBP short. Trading EURGBP would reduce the trading fees. Your performance effect is derived by comparing your profitability for trades with such conflict vs. trades without. For details, please refer to your personal output chart.`,
  },
  fail_to_call_it_a_day: {
    explanation: `Failing to call it a day describes the behavior to keep trading even after large gains or losses on that day. Traders tend to be emotionally influenced after having realized large losses or gains throughout the day. Those emotions, such as frustration or the feeling of being unbeatable, can negatively influence our trading behavior in the following trades. Oftentimes, those trades are characterized by an overly aggresive trading behavior. Your performance effect is derived by identifying underperformance after having losses or gains during the day. For details, please refer to your personal output chart.`,
  },
  blindly_follow_momentum: {
    explanation: `Blindly following momentum describes the behavior of entering a trade after the asset has just performed a strong price movement. Traders attempting to capitalize on a further move of the already strong existing price action face the risk of being late and getting trapped in the price correction. The common strong volatility adds to the complexity and need for quick decisions, oftentimes leading to a loss for the trader. Your performance effect is derived by identifying trades in which you traded along a strongly overbought or oversold market. For details, please refer to your personal output chart.`,
  },
  // Risk report
  emotional_loss_streaks: {
    explanation: `Emotional loss streak describes the behavior of increasing your risk per trade after incurring a number of losses in your previous trades. Some traders perceive the winning likelihood of their trades higher in case the had a couple of loss trades beforehand. This behavior roots in a human behavioral tendency described as the Gambler's Fallacy. Moreover, some traders tend to revenge trade after losses combined with higher risks per trade. Your risk behavior is derived by identifying whether you show an average increased risk per trade after some consecutive losses. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Gambler's Fallacy`,
        link: "https://hoc-trade.com/blogs/are-you-victim-to-the-gamblers-fallacy-in-trading"
      }
    ]
  },
  all_in_trades: {
    explanation: `All-in trades describes the behavior of entering risk that could lead to a margin call of the account. All-in trades are extremely risky and likely to sooner or later lead to a margin call of your trading account. All-in trades oftentimes are placed during highly emotional trading, mostly in combination with previous losses. Having clear risk management rules of risk for all open positions or stop trading after X% loss in a day can help preventing all-in trades. Your risk behavior is derived by identifying your total risk of all open positions. If exceeding a certain threshold, it is flagged as all-in trades. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Risk Management Guardrails`,
        link: "https://hoc-trade.com/blogs/set-risk-management-guardrails-in-crypto-trading-trading-academy"
      }
    ]
  },
  trade_without_sl: {
    explanation: `Trade without Stop Loss (SL) describes trades in which no Stop Loss was set by the trader. In general, every trade should be secured by a Stop Loss, yet some traders tend to not set Stop Losses for their trades as they are monitoring their trades. However, news, black swan events, etc. may heavily influence prices at any point in time. Active monitoring may not help to secure positions due to the speed of movements and emotional effects if strong price actions happen. Every trade without Stop Loss imposes a risk for the trader. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Risk Management Introduction`,
        link: "https://hoc-trade.com/blogs/risk-management-introduction-for-crypto-traders-trading-academy"
      }
    ]
  },
  excessive_risk_taking: {
    explanation: `Excessive risk taking describes the behavior of placing trades with risks that in the long-term likely leads to significant drawdowns for the trader. Every trader will have loss streaks and times with lower than average hit rates at one point. In case of excessive risks per trade, the trader is likely to experience large drawdowns or even margin calls during these times. Therefore, it is critical for the trader to tightly manage the risk per trade and thereby secure their account during times of loss streaks and low hit rates. Your risk behavior is derived by identifying your risk per trade. If exceeding a certain threshold, it is flagged as excessive. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Risk per Trade`,
        link: "https://hoc-trade.com/blogs/whats-your-risk-per-trade-as-a-crypto-trader-trading-academy"
      }
    ]
  },
  reward_risk_imbalance: {
    explanation: `Reward Risk imbalance describes the behavior of losing more in a loss trade than gaining in a profit trade. With such Reward Risk Ratio (RRR), the traders needs a hit rate of larger 50% in order to be profitable. The analysis refers to the actual, executed Return Risk Ratio. The designed Reward Risk Ratio based on originally set Take Profits and Stop Losses in a trade may differ if manually closing trades or stop and profit levels are adjusted. Note: Some profitable trading strategies are designed with low RRR but high hit rates. Your risk behavior is derived by comparing your average gain vs. loss per trade. For details, please refer to your personal output chart.`,
    furtherDetail: [
      {
        content: `Prospect Theory`,
        link: "https://hoc-trade.com/blogs/how-can-nobel-prize-winning-prospect-theory-explain-our-toxic-trading-behavior"
      }
    ]
  },
}

export default tradeMedicInfoContent;