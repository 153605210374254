import { chartKeys } from 'utils/constant';

const open_position_in_profit_loss = {
  chartTitle: 'Open position in profit/loss',
  chartKey: chartKeys.OPEN_POSITION_IN_PROFIT_LOSS,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'usdBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        },
        unit: ""
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ]
  },
  interval: [
    '<-4%',
    '-2%--4%',
    '-2%-0',
    '0',
    '0-2%',
    '2%-4%',
    '>4%'
  ],
}

export default open_position_in_profit_loss;