import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import styles from "./performanceOverTime.module.scss";
import htFonts from "shared/htFont.module.scss";
import options from "./performanceOverTime.options";
import ChartHeader from "../../../charts/chartHeader";
import { Link } from "react-router-dom";

// plugins
import createPerformanceOverTimePlugin from "./performanceOverTime.plugin";
import createBackgroundPlugin from "components/charts/plugins/createBackground.plugin";
import xAxisPlugin from "../xAxis.plugin";
import createPerformanceHoverTextPlugin from './PerformanceHoverText.plugin';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PerformanceOverTime = ({ chartData }) => {
  const performanceOverTimePlugin = createPerformanceOverTimePlugin();
  const backgroundPlugin = createBackgroundPlugin(true);
  const hoverTextPlugin = createPerformanceHoverTextPlugin(chartData);
  let scoreboardData = chartData[999][chartData[999].length-1];

  let allIntervals = chartData[999].map((data) => {
    // let time = typeof data.time === 'string'? new Date(data.time) : data.time;
    let time = typeof data.time === 'string'? new Date(data.time) : data.time;
    let timeOffsetInMillSecs = (time.getTimezoneOffset())*60*1000;
    let timestamp = (time.getTime());
    let clientTime = new Date(timestamp+timeOffsetInMillSecs);
    let interval = clientTime.toLocaleString("default", { month: "short" });

    return interval
  })

  let showInterval = true;
  let modifiedIntervals = allIntervals.map((interval) => {
    if (interval !== null) {
      if (showInterval) {
        showInterval = false;
        return interval;
      } else {
        showInterval = true;
        return null;
      }
    }
    return interval;
  });


  const data = {
    labels: modifiedIntervals,
    datasets: [
      {
        data: chartData[999].map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data.percentage
          }
        }),
        yAxisId: "y",
      },
      // feed data to right y axis
      {
        data: chartData[999].map((data) => {
          return {
            x: typeof data.time === 'string'? new Date(data.time) : data.time,
            y: data.percentage
          }
        }),
        yAxisID: "y1",
      }
    ]
  }

  return (
    <div className={styles.ChartContainer}>
      <ChartHeader 
        chartTitle={chartData.chartTitle}
        chartKey="performance-over-time"
      />
      <div className={styles.Chart}>
        <Line
          key={Math.random()}
          data={data}
          options={options}
          plugins={[
            performanceOverTimePlugin,
            backgroundPlugin,
            xAxisPlugin,
            hoverTextPlugin
          ]}
        />
      </div>
      <div className={styles.Scoreboard}>
        <div className={styles.ScoreboardLeft}>
          <div className={styles.columnOne}>
            <div style={{color: "#06AFC9"}}>Performance</div>
            <div>{scoreboardData?.performancePercentage?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</div>
            {
              scoreboardData?.performancePips ? <div className={styles.Label}>PIPS {scoreboardData.performancePips.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div> : null
            }
            {/* <div className={styles.Label}>PIPS {scoreboardData.performancePips.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div> */}
          </div>
          <div className={styles.columnTwo}>
            <div style={{color: "#06AFC9"}}>Equity</div>
            <div>{scoreboardData?.equityTotal?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD</div>
            <div className={styles.Label}>{scoreboardData?.equity.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USD</div>
          </div>
        </div>
        <div className={styles.ScoreboardRight}>
          <div style={{color: "#06AFC9"}}>Age</div>
          <div>{scoreboardData?.duration}</div>
          {/* <Link to="/dashboard/overview/account-overview" style={{ textDecoration: 'none' }}>
            <div className={styles.ScoreboardBtn}>
              <span className={htFonts.htMedal}></span>
              Scoreboard
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default PerformanceOverTime;