import TradeMedicContent from "./tradeMedicContent";
import chartKeyWithNameMap from 'utils/constant/chartKeyNameMap';
import generateChartsDataFormatForResponsiveTradeMedic from "utils/helpers/generateChartsDataFormatForResponsiveTradeMedic";
import { setResponsiveTradeMedicChartsData } from "redux/slices/responsiveTradeMedicChartsDataSlice";
import { useDispatch } from "react-redux";

const TradeMedicContentContainer = ({ tradeMedicData }) => {
  const dispatch = useDispatch();
  let currentTradingAccount = {
    currency: tradeMedicData.currency
  }
  let responsiveTradeMedicChartData = {};
  Object.keys(tradeMedicData).forEach(key => {
    if (chartKeyWithNameMap[key]) {
      responsiveTradeMedicChartData[key] = tradeMedicData[key]
    }
  });
  
  const convertedData = generateChartsDataFormatForResponsiveTradeMedic(responsiveTradeMedicChartData);
  convertedData.currentTradingAccount = currentTradingAccount;

  if (convertedData) {
    dispatch(setResponsiveTradeMedicChartsData({
      responsiveTradeMedicChartsData: convertedData,
    }))
  };

  return <TradeMedicContent tradeMedicData={tradeMedicData} />;
}

export default TradeMedicContentContainer;