// Draw first area with intersection
const drawFirstAreaTopToYAxis = (
  ctx,
  currentPoint,
  crossPointPosX,
  pointZeroPosY,
  chartArea,
  color
) => {
  const opacity = 0.8;
  // Draw area
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(crossPointPosX, pointZeroPosY);
  ctx.lineTo(currentPoint.x, pointZeroPosY);
  ctx.lineTo(currentPoint.x, currentPoint.y);

  const gradient = ctx.createLinearGradient(
    0,
    pointZeroPosY,
    0,
    chartArea.top
  );
  gradient.addColorStop(0, `rgba(${color}, 0)`);
  gradient.addColorStop(1, `rgba(${color}, ${opacity})`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw Line
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(crossPointPosX, pointZeroPosY);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawFirstAreaBottomToYAxis = (
  ctx,
  currentPoint,
  crossPointPosX,
  pointZeroPosY,
  chartArea,
  color
) => {
  const opacity = 0.8;
  // Draw area
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(crossPointPosX, pointZeroPosY);
  ctx.lineTo(currentPoint.x, pointZeroPosY);
  ctx.lineTo(currentPoint.x, currentPoint.y);

  const gradient = ctx.createLinearGradient(
    0,
    pointZeroPosY,
    0,
    chartArea.bottom
  );
  gradient.addColorStop(0, `rgba(${color}, 0)`);
  gradient.addColorStop(1, `rgba(${color}, ${opacity})`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw Line
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(crossPointPosX, pointZeroPosY);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
};

// Draw second area with intersection
const drawSecondAreaYAxisToBottom = (
  ctx,
  crossPointPosX,
  pointZeroPosY,
  nextPoint,
  chartArea,
  color
) => {
  const opacity = 0.8;
  // Draw area
  ctx.beginPath();
  ctx.moveTo(crossPointPosX, pointZeroPosY);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.lineTo(nextPoint.x, pointZeroPosY);
  ctx.lineTo(crossPointPosX, pointZeroPosY);

  const gradient = ctx.createLinearGradient(0, pointZeroPosY, 0, chartArea.bottom);
  gradient.addColorStop(0, `rgba(${color}, 0)`);
  gradient.addColorStop(1, `rgba(${color}, ${opacity})`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw Line
  ctx.beginPath();
  ctx.moveTo(crossPointPosX, pointZeroPosY);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
};

const drawSecondAreaYaxisToTop = (
  ctx,
  nextPoint,
  crossPointPosX,
  pointZeroPosY,
  chartArea,
  color
) => {
  const opacity = 0.8;
  // Draw area
  ctx.beginPath();
  ctx.moveTo(crossPointPosX, pointZeroPosY);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.lineTo(nextPoint.x, pointZeroPosY);
  ctx.lineTo(crossPointPosX, pointZeroPosY);

  const gradient = ctx.createLinearGradient(0, pointZeroPosY, 0, chartArea.top);
  gradient.addColorStop(0, `rgba(${color}, 0)`);
  gradient.addColorStop(1, `rgba(${color}, ${opacity})`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw Line
  ctx.beginPath();
  ctx.moveTo(crossPointPosX, pointZeroPosY);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
}

// Draw area without intersection
const drawAreaAboveYAxis = (
  ctx,
  currentPoint,
  nextPoint,
  pointZeroPosY,
  chartArea,
  color
) => {
  const opacity = 0.8;
  // Draw area
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.lineTo(nextPoint.x, pointZeroPosY);
  ctx.lineTo(currentPoint.x, pointZeroPosY);
  ctx.lineTo(currentPoint.x, currentPoint.y);

  const gradient = ctx.createLinearGradient(0, pointZeroPosY, 0, chartArea.top);
  gradient.addColorStop(0, `rgba(${color}, 0)`);
  gradient.addColorStop(1, `rgba(${color}, ${opacity})`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw Line
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
}

const drawAreaBelowYAxis = (
  ctx,
  currentPoint,
  nextPoint,
  pointZeroPosY,
  chartArea,
  color
) => {
  const opacity = 0.8;
  // Draw area
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.lineTo(nextPoint.x, pointZeroPosY);
  ctx.lineTo(currentPoint.x, pointZeroPosY);
  ctx.lineTo(currentPoint.x, currentPoint.y);

  const gradient = ctx.createLinearGradient(0, pointZeroPosY, 0, chartArea.bottom);
  gradient.addColorStop(0, `rgba(${color}, 0)`);
  gradient.addColorStop(1, `rgba(${color}, ${opacity})`);
  ctx.fillStyle = gradient;
  ctx.fill();

  // Draw Line
  ctx.beginPath();
  ctx.moveTo(currentPoint.x, currentPoint.y);
  ctx.lineTo(nextPoint.x, nextPoint.y);
  ctx.strokeStyle = `rgba(${color})`;
  ctx.lineWidth = 1;
  ctx.stroke();
}

// Draw solid y=0 line
const drawSolidZeroYLine = (ctx, chartArea, pointZeroPosY) => {
  ctx.beginPath()
  ctx.moveTo(chartArea.left, pointZeroPosY);
  ctx.lineTo(chartArea.right, pointZeroPosY);
  ctx.strokeStyle = "#4D4D4D";
  ctx.lineWidth = 1;
  ctx.stroke();
}


const createPerformanceOverTimePlugin = () => {
  const performanceOverTimePlugin = {
    id: "performance_over_time_plugin",
    afterDraw: (chart) => {
      const chartArea = chart.chartArea;
      const pointsElement = chart._metasets[0].data;
      const pointZero = 0;
      const yScale = chart.scales.y;
      const pointZeroPosY = yScale.getPixelForValue(pointZero);

      const pointsPos = pointsElement.map((point) => {
        return {
          x: point.x,
          y: yScale.getPixelForValue(point.$context.parsed.y),
        };
      });
      const ctx = chart.ctx;

      drawSolidZeroYLine(ctx, chartArea, pointZeroPosY);

      for (let i = 0; i < pointsPos.length; i++) {
        if (i === pointsPos.length - 1) break;
        const currentPoint = pointsPos[i];
        const nextPoint = pointsPos[i + 1];
        const sideX = nextPoint.x - currentPoint.x;
        const sideY = nextPoint.y - currentPoint.y;
        const pointZeroPosYToNextPointY = nextPoint.y - pointZeroPosY;
        const distanceToNextPointXFromCrossPoint =
          (pointZeroPosYToNextPointY / sideY) * sideX;
        const crossPointPosX = nextPoint.x - distanceToNextPointXFromCrossPoint;
        // const previousPoint = pointsPos[i - 1];

        // cross y = 0
        // top-left to bottom-right
        if (currentPoint.y < pointZeroPosY && nextPoint.y > pointZeroPosY) {
          const ctx = chart.ctx;
          drawFirstAreaTopToYAxis(
            ctx,
            currentPoint,
            crossPointPosX,
            pointZeroPosY,
            chartArea,
            "86, 160, 126"
          );
          drawSecondAreaYAxisToBottom(
            ctx,
            crossPointPosX,
            pointZeroPosY,
            nextPoint,
            chartArea,
            "193, 83, 87"
          );
        }
        // cross y = 0
        // bottom-left to top-right
        if (currentPoint.y > pointZeroPosY && nextPoint.y < pointZeroPosY) {
          const ctx = chart.ctx;
          drawFirstAreaBottomToYAxis(
            ctx,
            currentPoint,
            crossPointPosX,
            pointZeroPosY,
            chartArea,
            "193, 83, 87"
          );
          drawSecondAreaYaxisToTop(
            ctx,
            nextPoint,
            crossPointPosX,
            pointZeroPosY,
            chartArea,
            "86, 160, 126"
          )
        }
        // Non-cross y = 0
        // Above y = 0
        if (currentPoint.y < pointZeroPosY && nextPoint.y < pointZeroPosY) {
          const ctx = chart.ctx;
          drawAreaAboveYAxis(
            ctx,
            currentPoint,
            nextPoint,
            pointZeroPosY,
            chartArea,
            "86, 160, 126"
          )
        }
        // Non-cross y = 0
        // Below y = 0
        if (currentPoint.y > pointZeroPosY && nextPoint.y > pointZeroPosY) {
          const ctx = chart.ctx;
          drawAreaBelowYAxis(
            ctx,
            currentPoint,
            nextPoint,
            pointZeroPosY,
            chartArea,
            "193, 83, 87"
          )
        }
      }
    },
  };

  return performanceOverTimePlugin;
};

export default createPerformanceOverTimePlugin;
