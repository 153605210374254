import styles from './registerCompleted.module.scss';
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modalSlice';
import * as modalTypes from 'constants/modalTypes';
import { useNavigate } from "react-router-dom";

export default function RegisterCompleted() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const login = () => {
  //   return dispatch(openModal({
  //     displayedModal: modalTypes.LOGIN_MODAL,
  //   }));
  // }
  const goToLogin = () => {
    navigate('/login');
  }

  return (
    <div className={styles.Container}>
      <div>
        You have registered successfully
      </div>
      <div 
        className={styles.LoginButton}
        onClick={() => goToLogin()}>Login</div>
    </div>
  )
}