import styles from "./paginationButtons.module.scss";
import { useEffect, useState } from "react";
import nextBtn from "../../assets/tradeHistory/nextBtn.png";
import preBtn from "../../assets/tradeHistory/preBtn.png";

function PaginationButtons({ range, setPage, page, slice }) {
  const [showChunk, setShowChunk] = useState();

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    };
    function getRangeChunk () {
      let chunkSize = 10;
      for ( let i = 0; i < range.length; i += chunkSize ) {
        const chunk = range.slice(i, i+chunkSize);
        
        if (chunk.includes(page)) {
          setShowChunk(chunk);
        }
      }
    };
    getRangeChunk();
  }, [slice, page, setPage, range]);


  return (
    <div className={styles.paginationButtonsContainer}>
      <div
        className={styles.previousTenPagesButton}
        onClick={() => (page >= 11 ? setPage(page - 10) : setPage(page))}
      >
        <img src={preBtn} alt="previous button" />
        <img src={preBtn} alt="previous button" />
      </div>
      <div
        className={styles.previousPageButton}
        onClick={() => (page !== 1 ? setPage(page - 1) : setPage(page))}
      >
        <img src={preBtn} alt="previous button" />
      </div>
      {showChunk ? showChunk.map((el, index) => (
        <button
          key={index}
          className={`${styles.button} ${
            page === el ? styles.activeButton : styles.inactiveButton
          }`}
          onClick={() => setPage(el)}
        >
          {el}
        </button>
      )) : null}
      <div
        className={styles.nextPageButton}
        onClick={() =>
          page !== range.length ? setPage(page + 1) : setPage(page)
        }
      >
        <img src={nextBtn} alt="next button" />
      </div>
      <div
        className={styles.nextTenPagesButton}
        onClick={() =>
          page <= range.length-10 ? setPage(page + 10) : setPage(page)
        }
      >
        <img src={nextBtn} alt="next button" />
        <img src={nextBtn} alt="next button" />
      </div>
    </div>
  );
}

export default PaginationButtons;
