import createBarColorComparison from "components/charts/helpers/createBarColorComparison";
import createHoverBarColorComparison from "components/charts/helpers/createHoverBarColorComparison";
import createBarBorderColorComparison from "components/charts/helpers/createBarBorderColorComparison";
import { chartKeys } from 'utils/constant';

const profit_of_conflicting_symbols = {
  chartTitle: "Profit of conflicting symbols",
  chartKey: chartKeys.PROFIT_OF_CONFLICTING_SYMBOLS,
  createBarColorComparison: createBarColorComparison,
  createHoverBarColorComparison: createHoverBarColorComparison,
  createBarBorderColorComparison: createBarBorderColorComparison,
  hoverTextArray: {
    usd: [
      {
        templateString: "",
        valueKey: "usd",
        unit: "USD/trade",
        textColor: function (hoveredData) {
          return hoveredData.usd >= 0 ? "#56A07E" : "red";
        },
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
      },
      {
        templateString: "B:",
        valueKey: "pipsBenchmark",
        unit: "",
      },
    ],
    percentage: [
      {
        templateString: "",
        valueKey: "percentage",
        unit: "%",
        textColor: function (hoveredData) {
          return hoveredData.percentage >= 0 ? "#56A07E" : "red";
        },
      },
      {
        templateString: "Trades: ",
        valueKey: "trades",
      },
      {
        templateString: "",
        valueKey: "profit",
        unit: "USD",
      },
      {
        templateString: "B:",
        valueKey: "percentageBenchmark",
        unit: "",
      },
    ],
  },
  // haspercentageBenchmark: true,
  interval: ["No conflict", "Conflict"],
}

export default profit_of_conflicting_symbols;