import { chartKeys } from 'utils/constant';

const profit_by_weekday_per_trade = {
  chartTitle: "Profit By weekday (per trade)",
  chartKey: chartKeys.PROFIT_BY_WEEKDAY_PER_TRADE,
  createHoverTextArray: (currency) => {
    return {
      'pips': [
        {
          templateString: '',
          valueKey: 'pips',
          unit: 'pips',
          followBarColor: true,
        },
        {
          templateString: '',
          valueKey: 'profit',
          followBarColor: true,
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'pipsBenchmark',
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        }
      ],
      'percentage': [
        {
          templateString: '',
          valueKey: 'percentage',
          unit: '%',
          followBarColor: true,
        },
        {
          templateString: '',
          valueKey: 'profit',
          followBarColor: true,
          unit: currency
        },
        {
          templateString: 'Trades: ',
          valueKey: 'trades',
        },
        {
          templateString: 'B: ',
          valueKey: 'percentageBenchmark',
          createLabelColor: function() {
            return '#FFB700';
          },
          textColor: function() {
            return '#FFB700';
          }
        }
      ]
    }
  },
  // interval: [
  //   'Sun',
  //   'Mon',
  //   'Tue',
  //   'Wed',
  //   'Thu',
  //   'Fri',
  //   // 'Sat'
  // ],
}

export default profit_by_weekday_per_trade;