import styles from "./subPageLayout.module.scss";
import { useState, useEffect, useMemo } from "react";
// components
import TopFivePatterns from "components/topFivePatterns";
import BarChart from "components/charts/crypto/bar.chart";
// utils
import { fetchCryptoChartsData } from "utils/requests";
import { getChartsData, getCurrentTradingAccount } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { chartKeys } from "utils/constant";
import { setChartsData } from "redux/slices/chartSlices";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import { createDataObjectForCryptoChart } from "utils/helpers/chart";

const ChartMode = {
  USD: "usd",
  PERCENTAGE: "percentage",
};

function Behavioral() {
  const [chartMode, setChartMode] = useState(ChartMode.USD);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartsDataError, setChartsDataError] = useState(false);
  const dispatch = useDispatch();
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const chartKeysThisPage = useMemo(
    () => [
      chartKeys.TRADES_PER_DAY_PER_TRADE,
      chartKeys.BREAK_SINCE_LAST_TRADE_PER_TRADE,
      chartKeys.BREAK_AFTER_LOSS_TRADE_PER_TRADE,
      chartKeys.OPEN_DIFFERENT_SYMBOLS_PER_TRADE,
      chartKeys.OPEN_POSITION_IN_PROFIT_LOSS,
      chartKeys.PROFIT_AFTER_DAILY_PROFIT_LOSS,
    ],
    []
  );
  const chartsData = useSelector(getChartsData(chartKeysThisPage));

  useEffect(() => {
    (async function () {
      const result = await fetchCryptoChartsData(currentTradingAccount.trading_account_id, chartKeysThisPage);
      if (result.data) {
        dispatch(
          setChartsData({
            chartsData: result.data,
          })
        );
        setCurrentTimeFrame(1);
      } else {
        setChartsDataError(true);
      }
    })();
  }, [dispatch, chartKeysThisPage, currentTradingAccount]);

  if (!createIsChartsDataAvailable(chartsData, chartKeysThisPage) && !chartsDataError) {
    return <div className={styles.Subpage}>Loading Data</div>;
  }

  if (chartsDataError) {
    return (
      <div style={{ margin: "40px auto", fontWeight: 'bold', color: "#4D4D4D" }}>
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  let chartDataObjects = {};
  chartKeysThisPage.forEach(chartKey => {
    chartDataObjects[chartKey] = createDataObjectForCryptoChart(chartKey, chartsData[chartKey]);
  });

  return (
    <div className={styles.Subpage}>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns />
      </div>
      <BarChart 
        key="Trades per day (per trade)"
        chartTitle="Trades per day (per trade)"
        chartData={chartDataObjects["trades_per_day_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['trades_per_day_per_trade'].hoverTextArray} />
      <BarChart 
        key="Break since last trade (per trade)"
        chartTitle="Break since last trade (per trade)"
        chartData={chartDataObjects["break_since_last_trade_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['break_since_last_trade_per_trade'].hoverTextArray} />
      <BarChart 
        key="Break after loss trade (per trade)"
        chartTitle="Break after loss trade (per trade)"
        chartData={chartDataObjects["break_after_loss_trade_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['break_after_loss_trade_per_trade'].hoverTextArray} />
      <BarChart 
        key="Open different symbols (per trade)"
        chartTitle="Open different symbols (per trade)"
        chartData={chartDataObjects["open_different_symbols_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['open_different_symbols_per_trade'].hoverTextArray} />
      <BarChart 
        key="Open position in profit/loss"
        chartTitle="Open position in profit/loss"
        chartData={chartDataObjects["open_position_in_profit_loss"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['open_position_in_profit_loss'].hoverTextArray} />
      <BarChart 
        key="Profit after daily profit/loss"
        chartTitle="Profit after daily profit/loss"
        chartData={chartDataObjects["profit_after_daily_profit_loss"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={chartDataObjects['profit_after_daily_profit_loss'].hoverTextArray} />
    </div>
  );
}

export default Behavioral;
