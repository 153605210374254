import styles from "./subPageLayout.module.scss";
import { useState, useEffect, useMemo } from "react";
// components
import TopFivePatterns from "components/topFivePatterns";
import BarChart from "components/charts/crypto/bar.chart";
import BarWithPercentageSymbolYAxis from "components/charts/barWithPercentageSymbolYAxis.chart";
import PositionWithSL from "components/charts/positionWithSL.chart";
// utils
import { fetchCryptoChartsData } from "utils/requests";
import { getChartsData, getCurrentTradingAccount } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { chartKeys } from "utils/constant";
import { setChartsData } from "redux/slices/chartSlices";
import { createIsChartsDataAvailable } from "utils/helpers/chart";
import { createDataObjectForCryptoChart } from "utils/helpers/chart";

const ChartMode = {
  USD: "usd",
  PERCENTAGE: "percentage",
};

function RiskManagement() {
  const [chartMode, setChartMode] = useState(ChartMode.USD);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(999);
  const [chartsDataError, setChartsDataError] = useState(false);
  const dispatch = useDispatch();
  const currentTradingAccount = useSelector(getCurrentTradingAccount);
  const chartKeysThisPage = useMemo(
    () => [
      chartKeys.RISK_AFTER_LOSING_TRADES_PER_TRADE,
      chartKeys.RISK_AFTER_LOSING_TRADES_OPEN_TRADE,
      chartKeys.PROFIT_IF_ADDING_TO_NEGATIVE_POSITIONS,
      // chartKeys.PROFIT_SECURE_PAYOFF_FROM_REVERTING_TRADES,
      chartKeys.RISK_PER_TRADE_LAST_TRADES,
      chartKeys.MAX_RISK_ALL_OPEN_TRADES_LAST_TRADES,
      chartKeys.PERCENTAGE_OF_POSITION_WITH_SL
    ],
    []
  );

  const chartsData = useSelector(getChartsData(chartKeysThisPage));

  useEffect(() => {
    (async function () {
      const result = await fetchCryptoChartsData(currentTradingAccount.trading_account_id, chartKeysThisPage);
      if (result.data) {
        dispatch(
          setChartsData({
            chartsData: result.data,
          })
        );
        setCurrentTimeFrame(1);
      } else {
        setChartsDataError(true);
      }
    })();
  }, [dispatch, chartKeysThisPage, currentTradingAccount]);

  if (!createIsChartsDataAvailable(chartsData, chartKeysThisPage) && !chartsDataError) {
    return <div className={styles.Subpage}>Loading Data</div>;
  }

  if (chartsDataError) {
    return (
      <div style={{ margin: "40px auto", fontWeight: 'bold', color: "#4D4D4D" }}>
        We're having issue fetching charts' data. Please contact our support.
      </div>
    );
  }

  let chartDataObjects = {};
  chartKeysThisPage.forEach(chartKey => {
    chartDataObjects[chartKey] = createDataObjectForCryptoChart(chartKey, chartsData[chartKey]);
  });

  return (
    <div className={styles.Subpage}>
      <div className={styles.TopFivePatternsContainer}>
        <TopFivePatterns />
      </div>
      <BarWithPercentageSymbolYAxis
        key="Risk after losing trade(s) (per trade)"
        chartTitle="Risk after losing trade(s) (per trade)"
        chartData={chartDataObjects["risk_after_losing_trades_per_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        chartMode={ChartMode.PERCENTAGE}
        dualMode={false}
        hoverTextArray={chartDataObjects["risk_after_losing_trades_per_trade"].hoverTextArray}
      />
      <BarWithPercentageSymbolYAxis
        key="Risk after losing trade(s) (open trade)"
        chartTitle="Risk after losing trade(s) (open trade)"
        chartData={chartDataObjects["risk_after_losing_trades_open_trade"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        chartMode={ChartMode.PERCENTAGE}
        dualMode={false}
        hoverTextArray={chartDataObjects["risk_after_losing_trades_open_trade"].hoverTextArray}
      />
      <BarChart
        key="Profit if adding to negative position(s)"
        chartTitle="Profit if adding to negative position(s)"
        chartData={chartDataObjects["profit_if_adding_to_negative_positions"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["profit_if_adding_to_negative_positions"].hoverTextArray
        }
      />
      {/* <BarChart
        key="Profit secure, payoff from reverting trades"
        chartTitle="Profit secure, payoff from reverting trades"
        chartData={chartDataObjects["profit_secure_payoff_from_reverting_trades"]}
        hasTimeFrame={true}
        currentTimeFrame={currentTimeFrame}
        setCurrentTimeFrame={setCurrentTimeFrame}
        defaultUnit={chartMode}
        chartMode={chartMode}
        setChartMode={setChartMode}
        dualMode={true}
        hoverTextArray={
          chartDataObjects["profit_secure_payoff_from_reverting_trades"].hoverTextArray
        }
      /> */}
      <BarWithPercentageSymbolYAxis
        key="Risk per trade (last trades)"
        chartTitle="Risk per trade (last trades)"
        chartData={chartDataObjects["risk_per_trade_last_trades"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.PERCENTAGE}
        chartMode={ChartMode.PERCENTAGE}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects["risk_per_trade_last_trades"].hoverTextArray}
      />
      <BarWithPercentageSymbolYAxis
        key="Max. risk all open trades (last trades)"
        chartTitle="Max. risk all open trades (last trades)"
        chartData={chartDataObjects["max_risk_all_open_trades_last_trades"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.PERCENTAGE}
        chartMode={ChartMode.PERCENTAGE}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects["max_risk_all_open_trades_last_trades"].hoverTextArray}
      />
      <PositionWithSL
        key="% of positions with SL"
        chartTitle="% of positions with SL"
        chartData={chartDataObjects["percentage_of_position_with_sl"]}
        hasTimeFrame={false}
        currentTimeFrame={999}
        defaultUnit={ChartMode.PERCENTAGE}
        chartMode={ChartMode.PERCENTAGE}
        setChartMode={setChartMode}
        dualMode={false}
        hoverTextArray={chartDataObjects["percentage_of_position_with_sl"].hoverTextArray}
      />
    </div>
  );
}

export default RiskManagement;
