import { chartKeys } from "utils/constant";
import styles from "./generalInfo.module.scss";
import htFonts from "shared/htFont.module.scss";
import { useDispatch } from "react-redux";
import { openModal } from 'redux/slices/modalSlice';
import * as modalTypes from 'constants/modalTypes';

function GeneralInfo({ data }) {
  const dispatch = useDispatch();
  const infoIconOnClickHandler = () => {
    return dispatch(openModal({
      displayedModal: modalTypes.CHART_INFO_MODAL,
      displayedModalDataKey: chartKeys.GENERAL_INFO
    }));
  }

  if (data.noData === true) {
    return (
      <div className={styles.generalInfoWrapper}>
        <div className={styles.generalInfoheader}>
          <div>General Info</div>
          <div
          className={`${htFonts.htInfo} ${styles.InfoIcon}`}
          onClick={() => infoIconOnClickHandler()} >
        </div>
        </div>
        <div className={styles.generalInfoBody}>
          <div style={{ height: 200, width: '100%', gridColumn: '1 / end', lineHeight: '200px', fontSize: '1.3rem'}}>
            Not sufficient data available yet
          </div>
        </div>
      </div>
    );
  }

  data = data[999];

  let profitPipsColor = data.profitPips >= 0 ? "#009245" : "#C1272D";
  let profitPercentageColor = data.profitPercentage >= 0 ? "#009245" : "#C1272D";
  let profitUSDColor = data.profitUSD >= 0 ? "#009245" : "#C1272D";
  let lastThirtyDaysPercentageColor = data.lastThirtyDaysPercentage >= 0 ? "#009245" : "#C1272D";
  let drawdownPercentageColor = data.drawdownPercentage >= 0 ? "#009245" : "#C1272D";

  const [
    profitPipsValue,
    profitCurrent,
    profitPercentage,
    last30DaysPerformancePercentage,
    drawdown,
    balance,
    trades,
    pipsPerTrade,
  ] = [
    'profitPips', 
    'profitCurrent',
    'profitPercentage',
    'last30DaysPerformancePercentage',
    'drawdown',
    'balance',
    'trades',
    'pipsPerTrade'
  ].map((variable) => {
    const value = data[variable];
    if (variable === 'trades') {
      return value;
    }
    if (value) {
      return value.toFixed(2)
    } else {
      return 0;
    }
  });

  return (
    <div className={styles.generalInfoWrapper}>
      <div className={styles.generalInfoheader}>
        <div>General Info</div>
        <div
          className={`${htFonts.htInfo} ${styles.InfoIcon}`}
          onClick={() => infoIconOnClickHandler()}
        >
        </div>
      </div>
      <div className={styles.generalInfoBody}>
        <div className={styles.bodyColumns}>
          <div className={styles.rows}></div>
          <div className={styles.rows}>
            <strong>Profit</strong>
            {
              data.profitPips ? <strong style={{color: profitPipsColor}}>{profitPipsValue >= 0 ? `+${profitPipsValue}` : `${profitPipsValue}`}Pips</strong> : null
            }
          </div>
          <div className={styles.rows}>
            <strong style={{fontSize: "24px", color: profitPercentageColor}}>{profitPercentage >= 0 ? `+${profitPercentage}` : `${profitPercentage}`}%</strong>
            <strong style={{color: profitUSDColor}}>{profitCurrent >= 0 ? `+${profitCurrent}` : `${profitCurrent}`}USD</strong>
          </div>
        </div>
        <div className={styles.bodyColumns} id={styles.centerColumn}>
          <div className={styles.rows}>
            <div>Last 30 days</div>
            <strong style={{color: lastThirtyDaysPercentageColor}}>{last30DaysPerformancePercentage >= 0 ? `+${last30DaysPerformancePercentage}` : `${last30DaysPerformancePercentage}`}%</strong>
          </div>
          <div className={styles.rows}>
            <div>Drawdown</div>
            <strong style={{color: drawdownPercentageColor}}>{drawdown >= 0 ? `+${drawdown}` : `${drawdown}`}%</strong>
          </div>
          <div className={styles.rows}>
            <div>Balance</div>
            <strong style={{color: "#333333"}}>${balance}USD</strong>
          </div>
        </div>
        <div className={styles.bodyColumns}>
          <div className={styles.rows}>
            <div>Trades</div>
            <strong style={{color: "#333333"}}>{trades}</strong>
          </div>
          {
            data.pipsPerTrade ? <div className={styles.rows}>
            <div>Pips/trade</div>
            <strong style={{color: "#333333"}}>{pipsPerTrade}</strong>
          </div> : null
          }
          <div className={styles.rows}>
            <div>Since</div>
            <strong style={{color: "#333333"}}>{data.since}</strong>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralInfo;