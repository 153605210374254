const blogData = [
  {
    id: "what-is-hoc-trade",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*cMdQ16yWndpYoQ-RX3s9pQ.png",
    title: "What is hoc-trade? Pioneer in AI-powered Trading support",
    clue: "I am more than excited to introduce hoc-trade to you, an AI tool for traders we have been working on non-stop during the last 18 months...",
    tags: ["Trading", "Artificial Intelligence", "Trading Software", "Day Trading", "Trading Psychology"],
    time: "04-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `hoc-trade: AI-powered Trading Support`,
    content: `<p>I am more than excited to introduce hoc-trade to you, an AI tool for traders we have been working on non-stop during the last 18 months. Now is the time we can finally reveal it to the public!</p>

    <p>This article should give you a brief overview of what hoc-trade is about, how it introduces AI into Trading, and what you can expect using it as a Trader!</p>
    
    <p>hoc-trade: AI-powered Trading Support</p>
    
    <p><strong>1_What is hoc-trade?</strong></p>
    
    <p>Let&rsquo;s try to wrap it up into a few words:</p>
    
    <p>hoc-trade is an &ldquo;<strong>AI-powered Trading Support tool&rdquo;</strong>&nbsp;for active Traders&hellip; sounds kind of cryptic, right? Let&rsquo;s try it another way:</p>
    
    <p>Here is a few things that hoc-trade does:<br />
    -&nbsp;<strong>finds your bad trading behaviors &amp; prevents you from doing them again</strong><br />
    -&nbsp;<strong>finds your trading edge and supports you taking advantage of it more</strong><br />
    -&nbsp;<strong>learns more with every single trade, strengthening the input you get</strong></p>
    
    <p>It&rsquo;s all designed for Traders to&nbsp;<strong>improve their trading performance</strong>!</p>
    
    <p>Sounds too good to be true? It&rsquo;s actually possible, here is how it works:</p>
    
    <p><strong>2_How does hoc-trade work?</strong></p>
    
    <p>This is a high-level 3-step process to get you a better understanding:</p>
    
    <ol>
      <li>The hoc-trade AI&nbsp;<strong>finds trading patterns</strong>&nbsp;in millions of data points</li>
      <li>It&nbsp;<strong>applies those patterns to your trading</strong>&nbsp;&amp; checks whether they show significance to you as well</li>
      <li>It presents you those patterns in your dashboard and&nbsp;<strong>sends you near real-time alerts</strong>&nbsp;when acting in them again</li>
    </ol>
    
    <p>With every trade, the hoc-trade AI learns more patterns, with every trade of you, it learns more about your trading behavior and can improve the significance of selecting your trading patterns.</p>
    
    <p><strong>3_Why is it important?</strong></p>
    
    <p>Of course, improving the trading performance is the goal of all traders, whether profitable already or not. However, the question remains why do we think the t<strong>rading pattern-based approach</strong>&nbsp;is so important?</p>
    
    <p>Probably most of you know that 85+% of traders are losing money in the long run, while flipping a coin (so go long/ short) should give you a 50/50 chance. If we assume that traders don&rsquo;t completely misunderstand technical analysis, indicators, price action, etc, then there has to be s.th. else why the majority loses. By far&nbsp;<strong>the most important influence on the trading performance are the trading behaviors</strong>, oftentimes destructive behaviors.</p>
    
    <p><strong>This is exactly where the hoc-trade AI tackles the problem!</strong></p>
    
    <p>Unfortunately, it is not as easy as pointing out a few of those destructive behaviors easily identifiable in someones trading data, however commonalities exist if including internal + external + trigger data points (more on that and the theory behind it in later deep-dive article). Those commonalities are learned by the hoc-trade AI by analyzing millions of data points, and subsequently applied to your trading.</p>
    
    <p><strong>4_What can you expect as a Trader?</strong></p>
    
    <p>In this first evolution step of the hoc-trade AI (Yes, we are already planning much more :)), you can expect to receive tangible support from the hoc-trade in the following 5 ways:</p>
    
    <ol>
      <li><strong>Revea</strong>l your most&nbsp;<strong>destructive</strong>, but also most&nbsp;<strong>profitable behaviors&nbsp;</strong>(already&nbsp;<strong>40+ identified</strong>&nbsp;so far)</li>
      <li>Receive an&nbsp;<strong>alert if you act in a loss behavior again</strong></li>
      <li>Understand where is the (behavioral)&nbsp;<strong>gap between you and profitable traders</strong>&nbsp;with a similar trading style (benchmarks)</li>
      <li>Receive an&nbsp;<strong>alert</strong>&nbsp;in case your trade shows an&nbsp;<strong>unusually high risk profile</strong></li>
      <li><strong>Track your progress</strong>&nbsp;over time</li>
    </ol>
    
    <p><strong>5_How do I get started?</strong></p>
    
    <p>Most importantly, how can you use the hoc-trade AI? Good news, we are close to launching it to a selected group of traders in our&nbsp;<strong>Discord server</strong>. You are welcome to join through this link&nbsp;<a href="https://discord.com/invite/hcvytJnb5U" rel="noopener ugc nofollow" target="_blank"><strong>HERE</strong></a>.<br />
    Of course it will be free, no strings attached.</p>
    
    <p>We designed the process of using the hoc-trade AI as easy + safe as possible.<br />
    Users only have to connect their trading account through a&nbsp;<strong>read-only API (Crypto users)</strong>&nbsp;or through the&nbsp;<strong>Investor password of their MetaTrader (Forex).&nbsp;</strong>Both connections are strictly read-only, we can not perform any action on your account. Once connected, everything is fully automated.</p>
    
    <p>We are excited to pioneer the power of AI in the Trading market in a whole new way!</p>
    
    <p>Thanks for reading and hopefully talk to you soon in our&nbsp;<a href="https://discord.com/invite/hcvytJnb5U" rel="noopener ugc nofollow" target="_blank"><strong>Discord server</strong>!</a></p>`
  },
  {
    id: "outcome-bias-in-trading-a-major-obstacle-to-proftiable-trading",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*ooAqiSQM70t4Vrz55eem2Q.png",
    title: "Outcome Bias in Trading: A major obstacle to profitable trading",
    clue: "Does ‘the end justify the means’ if we scored big in a trade as a (Day) Trader? I would strongly argue NO and go even further: “Dumb luck”...",
    tags: ["Trading Psychology", "Behavioral Bias", "Day Trading", "Outcome Bias", "Trading"],
    time: "12-Jun-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `Outcome Bias cover picture`,
    content: `<p>Does &lsquo;the end justify the means&rsquo; if we scored big in a trade as a (Day) Trader?<br />
    <strong>I would strongly argue NO and go even further: &ldquo;Dumb luck&rdquo; is one of the most dangerous things that can happen to us in Trading!</strong></p>
    
    <p>Why? The reason to this can be explained with one of the most prominent behavioral biases, the Outcome Bias or also called &ldquo;disparity fallacy&rdquo; or &ldquo;equity fallacy&rdquo;.</p>
    
    <p>To fully understand this and the toxic effect on our trading performance, let&rsquo;s break it down step-by-step:<br />
    First, let&rsquo;s understand what is the Outcome Bias.<br />
    Second, analyze how it can effect our trading decisions with some examples,<br />
    and third, discuss what we can do as trader to countermeasure the effect and build a system of better trading decisions.</p>
    
    <p>If you are interested in trading psychology and common behavioral biases which explain a large extent of our oftentimes destructive trading behavior, feel invited to also check other articles I wrote on&nbsp;<a href="https://medium.com/coinmonks/are-you-victim-to-the-gamblers-fallacy-in-trading-24d5546f2247" rel="noopener">the Gambler&rsquo;s Fallacy</a>, the&nbsp;<a href="https://medium.com/@hoc-trade/trading-psychology-the-number-one-behavioral-bias-to-tackle-as-a-trader-blind-spot-bias-480e5f53ee70" rel="noopener">Blind Spot Bias</a>, the&nbsp;<a href="https://medium.com/coinmonks/does-the-pessimism-bias-hurt-your-trading-performance-trading-psychology-20a7b6088d9a" rel="noopener">Pessimism Bias</a>, or also&nbsp;<a href="https://medium.com/coinmonks/how-can-nobel-prize-winning-prospect-theory-explain-our-toxic-trading-behavior-993eb893a1d4" rel="noopener">Daniel Kahneman&rsquo;s Prospect Theory</a>.</p>
    
    <ol>
      <li><strong>What is the Outcome Bias?</strong></li>
    </ol>
    
    <p>To put it simple: The&nbsp;<strong>behavior of judging the quality of a decision only based on its outcome</strong>&nbsp;is what we call the&nbsp;<strong>Outcome Bias.</strong></p>
    
    <p>We can witness the Outcome Bias in nearly all aspects of life, whether it is in medicine, sports, at work, gambling, or trading.<br />
    When looking at other people, we have limited visibility on their decision making process, whether that decision was actually good or bad. Consider a heart surgeon who decides to perform a risky surgery, however sadly the patient dies during the surgery. It is easy to judge the surgeon&rsquo;s decision as bad, but what do we know about the background, the probabilities of success if doing the surgery vs. when not? It might be that a highly unlikely event happened during the surgery, and that the overall decision to perform the surgery was indeed the best decision at this moment.<br />
    What effect might this behavioral bias have though? To better understand it, and which type of the judgements are actually critical and dangerous, let&rsquo;s have a look at the following process outcome matrix.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:700/1*-ZXttO7iQGTwJtkDNm8GnQ.png" style="height:537px; width:700px" /></p>
    
    <p>A &ldquo;good&rdquo; process would refer to a rational decision based on probabilities, choosing the decision which provides a favorable risk reward outcome. On the other hand, a &ldquo;bad&rdquo; decision refers to a decision which does not weigh the probabilities of success.<br />
    <strong>The two dangerous combinations, especially for trading, in this matrix are &ldquo;dumb luck&rdquo;, so having a bad decision process</strong>, however still receiving a good outcome,&nbsp;<strong>and &ldquo;bad break&rdquo;</strong>, so having a good decision process however receiving a bad outcome. Before deep-diving into this, let&rsquo;s first understand a bit more how easy we are to misjudge due to the outcome bias and how common it happens.<br />
    Annie Duke gave a great example for this in her book &ldquo;Thinking in bets&rdquo; for poker players. Poker is a very good example as playing your hand is mostly based on probabilities with an unknown outcome at the time of decision making. We have a great poker player that strategically weighs the decisions based on the probabilities, competing against a player who does not. Consider it as the &ldquo;good&rdquo; decision making process vs. the &ldquo;bad&rdquo; decision making process in the matrix.&nbsp;<strong>Now if those two players compete for 8 hours, the likelihood of the good player losing is still over 40%, even though he/she did all the good decisions.</strong><br />
    As viewers of the poker match, we only see those snapshot of 8 hours and see the &ldquo;bad&rdquo; player winning, we are very likely to think the bad player is actually good and vice versa.<br />
    Of course, assuming unlimited capital and a long enough playing time, the chances of the &ldquo;good&rdquo; player to win would approach 100%,&nbsp;<strong>however in reality we barely have those kind of situations.</strong></p>
    
    <p>So far, we have discussed the Outcome Bias in situations in which we observe other people&rsquo;s decisions, however the Outcome Bias is not limited to this. We might follow, trust, bet on, or take advise from the wrong people,&nbsp;<strong>but it is oftentimes even more destructive judging our own decisions when being victim to the Outcome Bias</strong>. Why? Let&rsquo;s apply the Outcome Bias to Trading and see what effect it may have on ourselves and our performance.</p>
    
    <p><strong>2. Outcome Bias in Trading</strong></p>
    
    <p>Having understood the Outcome Bias, let&rsquo;s have a look at 2 common trading examples which I am sure many of you have already experienced in a similar form yourself. Many of our trading decisions can actually be explained by the human behavioral tendencies we&rsquo;ve discussed so far.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:660/1*sNZCFz-AcZ473C6iUiRi9w.png" style="height:630px; width:660px" /></p>
    
    <p>In the first example, we have a trade which initially shows little price action until it is hit by bad news. As the price drops from bad news, the trader is not willing to give up on the position, but decides to double-down on the trade, entering a second position. After entering the second trade, the price starts to increase and runs in the initial take-profit level, leaving the trader with a nice profit. Excited from the great trade outcome, the trader might conclude that he or she is great at news trading, stoked to have found an apparently effective trading strategy of trading the bounce-back from a strong price action due to news.</p>
    
    <p>However, what has happened here?&nbsp;<strong>The trader experienced a &ldquo;dumb luck&rdquo;</strong>&nbsp;situation as defined earlier in the process outcome matrix. Based on the trading system, the trader should have cut the losses, but instead doubled-down on the trade. Moreover, the trader this time got lucky, creating a &ldquo;good&rdquo; outcome. A situation like this can easily lead the trader to think of having found a new profitable trading strategy, and the recent success &ldquo;justifies&rdquo; an adjustment of his current strategy. Little do we know whether the previous strategy was profitable, or whether the new trading strategy will be profitable, deciding on this would require much more data on a longer timeframe.&nbsp;<strong>However, traders receptive to the Outcome Bias will not focus on the decision process, but the outcome, and in the given situation the outcome gives a clear direction.</strong></p>
    
    <p>The situation could be very different though. Consider this second example:</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:660/1*hX35ZlwXTKreyBhk0IxuSw.png" style="height:630px; width:660px" /></p>
    
    <p>The trader opens and then closes a trade in profit based on his or her trading strategy. After closing the trade, the trader witnesses how the price keeps and keeps increasing, creating a feeling of having &ldquo;lost&rdquo; this additional profit that could have been made in the trade. After a while, the trader decides to re-enter the position seeing all this forgone additional profit.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:660/1*iSF0zBHz0NaOYp26Gu9nPQ.png" style="height:630px; width:660px" /></p>
    
    <p>Shortly after, the price reverses, giving a loss in the second trade, completely offsetting the initial profit with the first trade. Therefore, the trader starts to question whether any of his or her decisions are actually correct and decides to double-down, hoping to recover at least the initial profit. The price keeps dropping though, leaving the trader with a big overall loss and full desperation.</p>
    
    <p>Again, what has happened here? This time,&nbsp;<strong>we could describe the situation has having a &ldquo;bad break&rdquo; for our decision outcome matrix.</strong>&nbsp;The trader even made a profit, and also the second trade entry may have been according to the trading system.&nbsp;<strong>An unfortunate development of the trade associated with a lot of pain led the trader to doubt the own system though</strong>, deciding to try saving the trade by doubling-down. Again, seeing how the first trade could have made much more profit and seeing an initial loss in the re-entry &ldquo;justifies&rdquo; the decision for the trader to change the current system as apparently the current system gives only losses.&nbsp;<strong>The initial system might be profitable, however due to some bad luck (which statistically is meant to happen all the time in trading), the trader deviates from it.</strong></p>
    
    <p>Based on the two examples, we can conclude that&nbsp;<strong>being receptive to the Outcome Bias in Trading will lead to constant doubt about the own strategy and skills</strong>, as we associate a bad trade outcome with a bad process, and a good trade outcome with a good process. Moreover, if the trader deviates from the current trading strategy due to the doubt or excitement from bad takes and dumb lucks,&nbsp;<strong>the trader is likely to apply a much more emotion-based decision approach</strong>, disregarding the inputs set by the trading strategy, and consequently due to the missing guardrails rather applying emotions as action triggers. Trading based on emotions will open ourselves up to many more behavioral biases with even more destructive results. Lastly,&nbsp;<strong>concluding from dumb luck trades to apply a different strategy strongly increases the likelihood to follow strategies that are actually not profitable</strong>, thereby entering a&nbsp;<strong>doom loop</strong>&nbsp;of constant adjustments, confusion, pain, and ultimately most likely a margin call.</p>
    
    <p>However, of course there are things we as traders can do to prevent or soften the impact of the Outcome Bias on our trading. Let&rsquo;s discuss those in part 3:</p>
    
    <p><strong>3. Countermeasures to the Outcome Bias</strong></p>
    
    <p>Before deep-diving in 3 countermeasures we can apply as traders, let&rsquo;s summarize in very short what we have to keep in mind as traders when dealing with the Outcome Bias.</p>
    
    <p><strong><em>a)</em></strong><em>&nbsp;</em><strong><em>A good trade does not necessarily have a good trading outcome!<br />
    b) A good trade outcome does not necessarily mean we traded good!</em></strong></p>
    
    <p>As a result, it becomes very clear that&nbsp;<strong>as traders we should detach our trading results from our trading strategy in the short term</strong>. Looking at&nbsp;<strong>single trade results has little to no correlation to how good or bad our trading strategy is</strong>. However, we are all human beings and it is very difficult to detach those two as we are wired to do so. Therefore, we&nbsp;<strong>need dedicated countermeasures</strong>&nbsp;to do so. The following three have shown to be effective for us and other traders we know:</p>
    
    <p>1) Situational Awareness, 2) Rigorous tracking &amp; analytics, 3) Reducing complexity during trading. Let&rsquo;s go step-by-step what those are:</p>
    
    <p><strong>Situational Awareness:</strong></p>
    
    <p>If you&rsquo;ve read our previous articles on different behavioral biases and traders&rsquo; countermeasures, you will be familiar with Situational Awareness already.&nbsp;<strong>By its nature, behavioral biases are unconscious,</strong>&nbsp;therefore we do not recognize their effect on our behavior until we are aware of them.&nbsp;<strong>By reading this article, you have done the first step necessary to start recognizing when you become victim to the Outcome Bias, thereby moving this bias from unconscious to conscious</strong>. You understand the bias, and the next time you think whether you should adjust your trading strategy, you may think about the Outcome Bias and challenge yourself whether the process leading to your decision was bad or good. This is the necessary foundation for every trader out there.&nbsp;<strong>Adjusting trading behaviors is never an instant process, it always starts with understanding, recognizing, and then step-by-step adjustments.</strong></p>
    
    <p><strong>Rigorous tracking &amp; analytics:</strong></p>
    
    <p>The very&nbsp;<strong>best counterargumen</strong>t against constantly adjusting our trading system and behavior is if we can&nbsp;<strong>see black and white what actually works for us and what doesn&rsquo;t</strong>. Before the advancements in tools, we were rigorously tracking every single trade of ours manually (in Excel or even on paper), identifying behaviors, listing our motivations and emotions during the trade, and try to match this with market indicators, volumes, or price levels. Luckily, nowadays we have automated trading journals and even more advanced behavioral analytics software. All of our trading actions will be automatically tracked, analyzed, and we just have to check which strategies and behaviors are actually profit- or loss-making for us.<br />
    As traders, we can easily deviate from our strategy if we are in doubt. However, seeing our decisions and outcomes based on hundreds of trades, how we would perform if closing our position slightly earlier or later&hellip;</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:700/1*3pA_2CZN6ZckjKIi_ejtNQ.png" style="height:735px; width:700px" /></p>
    
    <p>hoc-trade analysis dashboard 1</p>
    
    <p><strong>&hellip; doubling-down on trades that are already in loss&hellip;</strong></p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:700/1*UJsxvnPwvcJOAtdTxYKaTw.png" style="height:737px; width:700px" /></p>
    
    <p>hoc-trade analysis dashboard 2</p>
    
    <p><strong>&hellip;or how we perform trading with or against the market trend&hellip;</strong></p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:700/1*ntIx1lJARWa9xZHJE6COdw.png" style="height:726px; width:700px" /></p>
    
    <p>hoc-trade analysis dashboard 3</p>
    
    <p>&hellip;<strong>can give us as traders the necessary long-term viewpoint required to gain the confidence in our trading strategy, and thereby easily identify a &ldquo;bad break&rdquo; trade if they happen.</strong><br />
    Of course, every beginning is hard, and we need this large enough data set of historical trades in order to find those significances and gain this confidence. If we do not have this yet,&nbsp;<strong>we can either keep trading on a demo account or trade with such small position sizes, that we prevent strong emotions from overtaking the lead on our trading decisions.</strong></p>
    
    <p><em>hoc-trade is one of the tools which can be used for your tracking and analytics, and it actually tracks and analyses many more behaviors compared to what is shown here.&nbsp;</em><strong><em>If you would like to have a free testing access to the beta version, feel invited to join the&nbsp;</em></strong><a href="https://discord.com/invite/hcvytJnb5U" rel="noopener ugc nofollow" target="_blank"><strong><em>Discord server</em></strong></a><strong><em>, you&rsquo;ll get all accesses there.</em></strong></p>
    
    <p><strong>Reducing complexity during trading:</strong></p>
    
    <p>During trading, our brain processes a huge amount of data and new information, every new tick of price quotes, news, analyses, our emotions, and more. Naturally, we<strong>&nbsp;do not have much time during trading to make decisions, which fuels ourselves to work in ways that are naturally wired in our brain</strong>. As you can imagine, one example of this is the Outcome Bias.&nbsp;<strong>If we only have a very short time to make decisions, we may not account for the process, but use the shortcut to only look at the outcome.</strong>&nbsp;In order to give ourselves more time to make decisions, we should limit the complexity during trading as much as possible. This may include the amount of positions we enter at the same time, the amounts of trading symbols we are following, the number of news websites tracking, the social media running, or whatever else takes some of your focus. If we really want to build a successful trading system, we can focus on one asset only, during a specific time of the day, repeating our strategy over and over again. By doing so, we give ourself the chance to build confidence and the data set required to judge its effectiveness. If our strategy and approach are proving to be effective, we can slowly start to scale and thereby also increase complexity.</p>
    
    <p><strong>Summary</strong></p>
    
    <p>Working on behavioral biases is not an easy task, and most of the traders in the market will never do so due to its complexity and unconscious nature. At the same time, most of the traders are also losing in the market.<br />
    The moment we accept that we, just like everyone else, may act based on known behavioral biases, we can start working in them. For the Outcome Bias, we first need to recognize the moments in which we judge decisions purely based on its outcome. We build a solid database of our decisions and outcomes, thereby creating a reference point which we can confidently draw from. Lastly, we try to reduce the complexity in our trading as much as possible, to give ourselves and our brain to most possible time to process information and do not act in pre-wired behavioral structures.</p>
    
    <p>Thank you very much for reading, happy trading, and stay safe!</p>`
  },
  {
    id: "how-can-nobel-prize-winning-prospect-theory-explain-our-toxic-trading-behavior",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*90ArlEkYm7h9rbk_0KDiFQ.png",
    title: "How can Nobel Prize-winning Prospect Theory explain our toxic Trading Behavior?",
    clue: "Prospect Theory by Kahnemann and Tversky from 1979 is one of the core pillars of behavioral finance. It has shown such importance, that it...",
    tags: ["Trading Psychology", "Day Trading", "Crypto Trading", "Forex Trading", "Daniel Kahneman"],
    time: "18-May-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: " ",
    content: `<p><strong>Prospect Theory</strong>&nbsp;by Kahnemann and Tversky from 1979 is one of the&nbsp;<strong>core pillars of behavioral finance.&nbsp;</strong>It has shown such importance, that it was referenced for&nbsp;<strong>Kahnemann&rsquo;s Nobel Prize in Economics&nbsp;</strong>in 2002.</p>

    <p>What is Prospect Theory though and how can it affect traders in an extremely negative way?&nbsp;<strong>Prospect Theory very well explains the core trading error of a majority of traders</strong>, but let&rsquo;s go step-by-step.<br />
    Let&rsquo;s first&nbsp;<strong>understand what is Prospect Theory</strong>&nbsp;and look at some&nbsp;<strong>examples</strong>&nbsp;to get a better understanding. Second, deep-dive&nbsp;<strong>how it affects us as traders</strong>, and third, outline what we as&nbsp;<strong>traders can do as countermeasures</strong>&nbsp;and thereby improve our trading performance. Whether you are trading Crypto, Forex, stocks or Futures does not matter, as Prospect Theory is a general behavioral tendency applicable to any trading market.</p>
    
    <ol>
      <li><strong>Prospect Theory: What does it say?</strong></li>
    </ol>
    
    <p>At its core, Prospect Theory describes that&nbsp;<strong>humans tend to apply an asymmetric assessment of potential losses and gains</strong>. Kahnemann and Tversky said that &ldquo;in human decision-making,&nbsp;<strong>losses loom larger than gains</strong>&rdquo; (1979).<br />
    Still sounds a bit theoretical and hard to grasp, right? Let me walk you through a real experiment that describes the core of Prospect Theory very well.</p>
    
    <p>Imagine the following scenario: You are given&nbsp;<strong>two options</strong>&nbsp;to choose from:</p>
    
    <p>A) 100% chance to lose 3,000 USD<br />
    B) 80% chance to lose 4,000 USD, 20% chance to lose nothing</p>
    
    <p>Which one would you choose?<br />
    Next, you have to choose between the following:</p>
    
    <p>C) 100% chance to win 3,000 USD<br />
    D) 80% chance to win 4,000 USD, 20% chance to win nothing</p>
    
    <p>Again, which one would you choose?</p>
    
    <p>Interestingly, when asked to choose between A) and B),&nbsp;<strong>92% chose B)</strong>, so taking the chance to avoid losses. However, when choosing between C) and D)&nbsp;<strong>only 20% choose D)</strong>, meaning only a small number of people would take the risk to win more (note B &amp; D have a prospect of similar risk/ likelihood).</p>
    
    <p>The experiment nicely shows that&nbsp;<strong>humans tend to avoid risk when gains are at stake, however are much more willing to take risk to prevent losses (loss aversion).</strong><br />
    AGAIN, this is the core here: People show a strong tendency to not take risks when gains are at stake, but are willing to do so if they could avoid losses!<br />
    Having understood this, we can&nbsp;<strong>apply this to Trading as well!</strong></p>
    
    <p><strong>2. Prospect Theory applied to Trading</strong></p>
    
    <p>Before looking at Prospect Theory applied to Trading in detail, I would like to point out 2 general things about Behavioral Biases in Trading:<br />
    First,&nbsp;<strong>Behavioral Biases sometimes appear subjective</strong>, they are not a universal science phenomena that can easily be observed such as gravity.&nbsp;<strong>However, there exists a huge amount of research in behavioral economics</strong>; up to the point of Prospect Theory receiving a Nobel Price in Economics, probably the highest recognition and amount of peer review one can get. In Trading,&nbsp;<strong>we do not have to reinvent the wheel, we just need to take those known biases, fallacies, and behaviors and apply them to Trading!</strong><br />
    Second, working on behavioral biases in Trading is not easy, it requires you to look inside yourself, understand your thoughts and processes, and derive action items from it. It&rsquo;s not as straightforward as adding another indicator to your trading strategy, but it&nbsp;<strong>is the critical point in succeeding as a trader</strong>. It is completely normal to have behavioral biases, we all have them, and&nbsp;<strong>the earlier we understand and accept them, the quicker we can counteract their negative influence and actually use them to our favor in trading</strong>. We already outlined more on the background of working on behavioral biases in trading, you can just check our previous articles on&nbsp;<a href="https://medium.com/coinmonks/are-you-victim-to-the-gamblers-fallacy-in-trading-24d5546f2247" rel="noopener">The Gambler&rsquo;s Fallacy</a>,&nbsp;<a href="https://medium.com/@hoc-trade/trading-psychology-the-number-one-behavioral-bias-to-tackle-as-a-trader-blind-spot-bias-480e5f53ee70" rel="noopener">The Blind Spot Bias</a>, or also&nbsp;<a href="https://medium.com/coinmonks/does-the-pessimism-bias-hurt-your-trading-performance-trading-psychology-20a7b6088d9a" rel="noopener">The Pessimism Bias</a>&nbsp;to learn more.</p>
    
    <p>Now back to Prospect Theory and how it affects Traders:<br />
    Knowing from Prospect Theory that we tend avoid risks when gains are at stake and seek risks if we could prevent losses, we should have a look at our&nbsp;<strong>profit-taking</strong>, but also our&nbsp;<strong>loss-taking</strong>&nbsp;behavior. We know that in&nbsp;<strong>Trading it is about Cutting our losses early, while letting our profits run</strong>&nbsp;in order to have our profits outweigh out losses and be profitable. There are plenty of statements from the best traders in the world outlining this key element of trading.<br />
    What do we do in loss trades?&nbsp;<strong>According to Prospect Theory, we are willing to take risks to avoid losses</strong>. Is this actually good or bad in terms of trading strategy?<br />
    It is&nbsp;<strong>exactly the opposite of what we should do as traders</strong>. If we take additional risks to avoid our losses, that means we do not cut or loss early and move on. We rather show a behavior of taking on additional risk in a trade that is already in loss in order to potentially recover our loss and close it breakeven or in profit. In terms of trading, that means we may adjust our Stop Loss even further away in case we are in a loss trade, thereby not accepting our loss, but gambling higher in order to prevent the loss. This behavior can actually be witnessed with a large amount of traders, and&nbsp;<strong>it is extremely toxic to our performance</strong>, as we let our loss trades become big.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*Ha0qrDUoJvM5ASLAp2Bc7A.png" style="height:735px; width:700px" /></p>
    
    <p>hoc-trade analysis: Profit if SL adjusted vs. if not</p>
    
    <p>The hoc-trade AI tracks exactly this behavior as it found to have a significant negative influence on the performance of traders. Each trader can see their personal performance effect of this behavior in their dashboard.</p>
    
    <p>On the&nbsp;<strong>profit side</strong>, we can witness a similar toxic behavior, just instead of letting loss trades become big,&nbsp;<strong>we keep our profits small</strong>. In Prospect Theory we have seen that we rather take the safe smaller profit instead of taking the risk to grow our win even bigger. In terms of trading, profitable positions are oftentimes manually stopped by the trader even before reaching the initially set Take Profit level. Imagine you are in a trade, which went 1,000 USD in profit already, with a Take Profit set at 2,000 USD. Many traders tend to manually close the trade at 1,000 USD profit, thereby securing their win but forgoing the chance to reach the 2,000 USD profit.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:612/1*2oqU1sM_ztyDgBi18i6MSw.png" style="height:578px; width:556px" /></p>
    
    <p>hoc-trade analysis: Manual stop in profit</p>
    
    <p>Similarly to the Stop Loss adjustment in loss trades, hoc-trade also tracks the manual stops of traders and simulates the individual performance effect of this behavior on your performance (simulates trade outcome if the trader would have not manually stopped the trade in profit).</p>
    
    <p>Doing those two behaviors over and over again,&nbsp;<strong>we as traders set ourselves up for failure.</strong>&nbsp;It is extremely hard to be profitable overall if we keep our profits small and keep having big losses. However, it is the human tendency to do so as explained in Prospect Theory, and we all are humans, so we as traders have to find ways to adjust our behavior as we otherwise are part of the &gt;85% of traders that are losing money. How can we do that? Let&rsquo;s have a look in part 3 what we can do:</p>
    
    <p><strong>3. Countermeasures to toxic trading behavior</strong></p>
    
    <p>There might be very individual countermeasures to counteract this typical behavioral tendency, but let&rsquo;s focus on&nbsp;<strong>3 general ones here which show to have good effects and have helped many traders including ourselves</strong>: a) Situational awareness, b) Alternate profit metrics, and c) Leverage technology &amp; alerts. Let&rsquo;s get into what those are:</p>
    
    <p><strong>a) Situational Awareness</strong></p>
    
    <p>If you have read our previous articles on Trading Biases, you will be familiar with Situational Awareness already. It is extremely important and by reading this article, you are already doing your first step in creating this awareness. Behavioral biases and fallacies are unconscious in nature, hence we do not recognize this specific behavior with ourselves as it feels natural. The first step in working on the impact of those biases in trading is always to&nbsp;<strong>recognize your own behavior and be able to make sense of it</strong>. The next time your are adjusting your Stop Loss or manually close a profit trade early, you may first recognize that you now did something likely counterproductive to your performance and secondly, will hopefully be able to make sense of your own behavior by understanding Prospect Theory.&nbsp;<strong>Adjusting trading behaviors is not an instant process, it always starts with understanding, recognizing, and then step-by-step adjustments.</strong></p>
    
    <p><strong>b) Alternate profit metrics</strong></p>
    
    <p>Much of the trading behaviors we show are closely interlinked with how we value money, and the Prospect Theory is a very good example for this. Follow-up studies have actually shown a weakened effect of loss aversion with increased wealth, however this is beyond the content of this article. Kahnemann and Tversky argued in their Prospect Theory, that&nbsp;<strong>losses hurt 2&ndash;2.5 times as much compared to the pleasure equivalent gains</strong>, which explains much of our actual behavior. In order to soften this effect, and thereby also achieve a more rational behavior,&nbsp;<strong>as traders we can move away from thinking in currency terms</strong>&nbsp;(such as US Dollar), but rather think in o<strong>ther metrics such as risk or points/ pips</strong>. Instead of thinking we lost 2,000 USD on a trade, we can equate the 2,000 USD as 1R (risk). If we adjust our Stop Loss to 3,000 USD, we may lose 1.5R, if we win 4,000 USD, we win 2R, and so on.&nbsp;<strong>By doing so, we move away from our value of money, move away from the pain of losing money, and move away from the pleasure of winning money.</strong>&nbsp;However, as we learnt the pain is larger for losses compared to the equivalent pleasure from wins, so&nbsp;<strong>eliminating or reducing this pain and pleasure is actually doing us a favor</strong>, both emotionally as well as behaviorally.</p>
    
    <p><strong>c) Leverage technology &amp; alerts</strong></p>
    
    <p>Nowadays, traders can&nbsp;<strong>enjoy a great level of support during their trading by leveraging technology and software targeted at identifying those destructive trading behaviors</strong>. Hoc-trade is one of those tools, and as seen in the previous chapter, it measures exactly the kind of behaviors which can be explained with Prospect Theory. In case the hoc-trade AI identifies that you would be better off adjusting your behavior, it will send you (near)&nbsp;<strong>real-time alerts</strong>&nbsp;in case you have adjusted your Stop Loss again or just manually closed a profit trade. Prospect Theory is not the only behavioral bias with a direct effect on traders, there are many more biases, and tools such as hoc-trade also cover many more areas of trading behavior (40+).<br />
    While you can identify those behaviors and also will receive alerts to support you during trading with technology, it still requires you as the trader to make trading decisions. Therefore,&nbsp;<strong>technology can be seen as a great help which can give you an edge over other traders</strong>, but you will still need to understand, recognize, and alternate your behavior in order to utilize the full effect on your trading performance.</p>
    
    <p>If you are interested in hoc-trade,&nbsp;<strong>please feel welcome to join our&nbsp;</strong><a href="https://discord.com/invite/hcvytJnb5U" rel="noopener ugc nofollow" target="_blank"><strong>Discord</strong></a><strong>.</strong>&nbsp;We are currently in the closed testing phase and are happy to provide you free access and discuss your experiences.</p>
    
    <p><strong>Closing remarks</strong></p>
    
    <p>Behavioral economics/ finance is a fascinating field with incredibly valuable insights for trading. A majority of our oftentimes destructive behaviors can be explained by biases and fallacies, and the Prospect Theory is truly one of the most important ones. It&nbsp;<strong>covers our behavior at the heart of trading strategies, growing large profits while keeping our losses tight</strong>. If you are interested in more content on behavioral biases in trading, feel invited to follow our channel and also check the previous content.<br />
    I would like to close this article with 2 quotes, the first from one of the best traders in the world, and the second from likely the best investor in the world. Both quotes clearly outline how crucial it is to follow what we have discussed in this article:&nbsp;<strong>Cut your losses early&hellip;</strong></p>
    
    <blockquote>
    <p>Quote 1: Ed Seykota, Trader:<br />
    &ldquo;The elements of good trading are 1)&nbsp;<strong>cutting losses, 2) cutting losses, and 3) cutting losses</strong>. If you can follow these three rules, you may have a chance.&rdquo;</p>
    </blockquote>
    
    <p><strong>&hellip;and let your profits run!</strong></p>
    
    <blockquote>
    <p>Quote 2: Warren Buffet, Berkshire Hathaway<br />
    &ldquo;When it&rsquo;s&nbsp;<strong>raining gold, reach for a bucket, not a thimble&rdquo;</strong></p>
    </blockquote>
    
    <p>Happy Trading and stay safe!</p>`
  },
  {
    id: "does-the-pessimism-bias-hurt-your-trading-performance-trading-psychology",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*DQPpOBdW8WufG58XrlFktw.png",
    title: "Does the Pessimism Bias hurt your Trading Performance? (Trading Psychology)",
    clue: "Behavioral Biases have a strong effect on our Trading irrespective whether you are trading Forex, Crypto, Stocks, Commodities, Futures...",
    tags: ["Trading Psychology", "Trading", "Pessimism Bias", "Trading Education", "Day Trading"],
    time: "01-May-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: " ",
    content: `<p>Behavioral Biases have a strong effect on our Trading irrespective whether you are trading Forex, Crypto, Stocks, Commodities, Futures or whatever other market. They are&nbsp;<strong>oftentimes overlooked</strong>, as many traders heavily focus on the more &ldquo;technical&rdquo; parts of trading, however given that 85% and more of the traders actually lose money in trading with all the technical assistance at hand, while flipping a coin should give you 50/50 chance of winning, we can derive our behavior indeed has a strong influence on our performance. Those actions triggered by behavioral biases are unfortunately&nbsp;<strong>mostly with a negative effect on our performance</strong>.</p>

    <p>Not everyone holds the same behavioral biases, however as traders we can well understand the impact of certain biases on our trading performance and analyze whether these biases apply to us as well. The Pessimism Bias is one of those and while biases can have effects on all different parts of trading, this one is mostly related to&nbsp;<strong>our profit taking behavior.</strong>&nbsp;More in that in a second. Many traders are afraid to touch the area of trading psychology including all the behavioral biases underlying it because it is complex, can be subjective at times, and asks you to look inside yourself. It is not as straight-forward as looking at some readily-available trading indicators. However, we will do our best to outline the Pessimism Bias for you in a&nbsp;<strong>structured way</strong>, clearly explain how it effects your trading behavior and&nbsp;<strong>provide tangible options&nbsp;</strong>on how to work on it if you yourself are victim to the Pessimism Bias.</p>
    
    <p><strong>So what is the Pessimism Bias?</strong></p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*LBxCPRX1LoUSr_rZD8IAgA.png" style="height:730px; width:700px" /></p>
    
    <p>Pessimism Bias</p>
    
    <p>The Pessimism Bias describes the&nbsp;<strong>tendency of people to overestimate the likelihood of negative events and at the same time underestimate the likelihood of positive events</strong>. As the name already suggests, people tend to be more pessimistic about a positive outcome. What does this mean?</p>
    
    <p>To really understand this, let&rsquo;s have a look at an example based on on&nbsp;<strong>experiment&nbsp;</strong>that was actually performed like this.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*SIsowTcOwxiYgPK5UYw8yw.png" style="height:705px; width:700px" /></p>
    
    <p>Pessimism Bias Example</p>
    
    <p>Imagine you will get 10 coin flips, and every time the coin lands on heads, you are given 10 USD. Now, how often do you expect to win? Well, I think it should be pretty clear that based on probability, the answer most of you have in your head is 5. However,&nbsp;<strong>the 1500 experiment participants answered with an average of only 3.9</strong>! As you can see, the participants overestimated the probability of negative events, showing strong behavior of the Pessimism Bias.</p>
    
    <p>There are very strong interlinkages of this to trading behaviors which can be witnessed with many traders, however before getting into this, let&rsquo;s understand a bit more about the Pessimism Bias, as it will make those connections to typical trading behaviors even more obvious later on.</p>
    
    <p>The Pessimism Bias&nbsp;<strong>exists both on a personal and a societal level</strong>, however we are going to focus on the personal level, as this the layer which really influences your trading. Additionally, the Pessimism Bias is&nbsp;<strong>both general and situational</strong>. What does this mean? The Pessimism Bias may have an&nbsp;<strong>accelerated influence on your behavior depending on the situation</strong>, or the emotional state in which you are in. I believe it is quite easy to imagine that you may be more pessimistic in case you are in a bad mood anyways already. That may sound a little obvious, but becomes extremely important to remember when we are discussing the impact on the trading behavior. So, how can we witness this Pessimism Bias in Trading?</p>
    
    <p><strong>Pessimism Bias in Trading</strong></p>
    
    <p>There are actually multiple ways how the Bias can affect your trading, let&rsquo;s talk about the probably most destructive one here though, the impact on how you manage your profit trades. Every trader learns in the beginning that the key to success is to cut your losses early and let your profit trades run. On the profit trade side Warren Buffet also framed it nicely when he said: When it&rsquo;s raining gold, reach for a bucket, not a thimble. All that means is that you as a trader should strive to win big when you are winning in a trade!<br />
    Evidently, many traders struggle to let their profitable trades run though, and the Pessimism Bias plays an important role here. Once the trade is in profit, many traders decide to manually close their trade without waiting until the trade reaches their designated Take Profit Level. Why? Well, there can of course be multiple reasons, but one of them is that traders prone to the Pessimism Bias overestimate the probability of a negative outcome, so the trade reversing and going into loss from its current profit. Therefore, they decide to manually close the trade with a smaller profit. Thinking back to the experiment we discussed earlier, if we would only expect a payoff of 3.9 heads in 10 coin flips, it is understandable why we would close our trade early in profit, as the trade may have already reached our discounted probability. However, this&nbsp;<strong>bias-based behavior is extremely destructive to our trading performance. We keep our profits small, but let our losses become big, pretty much setting ourselves up for an overall trading loss.</strong></p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*FLi_GFqqXCwb9G_8LJ9pxA.png" style="height:732px; width:700px" /></p>
    
    <p>Analysis if manually stopping your profit trade before reaching TP</p>
    
    <p>How does this play out for a trader? The hoc-trade AI found significances in the traders data, and automatically simulates for the trader what would have been the profit in case the trader would have not chosen to manually close that profit trade, but rather let it run until it reaches the Take Profit or Stop Loss. Thereby, the trader can directly see whether&nbsp;<strong>closing the trades manually was actually hurting the performance or not</strong>, with many times showing a worse performance in case the trades were manually closed!</p>
    
    <p>Moreover, and remember when we talked about the situational nature of the Pessimism Bias earlier, the effect is oftentimes even accelerated for traders. In case the trader is already feeling down or in another negative emotional state, the trader might be even more likely be victim of the Pessimism Bias. How does this apply to trading? You may be in a bad mood already when trading, maybe a bit depressed or any other feeling. You may have just had a loss trade, and thereby your mood drops, and this is actually something we can measure.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*0iVAfTFLsr40TEyl7m5PMQ.png" style="height:730px; width:700px" /></p>
    
    <p>Percentage of trades stopped early in profit</p>
    
    <p>Therefore, the hoc-trade AI also tracks the&nbsp;<strong>probability of manually closing a profit trade in case the trader had a loss trade beforehand vs. your average likelihood of doing so.</strong></p>
    
    <p>As you can see, there can be strong interlinkages between the Pessimism Bias and your trading behavior, which is unfortunately mostly having a negative effect because it&nbsp;<strong>strongly contradicts the basics of profitable trading</strong>. What can you do though if you are receptive to the Pessimism Bias?</p>
    
    <p><strong>What to do as a Trader with Pessimism Bias?</strong></p>
    
    <p>Working on the own trading psychology and the resulting actions is unfortunately not as straightforward as changing the settings in your indicators, however if done correctly and consistently, it can have a much more beneficial effect on your trading performance. In case of the Pessimism Bias, let me&nbsp;<strong>highlight 3 aspects on how to deal with it as a trader</strong>:</p>
    
    <ol>
      <li><strong>Situational Awareness</strong></li>
    </ol>
    
    <p>If you have read articles on other Trading Biases from us, you probably know already that this is the very first step in handling the impact of a trading bias on your performance! Trading biases are, by its nature, unconscious, and therefore go for a long time completely undetected.&nbsp;<strong>You need to know the bias exists, understand it in its basics</strong>, and the next time you are cutting a profit trade early, you will probably think about the Pessimism Bias and can&nbsp;<strong>review your thought processes</strong>&nbsp;at this moment. This is the first big step, know about it, detect it in your own emotions, thoughts, and actions, and gather a better understanding of yourself.</p>
    
    <p><strong>2. Reduce or secure instead of close</strong></p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*i766_-p1F3Zo-GrRV9baQw.png" style="height:365px; width:700px" /></p>
    
    <p>Reduce or Secure trades as response to Pessimism Bias</p>
    
    <p>As with any trading bias, a&nbsp;<strong>sudden complete shift in behavior is usually not helpful and sustainable</strong>, as trading is a complex system of technicals and psychology. A sudden change in one area will most likely also impact other parts of this complex system. Therefore, g<strong>radual adjustments are usually more beneficial</strong>, think of it as a process instead of a one-time shift.<br />
    In terms of the Pessimism Bias, we as a trader have multiple options at hand. Closing a trade in full as soon as it is in profit is a very strong action, however why not&nbsp;<strong>reduce the position size</strong>? We &ldquo;satisfy&rdquo; our mind to a similar extent by taking some profits while letting another part of the trade run. Thereby, we also see how the trade would have turned out if we didn&rsquo;t close the trade early, as part of our position is still running.</p>
    
    <p>A second attractive option is to&nbsp;<strong>secure your trade</strong>. What do I mean with this? A loss after we were in profit already hurts many traders more than a loss that directly went into loss. This is actually part of the Nobel-prize winning prospect theory, but this is such an important theory that it deserves a dedicated article. Now,&nbsp;<strong>if we rule out the possibility that our trade could still go into loss, it calms many traders and enables them to ride winning trades without cutting their trades too early</strong>. To do so, we would set our Stop Loss to the break-even level of the trade instead of closing our winning trade early, and enjoy the ride of the trade knowing we are secured from any losses in this trade.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*mPA2t9FdGoOkKJhEHbwKcg.png" style="height:735px; width:700px" /></p>
    
    <p>Where to secure your profits?</p>
    
    <p>If you would like to derive the profit level at which it makes most sense for you to set your Stop Loss to breakeven, you can also have a look at the hoc-trade analysis &ldquo;Profit secure, payoff from reverting trades&rdquo;. This analyses tells you what would be the&nbsp;<strong>difference to your current profit in case you would have set your Stop Loss to breakeven at certain profit levels in every trade that reaches these profit levels.</strong>&nbsp;As a result, you as a trader know at how many pips profit (in case of a Forex trader) would I be best off to secure my trade. In this example, the trader could have increased the performance by 0.15% per trade or 2,691 USD overall in case a Stop Loss at breakeven would have been set every time a trade reached 16 pips profit.</p>
    
    <p><strong>3. Leverage technology to assist you</strong></p>
    
    <p>There are plenty of trading journals and analytics tools available for traders which support you in tracking and analyzing your trading data and derive the effect of trading biases on your performance.&nbsp;<strong>Visualizing and periodically re-visiting the effect of those biases</strong>&nbsp;very much helps keeping them top of your mind and deriving strategies on how to improve. The hoc-trade AI and the charts I&rsquo;ve shown you before serve a similar purpose by looking at very specific dimensions of your trading performance and behavior. It is important to broaden your view also when analysing data, such as in the example of cutting your loss trades early. Only looking at this chart gives you some insights, however including the dimensions of having a loss trade beforehand, so pretty much a trigger point for your situational Pessimism Bias, adds a complete new dimension to the analysis and can produce a much more holistic view.<br />
    If you would like to test the hoc-trade AI or are interested in discussing further, please feel welcome to&nbsp;<a href="https://discord.com/invite/hcvytJnb5U" rel="noopener ugc nofollow" target="_blank">join our Discord server</a>. We are currently testing hoc-trade in a closed setting on the Discord server before making it available publicly.</p>
    
    <p><strong>Some closing remarks</strong></p>
    
    <p>I hope you enjoyed this quick snapshot into the Pessimism Bias, how it can affect trader&rsquo;s behavior and what you as a trader can do in order to soften its impact. As already said initially, specific trading biases do not apply to everyone, as our individual characteristics and experiences differ. Moreover, behavioral biases oftentimes interplay with each other. The same applies to the Pessimism Bias too. Actually, there also is an Optimism Bias, which can even co-exist in the same person and the dominance of the two depend on the situation. We will cover the Optimism Bias in another article.<br />
    The Pessimism Bias itself may strongly impact your profit taking behavior, which is oftentimes overlooked in trading, because much focus is placed on finding the right entry and reducing losses. However, losses are only half of the equation, and in order to still be profitable while accepting our losses, we need to make sure our profits are big enough to outweigh our losses.&nbsp;<strong>So, don&rsquo;t let the glass be half empty but half full, don&rsquo;t discount your winning probabilities, and win big!</strong></p>
    
    <p>Thank you very much for reading, happy trading, and stay safe!</p>`
  },
  {
    id: "crypto-specific-risk-management-factors-in-trading-trading-academy",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*5xt7mhunHYGYRIZbStGCZw.png",
    title: "Crypto-specific Risk Management Factors in Trading (Trading Academy)",
    clue: "This article is Part 6, Crypto-specific Risk Management Factors of our Trading Academy series on Risk Management in Crypto Trading. See...",
    tags: ["Risk Management", "Trading", "Cryptocurrency", "Day Trading", "Crypto Trading"],
    time: "28-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `Cover — Risk Management in Crypto Part 6`,
    content: `<p>This article is<strong>&nbsp;Part 6, Crypto-specific Risk Management Factors&nbsp;</strong>of our Trading Academy series on Risk Management in Crypto Trading.</p>

    <p>Cover &mdash; Risk Management in Crypto Part 6</p>
    
    <p>See below the risk management framework developed by us. In the previous articles, we covered all important factors for general Risk Management in Trading already. This article is&nbsp;<strong>dedicated to some specifics for Crypto-Trading.</strong></p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*9tZXy2UaYvmv8CtXOGdu-Q.png" style="height:373px; width:700px" /></p>
    
    <p>hoc-trade Risk Management Framework</p>
    
    <p>While a large share of risk management is the same no matter which market you are trading, crypto does have some specifics traders need to be aware of. There is three topics I would like to point out: Slippage, Funding Rate, and Correlation.</p>
    
    <p>First,&nbsp;<strong>slippage:</strong>&nbsp;The crypto market can be much less liquid with little market depth compared to other trading markets such as stocks, commodities, or Forex. Depending on which exchange and which coin you are trading, a proper crypto risk management should account for the additional slippage you may face to exit your trade. Slippage varies a lot depending on your trading volume, the exchange, and the coin, therefore generalizing how much buffer you need to give is impossible.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*O523eel9tDihHEihrn-Xhw.png" style="height:372px; width:700px" /></p>
    
    <p>Slippage in Crypto Trading: Risk Management</p>
    
    <p>The best approach is to look at your past trades, see your trigger price for the SL and the actually executed price, and calculate how much slippage you faced.</p>
    
    <p>Second is&nbsp;<strong>funding rate:</strong>&nbsp;When trading crypto futures, you may gain or lose on the funding rate, depending whether you are long or short positioned. This is somewhat comparable to your overnight payments in leveraged Forex trades, but the funding rate will change all the time, it is not a fixed amount you can calculate with as in Forex.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*4XhSdDOOnfOgxlU3mmErKg.png" style="height:372px; width:700px" /></p>
    
    <p>Funding Rate in Crypto Trading: Risk Management</p>
    
    <p>The funding rate depends on whether the spot price is above or below the futures price, so sometimes you may gain in a long trade, while other times you may pay the funding rate in a long trade. As funding rate development is not really predicable, you&nbsp;<strong>need to monitor it as a trader</strong>&nbsp;in case you are holding positions for the long term. This is especially an important risk management point for swing traders, which may hold their positions for weeks or months.</p>
    
    <p>Third,&nbsp;<strong>correlation</strong>: In crypto trading, there is a strong correlation of pretty much any coin to Bitcoin. If Bitcoin goes up, all other coins follow to some extent, and the same applies to short movements. Thinking about our risk per trade again, we defined that risk per trade accounts for all trades in one asset. If you are trading Bitcoin, Ethereum, and maybe some other altcoins at the same time, you wouldn&rsquo;t combine all of them in the same risk per trade,&nbsp;<strong>but you need to be aware that they will all move together to some extent depending on what Bitcoin is doing</strong>.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*ANqUY19u_Q5JmMsP1SxoQg.png" style="height:372px; width:700px" /></p>
    
    <p>Crypto Asset Correlation Discount: Risk Management</p>
    
    <p>Therefore, when defining your max risk for all open positions, crypto traders&nbsp;<strong>should apply a discount&nbsp;</strong>compared to their defined risks from other markets, as the correlation of the assets you trade is much larger. If you want to check the correlations of the assets you trade, you can just check the crypto correlations matrix (<a href="https://cryptowat.ch/correlations" rel="noopener ugc nofollow" target="_blank">see here for the link</a>).</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*_hYzn0Xjti2KqzNVj5OKvg.png" style="height:354px; width:700px" /></p>
    
    <p>Crypto Asset Correlation (Source: CryptoWatch)</p>
    
    <p><strong>&mdash; &mdash; A closing note for this Crypto Risk Management Series &mdash; &mdash;</strong><br />
    As you can see throughout the articles we published, there is many layers to risk management in crypto trading, and it is&nbsp;<strong>important to not rush into setting your approach and values in stone</strong>.&nbsp;<strong>Risk management is very much a constantly evolving process</strong>, learning new things, risk appetites change or new strategies are added. Many traders have different sets of risk management depending on which strategy, which market, or which market environment they are trading.<br />
    I hope you enjoyed this article series and can take some tangible input for your own trading. If you did, please leave us a like and follow to our channel, there is much more trading content to come. As said earlier in previous articles, please feel welcome to&nbsp;<a href="https://discord.com/invite/hcvytJnb5U" rel="noopener ugc nofollow" target="_blank">join our Discord server</a>&nbsp;as well to get a first dips on the hoc-trade AI we are building and discuss on any of the content you find here in these articles.</p>
    
    <p>If you would like to&nbsp;<strong>leverage AI for Risk Management</strong>&nbsp;in Trading, please also see our recent article on this&nbsp;<a href="https://hoc-trade.medium.com/daytrading-leverage-ai-for-risk-management-7403b8f9a46f?source=friends_link&amp;sk=e8466dc762a9cafea417bae949e0ff49" rel="noopener">here</a>.</p>
    
    <p>Thank you for reading and happy Trading!</p>
    
    <p><em>Please note that none of the above should be considered financial advice! Please always do your own research!</em></p>`
  },
  {
    id: "trading-psychology-the-number-one-behavioral-bias-to-tackle-as-a-trader-blind-spot",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*SOMBnx8lIF1-qhUijlnrTA.png",
    title: "Why is the Blind Spot Bias the #1 Bias to improve in Trading?",
    clue: "As a trader, no matter which market, we will come to the point to realize that trading is a mind game, much more than technical...",
    tags: ["Trading Psychology", "Trading Strategy", "Day Trading", "Trading Education", "Trading Mindset"],
    time: "27-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `Blind Spot Bias in Trading`,
    content: `<p>As a trader, no matter which market, we will come to the point to realize that&nbsp;<strong>trading is a mind game</strong>, much more than technical analysis, price action, indicators, etc.. The brain can be our biggest strength in trading, however at the same time can also be our biggest weakness. We are all victim to behavioral biases, which naturally lead us to certain decisions or actions that are oftentimes destructive to our trading performance.</p>

    <p>While there are many biases that can explain a large share of traders decisions, there is one behavioral bias standing out.&nbsp;<strong>The Blind Spot Bias!&nbsp;</strong>In the process of working on your own psychological strengths as a trader, it is impossible to neglect this bias, as you can only move forward when being aware of and accepting this bias.</p>
    
    <p>Before we deep-dive what the Blind Spot Bias is and how it affects our trading, let me share one thing about Trading Biases which I believe is extremely important, comforting, but also challenging all at the same time:</p>
    
    <p><strong>We ALL have behavioral biases</strong>, it is nothing we could have controlled early in life but we failed, so we have them now&hellip; It is completely normal to have behavioral biases, and we are not better or worse because we have or don&rsquo;t have a specific bias. There is no point in artificially convincing ourselves in not having XYZ bias, because if we do, we ourselves are the main one to suffer from the impact, especially in trading. Many behavioral biases come naturally, sometimes based on millions of years of evolution.<br />
    But, and here comes the twist!&nbsp;<strong>It is extremely powerful to be aware of&nbsp;</strong>your own biases,&nbsp;<strong>acknowledge</strong>&nbsp;them,&nbsp;<strong>understand</strong>&nbsp;<strong>their</strong>&nbsp;<strong>impact</strong>&nbsp;on our own behavior, and take the necessary actions to prevent the impact if we derive the impact of that bias is negative. The Blind Spot Bias is a perfect example for this, so let&rsquo;s dig-in what it is:</p>
    
    <p><strong>The Blind Spot Bias: What is it?</strong></p>
    
    <p>The Blind Spot Bias in essence says, that&nbsp;<strong>we tend to believe that we are less prone to cognitive biases than the people around us</strong>. So, while we believe that other people have cognitive biases, we less frequently see that we have them ourselves. As the name already suggests, we have a blind spot on ourselves when judging behavioral biases.</p>
    
    <p>Biases are by definition unconscious, and the blind spot bias is formed by an introspective illusion, thereby leading people to believe that they are more rational and bias-free compared to others. Let&rsquo;s not get too deep to the psychological roots of this, there are many available resources online which you can check, but let&rsquo;s rather focus on how the bias will affect you as a Trader.</p>
    
    <p><strong>How does the Blind Spot Bias affect traders?</strong></p>
    
    <p>If a trader does not acknowledge that he or she indeed has biases that lead to certain decisions in trading, that trader will never be truly working on these biases and flip them to their upside, but rather keep all those destructive trading patterns caused by them. There are many known behavioral biases and fallacies which provably have a strong impact on the traders&rsquo; behavior,&nbsp;<a href="https://hoc-trade.medium.com/are-you-victim-to-the-gamblers-fallacy-in-trading-24d5546f2247?source=friends_link&amp;sk=fd58c809b46f9e36f0e65f9ec239dbb1" rel="noopener">the gambler&rsquo;s fallacy</a>, anchoring, conservatism bias, house money effect, overconfidence bias, recency bias, conjunction fallacy just to name a few&nbsp;<em>(let us know if you would be interested in learning more about those),&nbsp;</em>but the&nbsp;<strong>Blind Spot Bias is really your entry point&nbsp;</strong>to even start working on all of those.</p>
    
    <p>As a result, the Blind Spot Bias should be&nbsp;<strong>the Number One bias you want to look at as a trader</strong>&nbsp;before looking at any of the other biases out there. Chances are high, that if you actually think that none of those biases apply to you, you are victim of the blind spot bias in this moment. Of course, our own thoughts and decisions based on those thoughts seem rational to us, we are the creator, we &ldquo;know&rdquo; all the connections, while we have little to no clue about those thought processes of other people. Neither makes the knowledge of those connections and thoughts ourselves less prone to biases, nor does our lack of knowledge of those thought processes from others them more prone to those biases.</p>
    
    <p><strong>What does this all mean to me as a trader?</strong></p>
    
    <p>Honestly, I would just like to encourage you to think about the blind spot bias, keep an open mind about your own biases, acknowledge them and start working on them as a way to strengthen your own psychology during trading. Other trading biases can be detected in your trading data, this is exactly what the hoc-trade AI does by finding the correlations between trading behaviors of thousands of traders, and the statistical significances to your trading performance,&nbsp;<strong>but the Blind Spot Bias does not apply to this. It is in a way overarching to all the other biases, and therefore as the title states, your Number One bias to look at.</strong></p>
    
    <p>Thank you for reading, happy trading, and stay safe!</p>
    
    <p><em>Everything said in this article is only a personal opinion and should by no means be interpreted as financial advice!</em></p>`
  },
  {
    id: "scale-in-and-scale-out-of-crypto-trades-with-powerful-risk-management-trading-academy",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*mj7-KhjuYrbk4q1MQl8biQ.png",
    title: "Scale-in and Scale-out of Crypto Trades with powerful Risk Management (Trading Academy)",
    clue: "This article is Part 5, Scaling-in & out of Trades of our Trading Academy series on Risk Management in Crypto Trading. See below the risk...",
    tags: ["Risk Management", "Trading", "Cryptocurrency", "Day Trading", "Crypto Trading "],
    time: "27-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `Cover — Risk Management in Crypto Part 5`,
    content: `<p>This article is<strong>&nbsp;Part 5, Scaling-in &amp; out of Trades&nbsp;</strong>of our Trading Academy series on Risk Management in Crypto Trading.</p>

    <p>See below the risk management framework developed by us, which we will use use as guide to walk you through the different aspects of risk management in crypto trading:</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*2irnsSzUIvducG-VdYGUSg.png" style="height:370px; width:700px" /></p>
    
    <p>hoc-trade Risk Management Framework</p>
    
    <p>In this fifth chapter, and before looking at some crypto trading specific risk management aspects in the next chapter, let&rsquo;s have a look at risk management strategies during a specific trading approach, scaling in and out of trades. One more&nbsp;<strong>advanced trading strategy</strong>, but one used by many big and successful traders, is to&nbsp;<strong>build bigger position sizes without increasing their risk per trade</strong>, thereby growing their potential gains in a trade, while limiting it to the same amount of risk. This strategy very well fits one of the most important trading principles very well, cut your losses early while letting your profits run&hellip;</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*B_Su3N-7YlDOgx_ZC4PvHg.png" style="height:648px; width:700px" /></p>
    
    <p>&hellip;or as Warren buffet already said, &ldquo;<strong>when it&rsquo;s raining gold, reach for a bucket, not a thimble</strong>&rdquo;.<br />
    What is meant by this? If you catch a winning trade, make sure to win big with this trade. Actually, many traders do exactly the opposite, they cut their winning trades early in the fear of losing their profit again, while they add even more to their loss trades as they cannot accept the loss and move on. This is one of the major problems of traders, but this will goes beyond risk management and will be part of other articles to follow.<br />
    For risk management, I want to talk about the strategy of adding to your profit trade, while downsizing your loss trade. On the profit side, you can have&nbsp;<strong>trigger points&nbsp;</strong>as to when add more positions to your already profitable trade, thereby winning big if it becomes a strong profit trade. In risk management, many traders work with&nbsp;<strong>breakeven, so a zero payoff for your trade.</strong></p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*LEIM27WX48Vsy0tCY0W4lQ.png" style="height:372px; width:700px" /></p>
    
    <p>Scaling-in to profit trades graphic</p>
    
    <p>Let&rsquo;s say you bought 1000 USD worth of Bitcoin with your Stop Loss at 1%, or 10 USD risk. If Bitcoin is running long now from 30.000 USD per coin to 30.300, you are 1% in profit. To grow that position, you can add another 1000 USD Bitcoin position with a new SL for both trades at 30.150 USD. If the price decreases after that to 30.150 USD, you made 5 USD on your first trade, while you lost 5 USD on your second trade, so overall breakeven.<br />
    &mdash; &mdash; &mdash; &mdash;<br />
    <strong>You are NOT adding additional risk to you trade!<br />
    &mdash; &mdash; &mdash; &mdash;</strong><br />
    However, if it continues to go long, so as Warren Buffet would say it&rsquo;s raining gold, at 30.600 USD, so 1% further increase, you are now at a profit of 30 USD, not 20 USD in case you would have had only 1 position. You can now add another position to it and set your combined SL at 30.300 for breakeven. As you can imagine, you can build that position bigger and you can also vary to position sizes of the trades you add, whether you want to give the some more or less room for the price to fluctuate so you aren&rsquo;t stopped out too easily.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*HJ0ob3Z-qQ6Bs4AxYfAWHw.png" style="height:372px; width:700px" /></p>
    
    <p>Scaling-out of loss trades graphics</p>
    
    <p>The same concept also applies to trades that go into loss!&nbsp;<strong>If you are scaling out of loss positions, you can actually trade bigger position sizes from the beginning</strong>, as you will reduce the position in case the trade doesn&rsquo;t work out your way. You can think of this concept with these reverse pyramids as shown in the graphics, representing your position sizes depending on how the trade goes.<br />
    Scaling-in and scaling-out is actually<strong>&nbsp;one of the most powerful strategies in trading</strong>, you are acting in accordance with the most important trading principles and do pretty much the opposite than most of the traders out there (the majority of traders loses money in trading!).&nbsp;<strong>Risk management is at the core of this strategy, and you can use it in your favor.</strong>&nbsp;This was a bit more advanced theory about risk management now, if you are interested in more of those, let us know down in the comments.</p>
    
    <p><strong>We will release our Risk Management series step-by-step!</strong>&nbsp;The next article will be on Crypto-specific Risk Managemenet aspects. If you are interested, please give us a follow and get notified as soon as the next article is uploaded.</p>
    
    <p>If you would like to&nbsp;<strong>leverage AI for Risk Management</strong>&nbsp;in Trading, please also see our recent article on this&nbsp;<a href="https://hoc-trade.medium.com/daytrading-leverage-ai-for-risk-management-7403b8f9a46f?source=friends_link&amp;sk=e8466dc762a9cafea417bae949e0ff49" rel="noopener">here</a>.</p>
    
    <p>Thank you for reading and stay tuned for the next update!</p>
    
    <p><em>Please note that none of the above should be considered financial advice! Please always do your own research!</em></p>`
  },
  {
    id: "what-are-your-risk-management-weaknesses-in-crypto-trading-trading-academy",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*6XCalLy3h3TC1xgiINEWag.png",
    title: "What are your Risk Management Weaknesses in Crypto Trading? (Trading Academy)",
    clue: "This article is Part 4, Risk Management Weaknesses of our Trading Academy series on Risk Management in Crypto Trading. See below th...",
    tags: ["Risk Management", "Trading", "Cryptocurrency", "Day Trading", "Crypto Trading "],
    time: "26-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `Cover — Risk Management in Crypto Part 4`,
    content: `<p>This article is<strong>&nbsp;Part 4, Risk Management Weaknesses&nbsp;</strong>of our Trading Academy series on Risk Management in Crypto Trading.</p>

    <p>See below the risk management framework developed by us, which we will use use as guide to walk you through the different aspects of risk management in crypto trading:</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*sc8SAQnpIk0u1iYY6qUp4g.png" style="height:370px; width:700px" /></p>
    
    <p>hoc-trade Risk Management Framework</p>
    
    <p>Let&rsquo;s have a look at some&nbsp;<strong>risk management related weaknesses&nbsp;</strong>that many traders show, and for this let&rsquo;s have a look at another chart here.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*PTBWCN3IA5I6iW76Zo8xUw.png" style="height:726px; width:700px" /></p>
    
    <p>hoc-trade analysis: Risk after losing trade</p>
    
    <p>A very common behavior of traders is to&nbsp;<strong>increase their risk after loss trades</strong>, which can be an extremely destructive behavior.<br />
    First of all, for every new trade, your&nbsp;<strong>position sizing should keep your trade in the defined risk range</strong>, so if you have lost capital, you also downsize your position sizes accordingly. However, we see that many traders actually do quite the opposite, and rather increase their position size, with an accelerated effect on their risk per trade. In a way, those traders are trying to &ldquo;double down&rdquo;. This behavior is actually very well researched, and its root is in what we call the&nbsp;<strong>Gambler&rsquo;s fallacy, or also called Monte Carlo Fallacy</strong>. This fallacy comes from an error in thinking a lot of people are prone to, which is that random event is more or less likely to happen based on a previous outcome.<br />
    What does that mean? Imagine you are sitting Roulette table, playing black or red. Now there is 10 times red in a row and a lot of people are starting to bet big amounts on black, because well &ldquo;this must happen now, what is the likelihood of 11 times red in a row, right?&rdquo;</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*nH74Swai-4rorzFKvESm6g.png" style="height:723px; width:700px" /></p>
    
    <p>Gambler&rsquo;s Fallacy</p>
    
    <p>Well, the 11th round doesn&rsquo;t care or know whether there was 10 times red beforehand,&nbsp;<strong>the new round has exactly the same probabilities as any other round of Roulette</strong>. Black or red is not any more or less likely to happen.</p>
    
    <p><strong>The same applies to your trading as well,&nbsp;</strong>even though you had 10 loss trades in a row, the likelihood that the market gives you a win or loss trade in the 11th is completely unrelated. However, what we see is that traders tend think there must be a higher likelihood of success now, and therefore increase their position sizes.</p>
    
    <p>This is just one of the many aspects of trading of course, and the hoc-trade AI found more and will learn even more the more trading data it gets to analyze.</p>
    
    <p>If you would like to try the&nbsp;<strong>hoc-trade AI</strong>, you are very welcome to&nbsp;<a href="https://discord.com/invite/hcvytJnb5U" rel="noopener ugc nofollow" target="_blank">join our Discord server.</a>&nbsp;We are performing a closed testing exclusive to our Discord members (free of course) before releasing it to the public.</p>
    
    <p><strong>We will release our Risk Management series step-by-step!</strong>&nbsp;The next article will be on a special strategy which has Risk Management at its core: Scaling in and scaling out of trades. If you are interested, please give us a follow and get notified as soon as the next article is uploaded.</p>
    
    <p>If you would like to&nbsp;<strong>leverage AI for Risk Management</strong>&nbsp;in Trading, please also see our recent article on this&nbsp;<a href="https://hoc-trade.medium.com/daytrading-leverage-ai-for-risk-management-7403b8f9a46f?source=friends_link&amp;sk=e8466dc762a9cafea417bae949e0ff49" rel="noopener">here</a>.</p>
    
    <p>Thank you for reading and stay tuned for the next update!</p>
    
    <p><em>Please note that none of the above should be considered financial advice! Please always do your own research!</em></p>`
  },
  {
    id: "set-risk-management-guardrails-in-crypto-trading-trading-academy",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*qFpwt8IQiK5dAvZTG6Z_yQ.png",
    title: "Set Risk Management Guardrails in Crypto Trading! (Trading Academy)",
    clue: "This article is Part 3, Setting Risk Management Guardrails of our Trading Academy series on Risk Management in Crypto Trading. See...",
    tags: ["Risk Management", "Trading", "Cryptocurrency", "Day Trading", "Crypto Trading "],
    time: "25-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `Cover — Risk Management in Crypto Part 3`,
    content: `<p>This article is<strong>&nbsp;Part 3, Setting Risk Management Guardrails&nbsp;</strong>of our Trading Academy series on Risk Management in Crypto Trading.</p>

    <p>See below the risk management framework developed by us, which we will use use as guide to walk you through the different aspects of risk management in crypto trading:</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*JKo_gv7TLJXqpxGXQVvdIw.png" style="height:373px; width:700px" /></p>
    
    <p>hoc-trade Risk Management Framework</p>
    
    <p>In this part 3, let&rsquo;s have a deep-dive into some common guardrails for your risk management. Many traders initially set their risk per trade, which is the right first step, but yet still work their way around it. If you set a risk per trade, but then open multiple positions at the same time, the whole purpose of the risk per trade is bypassed.</p>
    
    <p>First, the&nbsp;<strong>risk per trade should include all the open positions for that asset you are trading</strong>. So if you open a position, and might want to DCA into it further at later point, you may only buy 50% of your risk per trade in the first transaction. We will have a dedicated chapter about risk management for scaling in and out of trades later on in this video, but it is important to note here already that risk per trade should be the combination of all trades you have in that asset.</p>
    
    <p>Second, many traders also set a&nbsp;<strong>max. risk on all their open positions</strong>, no matter how many assets are traded at the same time. Many traders seek the excitement of large impacts on their performance, and keep adding additional positions if no big price movements are seen, however this is not only the wrong motivation to trade, as you are just seeking dopamine in this moment, this is exactly the toxic behavior that leads to many margin calls and should be prevented by a good risk management system. This is actually so important that the hoc-trade AI tracks your behavior in this.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*Bmi7HZRZYvo44t0jIn6Uog.png" style="height:362px; width:700px" /></p>
    
    <p>hoc-trade output: Risk per trade &amp; max. risk all open positions</p>
    
    <p>As you can see, hoc-trade not only tracks your risk per trade, but it also automatically tracks your&nbsp;<strong>average risk of all open positions</strong>&nbsp;at the same time. As an additional risk management enforcement method for the trader, the&nbsp;<strong>hoc-trade system actually sends your real-time alerts</strong>&nbsp;in case it detects that&nbsp;<strong>your current risk behavior deviates from your past behavior</strong>.</p>
    
    <p>Another very common risk management aspect is to set a&nbsp;<strong>maximum risk per day</strong>. As we all know, large trading profits or losses can have a big influence on our emotions, which may lead us to take even higher risks or have an irrational decision making, moreover than not making things worse. A very large loss during the day is a great example for this, as this big loss which we have in the back of our traders mind may influence our decision making. As a result, many traders set a stop point, for example 5% loss per day, at which one will just close the computer and stop trading. This can prevent us from getting stuck in this negative spiral. The hoc-trade AI actually found significant correlations for this on the trading performance, so this analysis will also be included in hoc-trade.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*1PUZbtRItl1EDzlNcncu7A.png" style="height:725px; width:700px" /></p>
    
    <p>hoc-trade analysis: Profit after daily loss</p>
    
    <p>In the behavioral category, the tool is measuring your average trade performance of trades in which you didn&rsquo;t have a big loss during the day yet vs. trades in which you had a 2 or 5% loss throughout the day already. Also here the hoc-trade AI can assist the trader by sending you real-time alerts again in case you are entering another trade even after a big daily loss, which more likely than not will worsen you performance for that day even more.</p>
    
    <p>If you would like to try the hoc-trade AI, you are very welcome to&nbsp;<a href="https://discord.com/invite/hcvytJnb5U" rel="noopener ugc nofollow" target="_blank">join our Discord server.</a>&nbsp;We are performing a closed testing exclusive to our Discord members (free of course) before releasing it to the public.</p>
    
    <p><strong>We will release our Risk Management series step-by-step!</strong>&nbsp;The next article will be on identifying your Weaknesses in risk management in layer 3 of our risk management framework for crypto trading. If you are interested, please give us a follow and get notified as soon as the next article is uploaded.</p>
    
    <p>If you would like to&nbsp;<strong>leverage AI for Risk Management</strong>&nbsp;in Trading, please also see our recent article on this&nbsp;<a href="https://hoc-trade.medium.com/daytrading-leverage-ai-for-risk-management-7403b8f9a46f?source=friends_link&amp;sk=e8466dc762a9cafea417bae949e0ff49" rel="noopener">here</a>.</p>
    
    <p>Thank you for reading and stay tuned for the next update!</p>
    
    <p><em>Please note that none of the above should be considered financial advice! Please always do your own research!</em></p>`
  },
  {
    id: "are-you-victim-to-the-gamblers-fallacy-in-trading",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*vk4datXAhVf4KtnMYNlXNw.png",
    title: `Are you victim to the Gambler's Fallacy in Trading?`,
    clue: "The human mind is something incredibly powerful, but at the same time prone to a wide range of biases. We can be grateful for this extremely powerful machine up there in our head, cherish it, and tra...",
    tags: ["Trading Education", "Trading", "Trading Psychology", "Day Trading", "Behavioral Science"],
    time: "24-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: " ",
    content: `<p>The human mind is something incredibly powerful, but at the same time prone to a wide range of biases. We can be grateful for this extremely powerful machine up there in our head, cherish it, and train it, however we need to be aware of the tricks our mind is playing with us. This holds true for many areas of life, and&nbsp;<strong>is especially visible in Trading</strong>! Common biases can explain a lot of destructive trading behavior, our mind is simply not wired to be a great Trader. Knowing and accepting that you are prone to those biases (as we all are), is the first step to overcome them and use them to our favor in trading.</p>

    <p>If you accept that you may have those biases and are eager to work on them;&nbsp;<strong>Congratulations, you just successfully overcame the first</strong>: The &ldquo;blind spot bias&rdquo;, which describes the common believe that we are less prone to behavioral biases than the people around us.</p>
    
    <p>At hoc-trade, we cover many behavioral trading biases through the analytics our AI covers, but today I would like to discuss one very very dangerous one in more detail: The Gambler&rsquo;s Fallacy, or also called Monte Carlo Fallacy. It may be the behavioral bias that created more margin calls than any other bias.</p>
    
    <p><strong>First of all: What is the Gambler&rsquo;s Fallacy?</strong></p>
    
    <p>The Gambler&rsquo;s fallacy describes the tendency of humans to think that a random event is more or less likely to happen based on a previous outcome. Sounds a bit theoretical, right? Let me give you an example:</p>
    
    <p>Imagine you are sitting at a Roulette table, playing black or red.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*C7c4vXYOEu6nF1mbCFTHJg.png" style="height:565px; width:700px" /></p>
    
    <p>Now there is 10 times red in a row and a lot of people are starting to bet big amounts on black, because &ldquo;well this must happen now, what is the likelihood of 11 times red in a row, right?&rdquo;<br />
    Well, the 11th round doesn&rsquo;t care or know whether there was 10 times red beforehand, the new round has exactly the same probabilities as any other round of Roulette.&nbsp;<strong>Black or red is not any more or less likely to happen!</strong></p>
    
    <p><strong>How does the Gambler&rsquo;s fallacy relate to your Trading?</strong></p>
    
    <p>The very same thought process also applies to your trading. Even though you had 10 loss trades in a row, the likelihood that the market gives you a win or loss trade in the 11th is completely unrelated. However, what we see is that&nbsp;<strong>traders tend think there must be a higher likelihood of success now, and therefore increase their position sizes.</strong></p>
    
    <p>In a situation like this, 2 very dangerous trading behaviors come together:<br />
    1. Increase in risk per trade (position size)<br />
    2. Emotion-guided trading decisions</p>
    
    <p>Imagine your emotional state after having a loss streak of 10 trades, which is likely dominated by strong feelings of anger, fear, aggression, etc. (everyone creates different emotions) combined with the thought that the next trade is more likely to be profitable. A slightly increased risk with some not 100% thought-through trades is likely still a great performance at this moment, however&nbsp;<strong>all-in trades or total capitulation are unfortunately not uncommon in this moment.</strong></p>
    
    <p><strong>What to do as a Trader to prevent falling for those biases?</strong></p>
    
    <p>The first big step is actually to know these biases, know what might happen and how you yourself react in situations like this. The next time you are in a situation like this, you may think back to this article remembering &ldquo;hey, didn&rsquo;t I read something about this&rdquo;? If so, then you are already reviewing your own thought process in this moment!</p>
    
    <p>A second step is to use a Trading tool such as the hoc-trade AI. The tool constantly analyses your trading behavior and will send you a&nbsp;<strong>real-time alert</strong>&nbsp;in case you deviate from your past behavior. In terms of the Gambler&rsquo;s Fallacy, which might lead you to believe that you should increase your risk, it will send you an alert in case it detects a strong increase from your historic risk level. You are also able to check how you tend to behave after multiple loss trades by checking your dashboard including this chart&hellip;</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*PTBWCN3IA5I6iW76Zo8xUw.png" style="height:726px; width:700px" /></p>
    
    <p>&hellip;and see whether you actually tend to increase your risk after a few loss trades.</p>
    
    <p>I hope you enjoyed this quick snapshot into one of the most common trading biases.</p>
    
    <p>Happy trading and stay safe!</p>
    
    <p><em>Please note that none of the above should be considered financial advice! Please always do your own research!</em></p>`
  },
  {
    id: "whats-your-risk-per-trade-as-a-crypto-trader-trading-academy",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*J216cCB3vTQJoQqjk9Dv3A.png",
    title: `What’s your risk per trade as a Crypto Trader? (Trading Academy)`,
    clue: "This article is Part 2, risk/ trade as foundation of our Trading Academy series on Risk Management in Crypto Trading. See below the risk...",
    tags: ["Risk Management", "Trading", "Cryptocurrency", "Day Trading", "Crypto Trading"],
    time: "24-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `Cover — Risk Management in Crypto Part 2`,
    content: `<p>This article is<strong>&nbsp;Part 2, risk/ trade as foundation&nbsp;</strong>of our Trading Academy series on Risk Management in Crypto Trading.</p>

    <p>See below the risk management framework developed by us, which we will use use as guide to walk you through the different aspects of risk management in crypto trading:</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*fArZBD3jgLgmZyua3vtnEg.png" style="height:373px; width:700px" /></p>
    
    <p>hoc-trade Risk Management Framework</p>
    
    <p>All previous and following articles can be found on our page. Please give us a follow if you&rsquo;d like to stay up-to-date.</p>
    
    <p>In this first category, let&rsquo;s have a look at your risk per trade as a trader. This is the foundation for all the other additional risk management layers which are going to follow later. Therefore, we are going to take a little longer now to go through this. Risk per trade describes how much of your trading capital you are willing to risk in a single trade. Thereby, it describes how aggressive your trading approach is, as a higher risk per trade of course increases the probability of a margin call, however also increases your potential gains. Let me give you some&nbsp;<strong>guidance on the input factors that may influence your risk per trade:</strong>&nbsp;There are three very important ones:</p>
    
    <p><strong>1) Your trading style:</strong>&nbsp;So are you a scalper, a daytrader, or a swingtrader?</p>
    
    <p><strong>2) Your hit ratio</strong>, so the share of your profitable trades, and</p>
    
    <p><strong>3) Your risk appetite</strong></p>
    
    <p>Let&rsquo;s go step by step, and combine all those factors in the end:</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*JPeSBD03DERipolSHPqgbA.png" style="height:373px; width:700px" /></p>
    
    <p>Risk per trade: Trading style</p>
    
    <p>First, your&nbsp;<strong>trading style</strong>. Usually, scalpers, daytraders, and swingtraders set different risks per trade, as the behaviors differ a lot. A scalper mostly has a lot of trades per day, very short time of holding the position, and exit with a small profit. A daytrader usually closes the position within the day, and has a medium amount of trades per day, while a swingtrader may only have a few trades per month, however with bigger position sizes. As you can imagine, a daytrader with 100 trades per month vs. a swingtrader with maybe only 5 trades a month would risk different shares of their capital on a single trade.</p>
    
    <p>In the second point, let&rsquo;s have a look at the&nbsp;<strong>hit ratio</strong>, which is also closely interlinked with the&nbsp;<strong>return risk ratio (RRR)&nbsp;</strong>a trader aims for. Let me give you an example:</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*xJAo-YVZeza9vQhpWCsGmw.png" style="height:373px; width:700px" /></p>
    
    <p>Hit ratio Example &amp; sensitivity</p>
    
    <p>Trader A has a hit ratio of 90% with a Return Risk Ratio of 0.33. Hence, the profit expectancy is 2. In 9 trades, we will make a profit of 9*0.33, equals 3R, and one trade will be -1R.<br />
    Trader B has a hit ratio of 20% with a Return Risk Ratio of 5. The profit expectancy is also 2, just as for trader A. In 2 trades, the trader will make 10R, and in the other 8 trades lose 1R.</p>
    
    <p>While the overall profit expectancy is the same, the&nbsp;<strong>sensitivity on the high Return Risk Ratio is much higher</strong>. If trader B has one winning trade less, he or she will stand at -4R, while if Trader A has one winning trade less, he or she will still stand at 0.66R positive! This is of course rather edge case examples, but it nicely showcases the importance of properly weighing your risks depending on your hit ratio. If trader B had a 5% risk per trade set-up, he or she would be standing at -20% loss instead of the anticipated 10% after just 10 trades, easily entering a loss spiral.</p>
    
    <p>Point 3 is your&nbsp;<strong>risk appetite</strong>. Are you ok with a 50% likelihood to run into a margin call? Or do you want to risk to get to 5% or even less. The more you decrease the risk, the lower will be your potential gains, it is all a risk reward equilibrium. Oftentimes we see that new traders set their risk levels very very high, risking 5% or more on their trades, and then it only takes a slight deviation from the anticipated returns, and the trader is at a 50% loss or so, from where on the trader has likely difficulties to make rational decisions and may jump to gambling.<br />
    And I can tell you one thing, we work a lot with probabilities in trading, but&nbsp;<strong>deviations from probabilities are completely normal and statistically meant to happen</strong>. If you have a risk of 5% per trade, and let&rsquo;s say flip a coin 100 times. It is not very unprobable that you&rsquo;ll lose 55 times and only win 45 times, which would mean you are at a 50% loss with 10 more losses than wins. Actually, the probability for 55 OR MORE loss trades in such a scenario of 100 coin flips is nearly 20%.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*4SCgC_VA0jrf_LGCRYUfCw.png" style="height:654px; width:700px" /></p>
    
    <p>Source: Omnicalculator</p>
    
    <p><strong>So 20% likelihood you loose half or more than half your capital every 100 trades</strong>. At one point it will happen if you do this over and over again. This is a bit of a theoretical example, but extremely important. You see many traders that, even just statistically,&nbsp;<strong>set themselves up for failure with such high risk per trades.</strong></p>
    
    <p>Eventually, it is really up to you as the trader how high you set your risk per trade, and I wouldn&rsquo;t jump into conclusions here right away, as it is also an evolving process. But, I can give you a few&nbsp;<strong>benchmarks of risks per trade</strong>&nbsp;from other traders.</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*nY6UeLJeJ5UE3hUwOMthMg.png" style="height:373px; width:700px" /></p>
    
    <p>Risk/ trade benchmarks</p>
    
    <p>Just know, this is no financial advice and you&rsquo;ll have to come up with your own and decide what you feel comfortable with.<br />
    For a scalper with a high hit rate, 1&ndash;2% risk per trade are a good benchmark, while for a daytrader with a much lower hit ratio, rather 0.5% to 1% are on the conservative side. For a swing trader, the spread is a little higher, a good benchmark here would be 1&ndash;3%.</p>
    
    <p><strong>We will release our Risk Management series step-by-step!</strong>&nbsp;The next article will be on the &ldquo;Guardrails&rdquo; of risk management in layer 2 of our risk management framework for crypto trading. If you are interested, please give us a follow and get notified as soon as the next article is uploaded.</p>
    
    <p>If you would like to&nbsp;<strong>leverage AI for Risk Management</strong>&nbsp;in Trading, please also see our recent article on this&nbsp;<a href="https://hoc-trade.medium.com/daytrading-leverage-ai-for-risk-management-7403b8f9a46f?source=friends_link&amp;sk=e8466dc762a9cafea417bae949e0ff49" rel="noopener">here</a>.</p>
    
    <p>Thank you for reading and stay tuned for the next update!</p>
    
    <p><em>Please note that none of the above should be considered financial advice! Please always do your own research!</em></p>`
  },
  {
    id: "risk-management-introduction-for-crypto-traders-trading-academy",
    coverPhoto: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*m8eHpK4UM1fkm1TTgOWuhQ.png",
    title: `Risk Management Introduction for Crypto Traders (Trading Academy)`,
    clue: "Having a sound risk management in place and following it is really one of those make or break aspects in crypto trading. It is probably the...",
    tags: ["Risk Management", "Trading", "Cryptocurrency", "Day Trading", "Crypto Trading"],
    time: "23-Apr-2023",
    author: "hoc-trade - AI-powered Trading Support",
    caption: `Cover — Risk Management in Crypto: Part 1`,
    content: `<p>Having a sound&nbsp;<strong>risk management</strong>&nbsp;in place and following it is really one of those&nbsp;<strong>make or break aspects</strong>&nbsp;in crypto trading. It is probably the single most important aspect in trading, and I haven&rsquo;t seen even one trader to sustainably make profits in trading without risk management.<br />
    As the name already suggests, risk management is about managing your risk exposure, knowing when to add or stop adding risk, and the major goal of it is&nbsp;<strong>capital preservation</strong>! You can make thousands or millions of dollars over many trades, but if you don&rsquo;t manage your risk, you may lose it all over just one trade. What helped me is to think of risk management as the guard rails in which I can move, and under no circumstances I am allowed to cross those guard rails as I will likely crash.</p>
    
    <p>This article is<strong>&nbsp;Part 1, the introduction&nbsp;</strong>of our Trading Academy series on Risk Management in Crypto Trading. See below the risk management framework developed by us, which we will use use as guide to walk you through the different aspects of risk management in crypto trading:</p>
    
    <p><img alt="" src="https://miro.medium.com/v2/resize:fit:770/1*9tZXy2UaYvmv8CtXOGdu-Q.png" style="height:373px; width:700px" /></p>
    
    <p>Risk management framework hoc-trade</p>
    
    <p>As you may notice, our risk management framework is build as a pyramid, which means two things:</p>
    
    <p>1. The aspects listed at the bottom, the widest part of the pyramid,&nbsp;<strong>are the most important ones</strong></p>
    
    <p>2. The bottom of the pyramid is the foundation of the whole structure!&nbsp;<strong>The next layer on top would collapse if the layer below is not rock solid!</strong></p>
    
    <p>Consequently, we will work our way up from the foundation of risk management, the risk per trade, towards the guardrails in crypto risk management, such as risk of open positions &amp; risk per day. Next, we will cover the strategic aspects of risk management, such as identifying your own trading weaknesses or using risk management for the very powerful strategy of scaling in and out of trades. Lastly, risk management is not a one-time thing, it is always evolving depending on additional experiences gained, changes in risk appetites, changes in trading strategy or market environments.</p>
    
    <p>Risk management interlinks with many other trading aspects, such as position sizing, money management, and emotions control. Those points will be touched upon in this risk management series, however will get dedicated articles due to their importance as well.</p>
    
    <p><strong>We will release our Risk Management series step-by-step!</strong>&nbsp;So if you are interested, please give us a follow and get notified as soon as the next article is uploaded.</p>
    
    <p>If you would like to&nbsp;<strong>leverage AI for Risk Management</strong>&nbsp;in Trading, please also see our recent article on this&nbsp;<a href="https://hoc-trade.medium.com/daytrading-leverage-ai-for-risk-management-7403b8f9a46f?source=friends_link&amp;sk=e8466dc762a9cafea417bae949e0ff49" rel="noopener">here</a>.</p>
    
    <p>Thank you for reading and stay tuned for the next update!</p>
    
    <p><em>Please note that none of the above should be considered financial advice! Please always do your own research!</em></p>`
  },
];

export default blogData;