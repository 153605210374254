import { Routes, Route, Navigate } from "react-router-dom";

import General from "./general.page";
import RiskManagement from "./riskManagement.page";
import Behavioral from "./behavioral.page";
import Timing from "./timing.page";
import Strategy from "./strategy.page";
import Costs from './costs.page';

function CryptoAnalysisIndex() {
  return (
    <>
      <Routes>
        <Route path="/general" element={<General />}/>
        <Route path="/risk-management" element={<RiskManagement />}/>
        <Route path="/behavioral" element={<Behavioral />}/>
        <Route path="/timing" element={<Timing />} />
        <Route path="/strategy" element={<Strategy />}/>
        <Route path="/costs" element={<Costs />}/>
        <Route path="/*" element={<Navigate to="/general" replace />}/>
      </Routes>
    </>
  )
}

export default CryptoAnalysisIndex;