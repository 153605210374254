import { useState } from "react";
import { Link } from 'react-router-dom';

const ProgressBar = (props) => {
  const { data } = props;
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#001216",
    borderRadius: 5,
    marginBottom: 5,
  };

  const fillerStyles = {
    width: `${data.percentage}%`,
    height: 20,
    backgroundColor: isHover ? data.hoverColor : data.backgroundColor,
    borderRadius: "inherit",
    textAlign: "left",
    transition: "all 0.3s ease",
    key: data.key,
  };

  const labelStyles = {
    fontSize: 12,
    position: "absolute",
    marginTop: -20,
    marginLeft: 5,
    color: isHover ? "#FFFFFF" : data.fontColor,
    cursor: "pointer",
    transition: "all 0.3s ease"
  };

  let linkPath;
  
  switch (data.key) {
    case "Profit By daytime":
      linkPath = "/dashboard/analysis/general";
      break;
    case "Risk per trade":
      linkPath = "/dashboard/analysis/risk-management";
      break;
    case "Profit by symbol":
      linkPath = "/dashboard/analysis/general";
      break;
    case "% of positions with SL":
      linkPath = "/dashboard/analysis/risk-management";
      break;
    case "Profit if trade open":
      linkPath = "/dashboard/analysis/timing";
      break;
    default:
      linkPath = "/dashboard/overview/account-overview";
  }

  return (
    <Link to={`${linkPath}`}>
      <div
      style={containerStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={fillerStyles}></div>
      <div style={labelStyles}>{data.key}</div>
    </div>
    </Link>
    
  );
};

export default ProgressBar;
