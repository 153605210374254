import styles from "./advertisement.module.scss";

function EToroBanner() {
  return (
    <>
      <div className={styles.BannerContainer}>
        <a
          href="https://med.etoro.com/B19662_A106969_TClick.aspx"
          target="_Top"
        >
          <img
            border="0"
            src="https://med.etoro.com/B19662_A106969_TGet.aspx"
            alt=" "
          />
        </a>
      </div>
      <div className={styles.MobileBannerContainer}>
        <a
          href="https://med.etoro.com/B19654_A106969_TClick.aspx"
          target="_Top"
        >
          <img
            border="0"
            src="https://med.etoro.com/B19654_A106969_TGet.aspx"
            alt=" "
          />
        </a>
      </div>
    </>
  );
}

export default EToroBanner;
