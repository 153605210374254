import { chartKeys } from 'utils/constant';

const profit_if_tp_close_further_away = {
  chartTitle: "Profit if TP close/further away",
  chartKey: chartKeys.PROFIT_IF_TP_CLOSE_FURTHER_AWAY,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        textColor: function(hoveredData) {
          return hoveredData.usd >= 0? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        },
        unit: 'USD',
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'usdBenchmark',
        createLabelColor: function(hoveredData) {
          return "#FFB700";
        },
        textColor: function(hoveredData) {
          return '#FFB700';
        },
        unit: ""
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        }
      },
      {
        templateString: '',
        valueKey: 'profit',
        textColor: function(hoveredData) {
          return hoveredData.percentage >= 0? "#56A07E" : "#C15357"
        },
        unit: 'USD',
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function(hoveredData) {
          return "#FFB700";
        },
        textColor: function(hoveredData) {
          return '#FFB700';
        }
      }
    ]
  },
  interval: [
    '-10USD',
    '-5USD',
    '-3USD',
    '-1USD',
    'as-is',
    '1USD',
    '3USD',
    '5USD',
    '10USD'
  ],
}

export default profit_if_tp_close_further_away;