const averageTradeLength = {
  chartTitle: "Average trade length (mins)",
  barValueKey: "mins",
  chartKey: "average_trade_length",
  999: [
    {
      time: new Date("2022-04"),
      mins: 32,
      trades: 57,
    },
    {
      time: new Date("2022-05"),
      mins: 19,
      trades: 92,
    },
    {
      time: new Date("2022-06"),
      mins: 23,
      trades: 81,
    },
    {
      time: new Date("2022-07"),
      mins: 55,
      trades: 52,
    },
    {
      time: new Date("2022-08"),
      mins: 31,
      trades: 29,
    },
    {
      time: new Date("2022-09"),
      mins: 19,
      trades: 59,
    },
    {
      time: new Date("2022-10"),
      mins: 41,
      trades: 71,
    },
    {
      time: new Date("2022-11"),
      mins: 31,
      trades: 50,
    },
    {
      time: new Date("2022-12"),
      mins: 36,
      trades: 54,
    },
    {
      time: new Date("2023-01"),
      mins: 23,
      trades: 55,
    },
    {
      time: new Date("2023-02"),
      mins: 31,
      trades: 70,
    },
    {
      time: new Date("2023-03"),
      mins: 29,
      trades: 98,
    },
    {
      time: new Date("2023-04"),
      mins: 44,
      trades: 140,
    }
  ]
};

export default averageTradeLength;