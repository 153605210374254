export const CHART_INFO_MODAL = 'chart_info_modal';
export const ALERT_MODAL = 'alert_modal';
export const COMMISSION_COMPARISON_MODAL = 'commission_comparison_modal';
export const COMMISSION_COMPARISON_INFO_MODAL = 'commission_comparison_info_modal';
export const SIGN_UP_MODAL = 'sign_up_modal';
export const LOGIN_MODAL = 'login_modal';
export const ADD_TRADING_ACCOUNT_MODAL = 'add_trading_account_modal';
export const RESET_PASSWORD_MODAL = 'reset_password_modal';
export const TRADE_MEDIC_INFO_MODAL = 'trade_medic_info_modal';
export const ALERT_INFO_MODAL = 'alert_info_modal';
export const SANDBOX_TRADE_MEDIC_INFO_MODAL = 'sandbox_trade_medic_info_modal';
export const SANDBOX_COMMISSION_COMPARISON_MODAL = 'sandbox_commission_comparison_modal';
export const SANDBOX_COMMISSION_COMPARISON_INFO_MODAL = 'sandbox_commission_comparison_info_modal';
export const RESPONSIVE_TRADE_MEDIC_INFO_MODAL = "responsive_trade_medic_info_modal";