import { chartKeys } from 'utils/constant';

const break_since_last_trade_per_trade = {
  chartTitle: 'Break since last trade',
  chartKey: chartKeys.BREAK_SINCE_LAST_TRADE_PER_TRADE,
  hoverTextArray: {
    'usd': [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'usdBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        },
        unit: ""
      }
    ],
    'percentage': [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        followBarColor: true,
        unit: ' USD'
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
      {
        templateString: 'B: ',
        valueKey: 'percentageBenchmark',
        createLabelColor: function() {
          return '#FFB700';
        },
        textColor: function() {
          return '#FFB700';
        }
      }
    ]
  },
  interval: [
    '30m+',
    '20-30m',
    '10-20m',
    '5-10m',
    '0-5m',
    'ongoing'
  ],
}

export default break_since_last_trade_per_trade;