import createBarColorComparison from "components/charts/helpers/createBarColorComparison";
import createHoverBarColorComparison from "components/charts/helpers/createHoverBarColorComparison";
import createBarBorderColorComparison from "components/charts/helpers/createBarBorderColorComparison";
import { chartKeys } from 'utils/constant';

const profit_high_vs_low_volatility = {
  chartTitle: "Profit high vs. low volatility",
  chartKey: chartKeys.PROFIT_HIGH_VS_LOW_VOLATILITY,
  createBarColorComparison: createBarColorComparison,
  createHoverBarColorComparison: createHoverBarColorComparison,
  createBarBorderColorComparison: createBarBorderColorComparison,
  hoverTextArray: {
    usd: [
      {
        templateString: '',
        valueKey: 'usd',
        unit: 'USD/trade',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        unit: 'USD',
        followBarColor: true,
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ],
    percentage: [
      {
        templateString: '',
        valueKey: 'percentage',
        unit: '%',
        followBarColor: true,
      },
      {
        templateString: '',
        valueKey: 'profit',
        unit: 'USD',
        followBarColor: true,
      },
      {
        templateString: 'Trades: ',
        valueKey: 'trades',
      },
    ]
  },
  interval: [
    'High',
    'Low',
  ],
}

export default profit_high_vs_low_volatility;