import { Routes, Route, Navigate } from "react-router-dom";

import AccountOverview from "./accountOverview.page";
import BasicStatistics from "./basicStatistics.page";
import AdvancedStatistics from "./advancedStatistics.page";

import TradeHistory from "./tradeHistory.page";

function OverviewIndex() {
  return (
    <>
      <Routes>
        <Route path="/account-overview" element={<AccountOverview />}/>
        <Route path="/basic-stats" element={<BasicStatistics />}/>
        <Route path="/trade-history" element={<TradeHistory />}/>
        <Route path="/advanced-stats" element={<AdvancedStatistics />}/>
        <Route path="/*" element={<Navigate to="/account-overview" replace />}/>
      </Routes>
    </>
  )
}

export default OverviewIndex;